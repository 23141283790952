function RoleBadge({ roles, classes = '' }) {
  const colors = {
    funnels: 'bg-orange-100 text-orange-800 border',
    'magic-links': 'bg-blue-100 text-blue-800',
    analytics: 'bg-gray-400 text-gray-100 border',
    heatmaps: 'bg-red-100 text-red-800',
    'link-account': 'bg-gray-100 text-gray-800',
    ppcai: 'bg-purple-200 text-purple-900',
    'all roles': 'bg-orange-100 text-orange-800',
  };
  const roles_ = roles[0] === '*' ? ['all roles'] : roles;

  return (
    <div className={classes}>
      {roles_.map((r) => (
        <span
          key={r}
          className={`inline-flex mr-1 items-center px-2 py-0.5 rounded-full text-xs font-medium ${colors[r]}`}
        >
          {r}
        </span>
      ))}
    </div>
  );
}

export default RoleBadge;
