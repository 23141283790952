import { Badge } from '@mantine/core';
import React, { ReactElement, useEffect, useState } from 'react';

export const TabBadge = ({ max, value }: { max: number; value: number }): ReactElement => {
  const [color, setColor] = useState<string>('gray');

  useEffect(() => {
    if (value === max) {
      setColor('green');
    } else if (value !== max && value !== 0) {
      setColor('yellow');
    } else if (value === 0) {
      setColor('gray');
    }
  }, [value]);

  return (
    <Badge w={16} h={16} sx={{ pointerEvents: 'none' }} variant="filled" size="xs" p={0} color={color}>
      {value}
    </Badge>
  );
};
