import { setReportsParams } from 'pages/users/analytics/analytics-ads.slice';
import { ReportsByDateParamsInterface } from 'pages/users/analytics/analytics.interface';
import { store } from 'redux/store';
import { postSilentFetch } from 'request/request';
import { getApiUrl } from 'request/request.helpers';

export const getReportsByDate = async (values: ReportsByDateParamsInterface): Promise<void> => {
  const ads = await postSilentFetch('/ads/reports', values);
  store.dispatch(setReportsParams({ ...values, data: ads }));
};

export const exportReportsByDate = (reportValue: ReportsByDateParamsInterface): void => {
  window.location.href = getApiUrl(
    `/ads/reports/export/?startDate=${reportValue.startDate}&endDate=${reportValue.endDate}&amazonAdsUserId=${reportValue.amazonAdsUserId}`
  );
};
