import { getSilentFetch, postSilentFetch, putFetch } from 'request/request';
import { store } from 'redux/store';
import { setAccounts } from 'pages/admin/sqp/accounts/accounts.slice';
import { AccountsInterface } from '../../../../interfaces/accounts.interface';

export const getAccounts = async (): Promise<AccountsInterface[]> => {
  const res = await postSilentFetch('/sqp/admin/accounts/list', {});
  if (res) {
    store.dispatch(setAccounts(res));
  }
  return res;
};

export const addAccounts = async (values): Promise<AccountsInterface> => {
  const res = await postSilentFetch('/sqp/admin/accounts', values);
  if (res) {
    store.dispatch(setAccounts(res));
  }
  return res;
};

export const getAccountsDetails = async (id): Promise<AccountsInterface> => {
  const res = await getSilentFetch(`/sqp/admin/accounts/${id}`);
  return res;
};

export const updateAccount = async (id, values): Promise<AccountsInterface> => {
  const res = await putFetch(
    `/sqp/admin/accounts/${id}`,
    { ...values, _id: id },
    'Seller Account Updated Successfully!'
  );
  return res;
};
