import { ResponseSearchFilterInterface, SearchInterface } from 'interfaces/products.interface';
import { DefaultLabelsInterface, FunnelInterface, FunnelTemplateInterface } from 'interfaces/rebates.interface';
import { setFunnels, setFunnelsFilter, updateFunnels } from 'pages/users/funnels/funnels.slice';
import { setFunnel, updateFunnel } from 'pages/users/funnels/rebate/rebate.slice';
import { setAmazonAccount } from 'pages/users/funnels/select-product/select-product.slice';
import { store } from 'redux/store';
import { getSilentFetch, postSilentFetch, putFetch } from 'request/request';

export const loadFunnels = async (page: { page: number }): Promise<void> => {
  const res = await postSilentFetch('/funnels/page/', page);
  if (res) {
    store.dispatch(setFunnels(res));
  }
};

export const deployRebate = async (id): Promise<FunnelInterface> => {
  const res = await getSilentFetch(`/funnels/rebate/deploy/${id}`);
  if (res) {
    store.dispatch(updateFunnels(res));
    store.dispatch(updateFunnel(res));
  }
  return res;
};

export const stopFunnel = async (id): Promise<FunnelInterface> => {
  const res = await postSilentFetch(`/funnels/stop/`, { id });
  if (res) {
    store.dispatch(updateFunnels(res));
    store.dispatch(updateFunnel(res));
  }
  return res;
};

export const pauseFunnel = async (id): Promise<FunnelInterface> => {
  const res = await postSilentFetch(`/funnels/pause/`, { id });
  if (res) {
    store.dispatch(updateFunnels(res));
    store.dispatch(updateFunnel(res));
  }
  return res;
};

export const getRebateInfo = async (id, type): Promise<FunnelInterface> => {
  const res = await getSilentFetch(`/funnels/rebate/${type}/${id}`);

  if (res) {
    store.dispatch(setFunnel(res));
  }
  return res;
};

export const createFunnel = async (values): Promise<FunnelInterface> => {
  return await postSilentFetch('/funnels/create', values);
};

export const updateRebateFunnel = async (id, type, values): Promise<FunnelInterface> => {
  return putFetch(`/funnels/${type}/${id}`, values);
};

export const checkAvailability = async (id, values): Promise<unknown> => {
  return putFetch(`/funnels/site/${id}/check-availability`, values, 'Subdomain is available.');
};

export const getRebateDefaultLabels = async (id: string | undefined): Promise<DefaultLabelsInterface> => {
  const res = await getSilentFetch(`/funnels/labels/${id}`);
  return res;
};

export const getRebateLabels = async (page, id): Promise<unknown> => {
  const res = await getSilentFetch(`/funnels/labels/${page}/${id}`);
  return res;
};

export const updateRebateFunnelLabels = async (id, type, values): Promise<DefaultLabelsInterface> => {
  const res = await putFetch(`/funnels/label/${type}/${id}`, values);
  return res;
};

export const getFunnelTemplates = async (): Promise<FunnelTemplateInterface[]> => {
  return await getSilentFetch('/funnels/templates');
};

export const searchFilterFunnel = async (
  values: SearchInterface,
  sellerAccount,
  storeResult = false
): Promise<ResponseSearchFilterInterface<FunnelInterface>> => {
  const res = await postSilentFetch('/funnels/search-filter', {
    key: values?.key,
    value: values?.value,
    filters: [
      {
        key: 'sellerAccount',
        value: sellerAccount,
      },
    ],
  });
  if (res && storeResult) {
    store.dispatch(setFunnelsFilter(res));
  }

  return res;
};

export const filterFunnels = async (sellerAccount: string): Promise<ResponseSearchFilterInterface<FunnelInterface>> => {
  store.dispatch(setAmazonAccount(sellerAccount));
  const res = await postSilentFetch('/funnels/filter', {
    filters: [
      {
        key: 'sellerAccount',
        value: sellerAccount,
      },
    ],
  });

  if (res) {
    store.dispatch(setFunnelsFilter(res));
  }

  return res;
};
