import React, { createContext, ReactNode, useContext } from 'react';
import { emptyFunction } from 'lib/empty';
import { ABTestIGoalContextInterface } from 'pages/users/ab-test/abtest.interface';

const ABTestGoalContext = createContext({
  selection: '',
  setSelection: emptyFunction,
} as ABTestIGoalContextInterface);

export const ABTestGoalProvider = ({ data, children }: { data: ABTestIGoalContextInterface; children: ReactNode }) => {
  return <ABTestGoalContext.Provider value={{ ...data }}>{children}</ABTestGoalContext.Provider>;
};

export const abTestGoalContext = () => {
  return useContext(ABTestGoalContext);
};
