import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getSplitTest,
  getSplitTests,
  removeImageSplitTest,
  saveUploadResultSplitTests,
  updateGoalSplitTest,
} from 'pages/users/ab-test/abtest.thunk';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { handleUploadImageMultiple } from 'lib/helpers';

export const useABTest = (isList: boolean, page = 1) => {
  const navigate = useNavigate();
  const [goal, setGoal] = useState<string>('');
  const params = useParams();
  const id = params?.id || '';

  const {
    data: abTestData,
    refetch: reloadData,
    isFetching,
    isInitialLoading,
  } = useQuery(['abtest-image-get', id], {
    queryFn: () => getSplitTest(id as string),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== '',
    cacheTime: 0,
  });

  const { data: abTestListData, refetch: reloadListData } = useQuery(['abtest-list-get'], {
    queryFn: () => getSplitTests(page),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isList ?? false,
    cacheTime: 0,
  });

  const { mutate: deleteImage, isSuccess } = useMutation({
    mutationFn: (currentIndex: number) => {
      return removeImageSplitTest(id, currentIndex);
    },
  });

  const { mutate: updateGoal } = useMutation({
    mutationFn: () => {
      return updateGoalSplitTest(id, goal);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      reloadData();
    }

    if (goal !== '') {
      updateGoal();
    }
  }, [isSuccess, goal]);

  const uploadImage = async (e) => {
    const formData = new FormData();
    for (const file of e) {
      formData.append('files', file);
    }
    formData.append('name', e[0].name);
    formData.append('id', id);
    await handleUploadImageMultiple(formData, e, '/split-tests/ab/upload', (res) => {
      if (res && id === '') {
        navigate(`/dashboard/abtests/upload/${res._id}`);
      } else {
        reloadData();
      }
    });
  };

  const save = async (values) => {
    const data = await saveUploadResultSplitTests(values, id);
    navigate(`/dashboard/abtests/results/${data?._id}`);
  };

  return {
    id,
    navigate,
    deleteImage,
    abTestData,
    abTestListData,
    isLoading: isInitialLoading,
    reloadData,
    reloadListData,
    goal,
    setGoal,
    isFetching,
    uploadImage,
    save,
  };
};
