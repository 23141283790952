import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { CreditCardInterface } from 'pages/users/subscription/payment-method/payment-method.interface';

import { RootState } from 'redux/store';

const initialState: CreditCardInterface = {
  id: 'card',
  brand: 'visa',
  last4: 1,
  exp_month: 1,
  exp_year: 2023,
};

export const DefaultCardSlice = createSlice({
  name: 'defaultCard',
  initialState,
  reducers: {
    setCard: (state: Draft<CreditCardInterface>, action: PayloadAction<CreditCardInterface>) => {
      return action.payload;
    },
  },
});

export const { setCard } = DefaultCardSlice.actions;
export const selectDefaultCard = (state: RootState) => state.defaultCard;
export const defaultCardSliceReducer = DefaultCardSlice.reducer;
