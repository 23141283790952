import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { DayPicker, DayPickerDefaultProps, Matcher } from 'react-day-picker';
import { Field } from 'react-final-form';

// tailwind css instead of the default css
// todo move outside of this component
const classNames: DayPickerDefaultProps['classNames'] = {
  vhidden: 'sr-only',
  caption: 'flex justify-center font-bold items-center h-10',
  root: 'text-gray-800 m-2',
  months: 'flex gap-4 relative px-4',
  caption_label: 'text-xl px-1',
  nav_button:
    'inline-flex justify-center items-center absolute top-0 w-10 h-10 rounded-full text-gray-600 hover:bg-gray-100',
  nav_button_next: 'right-0',
  nav_button_previous: 'left-0',
  table: 'border-collapse border-spacing-0',
  head_cell: 'w-10 h-10 uppercase align-middle text-center',
  cell: 'w-10 h-10 align-middle text-center border-0 px-0',
  day: 'rounded-full w-10 h-10 transition-colors hover:bg-red-100 focus:outline-none focus-visible:ring focus-visible:ring-red-300 focus-visible:ring-opacity-50 active:bg-red-600 active:text-white',
  day_selected: 'text-white bg-red-500 hover:bg-red-500',
  day_today: 'font-bold',
  day_disabled: 'opacity-25 hover:bg-white active:bg-white active:text-gray-800',
  day_outside: 'enabled:opacity-50',
  day_range_middle: 'rounded-none',
  day_range_end: 'rounded-l-none rounded-r-full bg-red-700',
  day_range_start: 'rounded-r-none rounded-l-full bg-red-700',
  day_hidden: 'hidden',
};

export default function FormDaterange({
  name,
  isRightAligned,
  disabledDays,
}: {
  name: string;
  isRightAligned?: boolean;
  disabledDays?: Matcher;
}) {
  const rightAligned = isRightAligned ? 'inset-y-0 right-0 z-10 ' : 'left-1/2 -translate-x-1/2 ';
  const dateFormat = 'yyyy/MM/dd';
  let dateLabel = <p>Please pick the first day.</p>;

  return (
    <Field type="text" name={name}>
      {(props) => {
        const range = props.input.value;
        if (range?.from) {
          if (!range.to) {
            dateLabel = (
              <p>
                {format(range.from, dateFormat)} to {format(range.from, dateFormat)}
              </p>
            );
          } else if (range.to) {
            dateLabel = (
              <p>
                {format(range.from, dateFormat)} to {format(range.to, dateFormat)}
              </p>
            );
          }
        }
        return (
          <div className="w-fit">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`${open ? '' : 'text-opacity-90'}
                  group inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span>{dateLabel}</span>
                    <ChevronDownIcon
                      className={`${open ? '' : 'text-opacity-70'}
                    ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className={`absolute  mt-12 transform ${rightAligned}`}>
                      <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <DayPicker
                          classNames={classNames}
                          numberOfMonths={2}
                          disabled={disabledDays}
                          mode="range"
                          selected={props.input.value}
                          onSelect={props.input.onChange}
                        />
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        );
      }}
    </Field>
  );
}
