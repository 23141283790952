function TableWrapper({ children }) {
  return (
    <>
      <div className="flex flex-col">
        <div className=" overflow-x-auto ">
          <div className="py-4 pb-0 align-middle inline-block min-w-full ">
            <div className="">
              <table className="w-full table-auto  divide-y divide-gray-200">{children}</table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableWrapper;
