import React from 'react';
import QRCode from 'qrcode.react';

/**
 *
 * @param {string} {url - - url to be encoded
 * @param {string} width='100%'} - with of qr code
 */
const QR: React.FC<{
  url: string;
  width?: string;
}> = ({ url, width = '100%' }: any) => {
  return (
    <div className="flex items-center justify-start">
      <QRCode value={url} style={{ width, height: 'auto' }} />
    </div>
  );
};

export default QR;
