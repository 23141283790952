import React, { useEffect } from 'react';
import { LoadingOverlay, Paper } from '@mantine/core';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import TopSearchesChartFilters from './top-searches.chart-filters';
import { useTopSearchesContext } from './top-searches.context';
import useTopSearchesEvents from './top-searches.hooks';
import CustomLoader from '../ab-test/components/abtest.loader';
import { chartDataInterface } from './top-searches.interfaces';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const TopSearchesChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        reverse: true,
      },
    },
  };
  const { topSearchesStates } = useTopSearchesContext();
  const data: chartDataInterface = {
    labels: topSearchesStates.graphLabels,
    datasets: topSearchesStates.chartData,
  };
  const { loadChartData } = useTopSearchesEvents();
  useEffect(() => {
    if (topSearchesStates.chartData.length <= topSearchesStates.keywords.length) {
      loadChartData(topSearchesStates.fetchedData);
    }
  }, [topSearchesStates.fetchedData]);
  return (
    <Paper shadow="xs" p="xs" className="mt-3">
      <div className="h-full">
        <TopSearchesChartFilters />
        <div className=" w-full mt-3 relative">
          <LoadingOverlay
            visible={topSearchesStates.isLoading}
            overlayBlur={5}
            loader={<CustomLoader textMessage="Loading Data..." />}
          />
          <Line options={options} data={data} height={'100%'} />
        </div>
      </div>
    </Paper>
  );
};

export default TopSearchesChart;
