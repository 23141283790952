import { CreditCardInterface } from 'pages/users/subscription/payment-method/payment-method.interface';
import React from 'react';

export default function CreditCard({ card }: { card: CreditCardInterface }) {
  return (
    <>
      <div className="flex-1">
        <h3 className="text-sm font-bold leading-6 text-gray-700">
          {card?.brand.toUpperCase()} ending in {card?.last4}
        </h3>
        <p className="max-w-2xl text-sm text-gray-500">
          Expiry: {card?.exp_month.toString().padStart(2, '0')}/{card?.exp_year}
        </p>
      </div>
    </>
  );
}
