import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { UserDataInterface, UpdateUserDetailsInterface } from 'interfaces/user.interface';
import { PURGE } from 'redux-persist';
import { RootState } from 'redux/store';

const initialState: UserDataInterface = {
  _id: '',
  email: '',
  gravatar: '',
  isLoggedIn: false,
  roles: [],
  isAdmin: false,
  isVerified: false,
  isAdminMimickingUser: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    setUserData: (state: Draft<UserDataInterface>, action: PayloadAction<UserDataInterface>) => {
      action.payload.isLoggedIn = true;
      return action.payload;
    },
    updateUserDetails: (state: Draft<UserDataInterface>, action: PayloadAction<UpdateUserDetailsInterface>) => {
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        gravatar: action.payload.gravatar,
      };
    },
    setVerified: (state: Draft<UserDataInterface>) => {
      return { ...state, isVerified: true };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { logout, setUserData, updateUserDetails, setVerified } = userSlice.actions;

export const selectUserData = (state: RootState) => state.user;
export const userReducer = userSlice.reducer;
