import { PlusCircleIcon, UploadIcon, InformationCircleIcon } from '@heroicons/react/solid';
import FormButton from 'components/forms/form-button';
import React, { ReactElement } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Group, ActionIcon } from '@mantine/core';
import InstructionModal from './heat-map.instruction-modal';

const HeatmapHeader = ({ title = '', buttonLabel = '', handleAction }): ReactElement => {
  const Icon = buttonLabel === 'Add Group' ? PlusCircleIcon : UploadIcon;
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <InstructionModal opened={opened} close={close} />

      <div className="flex justify-between mx-auto mb-3">
        <Group position="center">
          <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900">{title}</h1>
          {/*<Button onClick={open} compact></Button>*/}
          <ActionIcon>
            <InformationCircleIcon onClick={open} aria-hidden="true" />
          </ActionIcon>
        </Group>
        <div className="flex justify-end p-1">
          {buttonLabel && (
            <FormButton
              buttonLabel={buttonLabel}
              leadingIcon={<Icon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />}
              onClick={handleAction}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HeatmapHeader;
