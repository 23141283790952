import { GroupInterface, HeatmapInterface } from 'interfaces/heatmap.interface';
import { addGroup, editGroup, setGroups } from 'pages/users/heat-maps/groups/heat-maps-groups.slice';
import { editHeatmapItem } from 'pages/users/heat-maps/heat-maps-item.slice';
import { store } from 'redux/store';
import { postFetch, postSilentFetch, putFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const loadGroups = async (values): Promise<ResponseListInterface<HeatmapInterface>> => {
  const res = await postSilentFetch('/heatmap/heatmaps/groups/page', values);
  if (res) {
    store.dispatch(setGroups(res));
  }
  return res;
};

export const addNewGroup = async (values): Promise<GroupInterface> => {
  const res = await postFetch('/heatmap/heatmaps/groups', values, 'Group Created Successfully');
  if (res) {
    store.dispatch(addGroup(res));
    store.dispatch(editHeatmapItem({ isOpen: false }));
  }
  return res;
};

export const groupEdit = async (values): Promise<GroupInterface> => {
  const { _id } = values;
  const res = await putFetch(`/heatmap/heatmaps/groups/${_id}`, values, 'Group updated Successfully');
  if (res) {
    store.dispatch(editGroup(res));
    store.dispatch(editHeatmapItem({ isOpen: false }));
  }
  return res;
};
