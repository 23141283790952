import GenericMessage from 'components/errors/generic-message';

const ConfirmEmailDone = () => (
  <GenericMessage
    title="Email Confirmation Successful!"
    desc="You can now login by clicking on the button bellow!"
    to="/"
    buttonLabel="Login"
  />
);

export default ConfirmEmailDone;
