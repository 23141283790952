import { flexRender } from '@tanstack/react-table';
import React, { ReactElement } from 'react';

export const MTableBody = (table): ReactElement => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row, i) => (
        <tr key={i}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="w-42 px-6 py-4 text-sm">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
