import { selectAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';

const useAmazonAccount = () => {
  const amazonAccounts = useAppSelector(selectAmazonAccounts);
  const [selectedAcc, setSelectedAcc] = useState(amazonAccounts[0]?._id);

  return { selectedAcc, setSelectedAcc };
};

export default useAmazonAccount;
