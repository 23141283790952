import { Text, Pagination } from '@mantine/core';
import If from 'components/conditionals/if.conditional';
import React, { useState } from 'react';
import IfElse from 'components/conditionals/if-else.conditional';
import { useQuery } from '@tanstack/react-query';
import { loadGroups } from './groups/heat-maps-groups.thunks';
import { useAppSelector } from 'redux/hooks';
import { selectGroup } from './groups/heat-maps-groups.slice';
import HeatMapsGroupItem from './heat-maps-group-item';
export default function HeatMapsGroupList() {
  const [activePage, setPage] = useState(1);
  useQuery({
    queryKey: ['heat-map-groups' + activePage],
    queryFn: async () => loadGroups({ page: activePage }),
    refetchInterval: 5000,
  });

  const { docs, totalPages, totalDocs, pagingCounter } = useAppSelector(selectGroup);
  const totalItems = docs ? docs.length : 0;
  return (
    <IfElse show={docs !== undefined && totalDocs !== 0}>
      <div className="flex-1 flex flex-col">
        <div className="text-right my-3">
          <If show={totalItems !== 0}>
            <span className="text-sm  text-slate-500">
              Showing {pagingCounter}-{Number(pagingCounter) + Number(docs.length) - 1} of {totalDocs}
            </span>
          </If>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
          >
            {docs.map((file) => {
              return <HeatMapsGroupItem file={file} key={file._id} />;
            })}
          </ul>
          <div className="w-full mt-3">
            <Pagination page={activePage} onChange={setPage} total={totalPages} className="!justify-center" />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-3 flex-1 items-center  text-slate-500 bg-white rounded-lg">
        <Text fz="lg" fw={500}>
          No heatmaps created yet.
        </Text>
      </div>
    </IfElse>
  );
}
