import { toggleSliderOver } from 'components/slide-over/slider-over.slice';
import { setCard } from 'pages/users/subscription/payment-method/default-card.slice';
import { savePaymentMethodByClientSecret } from 'pages/users/subscription/payment-method/payment-method.thunks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';

const PaymentMethodStatus = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clientSecret = new URLSearchParams(window.location.search).get('setup_intent');
  useEffect(() => {
    savePaymentMethodByClientSecret(clientSecret as string).then((card) => {
      dispatch(toggleSliderOver({ open: false }));
      dispatch(setCard(card));
      navigate('/dashboard/subscription');
    });
  }, []);

  return <>please wait..</>;
};

export default PaymentMethodStatus;
