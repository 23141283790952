import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FileUploadInterface } from '../interfaces/file-upload.interface';

export const useFileUpload = ({ fileUpload }: FileUploadInterface) => {
  const [file, setFile] = useState<File | null>(null);
  const fileUploadMutation = useMutation(fileUpload);
  useEffect(() => {
    if (file) {
      fileUploadMutation.mutate(file);
    }
  }, [file]);
  return { file, setFile, fileUploadMutation };
};
