import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import DeleteButton from 'components/dialogs-old/delete.button';
import TableRow from './table-row';
import get from 'lodash.get';
/**
 * displays table body
 * @param data - table data
 * @param deleteUrl - Delete URL
 * @param columns - array of columns to be rendered
 * @param confirmField - Column field to match text
 */
const TableBody = ({ columns, data, deleteUrl = '', confirmField = '' }): ReactElement => {
  const { pathname } = useLocation();
  const urlArr = pathname?.split('/');
  const url = urlArr[urlArr?.length - 1];

  return (
    <tbody>
      {data?.map(function (item: any, i: number) {
        const key = item._id || item.id;
        const confirmFieldText = get(item, confirmField, '');
        return (
          <tr key={key} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
            <TableRow columns={columns} item={item} />
            {deleteUrl ? (
              <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium relative -z-1">
                <DeleteButton
                  deleteUrl={deleteUrl ? deleteUrl : `/${url}/`}
                  deleteId={key}
                  confirmText={confirmFieldText ?? confirmField}
                />
              </td>
            ) : null}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
