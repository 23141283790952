import React from 'react';
import { Modal, List, Text, Badge } from '@mantine/core';
import { PhotographIcon, PlusCircleIcon, UploadIcon } from '@heroicons/react/solid';
function InstructionModal({ opened, close }) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title={<Text fw={700}>Instructions on How to Use Heatmaps</Text>}
      size="xl"
      overlayColor={'gray'}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <List listStyleType="disc" className="!list-decimal" type="ordered" spacing="sm">
        <List.Item>
          Click{' '}
          <Badge
            className="!p-4 !capitalize"
            radius="sm"
            variant="filled"
            leftSection={<PlusCircleIcon className="-ml-1 h-5 w-5" aria-hidden="true" />}
          >
            Add Group
          </Badge>{' '}
          to add a group (you can create a group specifically for one product, one campaign, etc. This is simply a
          folder).
        </List.Item>
        <List.Item>Click into your new group.</List.Item>
        <List.Item>
          Click{' '}
          <Badge
            className="!p-4 !capitalize"
            radius="sm"
            variant="filled"
            leftSection={<UploadIcon className="-ml-1 h-5 w-5" aria-hidden="true" />}
          >
            Upload
          </Badge>{' '}
          to add images.
        </List.Item>
        <List.Item>
          Choose a preset from the following list:
          <List withPadding listStyleType="disc" spacing="sm">
            <List.Item>
              <Text fw={700}>Normal</Text> (normal heat map showing focus concentration using red to blue spectrum).
            </List.Item>
            <List.Item>
              <Text fw={700}>Color-blind</Text> (for people that are color blind, this uses an aqua to violet spectrum).
            </List.Item>
            <List.Item>
              <Text fw={700}>Scientific</Text> (only shows the highest levels of focus and attention)
            </List.Item>
            <List.Item>
              <Text fw={700}>Reveal</Text> (blacks out everything but the elements with highest attention).
            </List.Item>
          </List>
        </List.Item>
        <List.Item>Upload image(s). Your results will be available within minutes.</List.Item>
        <List.Item>
          In your results, click the image icon(
          <Badge className="!px-2 !py-4 !capitalize" radius="sm" variant="filled">
            <PhotographIcon className="h-5 w-5" />
          </Badge>
          ) to toggle between the normal image and the heat map overlay.
        </List.Item>
      </List>
    </Modal>
  );
}

export default InstructionModal;
