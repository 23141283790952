import { format, addMonths, getQuarter, eachWeekOfInterval, getWeek, getYear } from 'date-fns';

export function getMonthsBetweenDates(startDate: Date, endDate: Date): string[] {
  const months: string[] = [];
  let currentDate = new Date(startDate);

  for (currentDate; currentDate <= endDate; currentDate = addMonths(currentDate, 1)) {
    const monthName = format(currentDate, 'MMM');
    const year = getYear(currentDate);

    months.push(`${monthName}-${year}`);
  }

  return months;
}

export function getYearlyQuarters(startDate, endDate) {
  const quarters: string[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const quarterStart = new Date(currentDate);
    const quarterNumber = getQuarter(quarterStart);
    const year = getYear(currentDate);

    const quarterMonths: number[] = [];
    for (let i = 0; i < 3; i++) {
      if (currentDate <= endDate) {
        quarterMonths.push(getQuarter(currentDate));
        currentDate = addMonths(currentDate, 1);
      }
    }

    quarters.push(`Q${quarterNumber}-${year}`);
  }

  return quarters;
}

export function getWeekNumbersBetweenDates(startDate: Date = new Date(), endDate: Date = new Date()): string[] {
  const weekNumbers: string[] = [];
  const weeks = eachWeekOfInterval({ start: startDate, end: endDate });
  for (const week of weeks) {
    const weekNumber = getWeek(week);
    const year = getYear(week);
    weekNumbers.push(`Week ${weekNumber}-${year}`);
  }

  return weekNumbers;
}
