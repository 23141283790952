import If from 'components/conditionals/if.conditional';
import ShimmerWrapper from 'components/shimmer';
import { ShimmerImg } from 'components/shimmer/utils';
import useFetch from 'hooks/useFetch';
import AmazonAccountHeader from 'pages/users/amazon/amazon-account.header';
import AmazonAccountInfo from 'pages/users/amazon/amazon-account.info';
import AmazonAdsForm from 'pages/users/amazon/amazon-ads.form';
import { selectAmazonAdsAccounts } from 'pages/users/amazon/amazon-ads.slice';
import AmazonSellerForm from 'pages/users/amazon/amazon-seller.form';
import { AmazonDataInterface } from 'pages/users/amazon/amazon.interface';
import { selectAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import { amazonAccounts } from 'pages/users/amazon/amazon.thunks';
import { useAppSelector } from 'redux/hooks';

export default function AmazonAccounts() {
  const { isFetching } = useFetch('seller-accounts', () => amazonAccounts());
  const sellerAccounts = useAppSelector(selectAmazonAccounts);
  const adsAccounts = useAppSelector(selectAmazonAdsAccounts);

  let mergedAccounts: AmazonDataInterface[] = [];
  let amazonAccountsWithAds: AmazonDataInterface[] = [];
  if (sellerAccounts) {
    mergedAccounts = sellerAccounts.map((acc) => ({
      ...acc,
      adAccount: adsAccounts.find((ad) => ad.seller === acc._id),
    }));

    amazonAccountsWithAds = mergedAccounts.filter((s) => s.adAccount === undefined);
  }

  return (
    <>
      <div className="grid grid-cols-2">
        <AmazonSellerForm />
        <If show={amazonAccountsWithAds.length > 0}>
          <AmazonAdsForm sellerAccounts={amazonAccountsWithAds} />
        </If>
      </div>
      <div className="bg-white shadow rounded-md">
        <ShimmerWrapper loading={isFetching} fallback={<ShimmerImg />}>
          <div className="bg-white shadow sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {mergedAccounts.map((account) => {
                return (
                  <li key={account._id}>
                    <AmazonAccountHeader account={account} unlink={false} />
                    <AmazonAccountInfo sellerAccounts={sellerAccounts} sellerAccount={account} />
                  </li>
                );
              })}
            </ul>
          </div>
        </ShimmerWrapper>
      </div>
    </>
  );
}
