import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeLinkAuthenticationElementOptions } from '@stripe/stripe-js/types/stripe-js/elements/link-authentication';
import { StripePaymentElementOptions } from '@stripe/stripe-js/types/stripe-js/elements/payment';
import Alert from 'components/alerts/alert';
import If from 'components/conditionals/if.conditional';
import React, { useState } from 'react';
import { store } from 'redux/store';
import { resetRequest, validationError } from 'request/request.slice';

export default function StripeCheckout({
  email,
  redirectUrl,
  buttonLabel,
  noAlert = false,
  clientSecret,
}: {
  email: string;
  redirectUrl: string;
  buttonLabel?: string;
  noAlert?: boolean;
  clientSecret: string;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    store.dispatch(resetRequest());
    if (!stripe || !elements) {
      return;
    }

    setIsStripeLoading(true);

    const setup = clientSecret.startsWith('pi_') ? 'confirmPayment' : 'confirmSetup';
    const { error } = await stripe[setup]({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectUrl,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      const message = 'Card Error : ' + error?.message;
      store.dispatch(validationError({ message }));
    } else {
      store.dispatch(validationError({ message: 'Payment failed.' }));
    }

    setIsStripeLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    paymentMethodOrder: ['card'],
  };
  const linkOption = { defaultValues: { email } } as StripeLinkAuthenticationElementOptions;

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <If show={!noAlert}>
        <Alert />
      </If>
      <LinkAuthenticationElement id="link-authentication-element" options={linkOption} />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={!stripe || !elements}
        hidden={!elements}
        id="submit"
        className="disabled:bg-gray-300 mt-4 w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
                          text-sm py-3 px-6 font-medium text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
      >
        <span id="button-text">{isStripeLoading ? 'Processing..' : buttonLabel}</span>
      </button>
    </form>
  );
}
