import React from 'react';
import { Button, Text, SegmentedControl } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useTopSearchesContext } from './top-searches.context';
import useTopSearchesEvents from './top-searches.hooks';
const TopSearchesChartFilters = () => {
  const { topSearchesStates } = useTopSearchesContext();
  const { setStartDate, setEndDate, setDataType, reloadData } = useTopSearchesEvents();

  return (
    <div className="h-full flex justify-between items-center">
      <SegmentedControl
        onChange={(e) => setDataType(e)}
        defaultValue={topSearchesStates.dataType}
        disabled={topSearchesStates.isLoading}
        data={[
          { label: 'Day', value: 'daily' },
          { label: 'Week', value: 'weekly' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Quarterly', value: 'quarterly' },
        ]}
      />
      <div className="flex items-center my-2">
        <Text fw={500} className="mr-3">
          Start Date:{' '}
        </Text>
        <DatePicker
          clearable={false}
          placeholder="Start Date"
          defaultValue={topSearchesStates.startDate}
          disabled={topSearchesStates.isLoading}
          onChange={(val) => setStartDate(val)}
        />
        <Text fw={500} className="mx-3">
          End Date:{' '}
        </Text>
        <DatePicker
          clearable={false}
          placeholder="End Date"
          defaultValue={topSearchesStates.endDate}
          disabled={topSearchesStates.isLoading}
          onChange={(val) => setEndDate(val)}
        />
        <Button
          className="ml-6"
          onClick={() => reloadData(topSearchesStates.keywords)}
          loading={topSearchesStates.isLoading}
        >
          {/*<Button className="ml-3">*/}
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

export default TopSearchesChartFilters;
