// import { classNames } from '../../../lib/helpers.common';
import { AnnotationIcon, CreditCardIcon, TemplateIcon, UserIcon, UsersIcon } from '@heroicons/react/solid';
import { classNames } from 'lib/helpers';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function RebatesFunnelSteps({ id }) {
  const { pathname } = useLocation();

  const subpage = pathname.split('/').slice(0, 5);
  const isCurrentPage = function (href) {
    const name = href.split('/').slice(0);
    return subpage[4] === name[4];
  };

  const tabs = [
    { name: 'Basic Information', href: '/dashboard/funnels/rebates/info/', icon: UserIcon },
    { name: 'Rebates', href: '/dashboard/funnels/rebates/main/', icon: CreditCardIcon },
    { name: 'Rebate Options', href: '/dashboard/funnels/rebates/options/', icon: UsersIcon },
    // { name: 'Blacklisting', href: '/dashboard/funnels/rebates/blacklisting/', icon: ShieldCheckIcon },
    { name: 'Site', href: '/dashboard/funnels/rebates/site/', icon: TemplateIcon },
    { name: 'Site Labels', href: '/dashboard/funnels/rebates/labels/home/', icon: AnnotationIcon },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => isCurrentPage(tab.href))?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const current = isCurrentPage(tab.href);

              return (
                <Link
                  key={tab.name}
                  to={`${tab.href}${id}`}
                  className={classNames(
                    current
                      ? 'border-red-500 text-red-600'
                      : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-400',
                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  <tab.icon
                    className={classNames(
                      current ? 'text-red-500' : 'text-gray-600 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
