import { StopIcon } from '@heroicons/react/solid';

import FormButton from 'components/forms/form-button';
import { stopFunnel } from 'pages/users/funnels/funnels.thunks';
import { useParams } from 'react-router-dom';

function StopFunnel({ funnel }) {
  const { id } = useParams();

  const stop = async (e) => {
    e.preventDefault();
    await stopFunnel(id);
  };

  return funnel?.isDeployed ? (
    <>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <FormButton
          leadingIcon={<StopIcon className="h-5 w-5 mr-1" />}
          onClick={(e) => stop(e)}
          buttonColor="red"
          buttonLabel="Stop Funnel"
        />
      </div>
    </>
  ) : null;
}

export default StopFunnel;
