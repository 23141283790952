import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React from 'react';
import AccountsTableData from 'pages/admin/sqp/accounts/accounts.tabledata';
import { useAccounts } from 'pages/admin/sqp/accounts/accounts.hooks';
import { useAppSelector } from 'redux/hooks';
import { selectAccounts } from 'pages/admin/sqp/accounts/accounts.slice';

const headers = ['Email', 'Action'];

const Accounts = () => {
  const { isFetching } = useAccounts('sqp-accounts');

  const columns = AccountsTableData();
  const colSpan = headers.length;
  const accountsData = useAppSelector(selectAccounts);

  return (
    <>
      <SubpageContainer title="Accounts" showAdd addUrl="new">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={accountsData}
          >
            <TableBody columns={columns} data={accountsData} deleteUrl="/sqp/admin/accounts/" confirmField="email" />
          </RequestContainer>
        </Table>
      </SubpageContainer>
    </>
  );
};

export default Accounts;
