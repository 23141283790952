import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import SwitchButton from 'pages/admin/manage-users/manage-users-switch-button';
import React from 'react';
import { UserTeamMembersInterface } from 'interfaces/user.interface';
import IfElse from 'components/conditionals/if-else.conditional';

function ManageUsersMemberTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'email',
      render: (rowData: UserTeamMembersInterface) => {
        const id = rowData?.userId?._id;
        return (
          <IfElse show={!rowData?.userId?.email}>
            <div>&mdash;</div>
            <a href={`/dashboard/users/edit/${id}`} className="hover:underline">
              {rowData?.userId?.email}
            </a>
          </IfElse>
        );
      },
    },
    {
      key: 'users',
      render: (rowData: UserTeamMembersInterface) => {
        return (
          <div className="w-1/4">
            {' '}
            <SwitchButton userId={rowData?.userId?._id} label={rowData?.userId?.email} />
          </div>
        );
      },
    },
    {
      key: 'firstName',
      render: (rowData: UserTeamMembersInterface) => {
        return <span>{rowData?.userId?.firstName}</span>;
      },
    },
    {
      key: 'lastName',
      render: (rowData: UserTeamMembersInterface) => {
        return <span>{rowData?.userId?.lastName}</span>;
      },
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default ManageUsersMemberTableData;
