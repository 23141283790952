import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapItemInterface } from 'interfaces/heatmap.interface';
import { RootState } from 'redux/store';

const initialState: HeatmapItemInterface = {
  isOpen: false,
  selectedGroup: '',
  selectedId: '',
  isEditing: false,
};

export const heatMapItemSlice = createSlice({
  name: 'heatMapItem',
  initialState,
  reducers: {
    toggleHeatmapForm: (state: Draft<HeatmapItemInterface>, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setSelectedName: (state: Draft<HeatmapItemInterface>, action: PayloadAction<string>) => {
      state.selectedGroup = action.payload;
    },
    setSelectedId: (state: Draft<HeatmapItemInterface>, action: PayloadAction<string>) => {
      state.selectedId = action.payload;
    },
    setEditMode: (state: Draft<HeatmapItemInterface>, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    editHeatmapItem: (state: Draft<HeatmapItemInterface>, action: PayloadAction<HeatmapItemInterface>) => {
      const heatmapItem = action.payload;
      return { ...state, ...heatmapItem };
    },
  },
});

export const { toggleHeatmapForm, setSelectedName, setEditMode, setSelectedId, editHeatmapItem } =
  heatMapItemSlice.actions;
export const selectHeatMapItem = (state: RootState) => state.heatMapItem;
export const heatMapItemReducer = heatMapItemSlice.reducer;
