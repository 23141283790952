import SubpageContainer from 'components/containers/subpage.container';
import { Form } from 'react-final-form';
import React, { useRef, useState } from 'react';
import { Button, FileButton, LoadingOverlay, Text } from '@mantine/core';
import { HeatmapPresetInterface } from '../heat-maps.interface';
import { IconUpload } from '@tabler/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { UploadHeatmapInterface } from 'interfaces/heatmap.interface';
import { handleUploadImage } from 'lib/helpers';
import CustomLoader from 'pages/users/ab-test/components/abtest.loader';
import { HeatMapsPresetSelection } from './heat-maps-preset';

const HeatMapsUpload = () => {
  const resetRef = useRef<() => void>(null);
  const params = useParams();
  const id = params?.id || '';
  const navigate = useNavigate();
  const [visible, { toggle, close }] = useDisclosure(false);
  const closeRequest = () => {
    //
  };
  const [preset, setPreset] = useState('normal');
  const presets: HeatmapPresetInterface[] = [
    {
      name: 'Normal',
      value: 'normal',
      image: 'normal-heatmap.jpg',
      description: 'Normal heat map showing focus concentration using red to blue spectrum',
    },
    {
      name: 'Scientific',
      value: 'scientific',
      image: 'scientific-heatmap.jpg',
      description: 'Only shows the highest levels of focus and attention',
    },
    {
      name: 'Reveal',
      value: 'reveal',
      image: 'reveal-heatmap.jpg',
      description: 'Blacks out everything but the elements with highest attention',
    },
    {
      name: 'Color-Blindness',
      value: 'color-blindness',
      image: 'color-blindness-heatmap.jpg',
      description: 'For people that are color blind, this uses an aqua to violet spectrum',
    },
  ];
  const handleUpload = async (e) => {
    toggle();
    const formData = new FormData();
    const file: File = e;
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    formData.append('preset', preset);
    formData.append('group', id);
    handleUploadImage(
      formData,
      file,
      '/heatmap/heatmaps/images/upload',
      (uploadedData: UploadHeatmapInterface) => {
        if (uploadedData.jobId) {
          navigate(`/dashboard/heatmaps/${id}/uploads`);
        }
      },
      () => {
        close();
      }
    );
  };
  return (
    <SubpageContainer title="">
      <Form
        onSubmit={closeRequest}
        render={() => {
          return (
            <div className="flex flex-col">
              <LoadingOverlay
                visible={visible}
                overlayBlur={5}
                loader={<CustomLoader textMessage="Uploading image..." />}
              />
              <div className="w-1/2 self-center text-center">
                <Text fz="xl" fw={700}>
                  Select A Heat-map Preset
                </Text>
                <Text c="dimmed" fz="sm" className="py-2">
                  Signalytics heatmapping tool visually highlights key areas in images, enabling data-driven decisions
                  for optimization and engagement.
                </Text>
              </div>
              <div className="w-4/6 py-5 self-center">
                <div className="grid grid-cols-4 gap-6 py-5">
                  {presets.map((x: HeatmapPresetInterface) => {
                    return <HeatMapsPresetSelection key={x.name} {...x} preset={preset} setPreset={setPreset} />;
                  })}
                </div>
              </div>
              <div className="self-center py-10">
                <FileButton resetRef={resetRef} onChange={handleUpload} accept="image/png,image/jpeg">
                  {(props) => (
                    <Button size="md" color="danger" {...props} leftIcon={<IconUpload size={'0.8rem'} />}>
                      Upload Your Image
                    </Button>
                  )}
                </FileButton>
              </div>
            </div>
          );
        }}
      />
    </SubpageContainer>
  );
};
export default HeatMapsUpload;
