import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import { UploadHeatmapInterface } from 'interfaces/heatmap.interface';
import { handleUploadImage } from 'lib/helpers';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { Button, FileButton, LoadingOverlay, Select } from '@mantine/core';
import { IconUpload } from '@tabler/icons';
import CustomLoader from '../../ab-test/components/abtest.loader';
import { useDisclosure } from '@mantine/hooks';
const HeatmapUpload = () => {
  const params = useParams();
  const id = params?.id || '';
  const navigate = useNavigate();
  const [preset, setPreset] = useState('normal');
  const resetRef = useRef<() => void>(null);
  const [visible, { toggle }] = useDisclosure(false);

  const closeRequest = () => {
    //
  };
  return (
    <SubpageContainer title="Upload Image">
      <Form
        onSubmit={closeRequest}
        render={() => {
          const selectPreset = (select) => {
            setPreset(select);
          };
          const handleUpload = async (e) => {
            toggle();
            const formData = new FormData();
            const file: File = e;
            formData.append('file', file, file.name);
            formData.append('name', file.name);
            formData.append('preset', preset);
            formData.append('group', id);
            handleUploadImage(
              formData,
              file,
              '/heatmap/heatmaps/images/upload',
              (uploadedData: UploadHeatmapInterface) => {
                if (uploadedData.jobId) {
                  navigate(`/dashboard/heatmaps-old/${id}/uploads`);
                }
              }
            );
          };

          return (
            <form onSubmit={(e) => e.preventDefault()} className="flex items-center">
              <LoadingOverlay
                visible={visible}
                overlayBlur={5}
                loader={<CustomLoader textMessage="Uploading image..." />}
              />
              <div className="flex items-center mr-2">
                <label htmlFor="preset" className="mr-2">
                  Choose a Preset:{' '}
                </label>
                <Select
                  label=""
                  className="w-40"
                  name="preset"
                  value={preset}
                  onChange={selectPreset}
                  data={[
                    { value: 'normal', label: 'Normal' },
                    { value: 'scientific', label: 'Scientific' },
                    { value: 'reveal', label: 'Reveal' },
                    { value: 'color-blindness', label: 'color-blindness' },
                  ]}
                />
              </div>
              <FormInput name="group" type="hidden" />
              <div className="justify-items-center">
                <FileButton resetRef={resetRef} onChange={handleUpload} accept="image/png,image/jpeg">
                  {(props) => (
                    <Button color="indigo" {...props} leftIcon={<IconUpload size={'0.8rem'} />}>
                      Upload image
                    </Button>
                  )}
                </FileButton>
              </div>
            </form>
          );
        }}
      />
    </SubpageContainer>
  );
};

export default HeatmapUpload;
