import React, { Dispatch } from 'react';
import {
  chartDataSetInterface,
  keywordInterface,
  stateInterface,
  tableInterface,
  TopSearchResultInterfaces,
} from 'pages/users/keyword-research/keyword-research.interfaces';

export type Action = {
  type: string;
  payload?:
    | boolean
    | TopSearchResultInterfaces[]
    | tableInterface
    | Date
    | chartDataSetInterface
    | keywordInterface
    | string[]
    | string
    | null
    | number[]
    | number;
};
interface contextInterface {
  topSearchesStates: stateInterface;
  dispatch: Dispatch<Action>;
}

const KeywordResearchContext = React.createContext({} as contextInterface);

export function useTopSearchesContext() {
  return React.useContext(KeywordResearchContext);
}

export default KeywordResearchContext;
