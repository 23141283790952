import { TrashIcon } from '@heroicons/react/solid';
import PrimaryButton from 'components/buttons/primary-button';

function DeleteButton({ size = 5, color = 'red', onClick }) {
  return (
    <>
      <PrimaryButton
        size="sm"
        onClick={onClick}
        type="button"
        className={`inline-flex items-center text-${color}-600 hover:text-${color}-700  focus:ring-${color}-500`}
      >
        <TrashIcon className={`h-${size} w-${size}`} aria-hidden="true" />
      </PrimaryButton>
    </>
  );
}

export default DeleteButton;
