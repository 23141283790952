import { fallbackImage } from 'lib/helpers';

import ProductDetails from 'pages/users/funnels/select-product/select-product.details';
import React from 'react';

function SelectProductPreview({ product }) {
  const addDefaultSource = (e) => {
    e.target.src = fallbackImage;
  };

  // amazon data has asin1,asin2 and asin3, while our database only has asin
  return product === null ? (
    <></>
  ) : (
    <>
      <div className="pt-4 font-semibold flex ">
        <img src={product?.productImages?.main} onError={addDefaultSource} alt="." className="mr-4 w-36 h-36" />
        <ProductDetails product={product} />
      </div>
    </>
  );
}

export default SelectProductPreview;
