import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { setCookieToken } from 'lib/cookie.helper';
import { defaultPage } from 'lib/helpers';
import { setUserData } from 'pages/users/user/user.slice';
import { login } from 'pages/users/user/user.thunks';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import Alert from '../../../components/alerts/alert';

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = async (values) => {
    const response = await login(values);

    if (response) {
      const { isAdmin, roles } = response.user;
      dispatch(setUserData(response.user));
      setCookieToken(response.token);
      const href = defaultPage(roles, isAdmin);
      if (!isAdmin) {
        window.location.href = process.env.REACT_APP_URL + '/tokenized/' + response.token || '';
      } else {
        navigate(href === 'ppc-ai' ? '/ppc-ai' : '/dashboard/' + href);
      }
    }
  };
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-auto" src="/logo-white.png" alt="Signalytics" />
        </div>

        <div className="my-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-12 px-8 shadow sm:rounded-lg sm:px-10">
            <h2 className="mb-6 text-center text-2xl font-bold text-gray-900">Sign in to your account</h2>
            <form className="space-y-6" onSubmit={form.onSubmit((values) => onSubmit(values))}>
              <Alert />
              <TextInput
                withAsterisk
                label="Email Address"
                placeholder="Email Address"
                {...form.getInputProps('email')}
              />
              <PasswordInput placeholder="Password" label="Password" withAsterisk {...form.getInputProps('password')} />

              <div className="text-sm">
                <Link to="forgot" className="font-medium text-red-600 hover:text-red-500">
                  Forgot your password?
                </Link>
              </div>
              <div>
                <Button
                  type="submit"
                  className="!w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
