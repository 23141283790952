import { getSilentFetch, postSilentFetch, putFetch } from 'request/request';
import { store } from 'redux/store';
import { setBrands } from 'pages/admin/sqp/brands/brands.slice';
import { BrandsInterface } from 'interfaces/brands.interface';

export const getBrands = async (): Promise<BrandsInterface[]> => {
  const res = await postSilentFetch('/sqp/admin/brands/list', {});
  if (res) {
    store.dispatch(setBrands(res));
  }
  return res;
};

export const addBrand = async (values): Promise<BrandsInterface> => {
  const res = await postSilentFetch('/sqp/admin/brands', values);
  if (res) {
    store.dispatch(setBrands(res));
  }
  return res;
};

export const getBrandDetails = async (id): Promise<BrandsInterface> => {
  const res = await getSilentFetch(`/sqp/admin/brands/${id}`);
  return res;
};

export const updateBrand = async (id, values): Promise<BrandsInterface> => {
  const res = await putFetch(`/sqp/admin/brands/${id}`, { ...values, _id: id }, 'Brand Updated Successfully!');
  return res;
};
