import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormElement from 'components/forms/form-element';

import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import useFetch from 'hooks/useFetch';
import { getUserDetails, UpdateUser } from 'pages/admin/manage-users/manage-users.thunks';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { addTeamMember } from 'pages/users/teams/teams.thunks';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

function InviteMemberForm() {
  const { id } = useParams();
  const isCreating = id === 'new';
  let user = {
    roles: [],
    isVerified: true,
  };
  let isFetching = false;

  if (!isCreating) {
    ({ isFetching, data: user } = useFetch('manage-user', () => getUserDetails(id)));
  }

  // initialize user before fetching is over
  user = !Array.isArray(user) ? user : { roles: [], isVerified: true };

  const onSubmit = async (values) => {
    if (isCreating) {
      await addTeamMember(values);
    } else {
      await UpdateUser(id, values);
    }
  };

  return (
    <SubpageContainer title={isCreating ? 'Add Member' : 'Update Member'}>
      <RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={user}
          render={({ handleSubmit }) => {
            const [changePassword, setChangePassword] = useState(false);
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormInput name="firstName" label="First Name" />
                      <FormInput name="lastName" label="Last Name" />
                      <FormInput name="email" label="Email" />
                      {isCreating || changePassword ? (
                        <FormInput name="password" required label="Password" type="password" />
                      ) : (
                        <FormElement label="Password">
                          <button
                            type="button"
                            onClick={() => setChangePassword(true)}
                            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Change Password
                          </button>
                        </FormElement>
                      )}
                    </div>
                    <div className="grid grid-cols-1 sm:items-start sm:border-t bg-gray-100 rounded-md sm:border-gray-200 sm:py-5">
                      <div className="grid grid-cols-4 gap-4">
                        <div />
                        <div className="mt-1 space-x-5 col-span-3">
                          <FormSubmit label="Invite Member" showButtonOnly disabled={isLoading} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default InviteMemberForm;
