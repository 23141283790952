import { PaperAirplaneIcon } from '@heroicons/react/solid';
import FormButton from 'components/forms/form-button';

function DeployFunnel({ funnel, deploy }) {
  return funnel?.isDeployed !== true ? (
    <>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <FormButton
          leadingIcon={<PaperAirplaneIcon className="h-5 w-5 mr-1" />}
          onClick={(e) => deploy(e)}
          buttonColor="green"
          buttonLabel="Deploy Funnel"
        />
      </div>
    </>
  ) : null;
}

export default DeployFunnel;
