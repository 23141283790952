import ProgressBar from 'components/progress-bar/progress-bar';
import { getPercentage } from 'lib/formatting.helpers';
import { PlanUsageInterface } from 'pages/users/subscription/plans/plans.interface';

export default function PlanUsage({ data }: { data: PlanUsageInterface }) {
  const { value, total, name } = data;
  const color = value === total ? 'red' : 'green';
  return (
    <div key={name}>
      <div className="text-sm text-gray-500 mb-1 capitalize">
        {value}/{total} {name}
      </div>
      <ProgressBar color={color} percent={getPercentage(value, total)} />
    </div>
  );
}
