import { analyticsColumns } from 'pages/users/analytics/analytics-columns';
import { safeDivide } from 'pages/users/analytics/analytics.helper';
import { AdsSalesReportsInterface, StatisticsColumnsInterface } from 'pages/users/analytics/analytics.interface';

export function getValueByColumn(
  column: string,
  adsData: AdsSalesReportsInterface,
  formatterFn?: (number) => string | number
): number | string {
  const formatter = formatterFn ? formatterFn : (value) => value.toFixed(2);
  switch (column) {
    case 'sponsoredSalesShare':
      return formatter(adsData.sponsoredSales / adsData.sales);
    case 'sales':
      return formatter(adsData.sales);
    case 'sponsoredSales':
      return formatter(adsData.sponsoredSales);
    case 'tacos':
      return formatter(safeDivide(adsData.cost, adsData.sales));
    case 'acos':
      return formatter(safeDivide(adsData.cost, adsData.sponsoredSales));
    case 'cost':
      return formatter(adsData.cost);
    case 'clicks':
      return formatter(adsData.clicks);
    case 'impressions':
      return formatter(adsData.impressions);
    case 'cr':
      return formatter(safeDivide(adsData.purchases7d, adsData.clicks));
    case 'cpc':
      return formatter(safeDivide(adsData.cost, adsData.clicks));
    case 'roas':
      return formatter(safeDivide(adsData.sponsoredSales, adsData.cost));
    case 'date':
      return formatter(adsData.date);
    default:
      return 0;
  }
}

export const calculateTabData = (adsData: AdsSalesReportsInterface): StatisticsColumnsInterface[] => {
  return analyticsColumns.map((c) => {
    return { ...c, stat: getValueByColumn(c.column, adsData, c.formatter) };
  });
};
