import { OrderDetailsInterface } from 'interfaces/rebates.interface';

const orderDetailTableData = (values: OrderDetailsInterface) => {
  const {
    itemId,
    price,
    promoDiscount,
    promoDiscountCurrency,
    promoDiscountTax,
    promoDiscountTaxCurrency,
    qty,
    tax,
    taxCurrency,
    total,
    currency,
    orderNumber,
    paypal,
  } = values;

  return [
    {
      header: 'Item ID',
      value: itemId,
    },
    {
      header: 'Price',
      value: price,
    },
    {
      header: 'Currency',
      value: currency,
    },
    {
      header: 'Quantity',
      value: qty,
    },
    {
      header: 'Promo Discount',
      value: promoDiscount,
    },
    {
      header: 'Promo Discount Currency',
      value: promoDiscountCurrency,
    },
    {
      header: 'Promo Discount Tax',
      value: promoDiscountTax,
    },
    {
      header: 'Promo Discount Tax Currency',
      value: promoDiscountTaxCurrency,
    },
    {
      header: 'Total',
      value: total,
    },
    {
      header: 'Tax',
      value: tax,
    },
    {
      header: 'Tax Currency',
      value: taxCurrency,
    },
    {
      header: 'Order Number',
      value: orderNumber,
    },
    {
      header: 'Paypal',
      value: paypal,
    },
    {
      header: 'Total Rebate',
      value: '',
    },
  ];
};

export default orderDetailTableData;
