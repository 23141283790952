import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserCredits, loadUsersMember, updateUserCredits } from 'pages/admin/manage-users/manage-users.thunks';
import { useParams } from 'react-router-dom';
import { UserCreditsInterface } from 'interfaces/user.interface';

export const useUserCredits = () => {
  const { id } = useParams();
  const { data: credits, isFetching } = useQuery(['manage-user-credits', id], {
    queryFn: () => getUserCredits(id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: update } = useMutation((data: UserCreditsInterface) => updateUserCredits(id, data));

  return {
    credits,
    isFetching,
    update,
  };
};

export const useMembers = (currentPage: number, queryKey: string) => {
  const { id } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => loadUsersMember({ page: currentPage, userId: id }),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data: {
      docs: data?.docs || [],
      hasNextPage: data?.hasNextPage || false,
      hasPrevPage: data?.hasPrevPage || false,
      nextPage: data?.nextPage || null,
      page: data?.page || 0,
      pagingCounter: data?.pagingCounter || 0,
      prevPage: data?.prevPage || null,
      totalPages: data?.totalPages || 0,
    },
  };
};
