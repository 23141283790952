import {
  ShimmerButton,
  ShimmerButtonWrapper,
  ShimmerCol,
  ShimmerDivider,
  ShimmerImg,
  ShimmerLabel,
  ShimmerNestedRow,
  ShimmerRow,
  ShimmerTransparent,
  ShimmerTxtField,
  ShimmerWrap,
} from 'components/shimmer/utils';

const BasicInfoShimmer = () => (
  <div className="w-100">
    <ShimmerWrap gap={5}>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField span={5} />
      </ShimmerRow>
      <ShimmerRow justify="start">
        <ShimmerLabel />
        <ShimmerImg />
        <ShimmerLabel span={4} height={8} />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerTransparent />
        <ShimmerNestedRow>
          <ShimmerLabel color="red-700" />
          <ShimmerCol>
            <ShimmerLabel />
            <ShimmerLabel />
          </ShimmerCol>
        </ShimmerNestedRow>
      </ShimmerRow>
      <ShimmerDivider />
      <ShimmerButtonWrapper>
        <ShimmerTransparent />
        <ShimmerButton />
      </ShimmerButtonWrapper>
    </ShimmerWrap>
  </div>
);

export default BasicInfoShimmer;
