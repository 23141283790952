import React, { useContext, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Center,
  createStyles,
  Grid,
  Group,
  Image,
  Input,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Table,
  Text,
} from '@mantine/core';
import { ExtractorContext } from './keyword-extractor.context';
import DownloadCsvButton from './components/keyword-extractor.csv-download.component';
import { IconPlus } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]}`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

const valueExtractorTableComponent = ({
  tableLabel,
  tableHeaders,
  tableData = [] as any[],
  searchLabel,
  dataType,
  fetchingSA,
}) => {
  const {
    asins,
    keywords,
    setasinCount,
    setkeywordCount,
    setAsins,
    setKeyword,
    setKeywords,
    asinCount,
    keywordCount,
    searchKeyword,
    setSearchKeyword,
    searchAsin,
    setSearchAsin,
  } = useContext(ExtractorContext);
  const [keywordSearchResults, setKeywordSearchResults] = useState([] as string[]);
  const [asinSearchResults, setAsinSearchResults] = useState([] as string[]);
  const isProductData = dataType === 'product';
  // add asin/keyword to keyword-extractor
  const addItem = (e) => {
    if (dataType === 'product' && asinCount !== 5) {
      const updatedData = [...asins, e];
      if (!asins.includes(e)) {
        setAsins([...updatedData]);
        setasinCount(updatedData.length);
      }
    } else if (dataType === 'keyword' && keywordCount !== 5) {
      const updatedData = [...keywords, e];
      if (!keywords.includes(e)) {
        setKeywords([...updatedData]);
        setKeyword(e);
        setkeywordCount(updatedData.length);
      }
    }

    setSearchAsin('');
    setSearchKeyword('');
  };

  //search bar
  const searchData = (e) => {
    setCurrentPage(1);
    if (dataType === 'product') {
      setSearchAsin(e.currentTarget.value);
      const filteryAsin = tableData.filter((item) => {
        const asin = item.asin.toLowerCase();
        const query = e.currentTarget.value.toLowerCase();
        return asin.includes(query);
      });
      const filterByTitle = tableData.filter((item) => {
        const asin = item.title.toLowerCase();
        const query = e.currentTarget.value.toLowerCase();
        return asin.includes(query);
      });
      setAsinSearchResults([...filterByTitle, ...filteryAsin]);
    } else {
      setSearchKeyword(e.currentTarget.value);
      const filteredData = tableData.filter((item) => {
        const keyword = item.search_term.toLowerCase();
        const query = e.currentTarget.value.toLowerCase();
        return keyword.includes(query);
      });
      setKeywordSearchResults(filteredData);
    }
  };

  //dataSet to differentiate search results for asin or keyword
  let dataSet;
  if (searchAsin !== '' && dataType === 'product') {
    dataSet = asinSearchResults;
  } else if (searchKeyword !== '' && dataType === 'keyword') {
    dataSet = keywordSearchResults;
  } else {
    dataSet = tableData;
  }
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(dataSet.length / itemsPerPage);
  dataSet = dataSet.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const rows = dataSet.map((element, index) => (
    <tr key={index}>
      {isProductData && (
        <td style={{ width: 'fit-content' }}>
          <Image
            radius="md"
            src={
              'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=' +
              element.asin +
              '&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250'
            }
            width={80}
            height={80}
          />
        </td>
      )}
      <td>
        <Group className={`flex !flex-nowrap !justify-between`}>
          <Anchor href="#" color="dark" onClick={() => addItem(isProductData ? element?.asin : element?.search_term)}>
            <p className="line-clamp-1 text-ellipsis">
              {isProductData ? `${element?.asin} - ${element?.title}` : element?.search_term}
            </p>
          </Anchor>
          <ActionIcon
            onClick={() => addItem(isProductData ? element?.asin : element?.search_term)}
            variant={'light'}
            color={'blue'}
          >
            <IconPlus size="1.125rem" />
          </ActionIcon>
        </Group>
      </td>
      <td className={`text-center ${dataType === 'keyword' ? '!py-9' : ''}`}>{index + 1 + 20 * (currentPage - 1)}</td>
    </tr>
  ));

  return fetchingSA ? (
    <Paper shadow="xs" p="md">
      <Group position="center">
        <Loader size="xl" />
      </Group>
    </Paper>
  ) : (
    <Paper shadow="xs" p="md" className="h-full">
      <Text fz="xl" fw={500} ta="center">
        {tableLabel}
      </Text>
      <Grid gutter="xs" align={'center'} className="pb-3 pt-7">
        <Grid.Col span={11}>
          <Input placeholder={searchLabel} onInput={searchData} />
        </Grid.Col>
        <Grid.Col span={1}>
          <DownloadCsvButton data={tableData} dataType={dataType} headers={tableHeaders} currentPage={currentPage} />
        </Grid.Col>
      </Grid>
      <ScrollArea.Autosize
        maxHeight={500}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        type={'auto'}
        className="pb-2 h-full"
      >
        <Table striped className={isProductData ? 'asins-table' : 'keywords-table'}>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })} style={{ zIndex: 1 }}>
            <tr>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  className={`whitespace-nowrap ${header === 'Similarity Rank' ? '!text-center w-[10%]' : ''}`}
                  colSpan={isProductData && index === 0 ? 2 : 1}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows}
            {dataSet.length === 0 && (
              <tr>
                <td colSpan={isProductData ? 3 : 2} style={{ padding: 20 }}>
                  <Center>No {dataType}</Center>
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea.Autosize>
      <Pagination total={totalPages} position="center" page={currentPage} onChange={handlePageChange} />
    </Paper>
  );
};

export default valueExtractorTableComponent;
