import RequestContainer from 'components/containers/request.container';

import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import { selectUsers } from 'pages/admin/manage-users/manage-users.slice';
import manageUsersTableData from 'pages/admin/manage-users/manage-users.tabledata';
import { loadUsers } from 'pages/admin/manage-users/manage-users.thunks';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import TableTabs from 'pages/admin/manage-users/components/manage-user.table-tabs';

const headers = ['Email', 'Manage', 'Switch', 'First Name', 'Last Name', 'Roles', 'Created Date', 'Action'];

const ManageUsers = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { status } = useParams();
  const isPaid = status !== 'inactive' || !status;
  const isSuperAdmin = status === 'superAdmin' || !status;
  const { isFetching } = useFetch('users' + currentPage + status, () =>
    loadUsers({
      page: currentPage,
      isPaid,
      isSuperAdmin,
    })
  );

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectUsers);

  const columns = manageUsersTableData();
  const colSpan = headers.length;
  return (
    <>
      <SubpageContainer title="Manage Users">
        <TableTabs>
          <Table headers={headers}>
            <RequestContainer
              loading={isFetching}
              fallback={<TableShimmer headers={headers} span={colSpan} />}
              colSpan={colSpan}
              data={docs}
            >
              <TableBody columns={columns} data={docs} deleteUrl="/users/superadmin/" confirmField="email" />
            </RequestContainer>
          </Table>
          <TablePagination
            setCurrentPage={setCurrentPage}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            nextPage={nextPage}
            page={page}
            pagingCounter={pagingCounter}
            prevPage={prevPage}
            totalPages={totalPages}
            docs={docs?.length}
          />
        </TableTabs>
      </SubpageContainer>
    </>
  );
};

export default ManageUsers;
