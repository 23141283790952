import Alert from 'components/alerts/alert';
import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import ConfirmDialog from 'components/dialogs-old/confirm.dialog';
import EmptyStates from 'components/empty-states/empty-states';
import { Outlet } from 'react-router-dom';

export default function Subpage({ title, rightElement, children, show }) {
  return (
    <>
      <main className="flex-1 relative overflow-y-auto focus:outline-none max-w-full">
        <div className="py-6">
          <div className={`flex mx-auto px-4 sm:px-6 ${title ? 'mb-3' : 'mb-0'}`}>
            <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900">{title}</h1>
            <div className="flex flex-grow justify-end">
              <If show={rightElement}>{rightElement}</If>
            </div>
          </div>
          <div className="mx-auto px-4 sm:px-6">
            <Outlet />
            <Alert />
            <IfElse show={!show}>
              <EmptyStates text="Nothing to show here, create one by clicking the upload button" />
              {children}
            </IfElse>

            <ConfirmDialog />
          </div>
        </div>
      </main>
    </>
  );
}
