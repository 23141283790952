import { chartColors } from 'lib/colors.helpers';
import { stateInterface } from 'pages/users/keyword-research/keyword-research.interfaces';

export const topSearchesStates: stateInterface = {
  dataSet: null,
  setName: '',
  keywords: [],
  keywordDetails: [],
  relatedKeywords: [],
  relatedKeywordsData: [],
  inputVisible: false,
  searchTerm: '',
  focusedKeyword: null,
  graphLabels: [],
  chartData: [],
  reportData: [],
  monthlyTableData: [],
  fetchedData: [],
  isLoading: false,
  tableDataLoading: false,
  currentPage: 1,
};
export const searchTermReducer = (state, action) => {
  switch (action.type) {
    case 'set_input_visibility':
      return { ...state, inputVisible: !state.inputVisible };
    case 'clear_data':
      return {
        ...state,
        reportData: [],
        fetchedData: [],
        chartData: [],
        relatedKeywords: [],
        keywordDetails: [],
        keywords: [],
      };
    case 'set_report_data':
      return { ...state, reportData: [...state.reportData, action.payload] };
    case 'set_input_value':
      return { ...state, searchTerm: action.payload };
    case 'set_current_page':
      return { ...state, currentPage: action.payload };
    case 'set_focused_keyword':
      return { ...state, focusedKeyword: action.payload };
    case 'add_keyword':
      return { ...state, keywords: [...state.keywords, action.payload] };
    case 'set_keyword_Details':
      return { ...state, keywordDetails: action.payload };
    case 'set_related_keywords':
      return { ...state, relatedKeywords: action.payload };
    case 'set_related_keywords_data':
      return { ...state, relatedKeywordsData: [...state.relatedKeywordsData, action.payload] };
    case 'remove_keyword': {
      const filterKeywords = state.reportData.filter(
        (data) => data.label.toLowerCase() !== action.payload.toLowerCase()
      );
      return { ...state, reportData: [...filterKeywords] };
    }
    case 'set_graph_labels':
      return { ...state, graphLabels: action.payload };
    case 'add_graph_data': {
      const data = [...state.chartData, action.payload];
      data.sort((a, b) => {
        return (
          state.keywords.findIndex((item) => item.keyword === a.label) -
          state.keywords.findIndex((item) => item.keyword === b.label)
        );
      });
      return { ...state, chartData: data };
    }
    case 'update_graph_labels': {
      const data = state.chartData.map((item, index) => {
        item.backgroundColor = chartColors[index];
        item.borderColor = chartColors[index];
        return item;
      });
      return { ...state, chartData: data };
    }
    case 'remove_graph_data': {
      const filterData = state.chartData.filter((data) => data.label.toLowerCase() !== action.payload.toLowerCase());
      return { ...state, chartData: [...filterData] };
    }
    case 'reset_graph_data':
      return { ...state, chartData: [] };
    case 'set_data_set':
      return { ...state, dataSet: action.payload };
    case 'set_set_name':
      return { ...state, setName: action.payload };
    case 'set_fetched_data':
      return { ...state, fetchedData: action.payload };
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    case 'set_table_loading':
      return { ...state, tableDataLoading: action.payload };
    default:
      return state;
  }
};
