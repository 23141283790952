import React, { FC } from 'react';
import { Field } from 'react-final-form';
import FormLabel from 'components/forms/form-label';
import { RadioGroup } from '@headlessui/react';
import { Required } from './validations';
import { RadioGroupInterface } from 'interfaces/magic-links.interface';

/**
 *
 * @param label - input label
 * @param labelSpan - labels column span
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param gap - column gap
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param props - other input props
 * @param options - radio group options
 */
const FormRadioGroup: FC<RadioGroupInterface> = ({
  col = 4,
  label = null,
  labelSpan = 1,
  name,
  gap = 4,
  span = 3,
  options = [],
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const field = (
    <Field name={name} validate={Required}>
      {({ input }) => {
        return (
          <>
            <RadioGroup value={input.value} onChange={input.onChange}>
              <RadioGroup.Label className="sr-only">Link Origin</RadioGroup.Label>
              <div className="grid grid-cols-5">
                {options.map(({ label, value }: { label: string; value: string }, i: number) => (
                  <RadioGroup.Option
                    key={value}
                    value={value}
                    className={({ active }) =>
                      classNames(
                        active ? 'ring-red-600 bg-red-600 border-red-600' : 'border-gray-300',
                        'flex justify-center items-center border p-2 cursor-pointer text-gray-900 hover:border-gray-400 focus:outline-none focus:text-white focus:border-red-500 focus:shadow-sm focus:shadow-red-500',
                        i === 0 ? 'rounded-l-lg' : '',
                        i === options.length - 1 ? 'rounded-r-lg' : ''
                      )
                    }
                  >
                    {() => (
                      <>
                        <div className="flex items-center justify-center">
                          <div className="text-sm">
                            <RadioGroup.Label as="p" className={classNames('font-medium ')}>
                              {label}
                            </RadioGroup.Label>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </>
        );
      }}
    </Field>
  );

  if (!label) return field;
  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} className={`sm:col-span-${labelSpan}`} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormRadioGroup;
