import React, { useState } from 'react';
import { Grid, BackgroundImage, Button, Text, Space } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import InstructionModal from './components/keyword-extractor.instruction-modal';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

interface KeywordExtractorProps {
  hasNext: boolean;
}

const banner = [
  {
    component: 'keyword-extractor',
    title: 'Keyword Extractor',
    content:
      'Keyword Extractor offers a streamlined solution for keyword optimization, enhancing the visibility and ' +
      'reach of your Amazon listings. By employing our advanced AI algorithm, we not only extract keywords but ' +
      "also provide valuable insights to boost your product's performance in the competitive marketplace, " +
      'making it an indispensable tool for your e-commerce success.',
  },
  {
    component: 'apollo',
    title: 'Apollo',
    content:
      'Leverage the power of AI to create titles, bullet points, and Amazon product descriptions effortlessly. ' +
      'Our cutting-edge AI technology is employed to generate these elements by analyzing your top competitors, narrative style, and ' +
      'additional crucial factors, ensuring your product listing stands out. With Apollo, you can optimize your content creation process ' +
      'and stay ahead in the competitive e-commerce landscape, all through the capabilities of artificial intelligence.',
  },
];

function KeywordExtractorBannerComponent({ hasNext }: KeywordExtractorProps) {
  const component = hasNext ? 'apollo' : 'keyword-extractor';
  const [opened, { open, close }] = useDisclosure(false);
  const [minimize, setMinimize] = useState<boolean>(false);
  const Icon = minimize ? ChevronDownIcon : ChevronUpIcon;

  const data = banner.find((b) => b.component === component);

  return (
    <>
      <InstructionModal
        opened={opened}
        close={close}
        title={<Text fw={700}>Instructions on how to use {data?.title}</Text>}
      />
      <BackgroundImage src="/image2.png" radius="md" className="!bg-right-top p-3 relative transition-all">
        <Grid m={0}>
          <Grid.Col
            span={7}
            p={{ lg: 30, sm: 20 }}
            className={`${minimize ? 'flex justify-between items-center' : ''}`}
          >
            <Text size={25} fw={500} color="white">
              {data?.title}
            </Text>
            <div className={`${minimize ? 'hidden' : ''}`}>
              <Space h="sm" />
              <Text fz={{ lg: 'md', sm: 'sm' }} color="white">
                {data?.content}
              </Text>
            </div>
            <Button my={minimize ? 0 : 20} onClick={open}>
              See How it works
            </Button>
          </Grid.Col>
        </Grid>
        <div className={`absolute right-2 flex items-center ${!minimize ? 'bottom-2' : 'inset-y-0'}`}>
          <Button compact rightIcon={<Icon className=" h-5 w-5" />} onClick={() => setMinimize(!minimize)}>
            <Text fw={500}>{minimize ? 'expand' : 'minimize'}</Text>
          </Button>
        </div>
      </BackgroundImage>
    </>
  );
}

export default KeywordExtractorBannerComponent;
