import React, { useEffect } from 'react';
import { LoadingOverlay, Paper, Text } from '@mantine/core';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
import CustomLoader from '../ab-test/components/abtest.loader';
import { chartDataInterface } from 'pages/users/keyword-research/keyword-research.interfaces';
import useTopSearchesEvents from 'pages/users/keyword-research/keyword-research.hooks';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const KeywordSearchChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        reverse: true,
      },
    },
  };
  const { topSearchesStates } = useTopSearchesContext();
  const data: chartDataInterface = {
    labels: topSearchesStates.graphLabels,
    datasets: topSearchesStates.chartData,
  };
  const { loadChartData } = useTopSearchesEvents();

  useEffect(() => {
    if (topSearchesStates.reportData.length !== 0) {
      topSearchesStates.reportData.map((data) => {
        loadChartData(data);
      });
    }
  }, [topSearchesStates.reportData]);
  return (
    <Paper shadow="xs" p="xs" className="mb-3">
      <div className="flex items-center justify-between">
        <Text fz="xl">Search Terms</Text>
        {/*<KeywordResearchChartFilters />*/}
      </div>
      <div className="h-full">
        <div className=" w-full mt-3 relative">
          <LoadingOverlay
            visible={topSearchesStates.isLoading}
            overlayBlur={5}
            loader={<CustomLoader textMessage="Loading Data..." />}
          />
          <Line options={options} data={data} height={'100%'} />
        </div>
      </div>
    </Paper>
  );
};

export default KeywordSearchChart;
