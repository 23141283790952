import { PlusCircleIcon } from '@heroicons/react/solid';
import If from 'components/conditionals/if.conditional';
import FormButton from 'components/forms/form-button';
import { Link } from 'react-router-dom';
import { singular } from 'pluralize';

const SubpageTaskbar = ({ addUrl = '', title = '', children }) => {
  return (
    <div className="w-full flex justify-end gap-10">
      {children}
      <If show={Boolean(addUrl)}>
        <Link to={addUrl}>
          <FormButton
            buttonLabel={`Add ${singular(title?.split(' ').slice(-1)[0])}`}
            leadingIcon={<PlusCircleIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />}
          />
        </Link>
      </If>
    </div>
  );
};

export default SubpageTaskbar;
