function PayoutBadge({ payout }) {
  const colors = {
    processed: 'bg-green-100 text-green-800',
    scheduled: 'bg-purple-100 text-purple-800',
    'not-started': 'bg-black text-white',
  };
  const color = colors[payout] || 'bg-gray-100 text-gray-800';

  return (
    <>
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}>
        {payout}
      </span>
    </>
  );
}

export default PayoutBadge;
