import FormButton from 'components/forms/form-button';
import { localDate } from 'lib/helpers';
import AmazonRenameUnlink from 'pages/users/amazon/amazon-rename.unlink';
import amazonUnlinkAccount from 'pages/users/amazon/amazon-unlink-account';
import { AmazonApiTypes } from 'pages/users/amazon/amazon.interface';

const AmazonAccountHeader = ({ account, unlink }) => {
  return (
    <>
      <div className="block bg-gray-50">
        <div className={`flex items-center px-4 py-2 justify-between`}>
          <div className="min-w-0  flex items-center w-3/4">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
              <div className="flex gap-2 justify-start items-center">
                {/*todo: separate this */}
                <AmazonRenameUnlink adAccount={account} type={AmazonApiTypes.SP} unlink={unlink} />
              </div>
              <p className="flex text-sm text-gray-900 items-center">
                <span className="font-medium pr-2">Created: </span>
                {localDate(account?.createdAt)}
              </p>
            </div>
          </div>
          <div className="flex justify-around items-center gap-4">
            <FormButton
              buttonLabel="Unlink"
              buttonColor="gray"
              classes="rounded-lg py-2"
              onClick={(e) => amazonUnlinkAccount(e, account, AmazonApiTypes.SP)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AmazonAccountHeader;
