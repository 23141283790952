import Badge from 'pages/users/funnels/select-product/select-product.badge';

const ProductDetails = ({ product }) => {
  return product ? (
    <div className="grid grid-cols-1 gap-2">
      <p className="truncate">{product?.name}</p>
      <div className="">
        <div className="flex gap-1">
          <Badge label="asin" value={product?.asin1} />
          <Badge label="sku" value={product?.sellerSku} />
          <Badge label="price" value={product?.price} />
          <Badge label="status" value={product?.status} />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="text-gray-400">{`Product ID: ${product?.productId}`}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProductDetails;
