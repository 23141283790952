import { LinkIcon } from '@heroicons/react/solid';

import StatusBadge from 'components/badges/status.badge';
import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import { FunnelInterface } from 'interfaces/rebates.interface';
import ActionButtons from 'pages/users/funnels/deploy-buttons/deploy.buttons';
import { Link } from 'react-router-dom';

function funnelsTableData(): TableColumnRowInterface[] {
  const columnData = [
    'name',
    'unitsSold',
    'sales',
    'addToCart',
    'pageViews',
    {
      key: 'customers',
      render: (rowData: FunnelInterface) => {
        const funnelId = rowData?._id;
        return rowData?.site?.url ? (
          <Link to={`${funnelId}/funnel-customers`} className="flex items-center hover:text-red-600">
            View
          </Link>
        ) : (
          <>-</>
        );
      },
    },
    {
      key: 'url',
      render: (rowData: FunnelInterface) => {
        const site = rowData?.site?.url;
        return site ? (
          <a
            className="text-red-600 flex items-center gap-2 hover:text-red-800"
            href={`https://${site}.${process.env.REACT_APP_FUNNEL_DOMAIN}`}
            target="_blank"
          >
            <LinkIcon className="h-4 w-4" />
            {` https://${site}.${process.env.REACT_APP_FUNNEL_DOMAIN}`}
          </a>
        ) : (
          <>-</>
        );
      },
    },
    'rebateCounter',
    {
      key: 'account',
      render: (rowData: FunnelInterface) => {
        const accountName = rowData?.sellerAccount?.name;
        return <div>{accountName || '-'}</div>;
      },
    },
    {
      key: 'status',
      render: (rowData: FunnelInterface) => {
        const status = rowData.status;
        return <StatusBadge text={status} />;
      },
    },
    {
      key: 'action',
      render: (rowData: FunnelInterface) => {
        const site = rowData?.site?.url;
        return site ? <ActionButtons rowData={rowData} /> : <div className="flex justify-center">-</div>;
      },
    },
  ];

  return tableColumns(columnData, { url: 'rebates/info', field: 'name' });
}

export default funnelsTableData;
