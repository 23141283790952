import { editAmazonAdsAccounts, setAmazonAdsAccounts } from 'pages/users/amazon/amazon-ads.slice';
import { getAmazonTypeUrl } from 'pages/users/amazon/amazon.helper';
import {
  AmazonApiTypes,
  AmazonDataInterface,
  AmazonLoginInterface,
  AmazonLoginRedirectInterface,
  AmazonTokenRedirect,
} from 'pages/users/amazon/amazon.interface';
import { editAmazonAccounts, setAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import { store } from 'redux/store';
import { getSilentFetch, postSilentFetch } from 'request/request';

export const login = async ({
  country,
  type = AmazonApiTypes.SP,
  sellerAccount,
}: AmazonLoginInterface & { sellerAccount: string }): Promise<{ loginUrl: string }> => {
  // country = /region/countryID
  // eg: sp/login-url/na/ATVPDKIKX0DER
  // eg: ads/login-url/www
  const customUri = type === AmazonApiTypes.SP ? `${country}` : `${country}/${sellerAccount}`;
  return getSilentFetch(`/${getAmazonTypeUrl(type)}/accounts/login-url/${customUri}`);
};

export const loginRedirect = async (
  values: AmazonLoginRedirectInterface,
  type = AmazonApiTypes.SP
): Promise<{ redirectUrl: string }> => {
  return postSilentFetch(`/${getAmazonTypeUrl(type)}/accounts/login-redirect`, values);
};

export const tokenRedirect = async (
  values: AmazonTokenRedirect,
  type = AmazonApiTypes.SP
): Promise<{ message: string }> => {
  return postSilentFetch(`/${getAmazonTypeUrl(type)}/accounts/refresh-token`, values);
};

const getAmazonUrl = (type: AmazonApiTypes) => {
  return getSilentFetch(`/${getAmazonTypeUrl(type)}/accounts/connected-accounts`);
};

export const amazonAccounts = async (): Promise<{
  sellerAccounts: AmazonDataInterface;
  adsAccounts: AmazonDataInterface;
}> => {
  const [sellerAccounts, adsAccounts] = await Promise.all([
    getAmazonUrl(AmazonApiTypes.SP),
    getAmazonUrl(AmazonApiTypes.ADS),
  ]);

  store.dispatch(setAmazonAccounts(sellerAccounts));
  store.dispatch(setAmazonAdsAccounts(adsAccounts));

  return { sellerAccounts, adsAccounts };
};

export const removeAdsInAmazonAccount = (sellerAccounts: AmazonDataInterface[], sellerAccountId: string) => {
  const mergedAccounts = sellerAccounts.map((acc) => {
    const copy = { ...acc };
    if (copy._id === sellerAccountId) {
      copy.adAccount = undefined;
    }
    return copy;
  });

  store.dispatch(setAmazonAccounts(mergedAccounts));
};

export const saveAmazonAccountName = async (account: AmazonDataInterface, type: AmazonApiTypes): Promise<void> => {
  const res = await postSilentFetch(`/${getAmazonTypeUrl(type)}/accounts/rename-account`, account);
  if (res) {
    const setState = type === AmazonApiTypes.SP ? editAmazonAccounts : editAmazonAdsAccounts;
    store.dispatch(setState(account));
  }
};
