export interface ButtonGroupsInterface {
  children: React.ReactNode | string;
  className?: string;
  value: string;
}

function ButtonGroups({
  items,
  onClick,
  currentValue,
  selectedClass = 'bg-red-500 text-white',
  customClass,
  defaultSelectedClass = 'bg-white text-gray-700',
}: {
  items: ButtonGroupsInterface[];
  currentValue: string;
  onClick(string): void;
  customClass?: string;
  selectedClass?: string;
  defaultSelectedClass?: string;
}) {
  const classes = customClass
    ? customClass
    : 'relative w-full items-center px-4 py-2 border border-gray-300  text-sm font-medium';
  return (
    <>
      <div className="relative z-0 flex w-full text-center shadow-sm rounded-md">
        {items.map((i, index) => {
          const edgeClasses = index === 0 ? 'rounded-l-md' : index === items.length - 1 ? 'rounded-r-md' : '';
          const relative = index !== 0 ? '-ml-px' : '';
          const isSelected = i.value === currentValue ? selectedClass : defaultSelectedClass;
          return (
            <button
              onClick={() => onClick(i.value)}
              key={i.value}
              type="button"
              className={` ${relative} ${edgeClasses} ${isSelected} ${classes} `}
            >
              {i.children}
            </button>
          );
        })}
      </div>
    </>
  );
}

export default ButtonGroups;
