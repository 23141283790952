import React, { createContext, useContext, ReactNode } from 'react';
import { emptyFunction } from 'lib/empty';
import { SuggestionResponse } from '../../apollo.interfaces';

export type ListingInterface = {
  title: string;
  bullets: string[];
  description: string;
  tags: string[];
  suggestion: SuggestionResponse[];
  setSelectedTitle: (string) => void;
  setSelectedBullets: (arr: string[]) => void;
  setDescription: (string) => void;
  setTags: (tags: string[]) => void;
  setSuggestion: (suggestion: SuggestionResponse[]) => void;
};

const ListingContext = createContext({
  title: '',
  bullets: [],
  description: '',
  suggestion: [],
  tags: [],
  setSelectedTitle: emptyFunction,
  setSelectedBullets: emptyFunction,
  setDescription: emptyFunction,
  setTags: emptyFunction,
  setSuggestion: emptyFunction,
} as ListingInterface);

export const listingContext = () => {
  return useContext(ListingContext);
};

export const ListingTabsProvider = ({ children, data }: { data: ListingInterface; children: ReactNode }) => {
  return <ListingContext.Provider value={{ ...data }}>{children}</ListingContext.Provider>;
};
