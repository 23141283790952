import { setFunnelCustomers } from 'pages/users/funnels/funnel-customers/funnel-customers.slice';
import { store } from 'redux/store';
import { postSilentFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const loadFunnelCustomers = async (values): Promise<ResponseListInterface> => {
  const res = await postSilentFetch('/rebates-customer/funnels-customers', values);
  if (res) {
    store.dispatch(setFunnelCustomers(res));
  }
  return res;
};
