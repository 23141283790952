import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapInterface, ResponseHeatmapListInterface } from 'interfaces/heatmap.interface';
import { RootState } from 'redux/store';

const initialState: ResponseHeatmapListInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const heatMapsListSlice = createSlice({
  name: 'heatMapList',
  initialState,
  reducers: {
    setHeatmaps: (state: Draft<ResponseHeatmapListInterface>, action: PayloadAction<ResponseHeatmapListInterface>) => {
      return action.payload;
    },
    editHeatmap: (state: Draft<ResponseHeatmapListInterface>, action: PayloadAction<HeatmapInterface>) => {
      const { docs: heatmaps } = state;
      const updatedHeatmaps = heatmaps.map((h) => (h._id === action?.payload._id ? action?.payload : h));
      return { ...state, docs: updatedHeatmaps, totalDocs: updatedHeatmaps?.length };
    },
    deleteHeatmap: (state: Draft<ResponseHeatmapListInterface>, action: PayloadAction<string>) => {
      const { docs: heatmaps } = state;
      const updatedHeatmaps = heatmaps.filter((h) => h._id !== action.payload);
      return { ...state, docs: updatedHeatmaps, totalDocs: updatedHeatmaps?.length };
    },
  },
});

export const { setHeatmaps, editHeatmap, deleteHeatmap } = heatMapsListSlice.actions;
export const selectHeatMapList = (state: RootState) => state.heatMapList;
export const heatMapListReducer = heatMapsListSlice.reducer;
