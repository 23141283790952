import { PackagesInterface, PlanDetailsInterface } from 'pages/users/subscription/plans/plans.interface';
import { getSilentFetch, postSilentFetch } from 'request/request';

export const getPlans = async (): Promise<PackagesInterface> => {
  return await getSilentFetch('/credits/plans');
};

export const cancelSubscription = async (): Promise<PackagesInterface> => {
  return await postSilentFetch('/credits/cancel', {});
};

export const changePlan = async (stripePriceId: string): Promise<PackagesInterface> => {
  return await postSilentFetch('/credits/change-plan', { stripePriceId });
};

export const getCredits = async (): Promise<PlanDetailsInterface[]> => {
  return await getSilentFetch('/credits/credits');
};
