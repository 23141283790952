import SubpageContainer from 'components/containers/subpage.container';
import Card from 'components/card/card';
import { Button } from '@mantine/core';
import React from 'react';

export default function ApolloCTA() {
  return (
    <SubpageContainer title="">
      <Card>
        <div className="max-w-7xl mx-auto text-center py-12 px-4">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Apollo</span>
          </h2>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 ">Image Here</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="text-left text-gray-500 text-sm">
                Quisque tellus mauris, vehicula non quam vel, tempor laoreet dui. Cras luctus tortor mi, non porta
                ligula sagittis quis. Phasellus gravida sem vel semper congue. Vestibulum nunc dui, dictum eget magna
                vulputate, elementum consectetur risus. Fusce bibendum, quam nec ullamcorper gravida, velit metus
                consequat purus, quis ornare massa nisi sed nibh. Nam vel ultrices magna, sed convallis sem. In lectus
                mauris, ornare in leo a, accumsan rhoncus sapien. Fusce dignissim viverra interdum. Cras vitae interdum
                justo. Morbi eu erat nec odio vehicula tincidunt quis id orci. Pellentesque habitant morbi tristique
                senectus et netus et malesuada fames ac turpis egestas. Phasellus ultricies risus ac lacinia luctus.
                Pellentesque malesuada tincidunt velit sed facilisis.
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Button>Start</Button>
            </div>
          </div>
        </div>
      </Card>
    </SubpageContainer>
  );
}
