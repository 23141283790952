import { Listbox, Transition } from '@headlessui/react';
import FormLabel from 'components/forms/form-label';
import { tailwindColors } from 'lib/colors.helpers';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

function getTailwindColor(color: string) {
  if (color === 'black') {
    return 'bg-black';
  } else if (color === 'white') {
    return `bg-white`;
  } else {
    return `bg-${color.toLowerCase()}-500`;
  }
}

export default function FormColors({ span = 3, gap = 4, col = 4, name, label = '' }) {
  const field = (
    <>
      <Field type="text" name={name}>
        {(props) => (
          <div>
            <div>
              <Listbox value={props.input.value} onChange={props.input.onChange}>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="border border-gray-300 rounded-md shadow-sm p-1 text-left cursor-pointer">
                        <div className={`h-8 w-10 ${props.input.value}`}>&nbsp;</div>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="grid grid-cols-7 w-48 gap-1 absolute ml-1 pl-1 z-10 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {tailwindColors.map((tailwindColor) => (
                            <Listbox.Option
                              key={tailwindColor.name}
                              className="h-5 w-5 cursor-pointer"
                              value={getTailwindColor(tailwindColor.name)}
                            >
                              <div
                                className={`h-5 w-5 ${getTailwindColor(tailwindColor.name)} border border-slate-200 `}
                              >
                                &nbsp;
                              </div>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        )}
      </Field>
    </>
  );

  if (!label) return field;
  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:grid-row-1 sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
}
