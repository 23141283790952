import SubpageContainer from 'components/containers/subpage.container';
import FunnelBasicInfo from 'pages/users/funnels/funnel-basic-info';
import React from 'react';

export default FunnelForm;

function FunnelForm() {
  return (
    <>
      <SubpageContainer title="Create Funnel">
        <div className="bg-white overflow-hidden rounded-lg">
          <div className="px-4 sm:p-6 space-y-6">
            <FunnelBasicInfo />
          </div>
        </div>
      </SubpageContainer>
    </>
  );
}
