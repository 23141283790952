import { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { searchResults } from 'pages/users/apollo/keyword-extractor/keyword-extractor.thunks';
import { QueryInterface } from 'pages/users/apollo/keyword-extractor/keyword-extractor.interfaces';

export default function () {
  const [active, setActive] = useState(0);
  const [keywordCount, setkeywordCount] = useState<number>(0);
  const [asinCount, setasinCount] = useState<number>(0);
  const [keywords, setKeywords] = useState([] as string[]);
  const [keyword, setKeyword] = useState(' ');
  const [dataSource, setDataSource] = useState('brand-analytics');
  const [asins, setAsins] = useState([] as string[]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchAsin, setSearchAsin] = useState('');

  const resetContextValues = () => {
    setSearchAsin('');
    setActive(0);
    setkeywordCount(0);
    setasinCount(0);
    setKeywords([]);
    setKeyword(' ');
    setDataSource('brand-analytics');
    setAsins([]);
    setSearchKeyword('');
    setSearchAsin('');
  };
  return {
    active,
    setActive,
    keywords,
    keyword,
    setKeywords,
    setKeyword,
    asins,
    setAsins,
    keywordCount,
    setkeywordCount,
    asinCount,
    setasinCount,
    searchKeyword,
    setSearchKeyword,
    searchAsin,
    setSearchAsin,
    dataSource,
    setDataSource,
    resetContextValues,
  };
}

export function useSimilar() {
  const [keywordQueries, setKeywordQueries] = useState<QueryInterface[]>([]);
  const [asinQueries, setAsinQueries] = useState<QueryInterface[]>([]);

  function getResults(keywords, asins, dataSource) {
    const asinQueries = asins.map((asin) => {
      return {
        queryKey: [asin, 'asins', dataSource],
        queryFn: () => searchResults({ keyword: asin, type: 'asin', dataSource }),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24,
        retryOnMount: false,
        refetchOnWindowFocus: false,
      };
    });
    const keywordQueries = keywords.map((keyword) => {
      return {
        queryKey: [keyword, 'keywords', dataSource],
        queryFn: () => searchResults({ keyword, type: 'keyword', dataSource }),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24,
        retryOnMount: false,
        refetchOnWindowFocus: false,
      };
    });
    setKeywordQueries(keywordQueries);
    setAsinQueries(asinQueries);
  }

  function rankResults(keywords) {
    const result: any = [];
    const maxLength = Math.max(...keywords.map((arr) => arr.length));
    for (let i = 0; i < maxLength; i++) {
      const temp: any = [];
      for (const keyword of keywords) {
        if (keyword[i]) {
          temp.push(keyword[i]);
        }
      }
      temp.sort((a, b) => a.sfr - b.sfr);
      result.push(...temp);
    }

    return result;
  }
  const queryResults: any = useQueries({
    queries: [...asinQueries, ...keywordQueries],
  });
  const isLoading = queryResults.some((queryResult) => queryResult.status === 'loading');
  return {
    queryResults,
    rankResults,
    getResults,
    isLoading,
  };
}
