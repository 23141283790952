import { format, fromUnixTime } from 'date-fns';
import { dateFormat, dateFormatTime } from 'lib/global-config';
import { safeDivide } from 'pages/users/analytics/analytics.helper';

export const tryIfNAN = function (number: number): number {
  return isNaN(+number) ? 0 : number;
};
export const moneyFormat = function (number: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(tryIfNAN(+number));
};

export const numberFormat = function (number: number | string): string {
  const formatter = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 });

  return formatter.format(tryIfNAN(+number));
};

export const percentageFormat = function (number: number | string): string {
  return parseFloat((100 * tryIfNAN(+number)).toFixed(2)) + '%';
};

export const percentageNumberFormat = function (number: number | string): number {
  return 100 * tryIfNAN(+number);
};

export const friendlyDate = function (date: Date) {
  return format(date, dateFormat);
};

export const getPercentage = function (numerator: number, denomirator: number): number {
  return parseFloat((100 * tryIfNAN(safeDivide(numerator, denomirator))).toFixed(2));
};
export function epochToDate(epoch: number) {
  return friendlyDate(fromUnixTime(epoch));
}

export const friendlyDateTime = function (date: Date) {
  return format(date, dateFormatTime);
};

export const stripeNumberToMoney = function (amount): string {
  return moneyFormat(safeDivide(amount, 100));
};
