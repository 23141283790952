import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loadImages } from 'pages/users/heatmap/images/heatmap-images.thunks';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectDeleteDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { selectHeatMapItem } from 'pages/users/heatmap/heat-map-item.slice';

export const useHeatmapImages = (currentPage: number, queryKey: string) => {
  const queryClient = useQueryClient();

  const [enabled, setFetchEnabled] = useState<boolean>(false);
  const { id } = useParams();

  const { isOpen } = useAppSelector(selectDeleteDialog);
  const { isOpen: editModalOpen } = useAppSelector(selectHeatMapItem);

  const { data } = useQuery({
    queryKey: [queryKey, { id }],
    queryFn: async () => loadImages(id, currentPage),
    refetchInterval: 10000,
    cacheTime: 0,
    enabled,
  });

  useEffect(() => {
    setFetchEnabled(data?.docs.filter((x) => x.status !== 'done').length !== 0);
  }, [data]);

  useEffect(() => {
    queryClient.fetchQuery([queryKey, { id }]);
  }, [isOpen, editModalOpen]);

  return {
    data: {
      docs: data?.docs || [],
      hasNextPage: data?.hasNextPage || false,
      hasPrevPage: data?.hasPrevPage || false,
      nextPage: data?.nextPage || null,
      page: data?.page || 0,
      pagingCounter: data?.pagingCounter || 0,
      prevPage: data?.prevPage || null,
      totalPages: data?.totalPages || 0,
    },
  };
};
