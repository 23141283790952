import { InitiatedPaymentInterface } from 'pages/users/subscription/payment-method/payment-method.interface';
import { initiatePaymentMethod } from 'pages/users/subscription/payment-method/payment-method.thunks';
import { paymentIntentRegistered } from 'pages/users/subscription/subscription.thunks';
import { useCallback, useEffect, useState } from 'react';

export function usePaymentMethodIntent() {
  const [initiatePayment, setInitiatePayment] = useState<InitiatedPaymentInterface>({ email: '', clientSecret: '' });

  const getPayment = useCallback(async () => {
    const data = await initiatePaymentMethod();
    if (data) {
      setInitiatePayment(data);
    }
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return initiatePayment;
}

export function usePaymentRegisteredIntent(priceId: string) {
  const [clientSecret, setClientSecret] = useState<string>('');

  const getPayment = useCallback(async () => {
    const data = await paymentIntentRegistered(priceId);
    if (data) {
      setClientSecret(data.clientSecret);
    }
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return clientSecret;
}
