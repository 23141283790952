import { PackageTypes, PlanType } from 'pages/users/subscription/plans/plans.interface';

const tabs = [
  { name: 'Dashboard Plans', href: '#', type: PlanType.BASIC },
  { name: 'PPC Plans', href: '#', type: PlanType.PPC },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PlanHeader({
  setPlanType,
  planType,
  setSubcriptionType,
  subcriptionType,
}: {
  setPlanType(PlanType): void;
  planType: PlanType;
  setSubcriptionType(PackageTypes): void;
  subcriptionType;
}) {
  return (
    <div className="grid grid-cols-2">
      <div className="">
        <div>
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.type === planType
                      ? 'border-red-500 text-red-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.type === planType ? 'page' : undefined}
                  onClick={() => setPlanType(tab.type)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="relative mt-2 flex justify-center sm:mt-6">
          <div className="bg-red-700 p-0.5 rounded-lg flex">
            <button
              type="button"
              onClick={() => setSubcriptionType(PackageTypes.MONTHLY)}
              className={classNames(
                subcriptionType === PackageTypes.MONTHLY
                  ? 'border-red-500 text-red-600 bg-white'
                  : 'border-transparent text-white hover:text-gray-700 hover:border-gray-300',
                'relative   py-2 px-6 border-red-700 rounded-md shadow-sm text-sm font-medium whitespace-nowrap hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-700 focus:ring-white focus:z-10'
              )}
            >
              Monthly
            </button>
            <button
              type="button"
              onClick={() => setSubcriptionType(PackageTypes.ANNUAL)}
              className={classNames(
                subcriptionType === PackageTypes.ANNUAL
                  ? 'border-red-500 text-red-600 bg-white'
                  : 'border-transparent text-white hover:text-gray-700 hover:border-gray-300',
                'relative   py-2 px-6 border-red-700 rounded-md shadow-sm text-sm font-medium whitespace-nowrap hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-700 focus:ring-white focus:z-10'
              )}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
