export interface StatisticsColumnsInterface {
  name: string;
  stat: string | number;
  color: string;
  column: string;
}

export type StatisticsColumnsWithFormatInterface = Omit<StatisticsColumnsInterface, 'stat'> & {
  formatter: (number) => string | number;
};

export type StatisticsTableColumns = Pick<StatisticsColumnsWithFormatInterface, 'column' | 'formatter'>;

export interface ReportsByDateParamsInterface {
  endDate: number;
  startDate: number;
  amazonAdsUserId: string;
  amazonUserId: string;
}

export interface ReportsByDateInterface {
  endDate: number;
  startDate: number;
  amazonAdsUserId: string | null;
  data: AdsSalesReportsInterface[];
}

export type AdsSalesReportsInterface = {
  _id: string;
  amazonUserId: string;
  amazonAdsUserId: string;
  date: number;
  user: string;
  clicks: number;
  cost: number;
  impressions: number;
  purchases7d: number;
  sponsoredSales: number;
  sales: number;
  sponsoredSalesShare: number;
  tacos: number;
  acos: number;
  cr: number;
  cpc: number;
  roas: number;
};

export interface AmazonMoneyInterface {
  amount: number;
  currencyCode: string;
}

export type SalesInterface = {
  date: number;
  unitCount: number;
  orderItemCount: number;
  orderCount: number;
  averageUnitPrice: AmazonMoneyInterface;
  totalSales: AmazonMoneyInterface;
  amazonUser: string;
};

export enum LineCharAxis {
  Y = 'y',
  Y1 = 'y1',
}

export interface LineChartDataInterface {
  label: string;
  borderColor: string;
  backgroundColor: string;
  tension: number;
  data: number[];
  yAxisID: LineCharAxis;
}
