import { ShimmerLabel, ShimmerRow, ShimmerWrap } from 'components/shimmer/utils';

const SiteLabelsShimmer = () => (
  <div className="w-100">
    <ShimmerWrap gap={4}>
      <ShimmerRow marginTop={4}>
        {Array.from({ length: 6 }).map((_, i) => (
          <ShimmerLabel key={i} />
        ))}
      </ShimmerRow>
    </ShimmerWrap>
  </div>
);

export default SiteLabelsShimmer;
