import { LinkIcon } from '@heroicons/react/outline';
import { LinkStatus } from 'components/badges/link-status.badge';
import FormInputAddon from 'components/forms/form-input-addon';
import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import { MagicLinksInterface } from 'interfaces/magic-links.interface';
import { friendlyDate } from 'lib/formatting.helpers';

function magicLinkTabledata(): TableColumnRowInterface[] {
  const columnData = [
    'name',
    {
      key: 'originalLink',
      render: (magicLink: MagicLinksInterface) => (
        <a
          target="_blank"
          href={magicLink.link}
          className="col-span-1 inline-flex justify-center items-center p-3 text-gray-500 hover:text-red-500"
        >
          <LinkIcon className="h-4 w-4" />
          Link
        </a>
      ),
    },
    {
      key: 'redirectLink',
      render: (magicLink: MagicLinksInterface) =>
        magicLink.status === 'working' ? <FormInputAddon link={magicLink.redirectLink} /> : <></>,
    },

    {
      key: 'status',
      render: (magicLink: MagicLinksInterface) => <LinkStatus link={magicLink.status} />,
    },
    {
      key: 'createdAt',
      render: (magicLink: MagicLinksInterface) => <span>{friendlyDate(new Date(magicLink.createdAt))}</span>,
    },
  ];

  return tableColumns(columnData);
}

export default magicLinkTabledata;
