import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { BrandsInterface } from 'interfaces/brands.interface';

const initialState: BrandsInterface[] = [
  {
    _id: '',
    name: '',
    label: '',
    seller: '',
  },
];

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setBrands: (state: Draft<BrandsInterface[]>, action: PayloadAction<BrandsInterface[]>) => {
      return action.payload;
    },
    deleteBrands: (state: Draft<BrandsInterface[]>, action: PayloadAction<string>) => {
      const brands = state;
      const updatedBrands = brands.filter((u: BrandsInterface) => u._id !== action.payload);
      return updatedBrands;
    },
  },
});

export const { setBrands, deleteBrands } = brandsSlice.actions;

export const selectBrands = (state: RootState) => state.brands;

export const brandsReducer = brandsSlice.reducer;
