import { CouponInterface, PaymentIntentInterface } from 'pages/users/signup/signup.interface';
import { PackageInterface, PackageTypes } from 'pages/users/subscription/plans/plans.interface';

import { getSilentFetch, postFetch, postSilentFetch } from 'request/request';

export const register = async (values): Promise<{ _id: string }> => {
  return postFetch(`/users/register/`, values, 'Please check your email for confirmation');
};

export const getPackage = async (priceId: string, type: PackageTypes): Promise<null | PackageInterface> => {
  return await getSilentFetch(`/credits/packages/${priceId}/${type}`);
};

export const getPaymentIntertStatus = async (paymentIntent: string): Promise<{ status: string }> => {
  return await getSilentFetch(`/credits/packages/verify-signup/${paymentIntent}`);
};

export const checkUserCheckout = async (userId: string): Promise<{ _id: string }> => {
  return getSilentFetch(`/users/check-user-checkout/${userId}`);
};
export const checkCouponCode = async (coupon: string, productId: string | undefined): Promise<CouponInterface> => {
  return await postSilentFetch(`/credits/packages/check-coupon/`, { coupon, productId });
};

export const paymentIntent = async (
  userId: string | undefined,
  priceId: string | undefined,
  type: string,
  coupon?: string | undefined
): Promise<PaymentIntentInterface> => {
  return await postSilentFetch(`/credits/packages/subscription/`, { userId, priceId, interval: type, coupon });
};
