import React, { ReactElement } from 'react';
import { PromptTabs } from 'pages/admin/prompts/tabs/prompt.tabs';
import { Text, Textarea } from '@mantine/core';
import { promptContext } from 'pages/admin/prompts/prompt.context';

export const PromptTitles = (): ReactElement => {
  const { titles, setTitles } = promptContext();
  return (
    <PromptTabs>
      <div>
        <Text className="mb-2" fw={500} size="md">
          Prompt 1
        </Text>
        <Textarea
          variant="filled"
          value={titles.prompt1}
          onChange={(data) =>
            setTitles({
              prompt1: data.target.value,
              model: titles.model,
            })
          }
          autosize
        />
      </div>
    </PromptTabs>
  );
};
