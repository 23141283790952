import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, List, ThemeIcon, Text, Divider } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { apolloData } from 'pages/users/apollo/apollo.thunk';
import SubpageContainer from 'components/containers/subpage.container';
import { IconRobot, IconPrompt } from '@tabler/icons';

export const ApolloPrompts = (): ReactElement => {
  const { id } = useParams();

  const { data } = useQuery(['apollo-data-', id], {
    queryFn: () => apolloData(id || '', 'titles'),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <SubpageContainer title="Apollo Prompt">
      <Card>
        <List spacing={'md'} size="sm">
          {data?.prompt?.prompts.map((x) => {
            if (x.human || x.system) {
              return (
                <>
                  <List.Item
                    p={10}
                    icon={
                      <ThemeIcon color={x.system ? 'red' : 'teal'} size={24} radius="xl">
                        <IconPrompt size="1rem" />
                      </ThemeIcon>
                    }
                  >
                    <Text style={{ whiteSpace: 'pre-line' }}>{x.human || x.system}</Text>
                  </List.Item>
                  <Divider my="sm" pb={4} variant="dashed" />
                </>
              );
            } else if (x.ai) {
              return (
                <>
                  <List.Item
                    bg={'light-gray'}
                    p={10}
                    icon={
                      <ThemeIcon color="blue" size={24} radius="xl">
                        <IconRobot size="1rem" />
                      </ThemeIcon>
                    }
                  >
                    {' '}
                    <Text style={{ whiteSpace: 'pre-line' }}>{x.ai}</Text>
                  </List.Item>
                  <Divider my="sm" p={8} variant="dashed" label={`${x.model?.toUpperCase()}`} labelPosition="center" />
                </>
              );
            }
          })}
        </List>
        {/*<Text style={{ whiteSpace: 'pre-line' }}>{prompts}</Text>*/}
      </Card>
    </SubpageContainer>
  );
};
