import React, { ReactElement } from 'react';
import IfElse from 'components/conditionals/if-else.conditional';

export const MTableWrapper = ({ hasData, children }): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-4 pb-0 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <IfElse show={!hasData}>
            <table className="w-full">{children}</table>
            {children}
          </IfElse>
        </div>
      </div>
    </div>
  );
};
