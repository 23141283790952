import React, { ReactElement, Suspense } from 'react';
import { Button, Text, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import SubpageContainer from 'components/containers/subpage.container';
import { ABTestGoalsSelection } from 'pages/users/ab-test/components/abtest.goals-selection';
import { ABTestGoalInterface } from 'pages/users/ab-test/abtest.interface';
import { useABTest } from 'pages/users/ab-test/abtest.hook';
import { ABTestGoalProvider } from 'pages/users/ab-test/abtest.goal-context';
import CustomLoader from './components/abtest.loader';

export const ABTestGoal = (): ReactElement => {
  const { goal, setGoal, navigate } = useABTest(false);
  const [visible, { toggle }] = useDisclosure(false);

  const goals: ABTestGoalInterface[] = [
    { name: 'Awareness', value: 'awareness', image: 'goal1.png' },
    { name: 'Product Sales', value: 'productSales', image: 'goal3.png' },
    { name: 'Clicks', value: 'clicks', image: 'goal2.png' },
  ];

  function goToDataList() {
    toggle();
    setTimeout(() => {
      navigate(`/dashboard/abtests`);
    }, 5000);
  }
  return (
    <SubpageContainer title={''}>
      <LoadingOverlay
        visible={visible}
        overlayBlur={5}
        loader={<CustomLoader textMessage="Analyzing your AB tests 🚀, we will redirect you after 5 seconds." />}
      />
      <ABTestGoalProvider data={{ selection: goal, setSelection: setGoal }}>
        <div className="flex flex-col">
          <div className="w-1/2 self-center text-center">
            <Text fz="xl" fw={700}>
              Select Your Ad Goal
            </Text>
            <Text c="dimmed" fz="sm" className="py-2">
              Signalytics A/B Testing will optimize your result based on your selected Ad Goal
            </Text>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <div className="px-60 py-5">
              <div className="grid grid-cols-3 gap-6 ">
                {goals.map((x: ABTestGoalInterface) => {
                  return <ABTestGoalsSelection key={x.value} {...x} />;
                })}
              </div>
            </div>
          </Suspense>
          <div className="self-center py-10">
            <Button
              color="dark"
              size="md"
              onClick={goToDataList}
              disabled={goal === ''}
              loading={visible}
              loaderPosition="center"
            >
              Analyze
            </Button>
          </div>
        </div>
      </ABTestGoalProvider>
    </SubpageContainer>
  );
};
