import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import SubpageContainer from 'components/containers/subpage.container';
import ShimmerWrapper from 'components/shimmer';
import SlideOver from 'components/slide-over/slide-over';
import get from 'lodash.get';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import PaymentMethod from 'pages/users/subscription/payment-method/payment-method';
import UpdatePaymentMethods from 'pages/users/subscription/payment-method/update-payment-methods';
import PlanDetails from 'pages/users/subscription/plans/plan-details';
import PlanHeader from 'pages/users/subscription/plans/plan-header';
import Plans from 'pages/users/subscription/plans/plans';
import { PackageTypes, PlanType } from 'pages/users/subscription/plans/plans.interface';
import { getSubscription } from 'pages/users/subscription/subscription.thunks';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function Subscription() {
  const { data, isFetching } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => getSubscription(),
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    cacheTime: 0,
  });
  const [planType, setPlanType] = useState(PlanType.BASIC);
  const [subscriptionType, setSubscriptionType] = useState(PackageTypes.MONTHLY);

  useEffect(() => {
    const defaultPlanType = get(data, 'plan.packagePlan.type', 'basic') === 'ppc' ? PlanType.PPC : PlanType.BASIC;
    const defaultPlanInterval =
      get(data, 'plan.interval', 'month') === 'year' ? PackageTypes.ANNUAL : PackageTypes.MONTHLY;
    setPlanType(defaultPlanType);
    setSubscriptionType(defaultPlanInterval);
  }, [data]);

  const plan: any = data?.plan;
  const upcoming: any = data?.upcoming;
  return (
    <SubpageContainer title="Plans and Billing">
      <ShimmerWrapper loading={isFetching} fallback={<RebatesShimmer />}>
        <If show={!isFetching}>
          <IfElse show={plan?.status !== 'canceled'}>
            <div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-lg">
                  <If show={plan && plan.id}>
                    <PlanDetails plan={plan} upcoming={upcoming} />
                  </If>
                </div>
                <PaymentMethod />
              </div>
              <div className="space-y-4 mt-4">
                <If show={plan && plan.id}>
                  <PlanHeader
                    setPlanType={setPlanType}
                    planType={planType}
                    subcriptionType={subscriptionType}
                    setSubcriptionType={setSubscriptionType}
                  />
                  <Plans
                    planType={planType}
                    type={subscriptionType}
                    currentPlanPackage={plan}
                    planStatus={plan?.status}
                  />
                </If>
              </div>
            </div>
            <If show={plan && plan.id}>
              <PlanHeader
                setPlanType={setPlanType}
                planType={planType}
                subcriptionType={subscriptionType}
                setSubcriptionType={setSubscriptionType}
              />
              <Plans planType={planType} type={subscriptionType} currentPlanPackage={plan} planStatus={plan?.status} />
            </If>
          </IfElse>
        </If>
        <SlideOver>
          <UpdatePaymentMethods />
        </SlideOver>
      </ShimmerWrapper>
    </SubpageContainer>
  );
}
