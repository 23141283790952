import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { SlideOverInterface } from './slide-over.interface';

const initialState: SlideOverInterface = {
  title: '',
  open: false,
  children: null,
};

export const SliderOverSlice = createSlice({
  name: 'sliderOver',
  initialState,
  reducers: {
    toggleSliderOver: (state: Draft<SlideOverInterface>, action: PayloadAction<SlideOverInterface>) => {
      state.open = action.payload.open;
      state.children = !action.payload.open ? null : action.payload.children;
    },
  },
});

export const { toggleSliderOver } = SliderOverSlice.actions;
export const selectSliderOver = (state: RootState) => state.sliderOver;
export const sliderOverReducer = SliderOverSlice.reducer;
