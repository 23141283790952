import { ReactElement } from 'react';
import { TableColumnType, TableLinkableType } from './tables.interface';
import { Link } from 'react-router-dom';

export interface TableColumnRowInterface {
  key: string;
  render: (value) => ReactElement;
}

export type TableDataInterface = string | TableColumnRowInterface;

/**
 * converts array of string or TableColumnRowInterface into an array of TableColumnRowInterface
 * @param arrColumns
 * @param linkableField
 */

export function tableColumns(
  arrColumns: TableColumnType[],
  linkableField?: TableLinkableType
): TableColumnRowInterface[] {
  return arrColumns.map((field: TableColumnType): TableColumnRowInterface => {
    // if the column is a linkable field
    if (linkableField && field === linkableField.field) {
      return {
        key: linkableField.field,
        render: (item) => (
          <Link to={`${linkableField.url}/${item._id}`} className="cursor-pointer hover:text-red-600">
            {item[linkableField.field]}
          </Link>
        ),
      };
    }
    // if the column is type `TableColumnRowInterface` , which has render and key properties
    else if (typeof field === 'object') {
      return { key: field.key, render: (item) => <>{field.render(item)}</> };
    }
    // if the column is simply a string
    else {
      return { key: field, render: (item) => <>{item[field]}</> };
    }
  });
}
