import { Chart as ChartJS, registerables } from 'chart.js';
import EmptyState from 'components/common/empty-state';
import IfElse from 'components/conditionals/if-else.conditional';
import SubpageContainer from 'components/containers/subpage.container';
import useFetch from 'hooks/useFetch';
import { numberToFriendlyDate } from 'lib/ads.helper';
import { selectAmazonAdsAccounts } from 'pages/users/amazon/amazon-ads.slice';
import { amazonAccounts } from 'pages/users/amazon/amazon.thunks';
import { selectReportsByDate } from 'pages/users/analytics/analytics-ads.slice';
import { getLineChartData } from 'pages/users/analytics/analytics-chart-formula';
import AnalyticsForm from 'pages/users/analytics/analytics-form';
import { calculateTabData } from 'pages/users/analytics/analytics-formula';
import LineChart from 'pages/users/analytics/analytics-line-chart';
import AnalyticsTableExport from 'pages/users/analytics/analytics-table-export';
import { calculateTotals, Obj } from 'pages/users/analytics/analytics.helper';
import {
  LineCharAxis,
  LineChartDataInterface,
  StatisticsColumnsInterface,
} from 'pages/users/analytics/analytics.interface';
import AnalyticsTotals from 'pages/users/analytics/analytics.totals';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

ChartJS.register(...registerables);

function Analytics() {
  const accounts = useAppSelector(selectAmazonAdsAccounts);
  const navigate = useNavigate();
  const [columns, setColumns] = useState(['clicks', 'impressions']);

  useFetch('amazon-accounts', () => amazonAccounts());
  const { data: adsData } = useAppSelector(selectReportsByDate);
  // const fromTitle = `From ${numberToFriendlyDate(startDate)} to ${numberToFriendlyDate(endDate)}`;

  let statColumns: StatisticsColumnsInterface[] = [];
  let calculatedData;
  let labels: string[] = [];
  let datasets: LineChartDataInterface[] = [];

  if (adsData) {
    calculatedData = calculateTotals(adsData as unknown as Obj[]);
    statColumns = calculateTabData(calculatedData);
    labels = adsData.map((d) => {
      return numberToFriendlyDate(d.date);
    });

    const column1 = getLineChartData(columns[0], adsData);
    const column2 = getLineChartData(columns[1], adsData, LineCharAxis.Y1);
    datasets = [column1, column2];
  }

  const setColumn = (column) => {
    if (columns[0] !== column) {
      setColumns([column, columns[0]]);
    }
  };

  return (
    <IfElse show={accounts.length === 0}>
      <SubpageContainer title="Analytics">
        <table className="w-full">
          <EmptyState
            buttonLabel="Login with Amazon SP"
            handleClick={() => navigate('/dashboard/amazon')}
            noDataMsg="You need to link your account before you can see this poge, Click the button below to link your Amazon seller account!"
          />
        </table>
      </SubpageContainer>
      <SubpageContainer title="" rightElement={<AnalyticsForm />}>
        <div className="space-y-2">
          <AnalyticsTotals data={statColumns} onClick={setColumn} columns={columns} />
          <div className="h-192">
            <LineChart labels={labels} datasets={datasets} />
          </div>
          <AnalyticsTableExport adsData={adsData} />
        </div>
      </SubpageContainer>
    </IfElse>
  );
}
export default Analytics;
