import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import { useTeamMember } from 'pages/users/teams/teams.hooks';
import teamsTableData from 'pages/users/teams/teams.tabledata';
import TablePagination from '../../../components/tables/table.pagination';
import React, { useState } from 'react';
// import {useMembers} from "../../admin/manage-users/manage-users.hooks";

export default function Teams() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const headers = ['name', 'email', ''];
  const queryKey = 'team' + currentPage;
  const { data } = useTeamMember(currentPage, queryKey);
  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } = data;
  return (
    <>
      <SubpageContainer title="Manage Team Members" showAdd addUrl="new" showAddString="Invite Member">
        <Table headers={headers}>
          <TableBody columns={teamsTableData()} data={docs} />
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
}
