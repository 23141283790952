import { getSilentFetch, postFetch } from 'request/request';
import { PromptTemplateInterface } from 'pages/admin/prompts/prompt.interface';

export const getPromptTemplate = async (): Promise<PromptTemplateInterface> => {
  return await getSilentFetch(`/apollo/superadmin/prompt-template`);
};

export const updatePromptTemplate = async (data?: PromptTemplateInterface): Promise<PromptTemplateInterface> => {
  return await postFetch(`/apollo/superadmin/prompt-template`, data);
};

export const getPromptOptimizer = async (): Promise<PromptTemplateInterface> => {
  return await getSilentFetch(`/apollo/superadmin/prompt-template/optimizer`);
};

export const updatePromptOptimizer = async (data?: PromptTemplateInterface): Promise<PromptTemplateInterface> => {
  return await postFetch(`/apollo/superadmin/prompt-template/optimizer`, data);
};
