import React, { ReactElement, useRef } from 'react';
import { Button, Group, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import If from 'components/conditionals/if.conditional';
import IfElse from 'components/conditionals/if-else.conditional';

interface MFormDropZoneButtonInterface {
  text: string;
  multiple?: boolean;
  handleUpload: (a) => void;
  subText?: string;
  image?: ReactElement;
  disabled?: boolean;
}

export const MFormDropZoneButton = ({
  text,
  multiple = true,
  handleUpload,
  subText,
  image,
  disabled = false,
}: MFormDropZoneButtonInterface): ReactElement => {
  const openRef = useRef<() => void>(null);
  const { isLoading } = useAppSelector(selectRequest);

  const onClick = () => {
    openRef.current?.();
  };
  return (
    <Dropzone
      loading={isLoading}
      openRef={openRef}
      maxFiles={10}
      onDrop={handleUpload}
      multiple={multiple}
      accept={{
        'image/*': [], // All images
      }}
      activateOnClick={false}
      styles={{ inner: { pointerEvents: 'all' } }}
    >
      <Group position="center">
        <div className="text-center py-10 align-middle">
          <IfElse show={!image}>
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            {image}
          </IfElse>
          <Button onClick={onClick} className="my-4" disabled={disabled}>
            {text}
          </Button>
          <If show={subText !== null}>
            <Text c="dimmed" fz="sm">
              {subText}
            </Text>
          </If>
        </div>
      </Group>
    </Dropzone>
  );
};
