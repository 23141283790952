import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapInterface } from 'pages/users/heatmap/heatmap.interface';
import { RootState } from 'redux/store';

interface HeatmapSliceInterface {
  isOpen: boolean;
  currentImage: HeatmapInterface | undefined;
}

const initialState: HeatmapSliceInterface = {
  isOpen: false,
  currentImage: undefined,
};

export const heatMapsSlice = createSlice({
  name: 'heatmap',
  initialState,
  reducers: {
    showImage: (state: Draft<HeatmapSliceInterface>, action: PayloadAction<{ currentImage: HeatmapInterface }>) => {
      return {
        currentImage: action.payload.currentImage,
        isOpen: true,
      };
    },
    hideImage: () => initialState,
  },
});

export const { showImage, hideImage } = heatMapsSlice.actions;
export const selectHeatmap = (state: RootState) => state.heatmap;
export const HeatmapSliceReducer = heatMapsSlice.reducer;
