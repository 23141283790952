import React, { useRef } from 'react';
import { Button, Group, Text, ActionIcon, Paper, Tooltip, Input } from '@mantine/core';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import useTopSearchesEvents from './top-searches.hooks';
import { useTopSearchesContext } from './top-searches.context';

const SearchTagComponent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { topSearchesStates } = useTopSearchesContext();
  const { enterKeyword, removeKeyword, showInputField } = useTopSearchesEvents();
  const lastKeywordProcessed = topSearchesStates.keywords[topSearchesStates.keywords.length - 1];
  return (
    <Paper shadow="xs">
      <Group position="center" grow>
        {topSearchesStates.keywords.map((keyword, index) => (
          <Group position="apart" key={index} className={`relative p-2 hover:bg-gray-200 group m-2 rounded`}>
            <Tooltip label={keyword?.keyword}>
              <Text fw={500} className="truncate">
                {keyword?.keyword}
              </Text>
            </Tooltip>
            <ActionIcon
              className="!absolute right-1 !hidden group-hover:!flex"
              size={24}
              variant="transparent"
              disabled={topSearchesStates.isLoading}
              onClick={() => removeKeyword(keyword)}
            >
              <TrashIcon className="text-red-600 " />
            </ActionIcon>
          </Group>
        ))}
        {topSearchesStates.inputVisible ? (
          <Input className="p-[6px]" ref={inputRef} onKeyPress={(e) => enterKeyword(e)} />
        ) : (
          topSearchesStates.keywords.length < 5 && (
            <Button
              onClick={showInputField}
              variant="subtle"
              color="dark"
              className="m-2"
              leftIcon={<PlusCircleIcon className="h-5 w-5 text-[#101113] mr-[5px]" />}
              loading={topSearchesStates.isLoading}
            >
              {' '}
              {topSearchesStates.isLoading && lastKeywordProcessed?.keyword !== topSearchesStates.searchTerm
                ? topSearchesStates.searchTerm
                : 'Add Search Term'}
            </Button>
          )
        )}
      </Group>
    </Paper>
  );
};

export default SearchTagComponent;
