import FunnelBasicInfo from 'pages/users/funnels/funnel-basic-info';
import RebateFunnelForm from 'pages/users/funnels/rebate/tabs/rebate-funnel-form';
import React from 'react';

function RebateFunnelBasicInfo() {
  return (
    <>
      <RebateFunnelForm>
        <FunnelBasicInfo />
      </RebateFunnelForm>
    </>
  );
}

export default RebateFunnelBasicInfo;
