import React, { ReactElement, Suspense, useEffect } from 'react';
import ManageUsersMembers from 'pages/admin/manage-users/manage-users.members';
import { Card, Text } from '@mantine/core';
import SubpageContainer from 'components/containers/subpage.container';
import MFormButton from 'components/mantine/forms/m-form-button';
import { joiResolver, useForm } from '@mantine/form';
import Joi from 'joi';
import MFormNumberInput from 'components/mantine/forms/m-form-number-input';
import { useUserCredits } from 'pages/admin/manage-users/manage-users.hooks';
import If from 'components/conditionals/if.conditional';

const ManageUsersEdit = (): ReactElement => {
  const { credits, isFetching, update: updateCredits } = useUserCredits();

  const form = useForm({
    initialValues: {
      heatmaps: 0,
      splitTests: 0,
      sellers: 0,
      members: 0,
    },
    validate: joiResolver(
      Joi.object({
        heatmaps: Joi.number().required(),
        splitTests: Joi.number().required(),
        sellers: Joi.number().required(),
        members: Joi.number().required(),
      })
    ),
  });

  useEffect(() => {
    if (credits) {
      form.setValues({
        heatmaps: credits?.heatmaps,
        splitTests: credits?.splitTests,
        sellers: credits?.sellers,
        members: credits?.members,
      });
    }
  }, [credits]);

  const update = (values) => {
    updateCredits(values);
  };

  return (
    <>
      <SubpageContainer title="Manage Account">
        <Card>
          <div className="flex gap-4">
            <div className="flex flex-col grow space-y-2">
              <div>
                <If show={!isFetching}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <form onSubmit={form.onSubmit((values) => update(values))}>
                      <div className="space-y-3">
                        <MFormNumberInput label="Heat Maps" {...form.getInputProps('heatmaps')} required />
                        <MFormNumberInput label="Split Tests" {...form.getInputProps('splitTests')} required />
                        <MFormNumberInput label="Sellers" {...form.getInputProps('sellers')} required />
                        <MFormNumberInput label="Members" {...form.getInputProps('members')} required />
                        <MFormButton type="submit" text="Update Credits" />
                      </div>
                    </form>
                  </Suspense>
                </If>
              </div>
            </div>
          </div>
        </Card>
        <div className="py-6">
          <Text fw={700} size="lg">
            Team Members
          </Text>
        </div>
        <ManageUsersMembers />
      </SubpageContainer>
    </>
  );
};

export default ManageUsersEdit;
