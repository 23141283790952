import React, { useRef } from 'react';
import BannerComponent from './components/abtest.banner';
import ABdataListComponent from './components/abtest.dataList';
import ABTestStart from './components/abtest.start';
function Abtest() {
  const containerRef = useRef(null);
  return (
    <div className="px-4 sm:px-6 py-6 overflow-auto flex flex-col h-full" ref={containerRef}>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <ABTestStart />
        </div>
        <div className="col-span-2">
          <BannerComponent />
        </div>
      </div>
      <ABdataListComponent />
    </div>
  );
}

export default Abtest;
