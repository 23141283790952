import { InvoiceInterface } from 'pages/users/subscription/invoices/invoices.interface';
import { getInvoices } from 'pages/users/subscription/invoices/invoices.thunks';
import { useCallback, useEffect, useState } from 'react';

export function useInvoices(): InvoiceInterface[] {
  const [invoices, setInvoices] = useState<InvoiceInterface[]>([]);

  const getPayment = useCallback(async () => {
    const data = await getInvoices();
    if (data) {
      setInvoices(data);
    }
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return invoices;
}
