import { ProductInterface } from 'interfaces/rebates.interface';
import { setProducts } from 'pages/users/products/products.slice';
import { store } from 'redux/store';
import { getSilentFetch, postSilentFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const loadProducts = async (values): Promise<ResponseListInterface<ProductInterface>> => {
  const res = await postSilentFetch('/amazon/products/page', values);
  if (res) {
    store.dispatch(setProducts(res));
  }
  return res;
};

export const productInfo = async (id): Promise<ProductInterface> => {
  const res = await getSilentFetch('/amazon/products/' + id);
  return res;
};
