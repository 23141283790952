import { deleteFetch, postSilentFetch } from 'request/request-api';
import { TopSearchResultInterfaces } from 'pages/users/keyword-research/keyword-research.interfaces';
import { tableDataInterface } from 'pages/users/apollo/keyword-extractor/keyword-extractor.interfaces';
import { getSilentFetch, putFetch } from 'request/request';
import { SplitTestInterface } from 'pages/users/ab-test/abtest.interface';
import { SuperLinksInterface } from 'interfaces/super-links.interface';

export const getKeywordResearch = async (): Promise<any> => {
  const res = await getSilentFetch('/brand-analytics/keyword-research');
  return res;
};

export const keywordResearch = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/keyword-research', data: values });
};

export const saveKeywordResearch = (values): Promise<any> => {
  return postSilentFetch({ url: '/brand-analytics/keyword-research', data: values });
};

export const searchResults = (values): Promise<tableDataInterface> => {
  const { keyword, type, dataSource } = values;
  return postSilentFetch({ url: '/brand-analytics/keyword-extractor/search', data: { keyword, type, dataSource } });
};

export const updateKeywordResearch = async ({ id, values }): Promise<SuperLinksInterface> => {
  return putFetch('/brand-analytics/keyword-research/' + id, values, 'Keyword Research Saved Successfully!');
};

export const deleteDataSet = async (id: string): Promise<SplitTestInterface> => {
  return await deleteFetch({ url: '/brand-analytics/keyword-research/' + id });
};
