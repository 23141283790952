import TableBodyWraper from 'components/tables/table-body.wraper';
import TablePagination from 'components/tables/table.pagination';
import HeatMapGroupForm from 'pages/users/heatmap/heat-map.form';
import HeatmapListWrapper from 'pages/users/heatmap/heatmap-list.wrapper';
import HeatmapDisplay from 'pages/users/heatmap/images/heatmap-display';
import { useState } from 'react';
import { useHeatmapImages } from 'pages/users/heatmap/images/heatmap-image.hook';
import EmptyState from 'components/common/empty-state';
import If from 'components/conditionals/if.conditional';

const HeatmapImage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const queryKey = 'heatmap-image' + currentPage;
  const { data } = useHeatmapImages(currentPage, queryKey);
  const { docs: heatmaps, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } = data;

  return (
    <>
      <table className="borde w-full">
        <TableBodyWraper>
          <div className="flex-1 relative overflow-y-auto focus:outline-none max-w-full pb-4">
            <HeatmapListWrapper uploads={heatmaps} />
          </div>
          <TablePagination
            setCurrentPage={setCurrentPage}
            bg="transparent"
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            nextPage={nextPage}
            page={page}
            docs={heatmaps?.length}
            pagingCounter={pagingCounter}
            prevPage={prevPage}
            totalPages={totalPages}
          />
        </TableBodyWraper>
        <HeatMapGroupForm isImage />
        <If show={data.docs.length === 0}>
          <EmptyState
            addNew={false}
            buttonLabel=""
            handleClick=""
            noDataMsg={`No heatmap items added yet. Click on the "Upload Button" to add a heatmap item.`}
          />
        </If>
      </table>
      <HeatmapDisplay />
    </>
  );
};

export default HeatmapImage;
