import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import React from 'react';
import { AccountsInterface } from 'interfaces/accounts.interface';

function AccountsTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'email',
      render: (rowData: AccountsInterface) => {
        // const id = rowData?._id;
        return <div className="flex flex-row">{rowData.email}</div>;
      },
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default AccountsTableData;
