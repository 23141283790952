import RequestContainer from 'components/containers/request.container';

import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { selectMagicLinks } from 'pages/users/magic-links/magic-link.slice';
import magicLinkTabledata from 'pages/users/magic-links/magic-link.tabledata';
import { loadLinks } from 'pages/users/magic-links/magic-links.thunks';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';

const headers = ['Name', 'Original Link', 'Redirect Link', 'Link status', 'Created', 'Actions'];

const MagicLink = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isFetching } = useFetch('links' + currentPage, () => loadLinks({ page: currentPage }));

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectMagicLinks);

  const columns = magicLinkTabledata();
  const colSpan = headers.length;

  return (
    <>
      <SubpageContainer title="Magic Links" addUrl="new" showAdd>
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={docs}
          >
            <TableBody columns={columns} data={docs} deleteUrl="/magic-links/" />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
};

export default MagicLink;
