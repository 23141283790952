import { CheckCircleIcon } from '@heroicons/react/solid';

import SubpageContainer from 'components/containers/subpage.container';
import { FunnelInterface } from 'interfaces/rebates.interface';
import { deployRebate, stopFunnel } from 'pages/users/funnels/funnels.thunks';
import RebatesFunnelSteps from 'pages/users/funnels/rebate/rebates.steps';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

export default RebateFunnelForm;

function RebateFunnelForm({ children }) {
  const { isLoading } = useAppSelector(selectRequest);
  const { id } = useParams();
  const [showDeployedFunnel, setShowFunnelStatus] = useState(false);
  const [link, setLink] = useState('');

  const showMessage = async (deployedFunnel: FunnelInterface) => {
    setShowFunnelStatus(true);

    const website = `https://${deployedFunnel?.site?.url}.${process.env.REACT_APP_FUNNEL_DOMAIN}`;

    setLink(website);
  };

  const deploy = async (e) => {
    e.preventDefault();
    const deployed = await deployRebate(id);

    if (deployed) {
      showMessage(deployed);
    }
  };

  const redeploy = async (e) => {
    e.preventDefault();

    let redeployedFunnel;
    const stoppedFunnel = await stopFunnel(id);

    if (stoppedFunnel) {
      redeployedFunnel = await deployRebate(id);
      await showMessage(redeployedFunnel);
    }
  };

  const updatedChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { isLoading });
  });

  return (
    <>
      <SubpageContainer title="Update Funnel" showActions deploy={deploy} redeploy={redeploy}>
        {/*show this if there is no error*/}
        {showDeployedFunnel && (
          <div className="rounded-md bg-green-50 p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Funnel Deployed</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>
                    Funnel is now deployed, you can now share the funnel link to your users.{' '}
                    <b>
                      <a href={link} target="_blank">
                        {link}
                      </a>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white overflow-hidden rounded-lg">
          <div className="px-4 sm:p-6 space-y-6">
            <div className="flex items-center pr-6">
              <div className="flex-1">
                <RebatesFunnelSteps id={id} />
              </div>
            </div>
            {updatedChildren}
          </div>
        </div>
      </SubpageContainer>
    </>
  );
}
