import React from 'react';
import KeywordResearchKeywordDetails from './keyword-research.keyword-details';
import KeywordResearchRelatedKeywords from 'pages/users/keyword-research/keyword-research.related-keywords';
import KeywordResearchSearchResults from 'pages/users/keyword-research/keyword-research.search-results';
function KeywordResearchTable() {
  return (
    <>
      <KeywordResearchSearchResults />
      <KeywordResearchRelatedKeywords />
      <KeywordResearchKeywordDetails />
    </>
  );
}

export default KeywordResearchTable;
