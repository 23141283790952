import { ReactElement } from 'react';

const FormElement = ({ label = '', children, align = 'items-center', col = 4, span = 3 }): ReactElement => {
  return (
    <div className={`grid grid-cols-${col} ${align}`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className={`flex items-center space-x-5 col-span-${span}`}>{children}</div>
    </div>
  );
};

export default FormElement;
