import React, { ReactElement } from 'react';
import { PromptTabs } from 'pages/admin/prompts/tabs/prompt.tabs';
import { Text, Textarea } from '@mantine/core';
import { promptContext } from 'pages/admin/prompts/prompt.context';

export const PromptSystem = (): ReactElement => {
  const { system, setSystem } = promptContext();
  return (
    <PromptTabs>
      <div>
        <Text className="mb-2" fw={500} size="md">
          Prompt 1
        </Text>
        <Textarea
          variant="filled"
          value={system?.prompt1}
          onChange={(data) => setSystem?.({ prompt1: data.target.value })}
          autosize
        />
      </div>
    </PromptTabs>
  );
};
