import { Outlet } from 'react-router-dom';

import Alert from 'components/alerts/alert';
import ConfirmDialog from 'components/dialogs-old/confirm.dialog';
import { SubpageCustomInterface, SubpageInterface } from 'interfaces/subpage-container.interface';

/**
 * Dashboard layout
 * @param title - page title
 * @param children - other component children
 * @param custom - custom component
 */
const SubpageContainerCustom = ({ title, children, custom }: SubpageInterface & SubpageCustomInterface) => {
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none max-w-full">
      <div className="py-6">
        <div className={`flex justify-between mx-auto px-4 sm:px-6 ${title ? 'mb-3' : 'mb-0'}`}>
          <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900">{title}</h1>
          {custom}
        </div>
        <div className="mx-auto px-4 sm:px-6">
          <Outlet />
          <Alert />
          {children}
          <ConfirmDialog />
        </div>
      </div>
    </main>
  );
};

export default SubpageContainerCustom;
