import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import If from 'components/conditionals/if.conditional';
import { Fragment, ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';

const FormSelectWithIcon = ({
  col = 4,
  gap = 4,
  span = 3,
  values,
  label = '',
  defaultLabel = 'name',
  name = 'name',
  targetKey = 'id',
  hasFlag = false,
  bgColor = 'bg-white',
  bgSelected = 'red-600',
  textColor = '',
  border = 'border',
  selectorColor = 'text-gray-400',
  defaultIndex = 0,
  ...props
}): ReactElement => {
  const { handleChange } = props;

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const field = (
    <Field name={name} className="mb-2" defaultValue={values[defaultIndex]?.['name']}>
      {({ input }) => {
        const selectedName = input?.value;

        const selectedItem = (name = selectedName) => values.find((i) => i[defaultLabel] === name) || values[0];

        const code = selectedItem()?.code;

        return (
          <Listbox
            value={input.value}
            onChange={(e) => {
              input.onChange(e);
              handleChange && handleChange(e, selectedItem(e)[targetKey]);
            }}
            {...props}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button
                    className={`relative w-full ${bgColor} ${textColor} ${border} border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm disabled:opacity-50`}
                  >
                    <span className="flex items-center">
                      <If show={hasFlag}>
                        <img src={`https://flagcdn.com/w20/${code?.toLowerCase()}.png`} alt="flag" className="mr-3" />
                      </If>
                      <span className="block truncate">{selectedName}</span>
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className={`h-5 w-5 ${selectorColor}`} aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 w-full border bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm disabled:opacity-50"
                    >
                      {values.map((value) => (
                        <Listbox.Option
                          key={value[defaultLabel]}
                          className={({ active }) =>
                            classNames(
                              active ? `text-white bg-${bgSelected}` : 'text-gray-900',
                              'cursor-default select-none relative py-2 pr-9'
                            )
                          }
                          value={value[defaultLabel]}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <If show={hasFlag}>
                                  <img
                                    src={`https://flagcdn.com/w20/${value?.code?.toLowerCase()}.png`}
                                    alt=""
                                    className="ml-3"
                                  />
                                </If>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate'
                                  )}
                                >
                                  {value[defaultLabel]}
                                  {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                </span>
                              </div>
                              <If show={selected}>
                                <span
                                  className={classNames(
                                    active ? 'text-white' : `text-${bgSelected}`,
                                    'absolute inset-y-0 right-0 ml-2 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              </If>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        );
      }}
    </Field>
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:grid-row-1 sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormSelectWithIcon;
