import React, { useState } from 'react';
import { Paper, Table, Tabs, Text } from '@mantine/core';
import If from '../../../components/conditionals/if.conditional';
import { useTopSearchesContext } from './top-searches.context';
import { format } from 'date-fns';
const tableData = ['weekly', 'monthly'];
function TopSearchesTable() {
  const { topSearchesStates } = useTopSearchesContext();
  const endDateString = format(topSearchesStates.endDate, 'MMMM dd, yyyy');
  const startDateString = format(topSearchesStates.startDate, 'MMMM dd, yyyy');
  const [dataTab, setDataTab] = useState<string | null>('weekly');
  const rowData = dataTab === 'weekly' ? topSearchesStates.weeklyTableData : topSearchesStates.monthlyTableData;
  const tableRows = rowData?.map((tData, index) => {
    const monthNumber = tData?.month_no - 1 || 1;
    const year = tData?.year || 2023;
    const month = ` ${tData?.month || format(new Date(year, monthNumber, 1), 'MMMM yyyy')} ${tData?.year}`;
    const week = tData?.week_no;
    return (
      <tr key={index}>
        <td>{tData.search_frequency_rank}</td>
        <td className="whitespace-nowrap">{dataTab === 'weekly' ? week : month}</td>
        <td>
          {' '}
          <a
            href={`/dashboard/brand-analytics/tsq-top-asins?keyword=${tData.search_term}&startDate=${startDateString}&endDate=${endDateString}`}
            className="hover:underline text-red-500"
          >
            {tData.search_term}
          </a>
        </td>
        <td>
          <div className="flex flex-col">
            <span>{tData.top_clicked_product_asin_1}</span>
            <span className="py-1">{tData.top_clicked_product_asin_2}</span>
            <span>{tData.top_clicked_product_asin_3}</span>
          </div>
        </td>
        <td>
          <div className="flex flex-col">
            <p className="line-clamp-1 text-ellipsis">{tData.top_clicked_product_title_1}%</p>
            <span className="py-1 line-clamp-1 text-ellipsis">{tData.top_clicked_product_title_2}%</span>
            <span className="line-clamp-1 text-ellipsis">{tData.top_clicked_product_title_3}%</span>
          </div>
        </td>
        <td>
          <div className="flex flex-col">
            <span>{tData.top_clicked_product_click_share_1}%</span>
            <span className="py-1">{tData.top_clicked_product_click_share_2}%</span>
            <span>{tData.top_clicked_product_click_share_3}%</span>
          </div>
        </td>
        <td>
          <div className="flex flex-col">
            <span>{tData.top_clicked_product_conversion_share_1}%</span>
            <span className="py-1">{tData.top_clicked_product_conversion_share_2}%</span>
            <span>{tData.top_clicked_product_conversion_share_3}%</span>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Paper shadow="xs" p="xs" className="mt-3">
      <Tabs defaultValue="weekly" onTabChange={setDataTab}>
        <Tabs.List>
          {tableData.map((data) => (
            <Tabs.Tab value={data} key={data}>
              <Text fz="md" tt="capitalize">
                {data}
              </Text>
            </Tabs.Tab>
          ))}
          {/*<Tabs.Tab value="messages" >Monthly</Tabs.Tab>*/}
        </Tabs.List>
        {tableData.map((data) => (
          <Tabs.Panel value={data} pt="xs" key={data} className="overflow-auto max-h-96">
            <Table className="border-slate-300 border p-3 mt-2 !rounded-md border-x-0" striped>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">SFR</th>
                  <th className="whitespace-nowrap">{data === 'weekly' ? 'Week' : 'Month'}</th>
                  <th>Keyword</th>
                  <th>Top 3 Asins</th>
                  <th className="whitespace-nowrap">Product Title</th>
                  <th className="whitespace-nowrap">Click Share</th>
                  <th className="whitespace-nowrap">Conversion Share</th>
                </tr>
              </thead>
              <tbody>
                {tableRows}
                <If show={rowData.length === 0}>
                  <tr>
                    <td colSpan={7} className="text-center	">
                      No keywords selected.
                    </td>
                  </tr>
                </If>
              </tbody>
            </Table>
          </Tabs.Panel>
        ))}
      </Tabs>
    </Paper>
  );
}

export default TopSearchesTable;
