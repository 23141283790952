import React, { ReactElement } from 'react';
import { Text, Image } from '@mantine/core';
import { ABTestGoalInterface } from 'pages/users/ab-test/abtest.interface';
import { abTestGoalContext } from 'pages/users/ab-test/abtest.goal-context';

export const ABTestGoalsSelection = (params: ABTestGoalInterface): ReactElement => {
  const { name, image } = params;
  const { selection, setSelection } = abTestGoalContext();

  const onSelect = (e) => {
    e.preventDefault();
    setSelection(params.value);
  };

  return (
    <div
      onClick={onSelect}
      className={`border-1 border-gray-300  ${
        selection === params.value
          ? 'bg-white border-2 border-rose-500 scale-105 '
          : 'bg-gray-200 hover:bg-gray-300 cursor-pointer hover:scale-105 transition-all'
      } w-full flex flex-col justify-between h-full rounded rounded-lg py-10  border-2`}
    >
      <div className="self-center pb-12">
        <Text fw={500} fz="lg">
          {name}
        </Text>
      </div>
      <Image maw={200} mx="auto" src={`/abtest/${image}`} alt="Goal image" />
    </div>
  );
};
