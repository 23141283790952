import React from 'react';
import { Paper, Table, Text } from '@mantine/core';
import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';

function KeywordResearchKeywordDetails() {
  const { topSearchesStates } = useTopSearchesContext();

  const tableRows = topSearchesStates.keywordDetails?.map((tData, index) => (
    <tr key={index}>
      <td>Week {tData.week_no}</td>
      <td className="whitespace-nowrap">{`${tData?.search_frequency_rank}`}</td>
      <td>{tData?.total_cvr_share}%</td>
      <td>{tData?.total_click_share}%</td>
      <td>{tData?.top_clicked_product_asin_1}</td>
      <td>{tData?.top_clicked_product_asin_1}</td>
      <td>{tData.top_clicked_product_click_share_1}%</td>
      <td>{tData.top_clicked_product_conversion_share_1}%</td>
      <td>{tData?.top_clicked_product_asin_2}</td>
      <td>{tData.top_clicked_product_click_share_2}%</td>
      <td>{tData.top_clicked_product_conversion_share_2}%</td>
      <td>{tData?.top_clicked_product_asin_3}</td>
      <td>{tData.top_clicked_product_click_share_3}%</td>
      <td>{tData.top_clicked_product_conversion_share_3}%</td>
    </tr>
  ));

  return (
    <If show={topSearchesStates.keywordDetails?.length > 0}>
      <Paper shadow="xs" p="xs" className="mt-3">
        <Text fw={500} fz="lg">
          Details for "{topSearchesStates.keywordDetails[0]?.label}"
        </Text>

        <IfElse show={topSearchesStates.keywordDetails?.length === 0}>
          <div className="p-2 rounded bg-gray-100 text-center	">
            <Text fw={500}>Select a keyword to view details.</Text>
          </div>
          <div className=" overflow-auto">
            <Table className="border-slate-300 border p-3 mt-2 !rounded-md border-x-0" striped>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Week/Reporting Date</th>
                  <th>SFR</th>
                  <th className="whitespace-nowrap">Click Share Total</th>
                  <th className="whitespace-nowrap">CVR Share Total</th>
                  <th className="whitespace-nowrap">Top 1 ASIN</th>
                  <th className="whitespace-nowrap">Product #1 Click Share</th>
                  <th className="whitespace-nowrap">Product #1 CVR Share</th>
                  <th className="whitespace-nowrap">Top 2 ASIN</th>
                  <th className="whitespace-nowrap">Product #2 Click Share</th>
                  <th className="whitespace-nowrap">Product #2 CVR Share</th>
                  <th className="whitespace-nowrap">Top 3 ASIN</th>
                  <th className="whitespace-nowrap">Product #3 Click Share</th>
                  <th className="whitespace-nowrap">Product #3 CVR Share</th>
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </Table>
          </div>
        </IfElse>
      </Paper>
    </If>
  );
}

export default KeywordResearchKeywordDetails;
