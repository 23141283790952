import { ResponseSearchFilterInterface, SearchInterface } from 'interfaces/products.interface';
import { ProductInterface } from 'interfaces/rebates.interface';
import { setAmazonAccount, setSelectProducts } from 'pages/users/funnels/select-product/select-product.slice';
import { setProductsFilter } from 'pages/users/products/products.slice';
import { store } from 'redux/store';
import { postSilentFetch } from 'request/request';

export const amazonProducts = async (
  values: SearchInterface,
  amazonUser,
  storeResult = false
): Promise<ProductInterface[]> => {
  const res = await postSilentFetch('/amazon/products/amazon-products', {
    key: values?.key,
    value: values?.value,
    filters: [
      {
        key: 'status',
        value: 'active',
      },
      {
        key: 'amazonUser',
        value: amazonUser,
      },
    ],
  });
  if (res && storeResult) {
    store.dispatch(setProductsFilter(res));
  }

  return res;
};

export const getProducts = async (
  amazonUser: string,
  isModal = true
): Promise<ResponseSearchFilterInterface<ProductInterface>> => {
  store.dispatch(setAmazonAccount(amazonUser));
  const res = await postSilentFetch('/amazon/products/filter', {
    filters: [
      {
        key: 'status',
        value: 'active',
      },
      {
        key: 'amazonUser',
        value: amazonUser,
      },
    ],
  });

  if (res) {
    const setProduct = isModal ? setSelectProducts : setProductsFilter;
    store.dispatch(setProduct(res));
  }

  return res;
};
