import { ResponseListInterface } from 'request/response.interface';
import { SplitTestInputOutFileInterface, SplitTestInterface } from 'pages/users/ab-test/abtest.interface';
import { postSilentFetch, putSilentFetch } from 'request/request';
import { deleteFetch, getFetch, postFetch as postFetchApi, putFetch } from 'request/request-api';
import { store } from 'redux/store';
import { validationError } from 'request/request.slice';
import { uploadsErrorMessage } from 'lib/global-config';

export const getSplitTests = async (page = 1): Promise<ResponseListInterface<SplitTestInterface>> => {
  return await postSilentFetch('/split-tests/ab/page', { page });
};

export const getSplitTest = async (id?: string): Promise<SplitTestInterface> => {
  return await getFetch({ url: '/split-tests/ab/' + id });
};

export const validateSplitTest = async (id?: string): Promise<SplitTestInterface> => {
  return await getFetch({ url: '/split-tests/validate-upload/' + id });
};

export const removeSplitTest = async (id: string): Promise<SplitTestInterface> => {
  return await deleteFetch({ url: '/split-tests/ab/' + id });
};

export const removeImageSplitTest = async (
  id: string,
  index: number
): Promise<ResponseListInterface<SplitTestInterface>> => {
  return await putFetch({ url: `/split-tests/ab/remove/${id}`, data: { index } });
};

export const updateGoalSplitTest = async (
  id: string,
  goal: string
): Promise<ResponseListInterface<SplitTestInterface>> => {
  return await putSilentFetch(`/split-tests/ab/goal/${id}`, { goal });
};

export const splitTestImageMultiple = async (
  data: FormData,
  files: File[],
  url: string,
  cb: (uploadedData: SplitTestInputOutFileInterface[]) => void,
  isAdmin = false
) => {
  const uploadedData = await postFetchApi({ url, data, saveMessage: 'Uploaded Successfully!', isAdmin });
  if (!uploadedData) {
    store.dispatch(validationError({ message: uploadsErrorMessage }));
  } else {
    cb(uploadedData);
  }
};

export const saveUploadResultSplitTests = async (data, id: string): Promise<SplitTestInterface> => {
  return await postSilentFetch('/split-tests/ab/output', { ...data, id });
};
