import SubpageContainer from 'components/containers/subpage.container';
import ShimmerWrapper from 'components/shimmer';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import FormSubmit from 'components/forms/form-submit';
import { useQuery, useMutation } from '@tanstack/react-query';
import { UserDataInterface } from 'pages/users/settings/setting.interface';
import { profile, saveProfile } from './user-settings.thunks';
import { Grid, Image, TextInput, FileButton, Button } from '@mantine/core';
import { useProfileForm } from 'hooks/useProfileForm';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconUpload } from '@tabler/icons';
import { handleUploadImage } from 'lib/helpers';

const UserSettings = () => {
  const { isLoading, data: userProfile } = useQuery<UserDataInterface>(['user'], profile);

  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const { form } = useProfileForm(isLoading, userProfile, setPreview);

  const handleFileUpload = async (e: File) => {
    const file: File = e;
    setPreview(URL.createObjectURL(file));
    setFile(file);
  };

  const handleSubmit = async (values: { [key: string]: string | undefined }) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('name', file.name);
      await handleUploadImage(formData, file, '/uploads/user-image/single', (uploadedData) => {
        if (uploadedData.Location) {
          values.gravatar = uploadedData.Location;
        }
      });
    }
    await saveProfile(values);
  };

  const settingUpdateMutation = useMutation(handleSubmit);

  return (
    <SubpageContainer title="Personal Information">
      <ShimmerWrapper loading={isLoading} fallback={<RebatesShimmer />}>
        <div className="p-6 bg-white rounded-md">
          <form
            className="space-y-1"
            onSubmit={form.onSubmit((values) => settingUpdateMutation.mutate({ ...values, user: userProfile?._id }))}
          >
            <Grid>
              <Grid.Col lg={2} md={2} sm={4} className="flex items-center">
                <span>First Name</span>
              </Grid.Col>
              <Grid.Col lg={6} md={10} sm={12}>
                <TextInput placeholder="First Name" {...form.getInputProps('firstName')} />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col lg={2} md={2} sm={4} className="flex items-center">
                <span>Last Name</span>
              </Grid.Col>
              <Grid.Col lg={6} md={10} sm={12}>
                <TextInput placeholder="Last Name" {...form.getInputProps('lastName')} />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col lg={2} md={2} sm={4} className="flex items-center">
                <span>Email</span>
              </Grid.Col>
              <Grid.Col lg={6} md={10} sm={12} className="">
                <span>{userProfile?.email}</span>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col lg={2} md={2} sm={4} className="flex items-center">
                <span>Photo</span>
              </Grid.Col>
              <Grid.Col lg={6} md={10} sm={12} className="space-y-2">
                <Image radius="md" src={preview} height={150} width={150} />
                <FileButton onChange={handleFileUpload} accept="image/png,image/jpeg">
                  {(props) => (
                    <Button color="indigo" leftIcon={<IconUpload size={'0.8rem'} />} {...props}>
                      Upload image
                    </Button>
                  )}
                </FileButton>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col lg={2} md={2} sm={4} className="flex items-center">
                <span>Password</span>
              </Grid.Col>
              <Grid.Col lg={6} md={10} sm={12}>
                <button
                  type="button"
                  onClick={() => navigate('change-password')}
                  className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Change Password
                </button>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col lg={8} md={12} sm={12}>
                <div className="grid grid-cols-4 gap-4 bg-gray-100 sm:border-t  rounded-md sm:border-gray-200 sm:py-5">
                  <div />
                  <div className="mt-1 space-x-5 col-span-3">
                    <FormSubmit label="Save Information" showButtonOnly disabled={settingUpdateMutation.isLoading} />
                  </div>
                </div>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </ShimmerWrapper>
    </SubpageContainer>
  );
};

export default UserSettings;
