import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import useFetch from 'hooks/useFetch';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import {
  AddSellerAccounts,
  getSellerAccountDetails,
  UpdateSellerAccount,
} from 'pages/admin/sqp/seller-accounts/seller-accounts.thunks';
import FormSelect from 'components/forms/form-select';
import React from 'react';
import { getAccounts } from 'pages/admin/sqp/accounts/accounts.thunks';
import { getBrands } from 'pages/admin/sqp/brands/brands.thunks';

function SellerAccountForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreating = id === 'new';
  const { data: accounts, isFetching: fetchingAccounts } = useFetch('accounts', () => getAccounts());
  const { data: brands, isFetching: fetchingBrands } = useFetch('brands', () => getBrands());
  let sellerAccount = {
    account: '',
    merchant: '',
    sourceBrand: '',
    country: '',
    targetBrand: '',
    status: 'enabled',
  };
  let isFetching = false;
  if (!isCreating) {
    ({ isFetching, data: sellerAccount } = useFetch('seller-account-details', () => getSellerAccountDetails(id)));
  }

  const country = [
    {
      value: 'United States',
    },
    {
      value: 'Canada',
    },
  ];
  const status = [
    {
      value: 'enabled',
    },
    {
      value: 'disabled',
    },
  ];
  const onSubmit = async (values) => {
    if (isCreating) {
      const res = await AddSellerAccounts(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/seller-accounts'), 2000);
      }
    } else {
      await UpdateSellerAccount(id, values);
    }
  };

  return (
    <SubpageContainer title={isCreating ? 'Add New Seller Account' : 'Update Seller Account'}>
      <RequestContainer loading={isFetching || fetchingAccounts || fetchingBrands} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={sellerAccount}
          render={({ handleSubmit }) => {
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid sm:w-full xl:w-3/4 grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormSelect
                        values={accounts}
                        name="account"
                        label="Account"
                        optionValue="email"
                        optionLabel="email"
                        initialValue={accounts[0]?.email}
                      />
                      <FormInput name="merchant" label="Merchant" />
                      <FormInput name="sourceBrand" label="Source Brand" />
                      <FormSelect
                        values={country}
                        name="country"
                        label="Country"
                        optionValue="value"
                        optionLabel="value"
                        initialValue={country[0]?.value}
                      />
                      <FormSelect
                        values={brands}
                        name="targetBrand"
                        label="Target Brand"
                        optionValue="name"
                        optionLabel="label"
                        initialValue={brands[0]?.name}
                      />
                      <FormSelect
                        values={status}
                        name="status"
                        label="Status"
                        optionValue="value"
                        optionLabel="value"
                        initialValue={status[0]?.value}
                      />
                    </div>
                    <div className="flex justify-end">
                      <FormSubmit label="Submit" showButtonOnly disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default SellerAccountForm;
