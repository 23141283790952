import FormInput from 'components/forms/form-input';
import FormWithInput from 'components/forms/form-input.suffix';
import FormSelectCurrency from 'components/forms/form-select-currency';
import FormSubmit from 'components/forms/form-submit';
import FormPercentOff from 'components/forms/form.percent-off';
import ShimmerWrapper from 'components/shimmer';
import RebateFunnelTabParent from 'pages/users/funnels/rebate/rebate-funnel-tab.parent';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';

export default RebateFunnelRebateMain;

function RebateFunnelRebateMain() {
  const currencies = [{ name: 'USD', _id: 'usd', symbol: '$' }];

  return (
    <>
      <RebateFunnelTabParent
        render={({ values, isFetching, isSubmitting }) => {
          const amazonPrice = values?.amazonPrice;
          const rebateAmount = values?.rebateAmount;
          const percentage = parseFloat(((rebateAmount / amazonPrice) * 100)?.toFixed(2));

          return (
            <ShimmerWrapper loading={isFetching} fallback={<RebatesShimmer />}>
              <div className="grid grid-cols-2 gap-4 px-4">
                <FormWithInput
                  type="text"
                  name="amazonPrice"
                  label="Amazon Price"
                  readOnly
                  hasSuffix
                  suffix="USD"
                  prefix="$"
                  classes="bg-gray-200 border-0 focus:border-0 focus:ring-0 cursor-not-allowed"
                />
                <div />
                <FormSelectCurrency
                  values={currencies}
                  name="rebateAmount"
                  currencyName="rebateCurrency"
                  label="Rebate Amount"
                  disabled={isSubmitting}
                />
                <FormPercentOff
                  name="percentageOff"
                  label="Percentage Off"
                  readOnly
                  hasSuffix
                  suffix="%"
                  value={percentage || 0}
                  classes="bg-gray-200 border-0 focus:border-0 focus:ring-0 cursor-not-allowed"
                  disabled={isSubmitting}
                />
                <FormSelectCurrency
                  values={currencies}
                  name="bonusRebate"
                  currencyName="bonusCurrency"
                  label="Bonus Rebate"
                  disabled={isSubmitting}
                />
                <FormSelectCurrency values={currencies} name="maxRebate" label="Max Rebate" disabled={isSubmitting} />
              </div>
              <div className="grid grid-cols-2 gap-4 border-t border-b border-gray-150 py-6 px-4">
                <FormInput
                  type="number"
                  disabled={isSubmitting}
                  min={0}
                  name="rebatesPerDate"
                  label="Rebates Per Day"
                />
                <FormInput type="number" disabled={isSubmitting} min={0} name="rebatesDelay" label="Rebate Delay" />
              </div>
              <FormSubmit label="Update Rebate" col={8} span={2} disabled={isSubmitting} />
            </ShimmerWrapper>
          );
        }}
      />
    </>
  );
}
