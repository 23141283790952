import {
  ShimmerButton,
  ShimmerButtonWrapper,
  ShimmerCircle,
  ShimmerContainer,
  ShimmerDivider,
  ShimmerLabel,
  ShimmerRow,
  ShimmerTransparent,
  ShimmerTxtField,
  ShimmerWrap,
} from 'components/shimmer/utils';

const RebateOptionsShimmer = () => (
  <div className="w-100">
    <ShimmerWrap>
      <ShimmerRow padding={4}>
        <ShimmerLabel />
        <ShimmerTxtField />
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerContainer>
        <ShimmerRow>
          <ShimmerLabel />
          <ShimmerTxtField />
          <ShimmerCircle />
        </ShimmerRow>
        <ShimmerRow>
          <ShimmerLabel />
          <ShimmerTxtField />
          <ShimmerLabel />
          <ShimmerTxtField />
        </ShimmerRow>
      </ShimmerContainer>
      <ShimmerDivider />
      <ShimmerButtonWrapper padding={4}>
        <ShimmerTransparent />
        <ShimmerButton />
      </ShimmerButtonWrapper>
    </ShimmerWrap>
  </div>
);

export default RebateOptionsShimmer;
