import React from 'react';
import { BackgroundImage, Center, Button, Box } from '@mantine/core';
import { store } from '../../../redux/store';
import { editHeatmapItem, selectHeatMapItem } from './heat-maps-item.slice';
import { useAppSelector } from '../../../redux/hooks';
import HeatMapGroupForm from './heat-maps.form';

function HeatMapsStart() {
  const { isOpen } = useAppSelector(selectHeatMapItem);
  const handleToggleForm = () => {
    store.dispatch(editHeatmapItem({ isOpen: !isOpen, isEditing: false }));
  };
  return (
    <Box className="border-dashed border-2 border-slate-800 rounded-lg bg-white" h="100%">
      <BackgroundImage src="/heatmaps/create-heatmap.png" h="100%" className="!bg-left-top">
        <Center p="md" h="100%">
          <Button onClick={handleToggleForm} size="md">
            Make A New Group
          </Button>
        </Center>
      </BackgroundImage>
      <HeatMapGroupForm />
    </Box>
  );
}

export default HeatMapsStart;
