import React, { ReactElement } from 'react';
import { PromptTabs } from 'pages/admin/prompts/tabs/prompt.tabs';
import { promptContext } from 'pages/admin/prompts/prompt.context';
import { Text, Textarea } from '@mantine/core';

export const PromptDescription = (): ReactElement => {
  const { description, setDescriptions } = promptContext();
  return (
    <PromptTabs>
      <div>
        <Text className="mb-2 " fw={500} size="md">
          Story
        </Text>
        <Textarea
          variant="filled"
          value={description.story}
          onChange={(data) => setDescriptions({ ...description, story: data.target.value })}
          autosize
        />

        <Text className="my-2" fw={500} size="md">
          Benefits
        </Text>
        <Textarea
          variant="filled"
          value={description.benefits}
          onChange={(data) => setDescriptions({ ...description, benefits: data.target.value })}
          autosize
        />

        <Text className="my-2" fw={500} size="md">
          Features
        </Text>
        <Textarea
          variant="filled"
          value={description.features}
          onChange={(data) => setDescriptions({ ...description, features: data.target.value })}
          autosize
        />
      </div>
    </PromptTabs>
  );
};
