import HeatMapsItem from './heat-maps-item';

const HeatMapsListWrapper = ({ uploads }) => {
  return (
    <>
      <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {uploads?.map((item) => (
          <HeatMapsItem item={item} key={item._id} />
        ))}
      </ul>
    </>
  );
};

export default HeatMapsListWrapper;
