import ModalAlert from 'components/dialogs-old/modal.alert';
import FormInput from 'components/forms/form-input';
import { addNewGroup, groupEdit } from 'pages/users/heatmap/groups/heatmap-groups.thunks';
import { editHeatmapItem, selectHeatMapItem } from 'pages/users/heatmap/heat-map-item.slice';
import { imageEdit } from 'pages/users/heatmap/images/heatmap-images.thunks';
import { useRef } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@mantine/core';

const HeatMapForm = ({ isImage = false }) => {
  const cancelButtonRef = useRef(null);
  const { isOpen, isEditing, selectedGroup, selectedId } = useAppSelector(selectHeatMapItem);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const closeModal = () => dispatch(editHeatmapItem({ isOpen: false }));

  const { mutate: addGroup, isLoading: addLoading } = useMutation((values) => addNewGroup(values));
  const { mutate: editGroup, isLoading: updateLoading } = useMutation((values) => groupEdit(values));

  const onSubmit = async (values) => {
    if (isImage && isEditing) {
      await imageEdit({ ...values, _id: selectedId, group: id, preset: 'normal' });
    } else if (!isImage && !isEditing) {
      addGroup(values);
    } else {
      editGroup({ ...values, _id: selectedId });
    }
  };

  const title = isEditing ? 'Edit' : 'New';
  const label = isImage ? 'Image' : 'Group';

  return (
    <ModalAlert open={isOpen} closeModal={closeModal} cancelButtonRef={cancelButtonRef}>
      <div className="my-0 w-80">
        <div className="sm:px-2">
          <h2 className="mb-4 text-2xl font-bold text-gray-900">{`${title} ${label}`}</h2>
          <Form
            onSubmit={onSubmit}
            initialValues={isEditing ? { name: selectedGroup } : null}
            render={({ handleSubmit, pristine }) => (
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    {`${label} Name`}
                  </label>
                  <div className="mt-1">
                    <FormInput name="name" disabled={addLoading || updateLoading} />
                  </div>
                </div>
                <div className="px-0 py-2 sm:px-0 sm:flex pl-0 ">
                  <Button
                    type="submit"
                    disabled={addLoading || updateLoading || pristine}
                    loading={addLoading || updateLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    disabled={addLoading || updateLoading}
                    variant="white"
                    className="ml-2"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </ModalAlert>
  );
};

export default HeatMapForm;
