import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { Tooltip } from '@mantine/core';

const SwitchButton = ({ userId, label = 'User' }) => {
  const [isOver, setIsOver] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleHover = () => {
    setIsOver(!isOver);
  };

  return (
    <div className="relative flex justify-center items-center">
      <Tooltip label="Switch to user" offset={8}>
        <button
          type="button"
          onClick={() =>
            dispatch(
              openDialog({
                isOpen: true,
                customProps: {
                  userId,
                  buttonLabel: 'Switch',
                  message: `Are you sure you want to switch to ${label}'s account?`,
                },
              })
            )
          }
          onMouseOver={handleHover}
          onMouseOut={handleHover}
          className="flex justify-center items-center p-0 h-8 w-8 border border-transparent shadow-sm text-sm
                font-medium rounded-full text-white
                bg-transparent hover:bg-red-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <SwitchHorizontalIcon
            className={`h-5 w-5 p-0 m-0 flex justify-center ${isOver ? 'text-white' : 'text-red-600'} hover:text-white`}
            aria-hidden="true"
          />
        </button>
      </Tooltip>
    </div>
  );
};

export default SwitchButton;
