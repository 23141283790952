import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

function ActionButton({ label, handleClick, Icon }: { label: string; handleClick: () => void; Icon: any }) {
  const { isLoading } = useAppSelector(selectRequest);
  const [isOn, setIsOn] = useState(false);

  const toggleTooltip = () => {
    setIsOn(!isOn);
  };

  const colorObj = {
    Deploy: 'bg-green-100 text-green-800',
    Pause: 'bg-purple-100 text-purple-800',
    Stop: 'bg-red-100 text-red-800',
    Redeploy: 'bg-blue-100 text-blue-800',
  };

  const color = colorObj[label];

  return (
    <div className="relative flex justify-center items-center">
      {isOn && (
        <div className="absolute -top-5 z-50 bg-gray-600 text-white text-xs -right-2 px-2 rounded-md min-w-max">
          {label}
        </div>
      )}
      <button
        onClick={handleClick}
        disabled={isLoading}
        onMouseOver={toggleTooltip}
        onMouseOut={toggleTooltip}
        className={`inline-flex items-center px-1 py-1 rounded-full text-xs font-medium ${color}`}
      >
        <Icon className="h-5 w-5" />
      </button>
    </div>
  );
}

export default ActionButton;
