import SubpageContainer from 'components/containers/subpage.container';
import HeatMapTable from 'pages/users/heatmap/heat-map-table';

function HeatMaps() {
  return (
    <>
      <SubpageContainer title="">
        <HeatMapTable />
      </SubpageContainer>
    </>
  );
}

export default HeatMaps;
