import { StatisticsColumnsInterface } from 'pages/users/analytics/analytics.interface';

const AnalyticsTotals = ({
  data,
  onClick,
  columns,
}: {
  data: StatisticsColumnsInterface[];
  onClick: (string) => void;
  columns: string[];
}) => {
  return (
    <div>
      <dl className="grid grid-cols-11 gap-1 my-5 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 divide-y-0 divide-x">
        {data.map((item) => {
          const bgClass = columns.includes(item.column) ? 'text-white bg-sai-color-' + item.column : '';

          return (
            <div
              key={item.name}
              className={`px-2 py-5 text-center cursor-pointer  ${bgClass}`}
              onClick={() => onClick(item.column)}
            >
              <dt className={`text-xs uppercase font-semibold text-gray-900 ${bgClass}`}>{item.name}</dt>
              <dd className="mt-1 flex justify-between items-baseline">
                <div className={`w-full items-baseline text-2xl font-semibold text-red-600 text-center ${bgClass}`}>
                  {item.stat}
                </div>
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};

export default AnalyticsTotals;
