import If from 'components/conditionals/if.conditional';
import { useState } from 'react';

const Tooltip = ({ label = '', children, classes = 'relative flex justify-center items-center' }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className={classes}>
      <If show={showTooltip}>
        <div className="absolute -top-5 z-50 bg-gray-600 text-white text-xs -right-2 px-2 rounded-md min-w-max">
          {label}
        </div>
      </If>
      <div onMouseOver={toggleTooltip} onMouseOut={toggleTooltip} className="relative">
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
