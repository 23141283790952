import { addDays, differenceInDays, format } from 'date-fns';
import { dateFormat } from 'lib/global-config';
import { AmazonDataInterface } from 'pages/users/amazon/amazon.interface';

/**
 * get the first seller account with ads
 */
export function getFirstSellerAccount(accounts: AmazonDataInterface[]): AmazonDataInterface | undefined {
  let defaultAccounts;

  for (const account of accounts) {
    if (account.adAccount) {
      defaultAccounts = account.adAccount;
      break;
    }
  }

  return defaultAccounts;
}

export const numberToDate = function (number: number): Date {
  const dateString = number.toString();
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return new Date(`${year}-${month}-${day}`);
};

export const numberToFriendlyDate = function (number: number): string {
  const date = numberToDate(number);
  return format(date, dateFormat);
};

export const getDaysArray = function (startDate: number, endDate: number): string[] {
  const numDays = differenceInDays(numberToDate(endDate), numberToDate(startDate));

  const days: string[] = [];
  for (let i = 0; i <= numDays; i++) {
    days.push(format(addDays(numberToDate(startDate), i), dateFormat).toString());
  }
  // console.log(days);
  return days;
};
