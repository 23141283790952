import { Link, useParams } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SiteLabelTabs({ tabs }) {
  const { id, label = 'home' } = useParams();
  const isCurrentPage = function (page) {
    return label === page;
  };
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-black focus:border-black border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => isCurrentPage(tab.name))?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => {
            const current = isCurrentPage(tab.name);
            return (
              <Link
                key={tab.name}
                to={`${tab.href}${id}`}
                className={classNames(
                  current ? 'border border-red-200 rounded text-red-600' : 'text-black hover:text-red-600',
                  'px-3 py-2 font-medium text-sm rounded-md'
                )}
                aria-current={current ? 'page' : undefined}
              >
                {tab.label}
              </Link>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
