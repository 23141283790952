import FormLabel from 'components/forms/form-label';
import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';

/**
 *
 * @param label - input label
 * @param labelSpan - labels column span
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param gap - column gap
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param props - other input props
 */
const FormInput = ({ col = 4, label = '', name, gap = 4, span = 3, type = 'text', ...props }): ReactElement => {
  const field = (
    <Field
      autoComplete="off"
      type={type}
      name={name}
      id={name}
      component="input"
      className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-s sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed read-only:bg-gray-200"
      placeholder={label}
      {...props}
    />
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:grid-row-1 sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormInput;
