import { CreditCardIcon } from '@heroicons/react/outline';
import If from 'components/conditionals/if.conditional';
import CreditCard from 'components/credit-card/credit-card';
import { FormStackOptionsInterface } from 'components/forms/form-stack-cards';
import { CreditCardInterface } from 'pages/users/subscription/payment-method/payment-method.interface';
import { getPaymentMethods } from 'pages/users/subscription/payment-method/payment-method.thunks';
import React, { useCallback, useEffect, useState } from 'react';

interface PaymentMethodsInterface {
  options: FormStackOptionsInterface[];
  default: CreditCardInterface | null;
  methods: CreditCardInterface[];
}

export function usePaymentMethods(): PaymentMethodsInterface {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsInterface>({
    options: [],
    default: null,
    methods: [],
  });

  const getPayment = useCallback(async () => {
    const { methods, defaultPayment } = await getPaymentMethods();
    if (methods) {
      const options: FormStackOptionsInterface[] = methods.map((d) => ({
        children: (
          <>
            <div className="px-1 py-1 flex w-full">
              <div className="flex-grow-0 mr-2">
                <CreditCardIcon className="h-6 w-5" />
              </div>
              <CreditCard card={d} />
              <If show={d.id === defaultPayment.id}>
                <div className="flex-grow-0 items-end">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold bg-gray-100 text-blue-800">
                    DEFAULT
                  </span>
                </div>
              </If>
            </div>
          </>
        ),
        value: d.id,
      }));
      setPaymentMethods({ options, default: defaultPayment, methods });
    }
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return paymentMethods;
}
