import { ReactElement } from 'react';

type WrapperProps = {
  children: any;
  justify?: string;
  gap?: number;
  padding?: number;
  span?: number;
  marginTop?: number;
};

type Props = {
  span?: number;
  height?: number;
  color?: string;
  padding?: number;
  width?: string;
  key?: number;
};

export const ShimmerContainer = ({ children }: WrapperProps) => (
  <div className={`grid grid-cols-1 gap-4 border rounded w-100 bg-red-50 px-4 py-4`}>{children}</div>
);

export const ShimmerWrap = ({ children, gap }: WrapperProps) => (
  <div className={`grid grid-cols-1 gap-${gap || 4} px-4`}>{children}</div>
);

export const ShimmerRow = ({ children, justify, padding, marginTop }: WrapperProps) => (
  <div
    className={`grid grid-cols-6 gap-6 my-${marginTop} justify-${justify || 'center'} items-${
      justify || 'center'
    } px-${padding}`}
  >
    {children}
  </div>
);

export const ShimmerNestedRow = ({ children }) => <div className="col-span-4 grid grid-cols-8 gap-4">{children}</div>;
export const ShimmerCol = ({ children }) => <div className="grid grid-cols-1 col-span-4 gap-2 px-4">{children}</div>;

export const ShimmerLabel = ({ span, height, color, width }: Props) => (
  <div className={`col-span-${span} animate-pulse ${width} rounded-md h-${height || 4} bg-${color || 'gray-300'}`} />
);

export const ShimmerSiteLabel = ({ span, height, color }: Props) => (
  <div className={`col-span-${span} w-1/2 animate-pulse rounded-md h-${height || 4} bg-${color || 'gray-300'}`} />
);

export const ShimmerTxtField = ({ span, padding }: Props) => (
  <div className={`col-span-${span || 2} animate-pulse p-${padding || 4} rounded-md h-4 bg-gray-300`} />
);

export const ShimmerField = ({ height = 3 }): ReactElement => (
  <div className={`p-3 animate-pulse rounded-md h-${height} bg-gray-300 m-r-4`} />
);

export const ShimmerDivider = () => (
  <div className="grid grid-cols-6 gap-6 justify-center py-2 items-center">
    <div className="col-span-6 animate-pulse rounded-md h-0.5 bg-gray-300" />
  </div>
);

export const ShimmerTransparent = () => <div className="animate-pulse rounded-md h-4 bg-transparent" />;

export const ShimmerButton = () => <div className="animate-pulse rounded-md h-8 bg-red-700" />;

export const ShimmerButtonWrapper = ({ children, padding, span, gap }: WrapperProps) => (
  <div className={`grid grid-cols-${span || 6} gap-${gap} py-4 px-${padding}`}>{children}</div>
);

export const ShimmerImg = () => <div className="animate-pulse rounded-md h-16 py-10 bg-gray-300" />;

export const ShimmerCircle = () => <div className="animate-pulse h-8 rounded-full w-8 bg-red-700" />;

export const ShimmerUpdateBtn = () => (
  <ShimmerButtonWrapper span={4} gap={10}>
    <ShimmerTransparent />
    <ShimmerButton />
  </ShimmerButtonWrapper>
);
