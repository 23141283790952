import IfElse from 'components/conditionals/if-else.conditional';
import GenericMessage from 'components/errors/generic-message';
import { snakeToCapitalize } from 'lib/helpers';
import { usePaymentIntentStatus } from 'pages/users/signup/signup.hooks';

const SignupSuccess = () => {
  const paymentIntent =
    new URLSearchParams(window.location.search).get('setup_intent') ||
    new URLSearchParams(window.location.search).get('payment_intent');
  const status = usePaymentIntentStatus(paymentIntent as string);
  const message = {
    succeeded: 'Success! Click login to continue',
    processing: "Processing payment details. We'll update you when processing is complete.",
    requires_payment_method: 'Failed to process payment details. Please try another payment method.',
    requires_confirmation: 'Failed to process payment details. Payment method needs confirmation',
  };
  return (
    <>
      <IfElse show={status !== ''}>
        <GenericMessage
          title={`${snakeToCapitalize(status)}`}
          desc={message[status] || 'Error'}
          to="/"
          buttonLabel="Login"
        />
        <>Please wait..</>
      </IfElse>
    </>
  );
};

export default SignupSuccess;
