import { ReactElement } from 'react';

/**
 *
 * @param {object} {suffix - input suffix
 * @param {boolean} hasSuffix - if input has suffix
 * @param {number} prefix - input prefix
 * @param {any} children} - other input props
 */
export const FormSuffix = ({ suffix, hasSuffix, children, prefix = '' }): ReactElement => {
  return hasSuffix ? (
    <div className="mt-1 relative rounded-md shadow-sm">
      {prefix && (
        <div className="absolute inset-y-0 left-0 pl-3 flex px-2 items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{prefix}</span>
        </div>
      )}
      {children}
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span className="text-gray-500 sm:text-sm" id="price-currency">
          {suffix}
        </span>
      </div>
    </div>
  ) : (
    children
  );
};
