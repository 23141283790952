import FormCheckboxSingle from 'components/forms/form-checkbox-single';
import FormCustom from 'components/forms/form-custom';
import FormElement from 'components/forms/form-element';

import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import ShimmerWrapper from 'components/shimmer';
import useFetch from 'hooks/useFetch';
import { fallbackImage } from 'lib/helpers';
import { getRebateInfo, updateRebateFunnel } from 'pages/users/funnels/funnels.thunks';
import ProductDetails from 'pages/users/funnels/select-product/select-product.details';
import BasicInfoShimmer from 'pages/users/funnels/shimmers/basic-info';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

export default function FunnelBasicInfoEdit() {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isFetching, data: funnel } = useFetch('funnel', () => getRebateInfo(id, 'info'));
  const formSubmitLabel = 'Update Funnel Information';

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      await updateRebateFunnel(id, 'info', { ...values, requireAndVerifyPhone: false });
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const addDefaultSource = (e) => {
    e.target.src = fallbackImage;
  };

  return (
    <ShimmerWrapper loading={isFetching} fallback={<BasicInfoShimmer />}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off" className="grid grid-cols-1 gap-6 px-4">
              <FormInput
                name="name"
                label="Funnel Name"
                col={6}
                span={5}
                initialValue={funnel?.name}
                disabled={isSubmitting}
              />
              <div className="">
                <FormElement label="Account Name" col={6} span={5}>
                  <div className="ml-1">{funnel?.sellerAccount?.name}</div>
                </FormElement>
              </div>

              <FormCustom label="Product" col={6} span={5}>
                <div className="flex">
                  <div className="flex-none w-50 h-50 p-2">
                    <img
                      src={funnel?.productData?.productImages?.thumbnail}
                      onError={addDefaultSource}
                      className="mr-4 w-28 h-28"
                    />
                  </div>
                  <ProductDetails product={funnel.productData} />
                </div>
              </FormCustom>
              <div className="hidden">
                <FormCustom col={6} span={5}>
                  <div>
                    <FormCheckboxSingle
                      label="Email Confirmation"
                      name="verifyEmail"
                      subtitle="User will need to confirm their email."
                      initialValue={true}
                    />
                  </div>
                </FormCustom>
              </div>
              <FormSubmit label={formSubmitLabel} margin={0} col={6} span={5} disabled={isSubmitting} />
            </form>
          );
        }}
      />
    </ShimmerWrapper>
  );
}
