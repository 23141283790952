import { useQuery } from '@tanstack/react-query';
import { getAccounts } from 'pages/admin/sqp/accounts/accounts.thunks';

export const useAccounts = (queryKey: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getAccounts(),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data,
  };
};
