import FunnelBasicInfoEdit from 'pages/users/funnels/funnel-basic-info-edit';
import RebateFunnelForm from 'pages/users/funnels/rebate/tabs/rebate-funnel-form';
import React from 'react';

function RebateFunnelBasicInfo() {
  return (
    <>
      <RebateFunnelForm>
        <FunnelBasicInfoEdit />
      </RebateFunnelForm>
    </>
  );
}

export default RebateFunnelBasicInfo;
