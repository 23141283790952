import EmptyState from 'components/common/empty-state';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

interface RequestContainerInterface {
  children: ReactNode;
  fallback: ReactNode;
  loading?: boolean;
  data?: unknown;
  addNew?: boolean;
  colSpan?: number;
  handleAction?: () => void;
  noDataMsg?: string;
  buttonLabel?: string;
}

/**
 *
 * @param children -  children components
 * @param fallback - component fallback when request is loading
 * @returns - returns children or fallback
 */
const RequestContainer = ({
  children,
  fallback,
  loading,
  data,
  addNew = true,
  colSpan,
  handleAction,
  noDataMsg = '',
  buttonLabel = '',
}: RequestContainerInterface) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const urlArr = pathname?.split('/');
  const url = urlArr ? urlArr[urlArr?.length - 1] : '';

  let { isLoading } = useAppSelector(selectRequest);
  isLoading = loading ?? isLoading;
  const isEmpty = Array.isArray(data) && data.length === 0 && !isLoading;
  const component = isEmpty ? (
    <EmptyState
      title={url}
      handleClick={handleAction ? handleAction : () => navigate('new')}
      addNew={addNew}
      colSpan={colSpan}
      noDataMsg={noDataMsg}
      buttonLabel={buttonLabel}
    />
  ) : (
    children
  );

  return <>{isLoading ? fallback : component}</>;
};

export default RequestContainer;
