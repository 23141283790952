import RequestContainer from 'components/containers/request.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import manageUsersMemberTableData from 'pages/admin/manage-users/manage-users.members.tabledata';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'redux/hooks';
import { selectDeleteDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { useMembers } from 'pages/admin/manage-users/manage-users.hooks';

const headers = ['Email', 'Switch', 'First Name', 'Last Name', 'Action'];

const ManageUsersMembers = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queryClient = useQueryClient();
  const { isOpen } = useAppSelector(selectDeleteDialog);
  const queryKey = 'users-members' + currentPage;
  const { isFetching, data } = useMembers(currentPage, queryKey);
  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } = data;

  const columns = manageUsersMemberTableData();
  const colSpan = headers.length;

  useEffect(() => {
    queryClient.refetchQueries([queryKey]);
  }, [isOpen]);

  return (
    <>
      <Table headers={headers}>
        <RequestContainer
          loading={isFetching}
          noDataMsg={'No Team Members'}
          addNew={false}
          fallback={<TableShimmer headers={headers} span={colSpan} />}
          colSpan={colSpan}
          data={docs || data}
        >
          <TableBody columns={columns} data={docs} deleteUrl="/teams/admin/member/" confirmField="userId.email" />
        </RequestContainer>
      </Table>
      <TablePagination
        setCurrentPage={setCurrentPage}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        nextPage={nextPage}
        page={page}
        pagingCounter={pagingCounter}
        prevPage={prevPage}
        totalPages={totalPages}
        docs={docs?.length}
      />
    </>
  );
};

export default ManageUsersMembers;
