const NoUserFound = () => {
  return (
    <>
      <div className="text-center w-2/3 m-auto pt-16 pb-16">
        <h1 className="font-md font-bold mb-2">No results found</h1>
        <p className="text-gray-500">
          We can’t find anything with that term at the moment, try searching something else.
        </p>
      </div>
    </>
  );
};

export default NoUserFound;
