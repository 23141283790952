import { TeamMemberInterface } from 'pages/users/teams/teams.interface';
import { deleteFetch, getSilentFetch, postFetch, postSilentFetch, putFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const getTeamMembers = async (page = 1): Promise<ResponseListInterface<TeamMemberInterface>> => {
  return await postSilentFetch('/teams/members/page', { page });
};

export const addTeamMember = async (values): Promise<ResponseListInterface<TeamMemberInterface>> => {
  return await postFetch('/teams/members/invite', values, values.email + ' invited');
};

export const updateTeamMember = async (id, values): Promise<ResponseListInterface<TeamMemberInterface>> => {
  return await putFetch(`/teams/${id}`, { ...values, _id: id }, 'TeamMember Updated Successfully!');
};

export const getTeamMember = async (id): Promise<ResponseListInterface<TeamMemberInterface>> => {
  return await getSilentFetch(`/teams/${id}`);
};

export const deleteTeamMember = async (id: string) => {
  await deleteFetch('/teams/members/' + id, 'Deleted Successfully');
};
