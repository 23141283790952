import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { GroupInterface, ResponseGroupListInterface } from 'interfaces/heatmap.interface';
import { RootState } from 'redux/store';

const initialState: ResponseGroupListInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroups: (state: Draft<ResponseGroupListInterface>, action: PayloadAction<ResponseGroupListInterface>) => {
      return action.payload;
    },
    addGroup: (state: Draft<ResponseGroupListInterface>, action: PayloadAction<GroupInterface>) => {
      const { docs: groups } = state;
      const updatedGroups = [action.payload, ...groups];
      return { ...state, docs: updatedGroups, totalDocs: updatedGroups?.length };
    },
    editGroup: (state: Draft<ResponseGroupListInterface>, action: PayloadAction<GroupInterface>) => {
      const { docs: groups } = state;
      const updatedGroups = groups.map((g) => (g._id === action?.payload._id ? action?.payload : g));
      return { ...state, docs: updatedGroups, totalDocs: updatedGroups?.length };
    },
    deleteGroup: (state: Draft<ResponseGroupListInterface>, action: PayloadAction<string>) => {
      const { docs: groups } = state;
      const updatedGroups = groups.filter((g) => g._id !== action.payload);
      return { ...state, docs: updatedGroups, totalDocs: updatedGroups?.length };
    },
  },
});

export const { setGroups, addGroup, editGroup, deleteGroup } = groupSlice.actions;
export const selectGroup = (state: RootState) => state.group;
export const groupReducer = groupSlice.reducer;
