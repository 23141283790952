import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ResponseSellerAccountsInterface, SellerAccountsInterface } from 'interfaces/seller-accounts.interface';

const initialState: ResponseSellerAccountsInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const sellerAccountsSlice = createSlice({
  name: 'sellerAccounts',
  initialState,
  reducers: {
    setSellerAccounts: (
      state: Draft<ResponseSellerAccountsInterface>,
      action: PayloadAction<ResponseSellerAccountsInterface>
    ) => {
      return action.payload;
    },
    deleteSellerAccount: (state: Draft<ResponseSellerAccountsInterface>, action: PayloadAction<string>) => {
      const { docs: sellerAccounts } = state;
      const updatedSellerAccounts = sellerAccounts.filter((u: SellerAccountsInterface) => u._id !== action.payload);
      return { ...state, docs: updatedSellerAccounts, totalDocs: updatedSellerAccounts?.length };
    },
  },
});

export const { setSellerAccounts, deleteSellerAccount } = sellerAccountsSlice.actions;

export const selectSellerAccounts = (state: RootState) => state.sellerAccounts;

export const sellerAccountsReducer = sellerAccountsSlice.reducer;
