import { CogIcon } from '@heroicons/react/outline';

const Maintenance = () => {
  return (
    <>
      <div className="flex h-screen i bg-white py-32">
        <div className="w-1/2 mx-auto text-center">
          <div className="flex-shrink-0 flex items-center justify-center h-16 w-16 m-auto rounded-full bg-white border border-red-200">
            <CogIcon className="h-14 w-14 text-red-500" aria-hidden="true" />
          </div>
          <h1 className="text-5xl font-light py-10">We&rsquo;ll be back soon!</h1>
          <div className="font-light text-xl">
            <p>
              Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. If you need to you can
              always{' '}
              <a
                href="https://www.signalytics.ai/contact/"
                className="font-bold underline text-red-500"
                target="_blank"
              >
                Contact us
              </a>{' '}
              for updates, otherwise we&rsquo;ll be back up shortly!
            </p>
            <p className="py-4">&mdash; The Signalytics Team</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
