import { getTeamMembers } from 'pages/users/teams/teams.thunks';
import { useQuery } from '@tanstack/react-query';

export const useTeamMember = (currentPage: number, queryKey: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getTeamMembers(currentPage),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data: {
      docs: data?.docs || [],
      hasNextPage: data?.hasNextPage || false,
      hasPrevPage: data?.hasPrevPage || false,
      nextPage: data?.nextPage || null,
      page: data?.page || 0,
      pagingCounter: data?.pagingCounter || 0,
      prevPage: data?.prevPage || null,
      totalPages: data?.totalPages || 0,
    },
  };
};
