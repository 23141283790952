import { Text, Tooltip, ActionIcon, Button } from '@mantine/core';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import { editHeatmapItem } from './heat-maps-item.slice';
import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { defaultHeatmapImage } from 'lib/helpers';
import { useNavigate } from 'react-router-dom';
import { friendlyDate } from '../../../lib/formatting.helpers';
export default function HeatMapsGroupItem({ file }) {
  let cover = file?.coverGrid ? file?.coverGrid[file?.coverGrid.length - 1] : defaultHeatmapImage;
  cover = cover ? cover : defaultHeatmapImage;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date: any = file.createdAt;
  const formattedCreatedDate = friendlyDate(new Date(date));
  function onEdit(selectedGroup: string, selectedId: string) {
    dispatch(
      editHeatmapItem({
        isOpen: true,
        selectedGroup,
        selectedId,
        isEditing: true,
      })
    );
  }

  function onDelete(selectedGroup: string, selectedId: string) {
    const deleteUrl = 'groups';
    dispatch(
      openDialog({
        isOpen: true,
        deleteId: selectedId,
        deleteUrl: `/heatmap/heatmaps/${deleteUrl}/`,
        customProps: { buttonLabel: 'Delete' },
      })
    );
  }
  return (
    <li
      key={file._id}
      className="group rounded-md border border-gray-200 drop-shadow-md hover:scale-[1.02] transition-all bg-black overflow-hidden"
    >
      <div className={'relative flex justify-center items-center w-full'}>
        <img
          src={cover}
          alt={file.name}
          className={`object-cover h-52 w-full cursor-pointer group-hover:opacity-30 transition-all`}
        />{' '}
        {/*</div>*/}
        <Button
          className="!absolute !hidden group-hover:!block"
          onClick={() => navigate(`/dashboard/heatmaps/${file._id}/uploads`)}
        >
          View
        </Button>
      </div>
      <div className="p-3 bg-white justify-between">
        <div className="font-medium text-xl mb-2 flex justify-between">
          <div className="flex justify-between flex-col truncate">
            <Tooltip label={file.name}>
              <span className="font-semibold truncate text-sm">{file.name}</span>
            </Tooltip>
          </div>
          <div className="w-16 space-x-2 text-right items-center flex justify-end">
            <ActionIcon color="dark" onClick={() => onEdit(file.name, file._id)}>
              <PencilIcon className={`h-6 w-6`} aria-hidden="true" />
            </ActionIcon>
            <ActionIcon color="red" onClick={() => onDelete(file.name, file._id)}>
              <TrashIcon className={`h-6 w-6`} aria-hidden="true" />
            </ActionIcon>
          </div>
        </div>
        <div className="flex m-0 truncate">
          <Text fz="xs" c="dimmed" fw={500} className="mr-1">
            Created on:
          </Text>
          <Tooltip label={formattedCreatedDate}>
            <Text tt="capitalize" fz="xs" c="dimmed" className="truncate">
              {formattedCreatedDate}
            </Text>
          </Tooltip>
        </div>
      </div>
    </li>
  );
}
