import arrayMutators from 'final-form-arrays';
import useFetch from 'hooks/useFetch';
import { getRebateInfo, updateRebateFunnel } from 'pages/users/funnels/funnels.thunks';
import RebateFunnelForm from 'pages/users/funnels/rebate/tabs/rebate-funnel-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useParams } from 'react-router-dom';

/**
 * All subtabs "extends" this component except Labels tab
 * @param render - we will use render instead of children because we might need the funnel data to the child component
 */

export default function RebateFunnelTabParent({ render }) {
  const { id } = useParams();
  const { pathname } = useLocation();
  const subpage = pathname.split('/').slice(0, 5)[4];

  const { isFetching, data: funnel } = useFetch('funnel', () => getRebateInfo(id, subpage));
  const [data, setData] = useState();

  const onSubmit = async (values) => {
    await updateRebateFunnel(id, subpage, values);
  };

  return (
    <>
      <RebateFunnelForm>
        <Form
          onSubmit={onSubmit}
          initialValues={data ?? funnel}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            values,
            submitting,
            form: {
              mutators: { push, pop },
            },
          }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off" className="space-y-6 pt-2">
                {render({ funnel, values, isFetching, data, isSubmitting: submitting, setData, push, pop })}
              </form>
            );
          }}
        />
      </RebateFunnelForm>
    </>
  );
}
