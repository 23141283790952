import { ActionIcon, CopyButton, LoadingOverlay, Select, Table, Text, Tooltip } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { ClipboardCheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { getSearchTermMonthly, getSearchTermWeekly, getTopAsins } from './top-searches.thunks';
import { chartDataInterface, TopSearchResultInterfaces } from './top-searches.interfaces';
import IfElse from '../../../components/conditionals/if-else.conditional';
import CustomLoader from '../ab-test/components/abtest.loader';
import useTopSearchesEvents from './top-searches.hooks';

function TopSearchesTopAsins() {
  const params = new URLSearchParams(window.location.search);
  const keyword = params.get('keyword');
  const startDate: string = params.get('startDate') ?? '';
  const endDate: string = params.get('endDate') ?? '';
  const [period, setPeriod] = useState<string | null>('weekly');

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };
  const {
    data: fetchedData,
    refetch,
    isLoading,
  } = useQuery(['top-asins'], {
    queryFn: () =>
      getTopAsins({
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        keyword,
        period,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: fetchWeeklyData } = useQuery(['weekly-chart-data'], {
    queryFn: () =>
      getSearchTermWeekly({
        keyword,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: fetchMonthlyData } = useQuery(['monthly-chart-data'], {
    queryFn: () =>
      getSearchTermMonthly({
        keyword,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { generateSearchTermChartData } = useTopSearchesEvents();
  const { dateArray, chartData } = generateSearchTermChartData(
    (period === 'weekly' ? fetchWeeklyData : fetchMonthlyData) || [],
    period
  );
  useEffect(() => {
    refetch();
  }, [period]);
  const topAsins: TopSearchResultInterfaces[] | [] = fetchedData ?? [];
  const currentAsins = topAsins[0] ?? [];
  const data: chartDataInterface = {
    labels: dateArray,
    datasets: [chartData],
  };
  return (
    <>
      <div className="p-3 h-full mb-2 overflow-hidden">
        <LoadingOverlay visible={isLoading} overlayBlur={5} loader={<CustomLoader textMessage="Loading..." />} />
        <div className="grid grid-cols-5 gap-4 h-full ">
          <div className="col-span-3 h-full ">
            <div className="p-3 border-slate-300 border rounded-md bg-white">
              <div className=" w-full">
                <Text fw={700} fz="xl">
                  {keyword}
                </Text>
                <Text fw={700} fz="xl" className="mt-2 ">
                  Amazon Brand Analytics
                </Text>
                <div className="flex items-center justify-between">
                  <div>
                    <Text fw={700} fz="xl">
                      TOP 3 ASINs Overview
                    </Text>
                    <Text fz="sm">
                      {startDate} - {endDate}
                    </Text>
                  </div>
                  <Select
                    placeholder="Pick one"
                    defaultValue={period}
                    onChange={setPeriod}
                    data={[
                      { value: 'weekly', label: 'Weekly' },
                      { value: 'monthly', label: 'Monthly' },
                    ]}
                  />
                </div>
                <div>
                  <div className="h-full w-full mt-3">
                    <Line options={options} data={data} />
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-200 rounded-md p-3">
                <div className="flex justify-between">
                  <Text fw={700}>TOP 3 Clicked ASINs</Text>
                  <Text className=" !text-gray-500" fw={500}>
                    <IfElse show={period === 'weekly'}>
                      {`Week No. ${topAsins[0]?.week_no || ''}`}
                      {`${topAsins[0]?.month || ''} ${format(new Date(), 'yyyy')}`}
                    </IfElse>
                  </Text>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  {[...Array(3)].map((x, index: number) => {
                    return (
                      <div className="col-span-1 " key={index}>
                        <Text>#{index + 1} Clicked ASIN</Text>
                        <div className="flex flex-col	py-3	">
                          <Text fz="sm" fw={700} className="line-clamp-2	">
                            {currentAsins['top_clicked_product_title_' + (index + 1)]}
                          </Text>
                          <div className="flex items-center">
                            <Text fz="xs" className=" !text-gray-500">
                              {currentAsins['top_clicked_product_asin_' + (index + 1)]}
                            </Text>
                            <CopyButton value={topAsins[0]?.top_clicked_product_asin_1} timeout={2000}>
                              {({ copied, copy }) => (
                                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                                    {copied ? (
                                      <ClipboardCheckIcon className="h-4 w-4" />
                                    ) : (
                                      <ClipboardCopyIcon className="h-4 w-4" />
                                    )}
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <Text fz="sm">Shares:</Text>
                          <div className="flex">
                            <Text fz="sm" className="px-1 !text-gray-500">
                              Click
                            </Text>
                            <Text fz="sm">{currentAsins['top_clicked_product_click_share_' + (index + 1)]}%</Text>
                          </div>
                          <div className="flex">
                            <Text fz="sm" className="px-1 !text-gray-500">
                              Conv.
                            </Text>
                            <Text fz="sm">{currentAsins['top_clicked_product_conversion_share_' + (index + 1)]}%</Text>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2 h-full overflow-auto ">
            <div>
              {topAsins.map((asin, index) => {
                // index !== 0 &&
                return (
                  index !== 0 && (
                    <Table className="border-slate-300 border p-3 !rounded-md !text-right mb-3 bg-white" key={index}>
                      <thead className="bg-gray-200">
                        <tr>
                          <IfElse show={period === 'weekly'}>
                            <th className="!text-right">Week No.{asin?.week_no} Top Clicked ASINs</th>
                            <th className="!text-right">
                              {asin?.month} {format(new Date(), 'yyyy')} Top Clicked ASINs
                            </th>
                          </IfElse>
                          <th className="!text-right">Click Share</th>
                          <th className="!text-right">Conv. Share</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>#1</b> {asin?.top_clicked_product_asin_1}
                          </td>
                          <td>{asin?.top_clicked_product_click_share_1}%</td>
                          <td>{asin?.top_clicked_product_conversion_share_1}%</td>
                        </tr>
                        <tr>
                          <td>
                            <b>#2</b> {asin?.top_clicked_product_asin_2}
                          </td>
                          <td>{asin?.top_clicked_product_click_share_2}%</td>
                          <td>{asin?.top_clicked_product_conversion_share_2}%</td>
                        </tr>
                        <tr>
                          <td>
                            <b>#3</b> {asin?.top_clicked_product_asin_3}
                          </td>
                          <td>{asin?.top_clicked_product_click_share_3}%</td>
                          <td>{asin?.top_clicked_product_conversion_share_3}%</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Subtotal</b>
                          </td>
                          <td>
                            <b>44.3%</b>
                          </td>
                          <td>
                            <b>44.7%</b>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopSearchesTopAsins;
