import { useMutation, useQuery } from '@tanstack/react-query';
import { getPromptTemplate, updatePromptTemplate } from 'pages/admin/prompts/prompt.thunks';
import { useEffect, useState } from 'react';
import {
  DefaultPromptInterface,
  DescriptionPromptInterface,
  PromptTemplateInterface,
  SuggestionInterface,
} from 'pages/admin/prompts/prompt.interface';
import { useParams } from 'react-router-dom';

export const usePromptSetup = () => {
  const initialSuggestion: SuggestionInterface[] = [
    {
      type: 'text',
      text: '',
    },
    {
      type: 'image_url',
      image_url: {
        url: '',
      },
    },
  ];

  const { tabValue = 'title' } = useParams();
  const [system, setSystem] = useState<DefaultPromptInterface>({ prompt1: '' });
  const [titles, setTitles] = useState<DefaultPromptInterface>({ prompt1: '', model: '' });
  const [bullets, setBullets] = useState<DefaultPromptInterface>({ prompt1: '', prompt2: '', model: '' });
  const [description, setDescriptions] = useState<DescriptionPromptInterface>({
    benefits: '',
    features: '',
    story: '',
    model: '',
  });
  const [suggestion, setSuggestion] = useState<SuggestionInterface[]>(initialSuggestion);

  const { data } = useQuery([`manage-prompt-setup`, 1], {
    queryFn: () => getPromptTemplate(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: updatePrompt } = useMutation({
    mutationFn: (data: PromptTemplateInterface) => {
      return updatePromptTemplate(data);
    },
  });

  const update = () => {
    updatePrompt({
      system,
      bullets,
      titles,
      description,
      suggestion,
      type: tabValue,
    });
  };

  useEffect(() => {
    if (data) {
      setSystem(data.system ?? { prompt1: '' });
      setTitles(data.titles);
      setDescriptions(data.description);
      setBullets(data.bullets);
      setSuggestion(data.suggestion ? data.suggestion : initialSuggestion);
    }
  }, [data]);

  return {
    data,
    context: {
      system,
      titles,
      bullets,
      description,
      suggestion,
      setSystem,
      setTitles,
      setBullets,
      setDescriptions,
      setSuggestion,
    },
    update,
  };
};
