import React, { ReactElement } from 'react';
import { PromptTabs } from 'pages/admin/prompts/tabs/prompt.tabs';
import { promptContext } from 'pages/admin/prompts/prompt.context';
import { Text, Textarea } from '@mantine/core';
import { useParams } from 'react-router-dom';
import If from 'components/conditionals/if.conditional';

export const PromptBullets = (): ReactElement => {
  const { bullets, setBullets } = promptContext();
  const { type = 'setup' } = useParams();
  return (
    <PromptTabs>
      <div>
        <Text className="mb-2" fw={500} size="md">
          Prompt 1
        </Text>
        <Textarea
          variant="filled"
          value={bullets.prompt1}
          onChange={(data) =>
            setBullets({
              prompt1: data.target.value,
              prompt2: bullets.prompt2,
              model: bullets.model,
            })
          }
          autosize
        />
        <If show={type === 'setup'}>
          <Text className="my-2" fw={500} size="md">
            Prompt 2
          </Text>
          <Textarea
            variant="filled"
            value={bullets.prompt2}
            onChange={(data) =>
              setBullets({
                prompt1: bullets.prompt1,
                prompt2: data.target.value,
                model: bullets.model,
              })
            }
            autosize
          />
        </If>
      </div>
    </PromptTabs>
  );
};
