import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React from 'react';
import { useBrands } from 'pages/admin/sqp/brands/brands.hooks';
import BrandsTableData from 'pages/admin/sqp/brands/brands.tabledata';
import { useAppSelector } from 'redux/hooks';
import { selectBrands } from 'pages/admin/sqp/brands/brands.slice';

const headers = ['Name', 'Label', 'Seller', 'Action'];

const Brands = () => {
  const { isFetching } = useBrands('sqp-brands');
  const columns = BrandsTableData();
  const colSpan = headers.length;
  const brandsData = useAppSelector(selectBrands);
  return (
    <>
      <SubpageContainer title="Brands" showAdd addUrl="new">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={brandsData}
          >
            <TableBody columns={columns} data={brandsData} deleteUrl="/sqp/admin/brands/" confirmField="name" />
          </RequestContainer>
        </Table>
      </SubpageContainer>
    </>
  );
};

export default Brands;
