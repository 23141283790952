import If from 'components/conditionals/if.conditional';
import { Link } from 'react-router-dom';

function GenericMessage({
  Icon,
  title,
  to,
  buttonLabel,
  desc,
}: {
  Icon?: JSX.Element;
  title: string;
  to?: string;
  buttonLabel?: string;
  desc: string;
}) {
  return (
    <>
      <div className="flex h-screen i bg-white py-32">
        <div className="w-1/2 mx-auto text-center">
          <div className="flex-shrink-0 flex items-center justify-center h-16 w-16 m-auto rounded-full bg-white">
            {Icon}
          </div>
          <h1 className="text-5xl font-light py-10">{title}</h1>
          <div className="font-light text-xl">
            <p className="text-gray-500">{desc}</p>
            <If show={buttonLabel !== undefined && to !== undefined}>
              <p className="py-8">
                <Link
                  to={to as string}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {buttonLabel}
                </Link>
              </p>
            </If>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenericMessage;
