import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { ReactNode } from 'react';

// todo change this
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

if (!process.env.REACT_APP_STRIPE_KEY) {
  throw 'process.env.REACT_APP_STRIPE_KEY is not set';
}

export default function Stripe({ clientSecret, children }: { clientSecret: string | undefined; children: ReactNode }) {
  const appearance: Appearance = {
    theme: 'stripe',
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {children}
        </Elements>
      )}
    </>
  );
}
