import { PauseIcon } from '@heroicons/react/solid';
import FormButton from 'components/forms/form-button';

import { pauseFunnel } from 'pages/users/funnels/funnels.thunks';
import { useParams } from 'react-router-dom';

function PauseFunnel({ funnel }) {
  const { id } = useParams();

  const pause = async (e) => {
    e.preventDefault();
    await pauseFunnel(id);
  };

  return funnel?.isDeployed === false || funnel?.status === 'paused' ? null : (
    <>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <FormButton
          leadingIcon={<PauseIcon className="h-5 w-5 mr-1" />}
          onClick={(e) => pause(e)}
          buttonColor="purple"
          buttonLabel="Pause Funnel"
        />
      </div>
    </>
  );
}

export default PauseFunnel;
