import React, { useReducer } from 'react';
import SearchTagComponent from 'pages/users/keyword-research/keyword-research.search.tag';
import KeywordSearchChart from 'pages/users/keyword-research/keyword-research.line-chart';
import KeywordResearchTable from 'pages/users/keyword-research/keyword-research.table';
import { searchTermReducer, topSearchesStates } from 'pages/users/keyword-research/keyword-research.reducers';
import KeywordResearchContext from 'pages/users/keyword-research/keyword-research.context';
import KeywordResearchDataSet from 'pages/users/keyword-research/keyword-research.data-set';

function KeywordResearch() {
  const [searchTermStates, searchTermDispatch] = useReducer(searchTermReducer, topSearchesStates);

  const providerState = {
    topSearchesStates: searchTermStates,
    dispatch: searchTermDispatch,
  };

  return (
    <KeywordResearchContext.Provider value={providerState}>
      <div className="p-3 overflow-auto	">
        <KeywordResearchDataSet />
        <KeywordSearchChart />
        <SearchTagComponent />
        <KeywordResearchTable />
      </div>
    </KeywordResearchContext.Provider>
  );
}

export default KeywordResearch;
