import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid';
import If from 'components/conditionals/if.conditional';
import React, { ReactNode } from 'react';

export default function AlertValidation({ message, onClose }: { message: string | ReactNode; onClose?(): void }) {
  onClose ||= () => Promise.resolve();
  const alertMessage =
    typeof message === 'string' ? <p className="text-sm font-medium text-yellow-800">{message}</p> : message;
  return (
    <div className="rounded-md bg-yellow-50 p-4 mb-3">
      <div className="flex">
        <div className="flex-shrink-0 mt-1">
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-1">{alertMessage}</div>
        <If show={!onClose}>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={onClose}
                type="button"
                className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </If>
      </div>
    </div>
  );
}
