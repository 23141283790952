export const tailwindColors = [
  { name: 'white' },
  { name: 'black' },
  { name: 'Slate' },
  { name: 'Stone' },
  { name: 'Red' },
  { name: 'Orange' },
  { name: 'Amber' },
  { name: 'Yellow' },
  { name: 'Lime' },
  { name: 'Green' },
  { name: 'Emerald' },
  { name: 'Teal' },
  { name: 'Cyan' },
  { name: 'Sky' },
  { name: 'Blue' },
  { name: 'Indigo' },
  { name: 'Violet' },
  { name: 'Purple' },
  { name: 'Fuchsia' },
  { name: 'Pink' },
  { name: 'Rose' },
];

export const chartColors = [
  '#dc2626',
  '#e67e22',
  '#9b59b6',
  '#2980b9',
  '#2ecc71',
  '#f1c40f',
  '#e84393',
  '#636e72',
  '#a29bfe',
  '#6F1E51',
  '#C4E538',
];
