import { numberFormat, percentageNumberFormat, tryIfNAN } from 'lib/formatting.helpers';
import { analyticsColumns } from 'pages/users/analytics/analytics-columns';
import { getValueByColumn } from 'pages/users/analytics/analytics-formula';
import {
  AdsSalesReportsInterface,
  LineCharAxis,
  LineChartDataInterface,
} from 'pages/users/analytics/analytics.interface';

export const getLineChartData = (
  column: string,
  adData: AdsSalesReportsInterface[],
  axis = LineCharAxis.Y
): LineChartDataInterface => {
  const currentColumn = analyticsColumns.find((c) => c.column === column) || {
    name: '',
    color: '',
    formatter: numberFormat,
  };
  const defaultData = {
    label: currentColumn.name,
    borderColor: currentColumn.color,
    backgroundColor: currentColumn.color,
    tension: 0.4,
    data: [],
    yAxisID: axis,
  };

  const mergedData = adData.map((d) => {
    const formatter = currentColumn.formatter.name === 'percentageFormat' ? percentageNumberFormat : tryIfNAN;
    return +getValueByColumn(column, d, formatter);
  });

  return { ...defaultData, data: mergedData };
};
