import React from 'react';
import { Image, Modal } from '@mantine/core';
import Button from '../../../../components/buttons/button';
import { XIcon } from '@heroicons/react/solid';

export const ABTestImageModal = ({ opened, setOpened, fullImage }) => {
  return (
    <Modal
      className="w-screen bg-black h-screen"
      opened={opened}
      onClose={() => setOpened(false)}
      withCloseButton={false}
      fullScreen
      padding={0}
    >
      <div className="w-screen bg-black h-screen flex items-center justify-center">
        <Image src={fullImage} alt="image" withPlaceholder height={'auto'} width={'auto'} />
        <div className="absolute right-2 top-2 z-20">
          <Button color="gray" onClick={() => setOpened(false)}>
            <XIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
