import { PaymentIntentInterface } from 'pages/users/signup/signup.interface';
import { setCard } from 'pages/users/subscription/payment-method/default-card.slice';
import { SubscriptionInterface } from 'pages/users/subscription/subscription.interface';
import { store } from 'redux/store';
import { getSilentFetch, postSilentFetch } from 'request/request';

export const getSubscription = async (): Promise<SubscriptionInterface> => {
  const subscription = await getSilentFetch('/credits/subscription');
  store.dispatch(setCard(subscription.defaultPayment));
  return subscription;
};

export const paymentIntentRegistered = async (stripePriceId: string): Promise<PaymentIntentInterface> => {
  return await postSilentFetch(`/credits/reactivate/`, { stripePriceId });
};
