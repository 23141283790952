import { ApolloResultInterface } from 'pages/users/apollo/apollo.interfaces';
import { TextLimit } from 'components/text-limit/text-limit';
import DeleteButton from 'components/buttons/delete-button';
import { openConfirmModal } from '@mantine/modals';
import { Text } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { deleteApolloResult } from 'pages/users/apollo/apollo.thunk';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import IfElse from 'components/conditionals/if-else.conditional';

const columnHelper = createColumnHelper<ApolloResultInterface>();

function apolloTableData() {
  const navigate = useNavigate();

  const openModal = (id: string) =>
    openConfirmModal({
      title: 'Delete Apollo',
      centered: true,
      children: (
        <Text size="sm">
          Do you really intend to remove this apollo item? Please be aware that this action is irreversible and cannot
          be undone.
        </Text>
      ),
      labels: { confirm: 'Delete Apollo', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await deleteApolloResult(id);
        navigate(0);
      },
    });

  return [
    columnHelper.accessor((row) => row, {
      id: 'contentTitle',
      cell: (row) => (
        <div className="w-full 2xl:w-full overflow-hiden py-2">
          <Link to={`create-list/title/${row.getValue().prompt}`} className="w-20 cursor-pointer hover:text-red-600">
            <TextLimit text={row.getValue().contentTitle || ''} limit={100} />
          </Link>
        </div>
      ),
      header: () => <span>Label</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: 'brand',
      cell: (row) => (
        <div className="w-full 2xl:w-full overflow-hiden py-2">
          <Link to={`create-list/title/${row.getValue().prompt}`} className="w-20 cursor-pointer hover:text-red-600">
            {row.getValue().brand}
          </Link>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row?.title, {
      id: 'title',
      cell: (row) => (
        <IfElse show={!row.getValue()}>
          <div>&mdash;</div>
          <TextLimit text={row?.getValue()} limit={100} />
        </IfElse>
      ),
    }),
    columnHelper.accessor((row) => row.tags, {
      id: 'notes',
      cell: (row) => (
        <IfElse show={row.getValue().length === 0}>
          <div>&mdash;</div>
          <div className="py-2">
            {row.getValue().map((r) => (
              <span
                key={r}
                className={`inline-flex mr-1 items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800`}
              >
                {r}
              </span>
            ))}
          </div>
        </IfElse>
      ),
      header: () => <span>Notes</span>,
    }),
    columnHelper.accessor((row) => row._id, {
      id: '_id',
      cell: (row) => <DeleteButton onClick={() => openModal(row.getValue())} />,
      header: () => <span>Action</span>,
    }),
  ];
}

export default apolloTableData;
