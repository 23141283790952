import RequestContainer from 'components/containers/request.container';
import ModalAlert from 'components/dialogs-old/modal.alert';
import { ProductInterface } from 'interfaces/rebates.interface';
import { fallbackImage } from 'lib/helpers';
import { headers } from 'pages/users/funnels/funnels';
import ProductDetails from 'pages/users/funnels/select-product/select-product.details';
import { openModal, selectProduct } from 'pages/users/funnels/select-product/select-product.slice';
import { amazonProducts } from 'pages/users/funnels/select-product/select-product.thunk';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { store } from 'redux/store';

// todo: bug, filter is empty on first loading
// to replicate, make sure to logout or clear redux state then create a funnel
export default function ModalSelectProduct({ setProduct }) {
  const { isOpen, amazonAccount, products } = useAppSelector(selectProduct);

  const cancelButtonRef = useRef(null);
  const [searchResult, setSearchResult] = useState<ProductInterface[]>();
  const [searchKey, setSearchKey] = useState<string>('asin1');

  const closeModal = () => store.dispatch(openModal({ isOpen: false, sellerAccount: '' }));

  const onSelect = (product) => {
    closeModal();
    setProduct(product);
  };

  useEffect(() => {
    if (products) {
      setSearchResult(products);
    }
  }, [products]);

  const handleSearch = async (e) => {
    if (e.target.value.length > 1) {
      const res = await amazonProducts({ key: searchKey, value: e.target.value }, amazonAccount);
      if (res) {
        setSearchResult(res);
      }
    }
    if (e.target.value.length === 0) {
      setSearchResult(products);
    }
  };

  const handleSelectChange = (e) => {
    setSearchKey(e.target.value);
  };

  const addDefaultSource = (e) => {
    e.target.src = fallbackImage;
  };

  return (
    <ModalAlert open={isOpen} closeModal={closeModal} cancelButtonRef={cancelButtonRef}>
      <div className="max-h-96 overflow-auto">
        <form autoComplete="off">
          <div className="relative my-1 border-b border-gray-300 focus-within:border-red-600">
            <input
              type="text"
              autoComplete="off"
              name="name"
              id="name"
              onChange={handleSearch}
              className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-red-600 focus:ring-0 sm:text-sm"
              placeholder="Search Product by Name or ASIN or Description"
            />
            <div className="absolute inset-y-0 right-0 flex items-center border-l rounded-lg">
              <select
                className="focus:ring-red-500 focus:border-red-500  text-center h-full py-0 pl-2 pr-7 border-transparent bg-gray-300 bg-opacity-30 text-gray-500 sm:text-sm rounded-md"
                onChange={handleSelectChange}
              >
                {[
                  { key: 'asin1', value: 'Asin' },
                  { key: 'name', value: 'Name' },
                  { key: 'description', value: 'Description' },
                ]?.map((o: any) => (
                  <option value={o.key} key={o.key}>
                    {o.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
        <table className="w-full divide-y divide-gray-200 table-fixed">
          <RequestContainer
            loading={false}
            fallback={<TableShimmer headers={headers} span={headers.length} />}
            colSpan={headers.length}
            data={searchResult}
            addNew={false}
            noDataMsg="No Product Found!"
          >
            <tbody>
              {searchResult?.map((product, index) => (
                <tr key={product._id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-1 py-4 text-sm font-medium text-gray-900 w-24">
                    <img src={product?.productImages?.thumbnail} onError={addDefaultSource} width={75} height={75} />
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    <ProductDetails product={product} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-36">
                    <button
                      onClick={() => onSelect(product)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4
                      py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Select
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </RequestContainer>
        </table>
      </div>
    </ModalAlert>
  );
}
