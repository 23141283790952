import {
  ApolloCreateInterface,
  ApolloInterface,
  ApolloPrompt,
  ApolloResultInterface,
  ApolloTypes,
  CompetitorInterface,
  ProductViewModel,
} from 'pages/users/apollo/apollo.interfaces';
import { getSilentFetch, postSilentFetch } from 'request/request-api';
import { deleteFetch, putFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const competitors = (asins: string[]): Promise<CompetitorInterface> => {
  return postSilentFetch({ url: '/ads/competitor/keywords', data: { asins } });
};

export const searchCatalog = (keywords: string): Promise<ProductViewModel[]> => {
  return postSilentFetch({ url: '/amazon/catalog/search/keywords', data: { keywords } });
};

export const searchProduct = (asins: string[]): Promise<ProductViewModel[]> => {
  return postSilentFetch({ url: '/amazon/catalog/search/asin', data: { asins } });
};

export const createListing = (data): Promise<{ _id: string }> => {
  return postSilentFetch({ url: '/apollo/listing', data });
};

export const apolloData = (id: string, type: ApolloTypes): Promise<ApolloInterface> => {
  return getSilentFetch({ url: `/apollo/prompt/${type}/${id}` });
};

export const titleVariant = (id: string): Promise<ApolloInterface> => {
  return getSilentFetch({ url: '/apollo/prompt/variations/title/' + id });
};

export const moreBullets = (id: string): Promise<ApolloInterface> => {
  return getSilentFetch({ url: '/apollo/prompt/more/bullets/' + id });
};

export const regenerateDescription = (id: string): Promise<ApolloInterface> => {
  return getSilentFetch({ url: '/apollo/prompt/regenerate/description/' + id });
};

export const createApolloResult = (data: ApolloCreateInterface): Promise<{ _id: string }> => {
  return postSilentFetch({ url: '/apollo/', data });
};

export const getApolloResult = (id: string): Promise<ApolloInterface> => {
  return getSilentFetch({ url: '/apollo/' + id });
};

export const getApolloPrompt = (id: string): Promise<ApolloPrompt> => {
  return getSilentFetch({ url: '/apollo/prompt/' + id });
};

export const deleteApolloResult = async (id: string) => {
  await deleteFetch('/apollo/' + id, 'Deleted Successfully');
};

export const updateApolloResult = async (
  data: ApolloCreateInterface
): Promise<ResponseListInterface<ApolloResultInterface>> => {
  return await putFetch(`/apollo/${data.promptId}`, { ...data }, 'Apollo Updated Successfully!');
};

export const getApolloResults = async (values): Promise<any> => {
  return await postSilentFetch({ url: '/apollo/page', data: values });
};

export const retryListing = async (id): Promise<{ _id: string }> => {
  return postSilentFetch({ url: '/apollo/retry', data: { id } });
};
