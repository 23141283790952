import { ShimmerField } from 'components/shimmer/utils';
import { ReactNode } from 'react';

/**
 * Table shimmer
 * @param headers - table headers
 * @param span - table data colSpan
 * @param children - component children
 */
const MTableShimmer = ({ span = 5, children }: { span?: number; children?: ReactNode }) => (
  <table className="w-full table-auto divide-y ">
    <tbody>
      {[...Array(span - 1)].map((__: any, i: number) => (
        <tr key={i}>
          <td scope="col" className="th" colSpan={span}>
            {children ? children : <ShimmerField />}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default MTableShimmer;
