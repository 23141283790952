import { useState } from 'react';

import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import { useAppSelector } from 'redux/hooks';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { loadSuperLinks } from './super-links.thunks';
import { selectSuperLinks } from './super-links.slice';
import superLinkTableData from './super-links.tabledata';

const headers = ['Campaign Name', 'Asin', 'Market Place', 'Redirect URL', 'Keywords', ''];

const SuperLinks = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isFetching } = useFetch('super-links' + currentPage, () => loadSuperLinks({ page: currentPage }));

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectSuperLinks);

  const columns = superLinkTableData();
  const colSpan = headers.length;

  return (
    <>
      <SubpageContainer title="Super Links" addUrl="new" showAdd>
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={docs}
          >
            <TableBody columns={columns} data={docs} deleteUrl="/superlinks/" />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
};

export default SuperLinks;
