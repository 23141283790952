import SubpageContainer from 'components/containers/subpage.container';
import FormUpload from 'components/forms/form-upload';
import { handleUploadImageMultiple } from 'lib/helpers';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * merge with other uploads
 * @constructor
 */
const SplitTestsForm = () => {
  const params = useParams();
  const id = params?.id || '';
  const navigate = useNavigate();

  const closeRequest = () => {
    //
  };

  return (
    <SubpageContainer title="Upload Image">
      <Form
        onSubmit={closeRequest}
        render={({ values }) => {
          const handleUpload = async (e) => {
            const formData = new FormData();
            const files: File[] = e.target.files;
            for (const file of files) {
              formData.append('files', file);
            }
            formData.append('name', files[0].name);
            await handleUploadImageMultiple(formData, files, '/split-tests/ab/upload', (res) => {
              if (res) {
                navigate(`/dashboard/split-tests`);
              }
            });
          };

          return (
            <form onSubmit={(e) => e.preventDefault()}>
              <FormUpload name="file" values={values} id={id} hasPreview handleUpload={handleUpload} multiple={true} />
            </form>
          );
        }}
      />
    </SubpageContainer>
  );
};

export default SplitTestsForm;
