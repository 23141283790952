import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import React from 'react';
import { LinkStatus } from 'components/badges/link-status.badge';
import { SellerAccountsInterface } from 'interfaces/seller-accounts.interface';

function SellerAccountsTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'account',
      render: (rowData: SellerAccountsInterface) => {
        const id = rowData?._id;
        return (
          <div className="flex flex-row">
            <a href={`/dashboard/seller-accounts/${id}`} className="hover:underline">
              {rowData.account}
            </a>
          </div>
        );
      },
    },
    {
      key: 'merchant',
      render: (rowData: SellerAccountsInterface) => {
        return <div className="flex flex-row">{rowData.merchant}</div>;
      },
    },
    {
      key: 'sourceBrand',
      render: (rowData: SellerAccountsInterface) => {
        return <div className="flex flex-row">{rowData.sourceBrand}</div>;
      },
    },
    {
      key: 'country',
      render: (rowData: SellerAccountsInterface) => {
        // const id = rowData?._id;
        return <div className="flex flex-row">{rowData.country}</div>;
      },
    },
    {
      key: 'status',
      render: (rowData: SellerAccountsInterface) => <LinkStatus link={rowData.status} />,
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default SellerAccountsTableData;
