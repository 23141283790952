import { ApolloTypes } from 'pages/users/apollo/apollo.interfaces';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  apolloData,
  getApolloResult,
  moreBullets,
  regenerateDescription,
  titleVariant,
} from 'pages/users/apollo/apollo.thunk';
import { cleanArray } from 'lib/helpers';

export const useApolloResult = (type: ApolloTypes) => {
  const { setDescription } = listingContext();
  const { setSuggestion } = listingContext();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [titles, setTitles] = useState<string[]>([]);
  const [bullets, setBullets] = useState<string[]>([]);

  const currentId = id || '';

  const { mutate: addVariantTitles } = useMutation({
    mutationFn: (id: string) => {
      return titleVariant(id);
    },
  });

  const { mutate: addMoreBullets } = useMutation({
    mutationFn: (id: string) => {
      return moreBullets(id);
    },
  });

  const { mutate: generateDescription } = useMutation({
    mutationFn: (id: string) => {
      return regenerateDescription(id);
    },
  });

  const { data, isFetching } = useQuery(['apollo-data-' + type, currentId], {
    queryFn: () => apolloData(currentId, type),
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  });

  useEffect(() => {
    const status = ['success', 'error'];

    // titles
    if (type === 'titles') {
      if (data?.titles) {
        const stats = data?.prompt?.postGeneration?.title.status;
        if (status.includes(stats as string)) {
          setLoading(false);
        }
        const newKeywords = cleanArray(data?.titles);
        setTitles(newKeywords);
      }
    }
    // bullets
    else if (type === 'bullets') {
      if (data?.bullets) {
        const stats = data?.prompt?.postGeneration?.bullets.status;
        if (status.includes(stats as string)) {
          setLoading(false);
        }
        setBullets(data?.bullets);
      }
    }
    // description
    else if (type === 'description') {
      if (data?.description) {
        const stats = data?.prompt?.postGeneration?.description.status;
        if (status.includes(stats as string)) {
          setLoading(false);
        } else if (stats === 'pending') {
          setLoading(true);
        }
        setDescription(data?.description);
      }
    } else if (type === 'suggestion' && data?.prompt?.postGeneration?.suggestion) {
      if (data?.suggestion) {
        const stats = data?.prompt?.postGeneration?.suggestion.status;
        if (status.includes(stats as string)) {
          setLoading(false);
        }
        setSuggestion(data?.suggestion);
      }
    }
  }, [data]);

  const createTitleVariant = (event) => {
    event.preventDefault();
    addVariantTitles(currentId);
    setLoading(true);
  };

  const createBulletsVariant = (event) => {
    event.preventDefault();
    addMoreBullets(currentId);
    setLoading(true);
  };

  const regenerateDescriptionVariant = (event) => {
    event.preventDefault();
    generateDescription(currentId);
    setLoading(true);
  };

  return {
    data,
    isLoading,
    setLoading,
    titles,
    bullets,
    isFetching,
    createTitleVariant,
    createBulletsVariant,
    regenerateDescriptionVariant,
  };
};

export const getApollo = () => {
  const { id } = useParams();
  const { data } = useQuery(['apollo-result-data', id], {
    queryFn: () => getApolloResult(id || ''),
    refetchOnWindowFocus: false,
  });
  return {
    data,
  };
};
