import React from 'react';
import { Badge, List, Modal } from '@mantine/core';
import { PlusCircleIcon, PlusIcon } from '@heroicons/react/outline';
import { IconListSearch } from '@tabler/icons';

const instructions = [
  {
    item: (
      <>
        To start using the Keyword Extractor/Apollo, begin by adding a keyword or product (ASIN). {'\n'} Click on
        the&nbsp;
        <Badge
          className="!p-4 !capitalize"
          radius="sm"
          variant="filled"
          leftSection={<PlusCircleIcon className="-ml-1 h-5 w-5" aria-hidden="true" />}
        >
          Add a new keyword
        </Badge>
        &nbsp; icon or&nbsp;
        <Badge
          className="!p-4 !capitalize"
          radius="sm"
          variant="filled"
          leftSection={<PlusCircleIcon className="-ml-1 h-5 w-5" aria-hidden="true" />}
        >
          Add a new product
        </Badge>
        &nbsp; to input your selection.
      </>
    ),
  },
  {
    item: (
      <>
        Once you've entered your keyword or ASIN, press the&nbsp;
        <Badge className="!p-4 !capitalize" radius="sm" variant="filled" leftSection={<IconListSearch size="1rem" />}>
          Get Similar Products/Keywords
        </Badge>
        &nbsp; button to generate a list of similar
        {'\n'} keywords and products.
      </>
    ),
  },
  {
    item: (
      <>
        To include additional ASINs or keywords, simply click the plus icon and add them to the list.
        {'\n'}
        <Badge className="!px-2 !py-4 !capitalize" radius="sm" variant="light" color="blue">
          <PlusIcon className="h-5 w-5" />
        </Badge>
      </>
    ),
  },
];

function InstructionModal({ opened, close, title }) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title={title}
      size="xl"
      overlayColor={'gray'}
      overlayOpacity={0.5}
      overlayBlur={5}
    >
      <List listStyleType="disc" className="!list-decimal" type="ordered" spacing="md">
        {instructions.map((x, index: number) => {
          return <List.Item key={index}>{x.item}</List.Item>;
        })}
      </List>
    </Modal>
  );
}

export default InstructionModal;
