import AlertSuccess from 'components/alerts/success.alert';
import AlertValidation from 'components/alerts/validation.alert';
import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import { resendEmail } from 'components/resend-email/resend-email.thunks';
import { UserDataInterface } from 'interfaces/user.interface';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setVerified } from 'pages/users/user/user.slice';

export default function ResendEmail({ user }: { user: UserDataInterface }) {
  const [hasSent, setHasSent] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const dispatch = useAppDispatch();

  const resend = () => {
    resendEmail().then(() => Promise.resolve());
    setHasSent(true);
    dispatch(setVerified());
  };

  const message = (
    <>
      <span className="text-sm font-bold text-yellow-800">Please verify your email. Resend email to {user.email}</span>
      <button
        onClick={resend}
        type="button"
        className=" ml-3 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Resend code
      </button>
    </>
  );
  return (
    <>
      <If show={!isClose}>
        <IfElse show={hasSent}>
          <AlertSuccess message="Verification code has been sent!" onClose={() => setIsClose(true)} />
          <AlertValidation message={message} />
        </IfElse>
      </If>
    </>
  );
}
