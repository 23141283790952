import React, { ReactElement, useState } from 'react';
import If from 'components/conditionals/if.conditional';
import { ActionIcon, Badge } from '@mantine/core';
import { IconCloudDownload, IconPictureInPictureOff, IconTrash } from '@tabler/icons';
import { useABTest } from 'pages/users/ab-test/abtest.hook';
import ShowAdmin from 'components/show-admin/show-admin';
import { ABTestImageModal } from './abtest.image-modal';

interface ABTestImageGridInterface {
  fullImage?: string;
  download?: string;
  thumbnail?: string;
  currentIndex: number;
  hideDelete?: boolean;
  isResults?: boolean;
  onRemoveClick?: (number) => void;
}

export const ABTestImageGrid = ({
  fullImage,
  thumbnail,
  download,
  currentIndex,
  hideDelete,
  isResults,
  onRemoveClick,
}: ABTestImageGridInterface): ReactElement => {
  const { deleteImage } = useABTest(false);
  const [opened, setOpened] = useState(false);

  const removeImage = (e) => {
    e.preventDefault();
    if (!isResults) {
      deleteImage(currentIndex - 1);
    } else {
      onRemoveClick?.(currentIndex);
    }
  };

  function showImagePreview() {
    setOpened(true);
  }
  return (
    <div className="relative">
      <ABTestImageModal opened={opened} setOpened={setOpened} fullImage={fullImage} />

      <If show={!isResults}>
        <div className="absolute p-2">
          <Badge color={thumbnail ? 'dark' : 'gray'} variant="filled" radius="lg">
            {currentIndex}
          </Badge>
        </div>
      </If>
      <div
        className={`${
          !thumbnail && 'border-2 border-gray-300'
        } "w-full flex flex-row-reverse flex-wrap h-64  rounded border-dashed rounded-lg"`}
      >
        <If show={thumbnail !== undefined}>
          <img
            src={thumbnail}
            alt="image"
            className="object-cover h-64 w-full pointer-events-none group-hover:opacity-75 rounded rounded-lg"
          />
        </If>
        <div className="flex absolute self-end p-2">
          <If show={!isResults}>
            <ActionIcon
              color="red"
              variant="filled"
              disabled={!thumbnail}
              className="mr-2"
              onClick={() => showImagePreview()}
            >
              <IconPictureInPictureOff size="1rem" />
            </ActionIcon>
          </If>
          <If show={!isResults}>
            <ShowAdmin>
              <ActionIcon
                color="red"
                variant="filled"
                disabled={!thumbnail}
                className="mr-2"
                component="a"
                href={download}
                target="_blank"
              >
                <IconCloudDownload size="1rem" />
              </ActionIcon>
            </ShowAdmin>
          </If>
          <If show={!hideDelete}>
            <ActionIcon color="red" variant="filled" disabled={!thumbnail} onClick={removeImage}>
              <IconTrash size="1rem" />
            </ActionIcon>
          </If>
        </div>
      </div>
    </div>
  );
};
