import ButtonGroups from 'components/buttons/button-groups';
import IfElse from 'components/conditionals/if-else.conditional';
import StripePaymentMethod from 'components/stripe/stripe-payment-method';
import PaymentMethods from 'pages/users/subscription/payment-method/payment-methods';
import React, { useState } from 'react';

export default function UpdatePaymentMethods() {
  const [tab, setTab] = useState('cards');
  return (
    <>
      <div className="mb-8 flex">
        <ButtonGroups
          selectedClass="bg-gray-500 text-white"
          onClick={(value) => setTab(value)}
          currentValue={tab}
          items={[
            {
              value: 'cards',
              children: 'List Cards',
            },
            {
              value: 'add',
              children: 'Add',
            },
          ]}
        ></ButtonGroups>
      </div>
      <IfElse show={tab === 'add'}>
        <StripePaymentMethod></StripePaymentMethod>
        <PaymentMethods></PaymentMethods>
      </IfElse>
    </>
  );
}
