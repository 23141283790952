import { useQuery } from '@tanstack/react-query';
import { getBrands } from 'pages/admin/sqp/brands/brands.thunks';

export const useBrands = (queryKey: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getBrands(),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data,
  };
};
