import React, { ReactElement } from 'react';
import { Text, Image, Tooltip } from '@mantine/core';
import { HeatmapPresetInterface } from '../heat-maps.interface';

export const HeatMapsPresetSelection = (params: HeatmapPresetInterface): ReactElement => {
  const { description, value, name, image, preset, setPreset } = params;

  const onSelect = (value) => {
    if (setPreset) {
      setPreset(value);
    }
  };

  return (
    <Tooltip label={description} offset={20} position="bottom" withArrow>
      <div
        key={value}
        onClick={() => onSelect(value)}
        className={`border-1 border-gray-300  ${
          preset === value
            ? 'bg-white border-2 border-rose-500 scale-105'
            : 'bg-gray-200 cursor-pointer border-2 hover:scale-105 transition-all'
        } w-full flex flex-col justify-between h-full items-center rounded rounded-lg py-10 `}
      >
        <div className="self-center pb-12">
          <Text fw={500} fz="lg">
            {name}
          </Text>
        </div>
        <Image maw={200} src={`/heatmaps/${image}`} alt="preset image" className="rounded-lg overflow-hidden mt-6" />
      </div>
    </Tooltip>
  );
};
