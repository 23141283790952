import { TableColumnRowInterface } from './table-body.helper';

function TableRow({ columns, item }: { columns: TableColumnRowInterface[]; item: any }) {
  return (
    <>
      {columns.map((column) => {
        return (
          <td key={column.key} className="w-42 px-6 py-4 text-sm">
            {column.render(item)}
          </td>
        );
      })}
    </>
  );
}

export default TableRow;
