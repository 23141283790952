import { SplitTestInterface } from 'pages/users/split-tests/split-tests.interface';
import { postSilentFetch } from 'request/request';
import { deleteFetch, getFetch } from 'request/request-api';
import { ResponseListInterface } from 'request/response.interface';

export const getSplitTests = async (page = 1): Promise<ResponseListInterface<SplitTestInterface>> => {
  return await postSilentFetch('/split-tests/page', { page });
};

export const getSplitTest = async (id: string): Promise<SplitTestInterface> => {
  return await getFetch({ url: '/split-tests/' + id });
};

export const removeSplitTest = async (id: string): Promise<SplitTestInterface> => {
  return await deleteFetch({ url: '/split-tests/' + id });
};
