import { LineChartDataInterface } from 'pages/users/analytics/analytics.interface';
import { Line } from 'react-chartjs-2';

const LineChart = ({ datasets, labels }: { datasets: LineChartDataInterface[]; labels: string[] }) => {
  const lineData = {
    labels,
    datasets,
  };

  return (
    <Line
      className="bg-white rounded-md"
      data={lineData}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            suggestedMin: 0,
          },
          y1: {
            type: 'linear',
            position: 'right',
            suggestedMin: 0,
          },
        },
      }}
    />
  );
};

export default LineChart;
