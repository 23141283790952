import { getPackage, getPaymentIntertStatus, paymentIntent } from 'pages/users/signup/signup.thunks';
import { PackageInterface } from 'pages/users/subscription/plans/plans.interface';

import { useCallback, useEffect, useState } from 'react';

export function useGetPackage(priceId, type, navigate) {
  const [selectedPrice, setSelectedPackage] = useState<PackageInterface | null>(null);

  const getData = useCallback(async () => {
    const data = await getPackage(priceId as string, type);
    if (data) {
      setSelectedPackage(data);
    } else {
      navigate('/invalid-package');
    }
  }, []);

  useEffect(() => {
    getData().catch(() => Promise.reject());
  }, []);

  return selectedPrice;
}

export function usePaymentIntent(
  userId: string | undefined,
  priceId: string | undefined,
  type: string,
  coupon?: string | undefined
) {
  const [clientSecret, setClientSecret] = useState<string>('');

  const getPayment = useCallback(async () => {
    const data = await paymentIntent(userId, priceId, type, coupon);
    if (data) {
      setClientSecret(data.clientSecret);
    }
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return clientSecret;
}

export function usePaymentIntentStatus(paymentIntent: string) {
  const [status, setStatus] = useState<string>('');

  const getData = useCallback(async () => {
    const { status } = await getPaymentIntertStatus(paymentIntent);
    setStatus(status);
  }, []);

  useEffect(() => {
    getData().catch(() => Promise.reject());
  }, []);
  return status;
}
