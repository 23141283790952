import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import DeleteButton from 'components/buttons/delete-button';
import IfElse from 'components/conditionals/if-else.conditional';
import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import Badge from 'pages/users/funnels/select-product/select-product.badge';
import { TeamMemberInterface } from 'pages/users/teams/teams.interface';
import { deleteTeamMember } from 'pages/users/teams/teams.thunks';
import { selectUserData } from 'pages/users/user/user.slice';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import React from 'react';

function teamsTableData(): TableColumnRowInterface[] {
  const navigate = useNavigate();
  const openModal = (id: string) =>
    openConfirmModal({
      title: 'Delete your profile',
      centered: true,
      children: (
        <Text size="sm">
          Do you really intend to remove this member from your team? Please be aware that this action is irreversible
          and cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete Member', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await deleteTeamMember(id);
        navigate(0);
      },
    });

  const { _id } = useAppSelector(selectUserData);
  const columnData = [
    {
      key: 'name',
      render: (rowData: TeamMemberInterface) => {
        return (
          <IfElse show={rowData?.userId === null}>
            <div>&mdash;</div>
            <div className="py-2">
              {rowData?.userId?.firstName} {rowData?.userId?.lastName}
            </div>
          </IfElse>
        );
      },
    },
    {
      key: 'email',
      render: (rowData: TeamMemberInterface) => {
        return (
          <IfElse show={rowData?.userId === null}>
            <div>&mdash;</div>
            <div className="py-2">{rowData?.userId?.email}</div>
          </IfElse>
        );
      },
    },
    {
      key: 'delete',
      render: (rowData: TeamMemberInterface) => {
        return (
          <IfElse show={_id !== rowData?.userId?._id}>
            <DeleteButton onClick={() => openModal(rowData?._id)} />
            <>
              <Badge label="admin" value="" />
            </>
          </IfElse>
        );
      },
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/teams', field: 'name' });
}

export default teamsTableData;
