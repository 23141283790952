import Stripe from 'components/stripe/stripe';
import StripePaymentMethodForm from 'components/stripe/stripe-payment-method-form';
import { usePaymentMethodIntent } from 'pages/users/subscription/subscription.hooks';
import React from 'react';

export default function StripePaymentMethod() {
  const { clientSecret } = usePaymentMethodIntent();
  return (
    <>
      <Stripe clientSecret={clientSecret}>
        <StripePaymentMethodForm></StripePaymentMethodForm>
      </Stripe>
    </>
  );
}
