import CheckCircleIcon from '@heroicons/react/solid/CheckCircleIcon';
import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import { SignupContext } from 'pages/users/signup/signup';
import { CouponInterface } from 'pages/users/signup/signup.interface';
import { PackageInterface, PackageTypes } from 'pages/users/subscription/plans/plans.interface';
import { useContext } from 'react';

export default function Price({ selectedPackage, type }: { selectedPackage: PackageInterface; type: PackageTypes }) {
  const isPPC = selectedPackage?.type === 'ppc';
  const costSpend = selectedPackage?.costSpend || '';
  const title = selectedPackage.title;
  const { coupon } = useContext(SignupContext);
  const price = getTotalDiscount(selectedPackage.price[type] || 0, coupon);

  // const [duration, setDuration] = useState('aa');
  // useEffect(() => {
  //   setDuration(showCouponDuration(coupon));
  // }, [coupon]);

  return (
    <>
      <div className="lg:w-1/2 lg:min-h-screen overflow-y-hidden">
        <div className="relative h-full">
          <div className="absolute right-0 w-full h-full object-cover  object-center z-20 bg-gray-800 opacity-75"></div>
          <div className="flex flex-col h-full items-center sm:items-start justify-center z-40 relative p-8">
            <div className="w-full lg:max-w-2xl">
              <div key={selectedPackage.name} className="lg:p-8 text-white h-fit">
                <h3 className="text-2xl lg:text-4xl font-semibold">{title}</h3>
                <p className="mt-1 lg:mt-4 flex items-baseline">
                  <span className="text-3xl lg:text-8xl font-extrabold tracking-tight text-rose-500">${price}</span>
                  <span className="ml-1 text-2xl lg:text-2xl font-semibold text-rose-500">
                    {selectedPackage.frequency}
                  </span>
                  {/*<span className="ml-1 text-sm font-semibold text-rose-300">{duration}</span>*/}
                </p>
                <If show={isPPC}>
                  <p className="text-xs">{costSpend}</p>
                </If>

                <p className="mt-2 lg:mt-6">{selectedPackage.description}</p>

                {/* Feature list */}
                <IfElse show={isPPC}>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {selectedPackage?.included?.map((feature) => (
                      <div key={feature} className="flex">
                        <CheckCircleIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                        <span className="ml-3">{feature}</span>
                      </div>
                    ))}
                    {selectedPackage?.platform?.map((feature) => (
                      <div key={feature} className="flex">
                        <CheckCircleIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                        <span className="ml-3">{feature}</span>
                      </div>
                    ))}
                  </div>
                  <ul role="list" className="mt-6 space-y-2 lg:space-y-6">
                    {selectedPackage.features.map((feature) => (
                      <li key={feature} className="flex">
                        <CheckCircleIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                        <span className="ml-3">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </IfElse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getTotalDiscount(subtotal: number, coupon: CouponInterface | null): number {
  if (!coupon || !coupon.valid) {
    return subtotal;
  }

  let discountedPrice = subtotal;

  if (coupon.percent_off !== null) {
    const percentDiscount = (coupon.percent_off / 100) * subtotal;
    discountedPrice -= percentDiscount;
  }

  if (coupon.amount_off !== null) {
    discountedPrice -= coupon.amount_off / 100;
  }

  return discountedPrice < 0 ? 0 : discountedPrice;
}
//
// function showCouponDuration(coupon: CouponInterface | null): string {
//   if (!coupon || !coupon.valid) {
//     return '';
//   }
//
//   let durationMessage = '';
//
//   if (coupon.duration === 'forever') {
//     durationMessage = 'forever';
//   } else if (coupon.duration === 'once') {
//     durationMessage = 'for one-time use';
//   } else if (coupon.duration === 'repeating' && coupon.duration_in_months !== null) {
//     durationMessage = `for ${coupon.duration_in_months} months`;
//   } else {
//     durationMessage = '';
//   }
//
//   let discountMessage = '';
//   if (coupon.percent_off !== null) {
//     discountMessage = `${Math.abs(coupon.percent_off)}% off`;
//   } else if (coupon.amount_off !== null && coupon.amount_off > 0) {
//     discountMessage = `$${Math.abs(coupon.amount_off / 100)} off`;
//   }
//
//   if (coupon.percent_off !== null && coupon.percent_off < 0) {
//     discountMessage = `-${discountMessage}`;
//   } else if (coupon.amount_off !== null && coupon.amount_off < 0) {
//     discountMessage = `-${discountMessage}`;
//   }
//
//   return `${discountMessage},  ${durationMessage}`;
// }
