import React, { ReactElement } from 'react';
import { Radio } from '@mantine/core';
import If from 'components/conditionals/if.conditional';

interface MFormRadioGroupData {
  key: string | number;
  label: string;
  description?: string;
}

interface MFormRadioGroupParams {
  label: string;
  value: string;
  onChange: (string) => void;
  data: MFormRadioGroupData[];
  required?: boolean;
}

const MFormRadioGroup = ({
  label,
  value,
  data,
  onChange,
  required = false,
  ...props
}: MFormRadioGroupParams): ReactElement => {
  return (
    <div className="sm:grid sm:grid-cols-7 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-end">
        {label} <span className={`text-red-500 ${!required && 'hidden'}`}>*</span>
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-6 ">
        <div className="grid grid-cols-6 gap-4 w-4/5">
          <Radio.Group {...props} required={required} value={value} onChange={onChange} className="col-span-6">
            {data.map((listingType, index: number) => (
              <div key={`${listingType.key}-${index}`} className="w-full">
                <div className="flex flex-row text-sm">
                  <Radio value={listingType.key} label={listingType.label} className="font-medium" />
                  <If show={listingType.description !== ''}>
                    <p className={`text-gray-500 `}> &#160; - {listingType.description}</p>
                  </If>
                </div>
              </div>
            ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default MFormRadioGroup;
