import FormCheckboxSingle from 'components/forms/form-checkbox-single';
import FormCustom from 'components/forms/form-custom';
import FormHidden from 'components/forms/form-hidden';
import FormInput from 'components/forms/form-input';
import FormSelect from 'components/forms/form-select';
import FormSubmit from 'components/forms/form-submit';
import ShimmerWrapper from 'components/shimmer';
import useAmazonAccount from 'hooks/useAmazonAccount';
import useFetch from 'hooks/useFetch';
import { ProductInterface } from 'interfaces/rebates.interface';

import { selectAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import { createFunnel } from 'pages/users/funnels/funnels.thunks';
import SelectProductForm from 'pages/users/funnels/select-product/select-product.form';
import { getProducts } from 'pages/users/funnels/select-product/select-product.thunk';
import BasicInfoShimmer from 'pages/users/funnels/shimmers/basic-info';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export default function FunnelBasicInfo() {
  const [product, setProduct] = useState<ProductInterface | null>(null);
  const navigate = useNavigate();

  const funnel: any = {
    funnelType: 'rebates',
    sellerAccount: null,
    verifyEmail: true,
    requireAndVerifyPhone: false,
  };

  const accounts = useAppSelector(selectAmazonAccounts);
  const { selectedAcc } = useAmazonAccount();

  // Fetch products filtered by amazon account when page renders
  useFetch('amazon-products' + selectedAcc, () => getProducts(selectedAcc));

  const funnelTypes = [{ value: 'rebates', name: 'Rebate Funnel' }];

  const onSubmit = async (values) => {
    const funnel = await createFunnel({ ...values, product: product?._id, requireAndVerifyPhone: false });

    if (funnel) {
      navigate(`/dashboard/funnels/rebates/main/${funnel._id}`);
    }
  };

  const handleChangeAccount = async (e) => {
    await getProducts(e.target.value);
  };

  return (
    <ShimmerWrapper fallback={<BasicInfoShimmer />}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off" className="grid grid-cols-1 gap-6 px-4">
              <FormInput
                name="name"
                label="Funnel Name"
                col={6}
                span={5}
                initialValue={funnel?.name}
                disabled={submitting}
              />
              <FormSelect
                name="sellerAccount"
                label="Account"
                values={accounts}
                initialValue={accounts[0]?._id}
                optionLabel="name"
                optionValue="_id"
                handleChange={handleChangeAccount}
                col={6}
                span={5}
              />
              <FormHidden name="product" initialValue={funnel?.product} />
              <FormCustom label="Product" col={6} span={5}>
                <SelectProductForm setProduct={setProduct} product={product} />
              </FormCustom>
              <FormSelect
                col={6}
                span={5}
                name="funnelType"
                label="Funnel Type"
                values={funnelTypes}
                initialValue="rebates"
                optionLabel="name"
                optionValue="value"
              />
              <div className="hidden">
                <FormCustom col={6} span={5}>
                  <div>
                    <FormCheckboxSingle
                      label="Email Confirmation"
                      name="verifyEmail"
                      subtitle="User will need to confirm their email."
                      initialValue={true}
                    />
                  </div>
                </FormCustom>
              </div>
              <FormSubmit label="Create Funnel" margin={0} col={6} span={5} disabled={submitting} />
            </form>
          );
        }}
      />
    </ShimmerWrapper>
  );
}
