import { ChevronLeftIcon } from '@heroicons/react/solid';
import { defaultPage } from 'lib/helpers';
import { selectUserData } from 'pages/users/user/user.slice';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const PPCAIHeader = (): ReactElement => {
  const navigate = useNavigate();
  const { roles, isAdmin } = useAppSelector(selectUserData);
  const href = defaultPage(roles, isAdmin);

  const handleClick = (e) => {
    navigate(`${e}`);
  };

  return (
    <div>
      <div className="flex ml-2 mt-2">
        <button
          onClick={() => handleClick(href === 'ppc-ai' ? '/ppc-ai' : '/dashboard/' + href)}
          className="flex w-60 hover:text-red-600"
        >
          <ChevronLeftIcon className="w-3 h-full" />
          <span className="text-xs">Main Dashboard</span>
        </button>
      </div>
    </div>
  );
};

export default PPCAIHeader;
