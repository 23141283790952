import RequestContainer from 'components/containers/request.container';

import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import customersTableData from 'pages/users/funnels/funnel-customers/funnel-customers.tabledata';
import { loadFunnelCustomers } from 'pages/users/funnels/funnel-customers/funnel-customers.thunk';
import PayoutModal from 'pages/users/funnels/funnel-customers/payout/payout.modal';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

function FunnelCustomers() {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isFetching, data: customers } = useFetch('funnel-customers' + currentPage, () =>
    loadFunnelCustomers({ page: currentPage, funnelId: id })
  );

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } = customers;
  const headers = ['Name', 'Email', 'Paypal Email', 'Status', 'Order Number', 'Order Status', 'Payout Status'];
  const columns = customersTableData();
  const colSpan = headers.length;

  return (
    <>
      <PayoutModal />
      <SubpageContainer title="Funnel Customers">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={6} />}
            colSpan={colSpan}
            data={docs}
            addNew={false}
            noDataMsg="No Funnel customers found"
          >
            <TableBody columns={columns} data={docs} />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
}

export default FunnelCustomers;
