export interface PackagePriceInterface {
  monthly: string;
  annual: string;
}

export enum PlanType {
  BASIC = 'basic',
  PPC = 'ppc',
}

export enum PackageTypes {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export interface PackageInterface {
  name: string;
  title: string;
  tier: number;
  price: {
    monthly: number;
    annual: number;
  };
  productId: string;
  priceId: PackagePriceInterface;
  frequency: string;
  description: string;
  features: string[];
  cta: string;
  type: PlanType;
  plan:
    | 'starter'
    | 'growth'
    | 'professional'
    | 'ppc_starter'
    | 'ppc_growth'
    | 'ppc_professional'
    | 'ppc_professionalPlus';
  costSpend?: string;
  included: [string];
  platform: [string];
}

export interface PackagesInterface {
  starter: PackageInterface;
  growth: PackageInterface;
  professional: PackageInterface;
}

export interface PlanInterface {
  id: string;
  status: string;
  name: string;
  price: string;
  endDate: Date;
  interval: string;
  packagePlan: PackageInterface;
  lastPaidAmount?: string;
}

export interface PlanUsageInterface {
  name: string;
  value: number;
  total: number;
}

export interface CurrentPlanInterface {
  id: string;
}

export interface PlanDetailsInterface {
  name: string;
  value: number;
  total: number;
}
