import { PaperAirplaneIcon, PauseIcon, RefreshIcon, StopIcon } from '@heroicons/react/solid';
import ActionButton from 'pages/users/funnels/deploy-buttons/action.button';

import { deployRebate, pauseFunnel, stopFunnel } from 'pages/users/funnels/funnels.thunks';
import React from 'react';

function ActionButtons({ rowData: { isDeployed, _id: id, status } }) {
  const deployFunnel = () => deployRebate(id);

  const stop = () => stopFunnel(id);

  const pause = async () => await pauseFunnel(id);

  const deploy = async () => await deployFunnel();

  const redeploy = async () => {
    if (status === 'paused') {
      const stoppedFunnel = await stopFunnel(id);

      if (stoppedFunnel) {
        await deployFunnel();
      }
    } else {
      deployFunnel();
    }
  };

  return (
    <div className="relative">
      <div className="mt-4 flex md:mt-0 md:ml-4 gap-2">
        {!isDeployed && <ActionButton label="Deploy" Icon={PaperAirplaneIcon} handleClick={deploy} />}
        {isDeployed && status !== 'paused' && (
          <>
            <ActionButton label="Pause" Icon={PauseIcon} handleClick={pause} />
            <ActionButton label="Stop" Icon={StopIcon} handleClick={stop} />
          </>
        )}
        {status === 'paused' && (
          <>
            <ActionButton label="Stop" Icon={StopIcon} handleClick={stop} />
            <ActionButton label="Redeploy" Icon={RefreshIcon} handleClick={redeploy} />
          </>
        )}
      </div>
    </div>
  );
}

export default ActionButtons;
