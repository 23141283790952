import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AmazonDataInterface } from 'pages/users/amazon/amazon.interface';

import { RootState } from 'redux/store';

const initialState: AmazonDataInterface[] = [];

export const amazonSlice = createSlice({
  name: 'amazon',
  initialState,
  reducers: {
    setAmazonAccounts: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<AmazonDataInterface[]>) => {
      return action.payload;
    },
    editAmazonAccounts: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<AmazonDataInterface>) => {
      const updatedAccounts = state.map((a: AmazonDataInterface) =>
        a._id === action.payload._id ? action.payload : a
      );
      return updatedAccounts;
    },
    unlinkAmazonAccount: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<string>) => {
      const updatedAccounts = state.filter((a: AmazonDataInterface) => a._id !== action.payload);
      return updatedAccounts;
    },
    unlinkAmazonAdsInAmazonAccount: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<string>) => {
      const updatedAccounts = state.filter((a: AmazonDataInterface) => a._id !== action.payload);
      return updatedAccounts;
    },
  },
});

export const { setAmazonAccounts, editAmazonAccounts, unlinkAmazonAccount } = amazonSlice.actions;

export const selectAmazonAccounts = (state: RootState) => state.amazon;

export const amazonReducer = amazonSlice.reducer;
