import React from 'react';
import { Button, Card, Chip, Group, TextInput } from '@mantine/core';
import { abTestDefineElementsContext } from 'pages/users/ab-test/abtest-define-elements/abtest-define.context';
import { IconFilePlus, IconMarquee2, IconTrash } from '@tabler/icons';

const elementTypes = [
  {
    label: 'Logo',
    value: 'logo',
  },
  {
    label: 'Message',
    value: 'message',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

const AbtestDefineElements = () => {
  const { data, setElementItems, setCurrentPosition, currentImageIndex, setTrace, isTrace } =
    abTestDefineElementsContext();
  const elements = data[currentImageIndex]?.elements;

  const addNewElement = () => {
    const els = data[currentImageIndex];
    if (els?.elements?.length !== 0) {
      setCurrentPosition(currentImageIndex, els?.elements?.length);
    }
    setElementItems(currentImageIndex, [
      ...elements,
      {
        crop: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          rotate: 0,
          scaleX: 0,
          scaleY: 0,
        },
        text: `Element ${elements.length + 1}`,
        type: 'logo',
        position: {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
          imageHeight: 0,
          imageWidth: 0,
        },
      },
    ]);
    setTrace(!isTrace);
  };

  const retrace = () => {
    setTrace(!isTrace);
  };

  const renameText = (value: string) => {
    const els = data[currentImageIndex];
    els.elements[els.currentItem].text = value;
    setElementItems(currentImageIndex, [...els.elements]);
  };

  const setElementType = (value: string) => {
    const els = data[currentImageIndex];
    els.elements[els.currentItem].type = value;
    setElementItems(currentImageIndex, [...els.elements]);
  };

  const deleteElementItem = (index: number) => {
    setTrace(false);
    const els = data[currentImageIndex];
    const newData = els.elements.slice(0, index).concat(els.elements.slice(index + 1));
    setElementItems(currentImageIndex, [...newData]);
  };

  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto mx-2">
      <Button onClick={addNewElement} leftIcon={<IconFilePlus size="1rem" />}>
        Add New Element
      </Button>
      <div className="flex flex-col overflow-auto">
        {data[currentImageIndex]?.elements?.map((da, index: number) => {
          return (
            <Card
              key={index}
              className={`!overflow-visible w-max  ${
                data[currentImageIndex].currentItem === index
                  ? 'border-dashed border-2 border-rose-400  my-2'
                  : 'border-2 border-white my-2'
              }`}
            >
              <div onClick={() => setCurrentPosition(currentImageIndex, index)} className="flex flex-col w-full">
                <TextInput
                  placeholder="Name"
                  label="Name"
                  value={da.text}
                  className="w-full"
                  onChange={(d) => renameText(d.target.value)}
                />
                <div className="flex flex-row mt-4 items-center justify-between">
                  <div
                    className="flex-none"
                    style={{
                      height: 90,
                      width: 90,
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <div>
                      <img
                        src={data[currentImageIndex].imageSource}
                        style={{
                          position: 'absolute',
                          width: da?.position?.imageWidth - 20,
                          height: da?.position?.imageHeight - 20,
                          maxWidth: da?.position?.imageWidth - 20,
                          maxHeight: da?.position?.imageHeight - 20,
                          top: -da?.position?.top,
                          left: -da?.position?.left,
                          transform: 'scale(0.99)',
                          // width: da?.crop?.width,
                          // height: da?.crop?.height,
                        }}
                        alt={'e'}
                      />
                    </div>
                  </div>

                  <Chip.Group className="flex-none " defaultValue={da.type} onChange={setElementType}>
                    <Group position="apart">
                      {elementTypes.map((e, index) => {
                        return (
                          <Chip value={e.value} variant={'filled'} radius={'lg'} key={index}>
                            {e.label}
                          </Chip>
                        );
                      })}
                    </Group>
                  </Chip.Group>
                  <Group className="flex-none" spacing="xs">
                    <Button
                      variant="filled"
                      onClick={retrace}
                      disabled={isTrace}
                      leftIcon={<IconMarquee2 size="1rem" />}
                    >
                      Retrace
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => deleteElementItem(index)}
                      leftIcon={<IconTrash size="1rem" />}
                    >
                      Delete
                    </Button>
                  </Group>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default AbtestDefineElements;
