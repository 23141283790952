import React, { ReactElement } from 'react';
import { PromptTabs } from 'pages/admin/prompts/tabs/prompt.tabs';
import { promptContext } from 'pages/admin/prompts/prompt.context';
import { Textarea } from '@mantine/core';

export const PromptSuggestion = (): ReactElement => {
  const { suggestion, setSuggestion } = promptContext();
  return (
    <PromptTabs>
      <div>
        <Textarea
          variant="filled"
          value={suggestion && suggestion.length !== 0 ? suggestion[0]?.text : ''}
          onChange={(data) =>
            setSuggestion?.([
              {
                type: 'text',
                text: data.target.value,
              },
              {
                type: 'image_url',
                image_url: {
                  url: '',
                },
              },
            ])
          }
          autosize
        />
      </div>
    </PromptTabs>
  );
};
