import { PaperAirplaneIcon, PencilIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import Tooltip from 'components/common/tooltip';
import IfElse from 'components/conditionals/if-else.conditional';
import If from 'components/conditionals/if.conditional';
import FormInput from 'components/forms/form-input';
import markets from 'lib/markets';
import amazonUnlinkAccount from 'pages/users/amazon/amazon-unlink-account';
import { AmazonApiTypes, AmazonRenameUnlinkInterface } from 'pages/users/amazon/amazon.interface';
import { saveAmazonAccountName } from 'pages/users/amazon/amazon.thunks';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

const AmazonRenameUnlink = ({
  adAccount,
  sellerAccounts,
  sellerAccount,
  type,
  unlink = true,
}: AmazonRenameUnlinkInterface) => {
  const [isEditing, toggleEdit] = useState(false);
  const editName = (e) => {
    e.preventDefault();
    toggleEdit(!isEditing);
  };

  const onSubmit = async (values) => {
    toggleEdit(!isEditing);
    await saveAmazonAccountName(values, type);
  };

  let flag = <></>;
  if (type === AmazonApiTypes.SP) {
    const countryCode = markets.find((m) => m.id === adAccount.country)?.code?.toLowerCase();
    flag = (
      <img
        className="inline-flex mr-2 mb-1 rounded-sm"
        src={`https://flagcdn.com/w20/${countryCode}.png`}
        alt={countryCode}
      />
    );
  }

  const deleteAdsAccount = async (e) => {
    if (sellerAccounts && sellerAccount) {
      amazonUnlinkAccount(e, adAccount, type);
    }
  };

  return (
    <>
      <IfElse show={isEditing}>
        <Form
          onSubmit={onSubmit}
          initialValues={adAccount}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="flex items-center space-x-2 w-full">
              {flag}
              <FormInput name="name" />
              <Tooltip label="Submit">
                <button
                  type="submit"
                  className="border border-indigo-500 rounded-full p-1 opacity-75"
                  onClick={handleSubmit}
                >
                  <PaperAirplaneIcon className="h-5 w-5 text-indigo-600" />
                </button>
              </Tooltip>
              <Tooltip label="Cancel">
                <button className="border border-red-600 rounded-full p-1 opacity-75" onClick={(e) => editName(e)}>
                  <XIcon className="h-5 w-5 text-red-600" />
                </button>
              </Tooltip>
            </form>
          )}
        />
        <>
          <h3 className="font-medium text-gray-900">
            {flag}
            {adAccount?.name}
          </h3>
          <div className="flex">
            <Tooltip label="Edit">
              <button
                className="group rounded-full p-1 border-indigo-500 opacity-75 hover:bg-indigo-500"
                onClick={(e) => editName(e)}
              >
                <PencilIcon className="h-5 w-5 text-indigo-600 group-hover:text-white" />
              </button>
            </Tooltip>

            <If show={unlink}>
              <Tooltip label="Unlink">
                <button
                  className="group rounded-full p-1 border-red-500 opacity-75 hover:bg-red-500"
                  onClick={(e) => deleteAdsAccount(e)}
                >
                  <TrashIcon className="h-5 w-5 text-red-600 group-hover:text-white" />
                </button>
              </Tooltip>
            </If>
          </div>
        </>
      </IfElse>
    </>
  );
};

export default AmazonRenameUnlink;
