import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import { useAppSelector } from 'redux/hooks';
import TablePagination from 'components/tables/table.pagination';
import { getSellerAccounts } from 'pages/admin/sqp/seller-accounts/seller-accounts.thunks';
import { selectSellerAccounts } from 'pages/admin/sqp/seller-accounts/seller-accounts.slice';
import SellerAccountsTableData from 'pages/admin/sqp/seller-accounts/seller-accounts.tabledata';

const headers = ['Account', 'Merchant', 'Source Brand', 'Country', 'Status', 'Action'];

const SellerAccounts = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isFetching } = useFetch('seller-accounts', () => getSellerAccounts({ page: currentPage }));

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectSellerAccounts);
  const columns = SellerAccountsTableData();
  const colSpan = headers.length;
  return (
    <>
      <SubpageContainer title="Seller Accounts" showAdd addUrl="new" showAddString="Add New Seller Account">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={docs}
          >
            <TableBody columns={columns} data={docs} deleteUrl="/sqp/admin/seller-accounts/" confirmField="account" />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
};

export default SellerAccounts;
