function KeywordBadge({ keywords }) {
  return (
    <>
      {keywords?.length ? (
        <div>
          {keywords?.map((k: string) => (
            <span
              key={k}
              className={`inline-flex mr-1 items-center px-2 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800 border}`}
            >
              {k}
            </span>
          ))}
        </div>
      ) : (
        <div className="px-4">—</div>
      )}
    </>
  );
}

export default KeywordBadge;
