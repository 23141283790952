import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import useFetch from 'hooks/useFetch';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import { addAccounts, getAccountsDetails, updateAccount } from 'pages/admin/sqp/accounts/accounts.thunks';
import FormTextarea from 'components/forms/form-textarea';
import React from 'react';
import FormInputPassword from 'components/forms/form-input-password';

function AccountsForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreating = id === 'new';

  let account = {
    email: '',
    password: '',
    totpSecret: '',
  };
  let isFetching = false;

  if (!isCreating) {
    ({ isFetching, data: account } = useFetch('brand-details', () => getAccountsDetails(id)));
  }

  const onSubmit = async (values) => {
    if (isCreating) {
      const res = await addAccounts(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/accounts'), 2000);
      }
    } else {
      await updateAccount(id, values);
    }
  };

  return (
    <SubpageContainer title={isCreating ? 'Add New Account' : 'Update Account'}>
      {/*<RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>*/}
      <RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={account}
          render={({ handleSubmit }) => {
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid sm:w-full xl:w-3/4 grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormInput name="email" label="Email" />
                      <FormInputPassword name="password" label="Password" />
                      {/*<FormInputPassword  name="confirm password" label="Confirm Password"/>*/}
                      <FormTextarea label="Totp Secret" name="totpSecret" placeholder="(optional)" />
                    </div>
                    <div className="flex justify-end">
                      <FormSubmit label="Submit" showButtonOnly disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default AccountsForm;
