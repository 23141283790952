import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { FunnelInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: FunnelInterface = {
  amazonPrice: 0,
  askReviewViaEmail: false,
  askReviewViaSMS: false,
  automaticUpdatePrice: false,
  bonusRebate: 0,
  bonusRebateType: '',
  createdAt: '',
  currentKeywordIndex: 0,
  deleted: false,
  deployDate: '',
  inviteViaEmail: false,
  inviteViaSMS: false,
  isDeployed: false,
  keywords: [],
  label: '',
  maxRebate: 0,
  name: '',
  product: '',
  productData: {},
  rebateAmount: 0,
  rebateAmountType: '',
  rebateCounter: 0,
  rebatesDelay: 0,
  rebatesPerDate: 0,
  refundDiscount: false,
  refundTax: false,
  requireAndVerifyPhone: false,
  productImages: {
    main: '',
    resized: '',
    thumbnail: '',
  },
  searchFindBy: '',
  sellerAccount: { _id: '', name: '' },
  site: {},
  status: '',
  updatedAt: '',
  user: '',
  _id: '',
};

export const funnelSlice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    setFunnel: (state: Draft<FunnelInterface>, action: PayloadAction<FunnelInterface>) => {
      return action.payload;
    },
    updateFunnel: (state: Draft<FunnelInterface>, action: PayloadAction<FunnelInterface>) => {
      return action.payload;
    },
  },
});

export const { setFunnel, updateFunnel } = funnelSlice.actions;

export const selectFunnel = (state: RootState) => state.funnel;

export const funnelReducer = funnelSlice.reducer;
