import Button from 'components/buttons/button';
import { ComponentPropsWithoutRef, MouseEventHandler, ReactNode } from 'react';

export default function PrimaryButton({
  onClick,
  children,
  color = 'rose',
  size = 'md',
  font = 'medium',
  shadow = 'sm',
  type = 'button',
  ...props
}: {
  onClick?: MouseEventHandler;
  children: ReactNode;
  color?: string;
  size?: string;
  font?: string;
  shadow?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
} & ComponentPropsWithoutRef<'button'>) {
  const buttonColor = color || 'rose';
  return (
    <>
      <Button
        type={type}
        color={color}
        onClick={onClick}
        className={`items-center px-2.5 py-1.5 border border-transparent text-${size} font-${font} rounded shadow-${shadow}
         text-white bg-${buttonColor}-600 hover:bg-${buttonColor}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${buttonColor}-500
         disabled:opacity-50
         `}
        {...props}
      >
        {children}
      </Button>
    </>
  );
}
