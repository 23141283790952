import { getToken } from 'lib/cookie.helper';
import { logout } from 'pages/users/user/user.slice';
import { store } from 'redux/store';
import { getApiUrl } from './request.helpers';
import {
  isRequestFinished,
  requestFailure,
  requestInProgress,
  resetRequest,
  saveSuccess,
  validationError,
} from './request.slice';

/**
 * Send a GET request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param saveMessage save message texts
 */
export const getFetch = (url: string, saveMessage = 'Saved'): Promise<any> => {
  return fetchRequest(url, null, 'get', true, saveMessage);
};

/**
 * Send a GET request and will always HIDE dispatch "Saved" messages
 * @param url - url of the request
 */
export const getSilentFetch = (url: string): Promise<any> => {
  return fetchRequest(url, null, 'get', false);
};
/**
 * Send a POST request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 */
export const postFetch = (url: string, data, saveMessage = 'Saved'): Promise<any> => {
  return fetchRequest(url, data, 'post', true, saveMessage);
};

/**
 * Send a POST request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 */
export const postSilentFetch = (url: string, data): Promise<any> => {
  return fetchRequest(url, data, 'post', false);
};

/**
 * Send a PUT request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 */
export const putFetch = (url: string, data, saveMessage = 'Saved'): Promise<any> => {
  return fetchRequest(url, data, 'put', true, saveMessage);
};

/**
 * Send a PUT request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 */
export const putSilentFetch = (url: string, data): Promise<any> => {
  return fetchRequest(url, data, 'put', false);
};

/**
 * Send a DELETE request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param saveMessage save message texts
 */
export const deleteFetch = (url: string, saveMessage = 'Saved'): Promise<any> => {
  return fetchRequest(url, null, 'delete', true, saveMessage);
};

/**
 * Send a DELETE request and will always show dispatch "Saved" messages
 * @param url - url of the request
 */
export const deleteSilentFetch = (url: string): Promise<any> => {
  return fetchRequest(url, null, 'delete', false);
};

/**
 * wrapper for all requests, automated request in progress and failure state
 * DO NOT USE THIS DIRECTLY, USE THE ABSTRACTION METHODS ABOVE INSTEAD
 * @private
 * @param url - url of the request
 * @param data - post body data
 * @param httpMethod - http method
 * @param dispatchSaveMessage - display save message
 * @param saveMessage = save message texts, default is "Saved"
 */
const fetchRequest = (
  url: string,
  formData = null,
  httpMethod: string | null = null,
  dispatchSaveMessage = true,
  saveMessage: string | null = null
): Promise<any> => {
  let data;
  let contentTypeJson: null | { 'Content-Type': string } = { 'Content-Type': 'application/json' };
  // upload , multi-part
  if ((formData as unknown) instanceof FormData) {
    data = formData;
    contentTypeJson = null;
  } else {
    data = formData ? JSON.stringify(formData) : null;
  }
  const method = httpMethod || (!data ? 'get' : 'post');
  const token = getToken();
  const adminToken = getToken('admin_token');

  store.dispatch(resetRequest());
  store.dispatch(requestInProgress());
  const apiUrl = getApiUrl(url);

  return fetch(apiUrl, {
    method,
    mode: 'cors',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers: {
      Authorization: `Bearer ${token}`,
      'x-admin-token': adminToken,
      ...contentTypeJson,
    },
    body: data,
  })
    .then((response) => {
      store.dispatch(isRequestFinished());
      if (response.status === 200) {
        if (dispatchSaveMessage && method != 'get') {
          store.dispatch(saveSuccess({ message: saveMessage }));
        }
        return response.text().then((t) => {
          if (t === 'OK') {
            return null;
          } else {
            return JSON.parse(t);
          }
        });
      } else if (response.status === 422) {
        return response.json().then((a) => {
          store.dispatch(validationError({ message: a.message }));
        });
      } else if (response.status === 401) {
        store.dispatch(logout());
      } else {
        store.dispatch(requestFailure({ url: apiUrl, method }));
      }

      return Promise.reject();
    })
    .catch((err) => {
      store.dispatch(isRequestFinished());
      store.dispatch(requestFailure({ url: apiUrl, method }));
      return Promise.reject(err);
    });
};
