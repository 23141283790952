import If from 'components/conditionals/if.conditional';
import Price from 'pages/users/signup/price';
import SignupForm from 'pages/users/signup/signup-form';
import { useGetPackage } from 'pages/users/signup/signup.hooks';
import { CouponInterface, Dispatch } from 'pages/users/signup/signup.interface';
import { PackageInterface, PackageTypes } from 'pages/users/subscription/plans/plans.interface';
import React, { createContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const SignupContext = createContext<{
  coupon: null | CouponInterface;
  setCoupon: Dispatch;
  productId: undefined | string;
}>({
  coupon: null,
  setCoupon: () => null,
  productId: '',
});

export default function Signup() {
  const navigate = useNavigate();
  const { priceId, type = PackageTypes.MONTHLY } = useParams();
  const selectedPackage = useGetPackage(priceId, type, navigate);
  const [coupon, setCoupon] = useState(null);

  return (
    <>
      <div className="flex flex-col min-h-screen bg-white">
        <div className="flex-1">
          <section>
            <div className="flex flex-col flex-col-reverse align-middle lg:flex-row md:items-strech">
              <If show={selectedPackage !== null}>
                <SignupContext.Provider
                  value={{ coupon, setCoupon: setCoupon as Dispatch, productId: selectedPackage?.productId }}
                >
                  <SignupForm />
                  <Price type={type as PackageTypes} selectedPackage={selectedPackage as PackageInterface} />
                </SignupContext.Provider>
              </If>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
