import { CreditCardIcon } from '@heroicons/react/outline';
import PrimaryButton from 'components/buttons/primary-button';
import Card from 'components/card/card';
import CreditCard from 'components/credit-card/credit-card';
import { SliderOverChild } from 'components/slide-over/slide-over.interface';
import { toggleSliderOver } from 'components/slide-over/slider-over.slice';
import { selectDefaultCard } from 'pages/users/subscription/payment-method/default-card.slice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';

export default function PaymentMethod() {
  const card = useAppSelector(selectDefaultCard);
  const dispatch = useDispatch();

  function newPaymentMethod(event) {
    event.preventDefault();
    dispatch(toggleSliderOver({ open: true, children: SliderOverChild.updatepaymentmethod }));
  }

  return (
    <>
      <Card title="Payment Method" subtitle="Change how you pay">
        <div className="flex-col flex">
          <div className="border-gray-100 border rounded-md">
            <div className="px-4 py-3 flex">
              <div className="flex-grow-0 mr-2">
                <CreditCardIcon className="h-6 w-5" />
              </div>
              <CreditCard card={card} />
              <div className="flex-grow-0">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold bg-gray-100 text-blue-800">
                  DEFAULT
                </span>
              </div>
            </div>
          </div>
          <div></div>
          <div className="items-end justify-end grow h-16 mt-2 flex">
            <div>
              <PrimaryButton size="xs" onClick={newPaymentMethod} color="gray">
                Manage Payment Method
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
