const TableBodyWraper = ({ children }) => {
  return (
    <tbody className="w-full">
      <tr className="w-full">
        <td colSpan={1} className="">
          {children}
        </td>
      </tr>
    </tbody>
  );
};

export default TableBodyWraper;
