import React from 'react';
import { Button, Paper, Select } from '@mantine/core';
import useTopSearchesEvents from 'pages/users/keyword-research/keyword-research.hooks';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
import { RefreshIcon, DocumentDownloadIcon, DocumentRemoveIcon } from '@heroicons/react/outline';
import If from 'components/conditionals/if.conditional';
import KeywordResearchConfirmModal from 'pages/users/keyword-research/keyword-research.confirm-modal';

function KeywordResearchDataSet() {
  const { dataSets, setDataSet, loadDataset, clearData, openedSaveModal, closeModal, openSaveModal, deleteSet } =
    useTopSearchesEvents();
  const { topSearchesStates } = useTopSearchesContext();

  const data =
    dataSets?.map((item) => {
      return { value: item?._id, label: item?.name };
    }) || [];
  const selectedDataSet = data.find((item) => item.value === topSearchesStates.dataSet?._id);
  return (
    <Paper shadow="xs" p="xs" className="mb-3 overflow-auto !flex gap-2">
      <KeywordResearchConfirmModal
        opened={openedSaveModal}
        close={closeModal}
        selectedDataSet={selectedDataSet}
        deleteSet={deleteSet}
      />

      <Select
        data={data}
        placeholder="Select Brand/Project"
        className="flex-auto"
        value={topSearchesStates.dataSet ? topSearchesStates.dataSet._id : ''}
        onChange={setDataSet}
      />
      <Button
        leftIcon={<DocumentDownloadIcon strokeWidth={2} className="h-5 w-5" />}
        loading={topSearchesStates.isLoading || topSearchesStates.tableDataLoading}
        onClick={loadDataset}
      >
        Load
      </Button>
      <If show={topSearchesStates.dataSet !== null && topSearchesStates.reportData.length !== 0}>
        <Button
          color="dark"
          leftIcon={<DocumentRemoveIcon strokeWidth={2} className="h-5 w-5" />}
          onClick={openSaveModal}
        >
          Delete
        </Button>
      </If>
      <If show={topSearchesStates.reportData.length !== 0}>
        <Button leftIcon={<RefreshIcon strokeWidth={2} className="h-5 w-5" />} onClick={clearData}>
          Reset
        </Button>
      </If>
    </Paper>
  );
}

export default KeywordResearchDataSet;
