import { Button, Center, Radio, Text } from '@mantine/core';
import TabsBranding from 'pages/users/apollo/steps/listing/tabs';
import React, { Suspense } from 'react';
import If from 'components/conditionals/if.conditional';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { SpinnerText } from 'components/shimmer/spinner-text';
import { useApolloResult } from 'pages/users/apollo/steps/apollo.result.hooks';
import IfElse from 'components/conditionals/if-else.conditional';
import Spinner from 'components/shimmer/spinner';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';

export default function Titles() {
  const { title: selectedTitle, setSelectedTitle } = listingContext();
  const { data, isLoading, titles, createTitleVariant } = useApolloResult('titles');
  const status = data?.prompt.postGeneration.title.status ?? 'pending';
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToBullets = () => {
    navigate('/dashboard/apollo/create-list/bullets/' + id);
  };
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <TabsBranding>
          <Text c="dimmed" fz="md" className="mb-2">
            Choose 1 title
          </Text>
          <Radio.Group required value={selectedTitle} onChange={setSelectedTitle}>
            {titles.map((listingType, index: number) => (
              <div className="flex items-center w-full" key={index}>
                <Radio value={listingType} label={listingType} />
              </div>
            ))}
          </Radio.Group>
          <IfElse show={isLoading || !['success', 'error'].includes(status)}>
            <SpinnerText text={'🤖 AI is Creating New Variants. Please wait... 🚀'} />
            <Center inline className="mt-8">
              <IfElse show={!isLoading && status === 'error'}>
                <Button onClick={createTitleVariant}>Retry</Button>
                <Button disabled={data?.prompt.postGeneration.title.variations === 0} onClick={createTitleVariant}>
                  Add More
                </Button>
              </IfElse>
              <If show={data?.prompt.postGeneration.title.variations !== 0}>
                <IfElse show={!isLoading && status === 'error'}>
                  <Text c="dimmed" fz="sm" className="ml-4" color="red">
                    There was an error generating new variants.
                  </Text>
                  <Text c="dimmed" fz="sm" className="ml-4">
                    AI will create 5 new variants
                  </Text>
                </IfElse>
              </If>
            </Center>
          </IfElse>
          <div className="flex justify-end mt-2">
            <Button onClick={navigateToBullets} rightIcon={<ArrowRightIcon className="w-5 h-5 " />} color="dark">
              Next
            </Button>
          </div>
        </TabsBranding>
      </Suspense>
    </>
  );
}
