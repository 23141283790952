import React, { ReactElement } from 'react';
import { Switch } from '@headlessui/react';
import { Field } from 'react-final-form';
import { classNames } from 'lib/helpers';
import FormLabel from 'components/forms/form-label';

const FormCheckboxSingle = ({ label, name, subtitle = '', ...props }): ReactElement => {
  const field = (
    <Field name={name} {...props}>
      {({ input }) => (
        <>
          <Switch
            checked={input.value}
            onChange={input.onChange}
            className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
            <span
              aria-hidden="true"
              className={classNames(
                input.value ? 'bg-red-600' : 'bg-gray-200',
                'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                input.value ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
              )}
            />
          </Switch>
        </>
      )}
    </Field>
  );

  if (!label) return field;

  return (
    <>
      <div className="relative flex items-start mb-4">
        <div className="flex items-center h-5">{field}</div>
        <div className="ml-3 text-sm">
          <FormLabel className="font-medium text-gray-700" label={label} />
          <p className="text-gray-500">{subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default FormCheckboxSingle;
