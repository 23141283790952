import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  loading?: boolean;
};

/**
 *
 * @param children -  children components
 * @param fallback - component fallback when request is loading
 * @returns - returns children or fallback
 */
const ShimmerWrapper = ({ children, fallback, loading }: Props) => {
  const { isLoading } = useAppSelector(selectRequest);
  const isRequestLoading = loading ?? isLoading;

  return <>{isRequestLoading ? fallback : children}</>;
};

export default ShimmerWrapper;
