function StatusBadge({ text }: { text: string }) {
  const colorObj = {
    active: 'bg-green-100 text-green-800',
    stopped: 'bg-red-100 text-red-800',
    created: 'bg-gray-200 text-gray-800',
    paused: 'bg-purple-100 text-purple-800',
  };

  const color = colorObj[text] || 'bg-gray-100 text-gray-800';

  return (
    <>
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}>{text}</span>
    </>
  );
}

export default StatusBadge;
