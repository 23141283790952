import { Button, Chip, Text, TextInput, Group } from '@mantine/core';
import TabsBranding from 'pages/users/apollo/steps/listing/tabs';
import React from 'react';
import Joi from 'joi';
import { joiResolver, useForm } from '@mantine/form';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import If from 'components/conditionals/if.conditional';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';

export default function Tags() {
  const { tags, setTags } = listingContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      tags: '',
    },
    validate: joiResolver(
      Joi.object({
        tags: Joi.array().items(Joi.string()).required().min(1),
      })
    ),
  });

  const addTags = () => {
    if (form.values?.tags.length > 0) {
      const newTags = tags || [];
      newTags.push(form.values?.tags);
      setTags(Array.from(new Set(newTags)));
      form.values.tags = '';
    }
  };

  const removeTag = (value) => {
    const filteredTags = tags?.filter((tag) => tag !== value);
    setTags(filteredTags);
  };

  const navigateToDescriptions = () => {
    navigate('/dashboard/apollo/create-list/description/' + id);
  };
  return (
    <>
      <TabsBranding>
        <Text c="dimmed" fz="md" className="mb-2">
          Add New Notes
        </Text>
        <div className="flex space-x-2 w-full mt-4">
          <TextInput {...form.getInputProps('tags')} />
          <Button onClick={addTags}>Add Note</Button>
        </div>
        <If show={tags.length !== 0}>
          <div className="py-10 ">
            <div className="bg-gray-50 block p-5 rounded space-x-1 flex-wrap flex">
              <Group position="left">
                {tags?.map((tag) => (
                  <Chip onClick={() => removeTag(tag)} key={tag} defaultChecked variant="filled" color="blue">
                    {tag}
                  </Chip>
                ))}
              </Group>
            </div>
          </div>
        </If>
        <Button
          onClick={navigateToDescriptions}
          leftIcon={<ArrowLeftIcon className="w-5 h-5" />}
          className=" mt-8"
          color="dark"
        >
          Previous
        </Button>
      </TabsBranding>
    </>
  );
}
