import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RebateSiteInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: RebateSiteInterface = {
  notify: false,
  isQROpen: false,
};

export const rebateSiteSlice = createSlice({
  name: 'rebateSite',
  initialState,
  reducers: {
    setNotify: (state: Draft<RebateSiteInterface>, action: PayloadAction<boolean>) => {
      state.notify = action.payload;
    },
    setIsQROpen: (state: Draft<RebateSiteInterface>, action: PayloadAction<boolean>) => {
      state.isQROpen = action.payload;
    },
  },
});

export const { setNotify, setIsQROpen } = rebateSiteSlice.actions;

export const selectRebateSite = (state: RootState) => state.rebateSite;

export const rebateSiteReducer = rebateSiteSlice.reducer;
