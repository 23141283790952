const TablePagination = ({
  setCurrentPage,
  hasNextPage,
  hasPrevPage,
  nextPage,
  page,
  prevPage,
  totalPages,
  docs = 0,
  bg = 'white',
}: {
  setCurrentPage: (p: number) => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: number | null;
  totalPages: number;
  docs: number;
  bg?: string;
}) => {
  const handleFetch = (p) => {
    setCurrentPage(p);
  };

  return (
    <div className="w-full">
      {totalPages > 1 && (
        <div className={`bg-${bg} px-4 py-4 flex justify-between items-center border-gray-200 sm:px-6 sm:rounded-b-lg`}>
          <button
            disabled={!hasPrevPage}
            onClick={() => handleFetch(prevPage)}
            className={`relative inline-flex items-center px-2 py-2 rounded-l-md  bg-${bg} text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50`}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr">Previous</span>
          </button>
          {docs ? (
            <div className="flex justify-evenly gap-2 ">
              <span className="text-gray-500">Showing</span>
              <span className="font-medium">{page}</span>
              <span className="text-gray-500">of</span>
              <span className="font-medium">{totalPages}</span>
              <span className="text-gray-500">pages</span>
            </div>
          ) : (
            <div className="sm:flex-1" />
          )}
          <button
            disabled={!hasNextPage}
            onClick={() => handleFetch(nextPage)}
            className={`flex justify-center items-center px-2 py-2 rounded-r-md bg-${bg} text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50`}
          >
            <span className="sr">Next</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default TablePagination;
