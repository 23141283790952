import { Textarea } from '@mantine/core';
import React, { ReactElement } from 'react';

const MFormTextarea = ({
  label,
  required = false,
  placeholder = '',
  ...props
}: {
  label: string;
  required?: boolean;
  placeholder?: string;
}): ReactElement => {
  return (
    <div className="sm:grid sm:grid-cols-7 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-end">
        {label} <span className={`text-red-500 ${!required && 'hidden'}`}>*</span>
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-6">
        <Textarea {...props} required={required} className="w-3/4" placeholder={placeholder} autosize />
      </div>
    </div>
  );
};

export default MFormTextarea;
