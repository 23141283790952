import { ShimmerField } from 'components/shimmer/utils';
import { ReactNode } from 'react';

/**
 * Table shimmer
 * @param headers - table headers
 * @param span - table data colSpan
 * @param children - component children
 */
const TableShimmer = ({
  headers = ['header'],
  span = 1,
  children,
}: {
  headers?: string[];
  span?: number;
  children?: ReactNode;
}) => (
  <tbody>
    {headers.map((__: string, i: number) => (
      <tr key={i}>
        <td scope="col" className="th" colSpan={span}>
          {children ? children : <ShimmerField />}
        </td>
      </tr>
    ))}
  </tbody>
);

export default TableShimmer;
