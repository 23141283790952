import { openConfirmModal, openModal } from '@mantine/modals';
import EditModal from 'components/modals/edit-modal';

export function deleteModal(name: string, id: string, onConfirm: (() => void) | undefined) {
  // const navigate = useNavigate();
  return openConfirmModal({
    title: 'Are you sure you want to delete ' + name,
    centered: true,
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onConfirm,
  });
}

// eg: editModal(splitTest,'name','/split-tests'), update this splitTest's name with this url /split-tests/id , id is automatically added
export function editModal(model, property, url: string) {
  return openModal({
    centered: true,
    title: 'Edit ' + name,
    children: <EditModal model={model} property={property} url={url} />,
  });
}
