import React, { useRef } from 'react';
import { FileButton, Grid } from '@mantine/core';
import IfElse from '../../conditionals/if-else.conditional';

const MFormFileUpload = ({ file, setFile }) => {
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    setFile(null);
    resetRef.current?.();
  };

  return (
    <Grid gutter={15}>
      <Grid.Col xs={12} sm={12} md={12} lg={10} xl={10}>
        <FileButton onChange={setFile} resetRef={resetRef} accept=".xlsx, .csv">
          {(props) => {
            return (
              <>
                <div className="w-full rounded border-dashed border-2 cursor-pointer">
                  <IfElse show={file !== null}>
                    <div
                      className="file-container flex flex-col items-center justify-center p-6 relative group"
                      onClick={clearFile}
                    >
                      <img src="/excel.png" alt="upload" width={50} />
                      <span className="font-medium">{file?.name}</span>
                      <div className="backdrop-blur-xl bg-red-100/30 opacity-0 absolute inset-0 flex items-center justify-center group-hover:opacity-100">
                        <img src="/trash.png" alt="upload" width={24} />
                        <span className="ml-3">Click to delete File</span>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center p-6" {...props}>
                      <img src="/upload.svg" alt="upload" width={50} />
                      <span className="font-medium">Browse Excel</span>
                    </div>
                  </IfElse>
                </div>
              </>
            );
          }}
        </FileButton>
      </Grid.Col>
    </Grid>
  );
};

export default MFormFileUpload;
