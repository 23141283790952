import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { chartColors } from 'lib/colors.helpers';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
import {
  deleteDataSet,
  getKeywordResearch,
  keywordResearch,
  saveKeywordResearch,
  searchResults,
  updateKeywordResearch,
} from 'pages/users/keyword-research/keyword-research.thunks';
import { TopSearchResultInterfaces } from 'pages/users/keyword-research/keyword-research.interfaces';
import { getWeekNumbersBetweenDates } from 'pages/users/keyword-research/keyword-research.helper';
import { QueryInterface } from 'pages/users/apollo/keyword-extractor/keyword-extractor.interfaces';
import { useDisclosure } from '@mantine/hooks';

export default function useTopSearchesEvents() {
  const { topSearchesStates, dispatch } = useTopSearchesContext();
  const [relatedKeywordsQueries, setRelatedKeywordsQueries] = useState<QueryInterface[]>([]);
  const [openedSaveModal, { open: openSaveModal, close: closeSaveModal }] = useDisclosure(false);
  const { data: dataSets, refetch } = useQuery(['get-data-sets'], {
    queryFn: () => getKeywordResearch(),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { mutate: search } = useMutation({
    mutationFn: (data: any) => keywordResearch(data),
    onSuccess: (data, variables) => {
      dispatch({ type: 'set_report_data', payload: { label: variables.keyword, data: sortByWeekNo(data) } });
      dispatch({ type: 'set_loading', payload: false });
      dispatch({ type: 'set_table_loading', payload: false });
    },
  });

  const { mutate: saveKeywords } = useMutation({
    mutationFn: (data: any) => saveKeywordResearch(data),
    onSuccess: (data) => {
      closeSaveModal();
      dataSets.push(data);
      setDataSet(data._id);
    },
  });

  const { mutate: deleteSet } = useMutation({
    mutationFn: (data: any) => deleteDataSet(data),
    onSuccess: () => {
      closeSaveModal();
      refetch();
      clearData();
    },
  });

  const { mutate: updateSet } = useMutation({
    mutationFn: (data: any) => updateKeywordResearch(data),
    onSuccess: (data) => {
      closeSaveModal();
      refetch();
      setDataSet(data._id);
    },
  });

  const closeModal = () => {
    closeSaveModal();
    dispatch({ type: 'set_set_name', payload: '' });
  };

  const { mutate: extractKeywords } = useMutation({
    mutationFn: (data: any) => searchResults(data),
    onSuccess: (data) => {
      const keywords = data.keywords.map((key) => {
        return key.search_term;
      });
      dispatch({ type: 'set_related_keywords', payload: keywords });
      dispatch({ type: 'set_table_loading', payload: false });
    },
  });

  function enterKeyword(e) {
    if (e.key === 'Enter') {
      if (e.target.value !== '' && e.target.value.replace(/\s/g, '').length) {
        dispatch({ type: 'set_input_value', payload: e.target.value });
        if (
          !topSearchesStates.reportData.find((keyword) => keyword.label.toLowerCase() === e.target.value.toLowerCase())
        ) {
          fetchData(e.target.value);
        } else {
          dispatch({ type: 'set_input_value', payload: '' });
        }
      }
    }
  }

  const removeKeyword = (keyword) => {
    dispatch({ type: 'set_input_value', payload: '' });
    dispatch({ type: 'remove_keyword', payload: keyword.label });
    dispatch({ type: 'remove_graph_data', payload: keyword.label });
    dispatch({ type: 'update_graph_labels' });
    dispatch({ type: 'set_focused_keyword' });
    dispatch({ type: 'set_related_keywords', payload: [] });
    dispatch({ type: 'set_keyword_Details', payload: [] });
  };

  const loadDataset = () => {
    if (topSearchesStates.dataSet) {
      dispatch({ type: 'clear_data' });
      dispatch({ type: 'set_table_loading', payload: true });
      setSetName(topSearchesStates.dataSet?.name);
      topSearchesStates.dataSet?.searchTerms.map(async (terms) => {
        await fetchData(terms);
      });
    }
  };

  const clearData = () => {
    dispatch({ type: 'clear_data' });
    dispatch({ type: 'set_data_set', payload: null });
    dispatch({ type: 'set_set_name', payload: '' });
  };

  const setDataSet = (dataSetId) => {
    const set = dataSets.find((set) => set._id === dataSetId);
    dispatch({ type: 'set_data_set', payload: set });
  };

  const setSetName = (value) => {
    dispatch({ type: 'set_set_name', payload: value });
  };

  const setKeywordDetails = (e) => {
    e.map((item) => {
      item.total_cvr_share =
        +item.top_clicked_product_conversion_share_1 +
        +item.top_clicked_product_conversion_share_2 +
        +item.top_clicked_product_conversion_share_3;
      item.total_cvr_share = item.total_cvr_share.toFixed(2);
      item.total_click_share =
        +item.top_clicked_product_click_share_1 +
        +item.top_clicked_product_click_share_2 +
        +item.top_clicked_product_click_share_3;
      item.total_click_share = item.total_click_share.toFixed(2);
    });
    dispatch({ type: 'set_keyword_Details', payload: e });
  };

  const setRelatedKeywords = (e) => {
    extractKeywords({ keyword: e?.label, type: 'keyword', dataSource: 'brand-analytics' });
    dispatch({ type: 'set_focused_keyword', payload: e });
    dispatch({ type: 'set_current_page', payload: 1 });
    dispatch({ type: 'set_table_loading', payload: true });
    dispatch({ type: 'set_keyword_Details', payload: [] });
  };

  const loadChartData = (fetchedData) => {
    dispatch({ type: 'set_loading', payload: false });
    const { dateArray, dataPoints } = generateLabels(fetchedData.data);

    dispatch({ type: 'set_graph_labels', payload: dateArray });
    const dataExists = topSearchesStates.chartData.find((data) => data.label === fetchedData.label);
    if (fetchedData.data.length !== 0 && !dataExists) {
      dispatch({
        type: 'add_graph_data',
        payload: {
          label: fetchedData.label,
          data: dataPoints,
          borderColor: chartColors[topSearchesStates.chartData.length],
          backgroundColor: chartColors[topSearchesStates.chartData.length],
        },
      });
      dispatch({ type: 'update_graph_labels' });
    }
    dispatch({ type: 'set_input_value', payload: '' });
  };

  const generateLabels = (fetchedData: TopSearchResultInterfaces[]) => {
    const { latestStartDate, oldestEndDate } = extractDates(fetchedData);
    const dataPoints: (number | null)[] = [];
    let dateArray: string[] | number[] = [] as string[];
    const weekly = getWeekNumbersBetweenDates(oldestEndDate, latestStartDate);

    weekly.map((weekNo) => {
      const dataByDate = fetchedData?.find((data) => `Week ${data.week_no}-${data.year}` === weekNo);
      if (dataByDate) {
        dataPoints.push(dataByDate.search_frequency_rank);
      } else {
        dataPoints.push(null);
      }
    });
    dateArray = weekly;
    return { dateArray, dataPoints };
  };

  const reloadData = (keywords) => {
    if (keywords.length !== 0) {
      dispatch({ type: 'set_loading', payload: true });
      dispatch({ type: 'reset_graph_data' });
      keywords.map((keyword) => {
        fetchData(keyword.keyword);
      });
    }
  };

  const fetchData = (keyword) => {
    dispatch({ type: 'set_input_value', payload: keyword });
    dispatch({ type: 'set_loading', payload: true });
    search({
      keyword,
      period: 'weekly',
    });
  };

  function extractDates(data) {
    let latestStartDate = new Date(); // Initialize variables to hold dates
    let oldestEndDate = new Date();

    // Loop through the data to find the latest start date and oldest end date
    data.forEach((entry) => {
      const startDate = new Date(entry.start_date);
      const endDate = new Date(entry.end_date);

      if (!latestStartDate || startDate > latestStartDate) {
        latestStartDate = startDate;
      }

      if (!oldestEndDate || endDate < oldestEndDate) {
        oldestEndDate = endDate;
      }
    });

    return { latestStartDate, oldestEndDate };
  }

  function searchTermsUpdated() {
    const data = topSearchesStates.reportData.map((d) => d.label);

    if (topSearchesStates.dataSet?.searchTerms?.length !== data.length) {
      return false;
    }

    const dataSet = topSearchesStates.dataSet?.searchTerms?.slice();
    const searchTerms = data.slice();

    return dataSet?.every((element) => {
      const index = searchTerms.indexOf(element);
      if (index === -1) {
        return false;
      }
      searchTerms.splice(index, 1);
      return true;
    });
  }

  function sortByWeekNo(data, label?) {
    data.map((item) => {
      item.label = label !== undefined ? label : item.label;
    });
    const sorted = [...data].sort((a, b) => {
      return (b.week_no || 0) - (a.week_no || 0);
    });
    return sorted;
  }

  function sortByFirstSFR(keywords) {
    const sorted = keywords.sort((a, b) => {
      const rankA = a[0]?.search_frequency_rank;
      const rankB = b[0]?.search_frequency_rank;

      return rankB - rankA; // Sort based on search_frequency_rank
    });

    return sorted;
  }

  const getSimilarKeywordsResults = (data) => {
    if (data?.length > 0) {
      const relatedKeywordsQueries = data?.map((item) => {
        return {
          queryKey: [item, 'related-keywords'],
          queryFn: () => keywordResearch({ keyword: item, period: 'weekly' }),
          staleTime: 1000 * 60 * 60 * 24,
          cacheTime: 1000 * 60 * 60 * 24,
          retryOnMount: false,
          refetchOnWindowFocus: false,
        };
      });
      setRelatedKeywordsQueries(relatedKeywordsQueries);
    } else {
      setRelatedKeywordsQueries([]);
    }
  };

  const queryResults: any = useQueries({
    queries: [...relatedKeywordsQueries],
  });

  useEffect(() => {
    getSimilarKeywordsResults(topSearchesStates?.relatedKeywords);
  }, [topSearchesStates?.relatedKeywords]);
  return {
    enterKeyword,
    removeKeyword,
    setRelatedKeywords,
    reloadData,
    fetchData,
    loadChartData,
    sortByWeekNo,
    queryResults,
    dataSets,
    loadDataset,
    clearData,
    setDataSet,
    setSetName,
    saveKeywords,
    updateSet,
    deleteSet,
    setKeywordDetails,
    openedSaveModal,
    openSaveModal,
    closeSaveModal,
    sortByFirstSFR,
    searchTermsUpdated,
    closeModal,
  };
}
