import { ReactElement } from 'react';

const IconButton = ({ bgColor = 'gray', Icon, text = '', ...props }): ReactElement => {
  return (
    <div>
      <button
        {...props}
        type="button"
        className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm 
          font-medium rounded-md text-white
          bg-${bgColor}-600 hover:bg-${bgColor} -700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${bgColor}-500`}
      >
        <Icon className={`-ml-1 mr-3 h-5 w-5`} aria-hidden="true" />
        {text}
      </button>
    </div>
  );
};

export default IconButton;
