import { UserCreditsInterface, UserInterface, UserTeamMembersInterface } from 'interfaces/user.interface';
import { setUsers } from 'pages/admin/manage-users/manage-users.slice';
import { store } from 'redux/store';
import { getSilentFetch, postFetch, postSilentFetch, putFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const loadUsers = async (values): Promise<ResponseListInterface<UserInterface>> => {
  const res = await postSilentFetch('/users/superadmin/page', values);
  if (res) {
    store.dispatch(setUsers(res));
  }
  return res;
};

export const AddUser = async (values): Promise<ResponseListInterface<UserInterface>> => {
  const res = await postFetch('/users/superadmin', values, 'User added Successully');
  if (res) {
    store.dispatch(setUsers(res));
  }
  return res;
};

export const UpdateUser = async (id, values): Promise<ResponseListInterface<UserInterface>> => {
  const res = await putFetch(`/users/superadmin/${id}`, { ...values, _id: id }, 'User Updated Successfully!');
  if (res) {
    store.dispatch(setUsers(res));
  }
  return res;
};

export const getUserDetails = async (id): Promise<ResponseListInterface<UserInterface>> => {
  const res = await getSilentFetch(`/users/superadmin/${id}`);
  return res;
};

export const loadUsersMember = async (values): Promise<ResponseListInterface<UserTeamMembersInterface>> => {
  return await postSilentFetch('/teams/admin/get-members', values);
};

export const getUserCredits = async (id?: string): Promise<UserCreditsInterface> => {
  return await getSilentFetch(`/credits/admin/get-credits/${id}`);
};

export const updateUserCredits = async (id?: string, data?: UserCreditsInterface): Promise<UserCreditsInterface> => {
  return await putFetch(`/credits/admin/update-credits/${id}`, { ...data });
};
