import { Button, Center, Text } from '@mantine/core';
import TabsBranding from 'pages/users/apollo/steps/listing/tabs';
import React from 'react';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { SpinnerText } from 'components/shimmer/spinner-text';
import IfElse from 'components/conditionals/if-else.conditional';
import { useApolloResult } from 'pages/users/apollo/steps/apollo.result.hooks';
import If from 'components/conditionals/if.conditional';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';

export default function Description() {
  const { description } = listingContext();
  const { data, isLoading, regenerateDescriptionVariant } = useApolloResult('description');
  const status = data?.prompt.postGeneration.description.status ?? 'pending';
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToTags = () => {
    navigate('/dashboard/apollo/create-list/tags/' + id);
  };
  const navigateToBullets = () => {
    navigate('/dashboard/apollo/create-list/bullets/' + id);
  };
  return (
    <>
      <TabsBranding>
        <IfElse show={isLoading || !['success', 'error'].includes(status)}>
          <SpinnerText text={'🤖 AI is Creating New Variants. Please wait... 🚀'} />
          <div>
            <div className="px-4 py-6 bg-gray-100 flex items-center my-4 ">
              <Text>
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: description?.replaceAll('\n', '<br />') }}
                ></div>
              </Text>
            </div>
            <Center inline className="mt-8 ">
              <IfElse show={!isLoading && status === 'error'}>
                <Button onClick={regenerateDescriptionVariant}>Retry</Button>
                <Button
                  disabled={data?.prompt.postGeneration.description.recreate === 0}
                  onClick={regenerateDescriptionVariant}
                >
                  Regenerate
                </Button>
              </IfElse>
              <If show={data?.prompt.postGeneration.description.recreate !== 0}>
                <IfElse show={!isLoading && status === 'error'}>
                  <Text c="dimmed" fz="sm" className="ml-4" color="red">
                    There was an error generating new variants.
                  </Text>
                  <Text c="dimmed" fz="sm" className="ml-4">
                    Generate new description variant
                  </Text>
                </IfElse>
              </If>
            </Center>
          </div>
        </IfElse>

        <div className="flex justify-between mt-5">
          <Button onClick={navigateToBullets} leftIcon={<ArrowLeftIcon className="w-5 h-5 " />} color="dark">
            Previous
          </Button>
          <Button onClick={navigateToTags} rightIcon={<ArrowRightIcon className="w-5 h-5 " />} color="dark">
            Next
          </Button>
        </div>
      </TabsBranding>
    </>
  );
}
