import { useQuery } from '@tanstack/react-query';
import TableBodyWraper from 'components/tables/table-body.wraper';
import TablePagination from 'components/tables/table.pagination';
import { selectGroup } from 'pages/users/heatmap/groups/heatmap-groups.slice';
import { loadGroups } from 'pages/users/heatmap/groups/heatmap-groups.thunks';
import { editHeatmapItem, selectHeatMapItem } from 'pages/users/heatmap/heat-map-item.slice';
import HeatMapGroupForm from 'pages/users/heatmap/heat-map.form';
import HeatmapHeader from 'pages/users/heatmap/heat-map.header';
import HeatmapItem from 'pages/users/heatmap/heatmap-item';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { store } from 'redux/store';
import EmptyState from 'components/common/empty-state';
import IfElse from '../../../components/conditionals/if-else.conditional';

const HeatMapTable = () => {
  const { isOpen } = useAppSelector(selectHeatMapItem);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useQuery({
    queryKey: ['heat-map-groups' + currentPage],
    queryFn: async () => loadGroups({ page: currentPage }),
    refetchInterval: 5000,
  });

  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectGroup);

  const handleToggleForm = () => {
    store.dispatch(editHeatmapItem({ isOpen: !isOpen, isEditing: false }));
  };

  return (
    <>
      <div className="flex-1 relative overflow-y-auto focus:outline-none max-w-full">
        <HeatmapHeader title="Heat Map Groups" buttonLabel="Add Group" handleAction={handleToggleForm} />
        <table className="w-full">
          <IfElse show={docs.length === 0}>
            <EmptyState
              addNew={false}
              buttonLabel=""
              handleClick=""
              noDataMsg={`No Heat Map Groups created yet. Click on the "Add Group" button to add a heatmap.`}
            />
            <TableBodyWraper>
              <ul
                role="list"
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
              >
                {docs?.map((item) => (
                  <HeatmapItem key={item._id} item={item} />
                ))}
              </ul>
              <TablePagination
                setCurrentPage={setCurrentPage}
                bg="transparent"
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                nextPage={nextPage}
                page={page}
                docs={docs?.length}
                pagingCounter={pagingCounter}
                prevPage={prevPage}
                totalPages={totalPages}
              />
            </TableBodyWraper>
          </IfElse>
        </table>
      </div>
      <HeatMapGroupForm />
    </>
  );
};

export default HeatMapTable;
