import {
  CreditCardInterface,
  InitiatedPaymentInterface,
} from 'pages/users/subscription/payment-method/payment-method.interface';
import { getSilentFetch, postSilentFetch } from 'request/request';

export const initiatePaymentMethod = async (): Promise<InitiatedPaymentInterface> => {
  return await getSilentFetch('/credits/initiate-payment');
};

export const savePaymentMethod = async (id: string | null): Promise<any> => {
  return await postSilentFetch('/credits/payment-method/' + id, {});
};

export const savePaymentMethodByClientSecret = async (secret: string): Promise<CreditCardInterface> => {
  return await postSilentFetch('/credits/payment-method-by-client-secret/' + secret, {});
};

export const getPaymentMethods = async (): Promise<{
  methods: CreditCardInterface[];
  defaultPayment: CreditCardInterface;
}> => {
  return await getSilentFetch('/credits/payment-methods');
};
