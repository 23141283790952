import { RefreshIcon } from '@heroicons/react/solid';

import FormButton from 'components/forms/form-button';
import React from 'react';

function RedeployFunnel({ funnel, redeploy }) {
  return funnel?.status === 'paused' ? (
    <>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <FormButton
          leadingIcon={<RefreshIcon className="h-5 w-5 mr-1" />}
          onClick={(e) => redeploy(e)}
          buttonColor="blue"
          buttonLabel="Redeploy Funnel"
        />
      </div>
    </>
  ) : null;
}

export default RedeployFunnel;
