import React, { useState, ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';

/**
 * Create a form dropdown
 * @param {number} span - grid span, see tailwind col
 * @param {number} gap - grid gap, see tailwind col
 * @param {number} col - grid col, see tailwind col
 * @param label - form label
 * @param name - dropdown name
 * @param {string} currencyName - dropdown name
 * @param values - array of values for the dropdown
 * @param optionValue - we will use this as value for select option
 * @param optionLabel- we will use this as label for select option
 * @param {any} ...props - other input props
 */
const FormSelectCurrency = ({
  span = 3,
  gap = 4,
  col = 4,
  label,
  name,
  currencyName = '',
  values,
  optionValue = '_id',
  optionLabel = 'name',
  ...props
}): ReactElement => {
  const [selected, setSelected] = useState(values[0]?.symbol || 'usd');

  const handleSelectChange = (e) => {
    setSelected(e.target.value);
  };

  const symbol = values.find((o: any) => o._id === selected)?.symbol || '$';
  const length = symbol.length === 1 ? 1.5 : symbol.length;

  const input = (
    <Field
      autoComplete="off"
      type="number"
      name={name}
      id={name}
      component="input"
      className={`block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 px-${
        length && length * 4
      } pr-14 sm:max-w-s sm:text-sm border-gray-300 rounded-md`}
      placeholder={label}
      {...props}
    />
  );
  const field = (
    <Field name={currencyName}>
      {({ input }) => {
        return (
          <select
            className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            onChange={(e) => {
              input.onChange;
              handleSelectChange(e);
            }}
          >
            {values?.map((o: any) => (
              <option value={o[optionValue]} key={o[optionValue]}>
                {o[optionLabel]}
              </option>
            ))}
          </select>
        );
      }}
    </Field>
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">{symbol}</span>
            </div>
            {input}
            <div className="absolute inset-y-0 right-0 flex items-center">{field}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSelectCurrency;
