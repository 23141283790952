import { ArrowsExpandIcon, DownloadIcon, MinusIcon, PhotographIcon, PlusIcon, XIcon } from '@heroicons/react/solid';
import Button from 'components/buttons/button';
import If from 'components/conditionals/if.conditional';
import { hideImage, selectHeatmap } from 'pages/users/heat-maps/heat-maps.slice';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export default function HeatMapsPreviewControles({
  nonDualImage,
  imageType,
  setImageType,
  zoomIn,
  zoomOut,
  resetTransform,
}) {
  const dispatch = useAppDispatch();
  const { currentImage } = useAppSelector(selectHeatmap);
  const btnColor = imageType !== 'original' ? 'orange' : 'red';
  const [color, setColor] = useState(btnColor);

  const closeModal = () => dispatch(hideImage());

  const setImageTypeClick = () => {
    setImageType(imageType === 'original' ? 'heatmap' : 'original');
    setColor(!(btnColor !== 'red') ? 'orange' : 'red');
  };

  const onDownloadImage = () => {
    // simply open a new tab of the image
    window.open(nonDualImage);
  };

  return (
    <>
      <div className="flex-1 ">
        <div className="flex items-center h-screen px-2">
          <If show={imageType !== 'dual'}>
            <div className="space-y-2 py-2">
              <If show={currentImage?.status === 'done'}>
                <Button color={color} onClick={() => setImageTypeClick()}>
                  <PhotographIcon className="h-5 w-5" />{' '}
                </Button>
              </If>
              <Button
                onClick={() => {
                  return zoomIn();
                }}
              >
                <PlusIcon className="h-5 w-5" />{' '}
              </Button>
              <Button
                onClick={() => {
                  return zoomOut();
                }}
              >
                <MinusIcon className="h-5 w-5" />{' '}
              </Button>
              <Button
                onClick={() => {
                  return resetTransform();
                }}
              >
                <ArrowsExpandIcon className="h-5 w-5" />{' '}
              </Button>
              <Button onClick={() => onDownloadImage()}>
                <DownloadIcon className="h-5 w-5" />{' '}
              </Button>
            </div>
          </If>
        </div>

        <div className="absolute right-2 top-2 z-20">
          <Button color="gray" onClick={() => closeModal()}>
            <XIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </>
  );
}
