import { LoadingOverlay, Title } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import React, { Suspense, useState } from 'react';
import { createListing } from 'pages/users/apollo/apollo.thunk';
import SubpageContainer from 'components/containers/subpage.container';
import Card from 'components/card/card';
import MFormInput from 'components/mantine/forms/m-form-input';
import MFormTextarea from 'components/mantine/forms/m-form-textarea';
import MFormRadioGroup from 'components/mantine/forms/m-form-radio-group';
import MFormButton from 'components/mantine/forms/m-form-button';
import MFormFileUpload from 'components/mantine/forms/m-form-file-upload';
import { useApolloCompetitors } from 'pages/users/apollo/apollo.hooks';
import { flatMap } from 'lodash';
import CustomLoader from 'pages/users/ab-test/components/abtest.loader';
import { useDisclosure } from '@mantine/hooks';
import { useFileUpload } from 'hooks/useFileUpload';
import If from 'components/conditionals/if.conditional';
import { handleUploadImage } from 'lib/helpers';

export default function ApolloListing() {
  const navigate = useNavigate();
  const [descriptionType, setDescriptionType] = useState<string>('story');
  const [includeReviews, setIncludeReviews] = useState<string>('no');
  const [fileUpload, setFileUpload] = useState<string | null>(null);

  const uploadFileToServer = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return await handleUploadImage(formData, file, `/apollo/upload/reviews`, (res) => {
      setFileUpload(res.key);
    });
  };

  const { file, setFile, fileUploadMutation } = useFileUpload({ fileUpload: uploadFileToServer });
  const { keywords, asins, products } = useApolloCompetitors();
  const [visible] = useDisclosure(false);

  const listingTypes = [
    {
      label: 'Story',
      key: 'story',
      description:
        'a story from the perspective of, or about, a customer describing how your product has impacted their life',
    },
    { label: 'Benefits', key: 'benefits', description: 'benefit focused copy' },
    {
      label: 'Features and Specs',
      key: 'features',
      description: 'a list and description of the product features and specifics',
    },
  ];
  const listingIncludeReviews = [
    { label: 'Yes', key: 'yes', description: '' },
    { label: 'No', key: 'no', description: '' },
  ];

  const form = useForm({
    initialValues: {
      targetAudience: '',
      productSpecifics: '',
      brand: '',
      contentTitle: '',
    },
    validate: joiResolver(
      Joi.object({
        brand: Joi.string().required(),
        targetAudience: Joi.string().required(),
        productSpecifics: Joi.string().required(),
        contentTitle: Joi.string().required(),
      })
    ),
  });

  const save = async (values) => {
    // toggle();
    // get 5 competitor titles
    const competitorTitles = products?.slice(0, 5).map((x, index: number) => {
      return `${index + 1}/ ${x.title} \n`;
    });

    // get 10 competitor bullets
    const competitorBullets = flatMap(
      products?.map((x, index: number) => {
        return `${index + 1}/ ${x.bullets.slice(1, 3).join('\n\n')}`;
      })
    );

    const data = {
      ...values,
      descriptionType,
      keywords,
      asins,
      competitorTitles,
      competitorBullets,
      specifics: 'unit color and color and measurement', // hardcode for now
    };

    const { _id } = await createListing(data);
    setTimeout(() => {
      navigate('/dashboard/apollo/create-list/title/' + _id);
    }, 3000);
  };

  return (
    <>
      <SubpageContainer title="Apollo">
        <Card>
          <LoadingOverlay
            visible={visible}
            overlayBlur={5}
            loader={<CustomLoader textMessage="Creating... Please wait" />}
          />
          <div className="flex gap-4">
            <div className="flex flex-col grow space-y-2">
              <Title order={3} className="px-2 py-2">
                Listing Details
              </Title>
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <form
                    onSubmit={form.onSubmit((values) => save({ ...values, reviews: fileUpload ? fileUpload : [] }))}
                  >
                    <div className="space-y-3">
                      <MFormInput label="Label" {...form.getInputProps('contentTitle')} required />
                      <MFormInput label="Brand" {...form.getInputProps('brand')} required />
                      <MFormTextarea
                        label="Target Audience"
                        {...form.getInputProps('targetAudience')}
                        required
                        placeholder={
                          'Enter demographic information here. Who is your customer avatar or ideal buyer? Who is this product for? Be as specific as possible. E.g. ‘First-time moms between the age of 25 and 32, married, active'
                        }
                      />
                      <MFormTextarea
                        label="Specifics"
                        {...form.getInputProps('productSpecifics')}
                        required
                        placeholder={
                          'Enter any product specific details, such as size, color, number of units in the pack, etc. Be specific so the A.I. gets the details right)'
                        }
                      />
                      <MFormRadioGroup
                        label="Description Type"
                        required
                        value={descriptionType}
                        onChange={setDescriptionType}
                        data={listingTypes}
                      />
                      <MFormRadioGroup
                        label="Include Reviews"
                        required
                        value={includeReviews}
                        onChange={setIncludeReviews}
                        data={listingIncludeReviews}
                      />
                      <If show={includeReviews === 'yes'}>
                        <MFormFileUpload file={file} setFile={setFile} />
                      </If>
                      <MFormButton type="submit" text="Create" disabled={fileUploadMutation.isLoading} />
                    </div>
                  </form>
                </Suspense>
              </div>
            </div>
          </div>
        </Card>
      </SubpageContainer>
    </>
  );
}
