import { ReactElement } from 'react';
import { Loader } from '@mantine/core';

const CustomLoader = ({ textMessage }): ReactElement => {
  return (
    <div className="flex justify-center items-center">
      <div className="p-6 flex items-center flex-col">
        <Loader size="lg" />
        <p className="mt-3 w-3/5 text-center font-semibold	">{textMessage}</p>
      </div>
    </div>
  );
};

export default CustomLoader;
