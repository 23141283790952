import React, { ReactElement, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import { Button, FileButton } from '@mantine/core';
import { IconUpload } from '@tabler/icons';
import { SplitTestInputOutFileInterface } from 'pages/users/ab-test/abtest.interface';
import { splitTestImageMultiple } from 'pages/users/ab-test/abtest.thunk';
import { ABTestImageGrid } from 'pages/users/ab-test/components/abtest.image-grid';

export const ABTestImageMultipleUpload = ({ label, onChangeUpload, max = 8 }): ReactElement => {
  const { isLoading } = useAppSelector(selectRequest);
  const [images, setPreviewImages] = useState<SplitTestInputOutFileInterface[]>([]);
  const resetRef = useRef<() => void>(null);

  const onMultipleUpload = async (e) => {
    const formData = new FormData();
    const newFiles = e.slice(0, max - images.length);
    for (const file of newFiles) {
      formData.append('files', file);
    }
    formData.append('name', newFiles[0].name);
    await splitTestImageMultiple(formData, newFiles, '/split-tests/ab/upload/multiple', (uploadedData) => {
      setPreviewImages([...images, ...uploadedData]);
      onChangeUpload([...images, ...uploadedData]);
    });
    resetRef.current?.();
  };

  const onRemove = (index: number) => {
    const im = images.filter((_, i) => index !== i);
    setPreviewImages([...im]);
    onChangeUpload([...im]);
  };

  return (
    <div className="sm:grid sm:grid-cols-7 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-end">{label}</label>
      <div className="mt-1 sm:mt-0 sm:col-span-5">
        <div className="pt-1 pb-4">
          <div className="grid grid-cols-4 gap-2">
            {[...Array(max)].map((x, index: number) => {
              const fullImage = images[index]?.images[0]?.image;
              const thumbnail = images[index]?.images[1]?.image;
              const download = images[index]?.images[2]?.image;
              return (
                <div key={index}>
                  <ABTestImageGrid
                    currentIndex={index}
                    fullImage={fullImage}
                    thumbnail={thumbnail}
                    download={download}
                    isResults
                    onRemoveClick={() => onRemove(index)}
                  />
                </div>
              );
            })}
          </div>
          <div className="pt-6 justify-items-center">
            <FileButton resetRef={resetRef} onChange={onMultipleUpload} accept="image/png,image/jpeg" multiple>
              {(props) => (
                <Button
                  color="indigo"
                  disabled={images.length === max}
                  {...props}
                  loading={isLoading}
                  leftIcon={<IconUpload size={'0.8rem'} />}
                >
                  Upload image
                </Button>
              )}
            </FileButton>
          </div>
        </div>
      </div>
    </div>
  );
};
