import React, { ReactElement } from 'react';
import { Button } from '@mantine/core';

const MFormButton = ({
  text,
  type,
  disabled = false,
}: {
  text: string;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
}): ReactElement => {
  return (
    <div className="sm:grid sm:grid-cols-7 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-end"></label>
      <div className="mt-1 sm:mt-0 sm:col-span-6">
        <div className="pt-1 pb-4">
          <Button type={type} disabled={disabled}>
            {text}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MFormButton;
