import { Tabs } from '@mantine/core';
import { IconAlphabetLatin, IconBlockquote, IconListSearch, IconPictureInPictureOff, IconPrompt } from '@tabler/icons';
import If from 'components/conditionals/if.conditional';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const PromptTabs = ({ children }) => {
  const navigate = useNavigate();
  const { tabValue = 'title', type = 'setup' } = useParams();
  return (
    <Tabs
      defaultValue={tabValue}
      onTabChange={(value) => {
        navigate(`/dashboard/prompts/${type}/${value}`);
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="system" icon={<IconPrompt size="0.8rem" />}>
          Manage System
        </Tabs.Tab>
        <Tabs.Tab value="title" icon={<IconAlphabetLatin size="0.8rem" />}>
          Manage Titles
        </Tabs.Tab>
        <Tabs.Tab value="bullets" icon={<IconListSearch size="0.8rem" />}>
          Manage Bullets
        </Tabs.Tab>
        <Tabs.Tab value="description" icon={<IconBlockquote size="0.8rem" />}>
          Manage Descriptions
        </Tabs.Tab>
        <If show={type !== 'optimizer'}>
          <Tabs.Tab value="suggestion" icon={<IconPictureInPictureOff size="0.8rem" />}>
            Manage Image Suggestions
          </Tabs.Tab>
        </If>
      </Tabs.List>
      <div className="py-4">{children}</div>
    </Tabs>
  );
};
