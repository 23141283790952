import FormSubmit from 'components/forms/form-submit';
import ShimmerWrapper from 'components/shimmer';
import { ShimmerUpdateBtn } from 'components/shimmer/utils';
import useFetch from 'hooks/useFetch';
import { snakeToCapitalize } from 'lib/helpers';
import { getRebateDefaultLabels, getRebateLabels, updateRebateFunnelLabels } from 'pages/users/funnels/funnels.thunks';
import RebateFunnelForm from 'pages/users/funnels/rebate/tabs/rebate-funnel-form';
import SiteLabelRender from 'pages/users/funnels/rebate/tabs/site-labels/site-labels.render';
import SiteLabelTabs from 'pages/users/funnels/rebate/tabs/site-labels/site-labels.tabs';
import SiteLabelsShimmer from 'pages/users/funnels/shimmers/site-labels';
import React from 'react';
import { Form } from 'react-final-form';
import { useLocation, useParams } from 'react-router-dom';

export default RebateFunnelLabels;
function RebateFunnelLabels() {
  const { label = 'home', id } = useParams();
  const { pathname } = useLocation();
  const subpage = pathname.split('/').slice(0, 6)[5];

  const { isFetching, data: defaultLabels } = useFetch('rebate-labels', () => getRebateDefaultLabels(id));
  const pages = Object.keys(defaultLabels || {});
  const mainPages = pages.map((p) => (p === 'searchFindBy' ? 'searchFindBuy' : p));

  const tabs = mainPages.map((p) => {
    return {
      current: label === p,
      href: `/dashboard/funnels/rebates/labels/${p}/`,
      name: p,
      label: snakeToCapitalize(p) + ' Page',
    };
  });

  const { data: userLabels } = useFetch('rebate-user-labels-' + label + id, () =>
    getRebateLabels(label === 'searchFindBuy' ? 'searchFindBy' : label, id)
  );

  const onSubmit = async (values) => {
    await updateRebateFunnelLabels(id, subpage === 'searchFindBuy' ? 'searchFindBy' : subpage, values);
  };

  return (
    <RebateFunnelForm>
      {isFetching ? (
        <SiteLabelsShimmer />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={userLabels}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit} autoComplete="off" className="space-y-6 pt-2">
                <SiteLabelTabs tabs={tabs} />
                <SiteLabelRender
                  defaultLabels={defaultLabels}
                  label={label === 'searchFindBuy' ? 'searchFindBy' : label}
                />
                <ShimmerWrapper fallback={<ShimmerUpdateBtn />}>
                  <FormSubmit label="Update Rebate" />
                </ShimmerWrapper>
              </form>
            </>
          )}
        />
      )}
    </RebateFunnelForm>
  );
}
