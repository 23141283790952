import { useABTest } from './abtest.hook';
import React, { useState } from 'react';
import { ActionIcon } from '@mantine/core';
import { IconCloudDownload, IconPictureInPictureOff } from '@tabler/icons';
import ShowAdmin from 'components/show-admin/show-admin';
import CarouselComponent from './components/abtest.carousel';
import { ABTestImageModal } from './components/abtest.image-modal';

export default function ABTestResults() {
  const { abTestData: data } = useABTest(false);
  const winners = data?.outputFile.filter((file) => file.category === 'winners');
  const others = data?.outputFile.filter((file) => file.category === 'others');
  const [opened, setOpened] = useState(false);
  const [fullImage, setfullImage] = useState('');

  function showImagePreview(image) {
    setfullImage(image);
    setOpened(true);
  }
  return (
    <div className="px-4 sm:px-6 py-6 overflow-auto h-full">
      <ABTestImageModal opened={opened} setOpened={setOpened} fullImage={fullImage} />

      <div className="flex flex-col items-center h-full ">
        <h1 className="font-semibold self-start">Results</h1>
        <div className="flex flex-col items-center mb-9">
          <h1 className="font-bold text-white bg-red-600  px-8 rounded-full ">WINNER</h1>
          <div className="flex justify-center py-5">
            {winners?.map((winner, index) => {
              return (
                <div
                  className="w-full flex flex-row-reverse flex-wrap h-64  rounded border-dashed rounded-lg mx-2 relative"
                  key={index}
                >
                  <img
                    src={winner.images[1].image ? winner.images[1].image : '/placeholder.jpeg'}
                    className="object-cover h-64 w-full pointer-events-none group-hover:opacity-75 rounded rounded-lg"
                  />
                  <div className="flex absolute self-end p-2 bottom-0">
                    <ActionIcon
                      color="red"
                      variant="filled"
                      className="mr-2"
                      onClick={() => showImagePreview(winner.images[0].image)}
                    >
                      <IconPictureInPictureOff size="1rem" />
                    </ActionIcon>
                    <ShowAdmin>
                      <ActionIcon
                        color="red"
                        variant="filled"
                        className="mr-2"
                        component="a"
                        href={winner.images[2].image}
                        target="_blank"
                      >
                        <IconCloudDownload size="1rem" />
                      </ActionIcon>
                    </ShowAdmin>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {data && <CarouselComponent data={others} showImage={showImagePreview} />}
      </div>
    </div>
  );
}
