import React, { ReactElement } from 'react';

/**
 * Form submit
 * @param label - Form Lable
 * @param showButtonOnly - whether to show the button only
 * @param col - table grid columns
 * @param span - column span
 * @param props - other component props
 */
const FormSubmit = ({
  label = 'Submit',
  showButtonOnly = false,
  col = 4,
  span = 3,
  margin = 3,
  ...props
}): ReactElement => {
  const { disabled } = props;

  const button = (
    <button
      type="submit"
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
      {...props}
    >
      {disabled ? 'Submitting...' : label}
    </button>
  );
  if (showButtonOnly) return button;

  return (
    <>
      <div
        className={`sm:grid sm:grid-cols-${col} sm:gap-4 sm:items-center sm:border-t bg-gray-100 sm:border-gray-200 sm:py-5`}
      >
        <div></div>
        <div className={`m-1 ml-${margin} sm:mt-0 sm:col-span-${span}`}>{button}</div>
      </div>
    </>
  );
};

export default FormSubmit;
