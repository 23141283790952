import { PlanInterface } from 'pages/users/subscription/plans/plans.interface';

export default function PlanTitle({ plan }: { plan: PlanInterface }) {
  const activeClass = plan.status === 'active' ? 'green' : 'gray';
  return (
    <>
      <div className="flex">
        <div className="flex-grow">
          {plan.name} @ {plan.price}/{plan.interval}
        </div>
        <div className="flex-grow-0">
          <span
            className={`inline-flex border border-${activeClass}-400 items-center px-2 py-0.5 rounded-lg text-sm font-medium bg-${activeClass}-100 text-${activeClass}-800`}
          >
            <svg className={`mr-1.5 h-2 w-2 text-${activeClass}-400`} fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            {plan.status}
          </span>
        </div>
      </div>
    </>
  );
}
