import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import useFetch from 'hooks/useFetch';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import { addBrand, getBrandDetails, updateBrand } from 'pages/admin/sqp/brands/brands.thunks';

function BrandsForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreating = id === 'new';
  let brand = {
    name: '',
    label: '',
    seller: '',
  };
  let isFetching = false;

  if (!isCreating) {
    ({ isFetching, data: brand } = useFetch('brand-details', () => getBrandDetails(id)));
  }

  const onSubmit = async (values) => {
    if (isCreating) {
      const res = await addBrand(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/brands'), 2000);
      }
    } else {
      await updateBrand(id, values);
    }
  };

  return (
    <SubpageContainer title={isCreating ? 'Add New Brand' : 'Update Brand'}>
      <RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={brand}
          render={({ handleSubmit }) => {
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid sm:w-full xl:w-3/4 grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormInput name="name" label="Name" />
                      <FormInput name="label" label="Label" />
                      <FormInput name="seller" label="Seller" />
                    </div>
                    <div className="flex justify-end">
                      <FormSubmit label="Submit" showButtonOnly disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default BrandsForm;
