import { Transition } from '@headlessui/react';
import FormInputSelect from 'components/forms/form-input-search-with-select';
import NoUserFound from 'pages/admin/manage-users/switch-user/no-user-found';
import SearchItem from 'pages/admin/manage-users/switch-user/switch-user.search.item';
import { Fragment, ReactElement, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

const searchOptions = [
  {
    label: 'Email',
    _id: 'email',
    value: 'email',
  },
  {
    label: 'First Name',
    _id: 'firstName',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    _id: 'lastName',
    value: 'lastName',
  },
];

const SwitchUserSearchBar = ({
  searchApi,
  formValues,
  handleAutoFill,
  autoFillValue,
  data,
  setSelectedUser,
}): ReactElement => {
  const [isPopped, setIsPopped] = useState(false);
  const { isLoading } = useAppSelector(selectRequest);

  const handleChange = async (v) => {
    if (v.target.value?.length > 1) {
      await searchApi({ ...formValues, value: v.target.value });
    }
  };

  const handleFocus = () => {
    setIsPopped(true);
  };

  const handleFocusOut = () => {
    setIsPopped(false);
  };

  const handleSelect = async (user) => {
    setSelectedUser(user);
  };

  const isTyping = (autoFillValue ?? true) || formValues?.value?.length > 1; // check if input is not empty
  const isUserReset = formValues?.value?.length > 1 && Array.isArray(data) && isTyping; // check is user has reset the input

  const noData = !isLoading && isUserReset && !data.length;

  return (
    <>
      <div className="w-full relative">
        <div className="absolute w-full bg-white">
          <FormInputSelect
            values={searchOptions}
            optionLabel="label"
            optionValue="value"
            name="value"
            selectName="key"
            label=""
            placeholder="Search user..."
            aria-label="Search"
            handleChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleFocusOut}
            handleAutoFill={handleAutoFill}
            autoFillValue={autoFillValue}
          />
          <Transition
            show={isPopped}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="z-10 top-2 w-full text-sm shadow-md">
              {noData ? (
                <NoUserFound />
              ) : (
                <div className="grid grid-cols-1">
                  {isUserReset &&
                    data?.map((user) => <SearchItem key={user._id} user={user} handleSelect={handleSelect} />)}
                </div>
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default SwitchUserSearchBar;
