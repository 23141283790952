import { RadioGroup } from '@headlessui/react';
import { classNames } from 'lib/helpers';
import { ReactNode } from 'react';
import { Field } from 'react-final-form';

export interface FormStackOptionsInterface {
  children: string | ReactNode;
  value: string;
}

export default function FormStackCards({ name, options }: { name: string; options: FormStackOptionsInterface[] }) {
  return (
    <>
      <Field type="text" name={name}>
        {(props) => (
          <RadioGroup value={props.input.value} onChange={props.input.onChange}>
            <div className="space-y-4">
              {options.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option.value}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      active ? 'ring-2 ring-red-500' : '',
                      'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full">
                        <RadioGroup.Description as="div" className="w-full">
                          {option.children}
                        </RadioGroup.Description>
                      </div>
                      <div
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-red-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      </Field>
    </>
  );
}
