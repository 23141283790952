import { DownloadIcon } from '@heroicons/react/outline';
import IconButton from 'components/common/icon.button';
import FormDateRange from 'components/forms/form-daterange';
import FormSelect from 'components/forms/form-select';
import FormSubmit from 'components/forms/form-submit';
import { addDays, addYears, format } from 'date-fns';
import { numberToDate } from 'lib/ads.helper';
import { selectAmazonAdsAccounts } from 'pages/users/amazon/amazon-ads.slice';
import { selectReportsByDate } from 'pages/users/analytics/analytics-ads.slice';
import { exportReportsByDate, getReportsByDate } from 'pages/users/analytics/analytics.thunks';
import React from 'react';
import { Matcher } from 'react-day-picker';
import { Form } from 'react-final-form';
import { useAppSelector } from 'redux/hooks';

function AnalyticsForm() {
  const accounts = useAppSelector(selectAmazonAdsAccounts);
  const { endDate, startDate, amazonAdsUserId } = useAppSelector(selectReportsByDate);

  const onSubmit = async (values, isExport = false) => {
    const reportValue = {
      endDate: +format(values.range.to || values.range.from, 'yyyyMMdd'),
      startDate: +format(values.range.from, 'yyyyMMdd'),
      amazonAdsUserId: values.adsAccount,
    };
    const adsAccount = accounts.find((a) => a._id === values.adsAccount);
    const formParam = { ...reportValue, amazonUserId: adsAccount?.amazonUserId as string };
    await getReportsByDate(formParam);
    if (isExport) {
      exportReportsByDate(formParam);
    }
  };

  const disabledDays: Matcher = { from: addDays(new Date(), 1), to: addYears(new Date(), 100) };
  const initialValues = {
    range: { to: numberToDate(endDate), from: numberToDate(startDate) },
    adsAccount: amazonAdsUserId || accounts[0]._id,
  };

  return (
    <>
      <Form
        onSubmit={(values) => onSubmit(values)}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => (
          <form className=" space-x-1 flex" onSubmit={handleSubmit}>
            <FormSelect name="adsAccount" values={accounts} />
            <div className="flex-none">
              <FormDateRange name="range" isRightAligned={true} disabledDays={disabledDays} />
            </div>
            <FormSubmit label="Submit" showButtonOnly />
            <IconButton bgColor="blue" Icon={DownloadIcon} text="Export" onClick={() => onSubmit(values, true)} />
          </form>
        )}
      />
    </>
  );
}

export default AnalyticsForm;
