import React from 'react';
import { Grid, BackgroundImage, Button, Text, Space } from '@mantine/core';
import InstructionModal from './heat-maps.instruction-modal';
import { useDisclosure } from '@mantine/hooks';
function HeatMapsBanner() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <InstructionModal opened={opened} close={close} />

      <BackgroundImage src="/heatmaps/banner-heatmap.png" radius="md" className="!bg-right-top">
        <Grid m={0}>
          <Grid.Col span={7} p={{ lg: 35, sm: 20 }}>
            <Text size={25} fw={500} color="white">
              Uncover the X-Factor of Visual Excellence
            </Text>
            <Text size={18} fw={500} color="white">
              The Ultimate Image Optimization Tool
            </Text>
            <Space h="sm" />
            <Text fz={{ lg: 'md', sm: 'sm' }} color="white">
              Signalytics heatmapping tool visually highlights key areas in images, enabling data-driven decisions for
              optimization and engagement. Make informed adjustments to improve user experience, resulting in a more
              compelling visual presentation on your platform.
            </Text>
            <Button my={20} onClick={open}>
              See How it works
            </Button>
          </Grid.Col>
        </Grid>
      </BackgroundImage>
    </>
  );
}

export default HeatMapsBanner;
