import { Menu, Transition } from '@headlessui/react';
import If from 'components/conditionals/if.conditional';
import SwitchUser from 'pages/admin/manage-users/switch-user/switch-user';
import ResendEmail from 'components/resend-email/resend-email';
import { logout, selectUserData } from 'pages/users/user/user.slice';
import { classNames } from 'lib/helpers';
import { Fragment, ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const userNavigation = [
  { name: 'Your Profile', href: '/dashboard/settings' },
  { name: 'Settings', href: '/dashboard/settings' },
];

function Topbar({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);
  const isAdmin = userData?.isAdmin;

  const logoutUser = () => {
    dispatch(logout());
  };

  const justify = isAdmin ? 'justify-between' : 'justify-end';

  return (
    <>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <div className={`flex-1 px-6 flex ${justify}`}>
            {isAdmin && <SwitchUser />}
            <div className="ml-4 flex items-center md:ml-6">
              {userData?.firstName}
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full bg-white"
                          src="https://s.gravatar.com/avatar/3734f35792a6c05ed4c4783c5be4df2c?s=80"
                          alt="logo"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <If show={!isAdmin}>
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </If>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => logoutUser()}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer'
                              )}
                            >
                              Logout
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
        <If show={!userData.isVerified}>
          <ResendEmail user={userData} />
        </If>
        {children}
      </div>
    </>
  );
}

export default Topbar;
