import Cookies from 'js-cookie';
import { persistor } from 'redux/store';

export const defaultCookieName = 'token';
export const adminCookieName = 'admin_token';
export function getToken(name = defaultCookieName): string | null {
  try {
    return Cookies.get(name);
  } catch (e) {
    return null;
  }
}

export function setCookieToken(value, name = defaultCookieName) {
  Cookies.set(name, value, { expires: 1, secure: true, sameSite: 'strict' });
}

export function clearAllData() {
  Cookies.remove(defaultCookieName);
  Cookies.remove(adminCookieName);
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
}
