import React, { ReactElement } from 'react';
import SubpageContainer from 'components/containers/subpage.container';
import Card from 'components/card/card';
import MFormButton from 'components/mantine/forms/m-form-button';
import { ABTestImageMultipleUpload } from 'pages/users/ab-test/components/abtest.image-multiple-upload';
import { Divider, Skeleton } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import Joi from 'joi';
import { useABTest } from 'pages/users/ab-test/abtest.hook';
import If from 'components/conditionals/if.conditional';

export const ABTestUploadOutput = (): ReactElement => {
  const { save, abTestData, id, isLoading } = useABTest(false);

  const status = abTestData?.status ?? 'processing';

  const form = useForm({
    initialValues: {
      winners: [],
      others: [],
    },
    validate: joiResolver(
      Joi.object({
        winners: Joi.array()
          .items({
            images: Joi.array()
              .items({
                image: Joi.string().required(),
                width: Joi.string().required(),
              })
              .min(3)
              .max(3)
              .required(),
          })
          .min(2)
          .max(2)
          .required(),
        others: Joi.array()
          .items({
            images: Joi.array()
              .items({
                image: Joi.string().required(),
                width: Joi.string().required(),
              })
              .min(3)
              .max(3)
              .required(),
          })
          .min(1)
          .max(8)
          .required(),
      })
    ),
  });

  return (
    <>
      <Skeleton visible={isLoading} height={isLoading ? 600 : 0} />
      <If show={id === '' || status !== 'processing'}>
        <div className="max-w-7xl mx-auto px-4 py-2 text-center sm:px-6 sm:py-2 lg:px-16 lg:py-16">
          <h1 className="mt-2 text-4xl font-extrabold text-gray-700 tracking-tight sm:text-5xl">
            Sorry, this page can't be found
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
            it may have been deleted because the feature doesn't exist anymore.
          </p>
        </div>
      </If>
      <If show={id !== '' && status === 'processing'}>
        <SubpageContainer title={'Results'}>
          <Card>
            <form onSubmit={form.onSubmit((values) => save(values))}>
              <div className="space-y-3">
                <ABTestImageMultipleUpload
                  label="Winners"
                  max={2}
                  onChangeUpload={(v) => form.setValues({ winners: v })}
                />
                <Divider my="sm" variant="dashed" />
                <ABTestImageMultipleUpload label="Others" onChangeUpload={(v) => form.setValues({ others: v })} />
                <Divider my="sm" variant="dashed" />
                <MFormButton type="submit" text="Upload Result" disabled={!form.isValid()} />
              </div>
            </form>
          </Card>
        </SubpageContainer>
      </If>
    </>
  );
};
