import SearchFilter from 'components/common/search-filter';

import RequestContainer from 'components/containers/request.container';
import SubpageContainerCustom from 'components/containers/subpage-container.custom';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import { selectProducts } from 'pages/users/products/products.slice';
import productsTableData from 'pages/users/products/products.tabledata';
import { loadProducts } from 'pages/users/products/products.thunks';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';

const headers = ['Name', 'Price', 'Asin', 'Seller SKU', 'Status'];

const Products = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { isFetching } = useFetch('products' + currentPage, () => loadProducts({ page: currentPage }));

  const {
    docs: products,
    hasNextPage,
    hasPrevPage,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalPages,
  } = useAppSelector(selectProducts);

  const columns = productsTableData();
  const colSpan = headers.length;

  return (
    <>
      <SubpageContainerCustom title="Products" custom={<SearchFilter />}>
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={products}
            noDataMsg="No product found!"
            addNew={false}
          >
            <TableBody columns={columns} data={products} />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={products?.length}
        />
      </SubpageContainerCustom>
    </>
  );
};

export default Products;
