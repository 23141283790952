import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormRadioGroup from 'components/forms/form-radio-group';
import FormSubmit from 'components/forms/form-submit';
import { createLink } from 'pages/users/magic-links/magic-links.thunks';
import React from 'react';
import { Form } from 'react-final-form';
import { useAppSelector } from 'redux/hooks';

export default () => {
  const { isLoading } = useAppSelector((state) => state.request);

  const onSubmit = async (values) => await createLink(values);

  return (
    <>
      <SubpageContainer title="Redirect Links">
        <Form
          onSubmit={onSubmit}
          initialValues={null}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className="bg-white overflow-hidden shadow rounded-lg py-6 px-8"
            >
              <div className="grid grid-cols-1 gap-4 w-3/4 bg-white">
                <FormInput disabled={isLoading} name="name" label="Name" />
                <FormInput disabled={isLoading} name="link" label="Link" />
                <FormRadioGroup
                  name="origin"
                  label="Link origin"
                  options={[
                    { label: 'Google', value: 'gmail' },
                    { label: 'Youtube', value: 'youtube' },
                    { label: 'Tiktok', value: 'tiktok' },
                    { label: 'Facebook', value: 'facebook' },
                    { label: 'Twitter', value: 'twitter' },
                  ]}
                />
                <FormSubmit disabled={isLoading} label="Submit" />
              </div>
            </form>
          )}
        />
      </SubpageContainer>
    </>
  );
};
