import { getSilentFetch, postFetch, postSilentFetch, putFetch } from 'request/request';
import { store } from 'redux/store';
import { setSellerAccounts } from 'pages/admin/sqp/seller-accounts/seller-accounts.slice';
import { ResponseListInterface } from 'request/response.interface';
import { setUsers } from 'pages/admin/manage-users/manage-users.slice';
import { SellerAccountsInterface } from 'interfaces/seller-accounts.interface';

export const getSellerAccounts = async (values): Promise<ResponseListInterface<SellerAccountsInterface>> => {
  const res = await postSilentFetch('/sqp/admin/seller-accounts/page', values);
  if (res) {
    store.dispatch(setSellerAccounts(res));
  }
  return res;
};

export const AddSellerAccounts = async (values): Promise<SellerAccountsInterface> => {
  const res = await postFetch('/sqp/admin/seller-accounts', values, 'Seller Account added Successully');
  if (res) {
    store.dispatch(setSellerAccounts(res));
  }
  return res;
};

export const getSellerAccountDetails = async (id): Promise<ResponseListInterface<SellerAccountsInterface>> => {
  const res = await getSilentFetch(`/sqp/admin/seller-accounts/${id}`);
  return res;
};

export const UpdateSellerAccount = async (id, values): Promise<ResponseListInterface<SellerAccountsInterface>> => {
  const res = await putFetch(
    `/sqp/admin/seller-accounts/${id}`,
    { ...values, _id: id },
    'Seller Account Updated Successfully!'
  );
  if (res) {
    store.dispatch(setUsers(res));
  }
  return res;
};
