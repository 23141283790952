import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ResponseUsersInterface, UsersInterface } from 'interfaces/users.interace';
import { RootState } from 'redux/store';

const initialState: ResponseUsersInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state: Draft<ResponseUsersInterface>, action: PayloadAction<ResponseUsersInterface>) => {
      return action.payload;
    },
    deleteUser: (state: Draft<ResponseUsersInterface>, action: PayloadAction<string>) => {
      const { docs: users } = state;
      const updatedUsers = users.filter((u: UsersInterface) => u._id !== action.payload);
      return { ...state, docs: updatedUsers, totalDocs: updatedUsers?.length };
    },
  },
});

export const { setUsers, deleteUser } = usersSlice.actions;

export const selectUsers = (state: RootState) => state.users;

export const usersReducer = usersSlice.reducer;
