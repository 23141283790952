import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ResponseProductsInterface } from 'interfaces/products.interface';

import { ProductInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: ResponseProductsInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state: Draft<ResponseProductsInterface>, action: PayloadAction<ResponseProductsInterface>) => {
      return action.payload;
    },
    setProductsFilter: (state: Draft<ResponseProductsInterface>, action: PayloadAction<ProductInterface[]>) => {
      const products = action.payload;
      return { ...initialState, docs: products };
    },
  },
});

export const { setProducts, setProductsFilter } = productsSlice.actions;

export const selectProducts = (state: RootState) => state.products;

export const productsReducer = productsSlice.reducer;
