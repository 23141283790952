import { moneyFormat, numberFormat, percentageFormat } from 'lib/formatting.helpers';
import { StatisticsColumnsWithFormatInterface } from 'pages/users/analytics/analytics.interface';

export const analyticsColumns: StatisticsColumnsWithFormatInterface[] = [
  { name: 'All Sales', color: '#0d24e9', column: 'sales', formatter: moneyFormat },
  {
    name: 'Sponsored Sales Share',
    color: '#8009f5',
    column: 'sponsoredSalesShare',
    formatter: percentageFormat,
  },
  {
    name: 'Sponsored Sales',
    color: '#DB5A0FFF',
    column: 'sponsoredSales',
    formatter: moneyFormat,
  },
  {
    name: 'TACOS',
    color: '#43DFE7FF',
    column: 'tacos',
    formatter: percentageFormat,
  },
  {
    name: 'ACOS',
    color: '#AFE313FF',
    column: 'acos',
    formatter: percentageFormat,
  },
  { name: 'Cost', color: '#d21994', column: 'cost', formatter: numberFormat },
  { name: 'Clicks', color: '#ea1212', column: 'clicks', formatter: numberFormat },
  {
    name: 'Impressions',
    color: '#36bf44',
    column: 'impressions',
    formatter: numberFormat,
  },
  {
    name: 'CR',
    color: '#4d56d8',
    column: 'cr',
    formatter: percentageFormat,
  },
  {
    name: 'CPC',
    color: '#d95732',
    column: 'cpc',
    formatter: moneyFormat,
  },
  {
    name: 'ROAS',
    color: '#c418ea',
    column: 'roas',
    formatter: numberFormat,
  },
];
