import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ResponseSuperLinksInterface, SuperLinksInterface } from 'interfaces/super-links.interface';
import { RootState } from 'redux/store';

const initialState: ResponseSuperLinksInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const superLinksSlice = createSlice({
  name: 'superLinks',
  initialState,
  reducers: {
    setSuperLinks: (state: Draft<ResponseSuperLinksInterface>, action: PayloadAction<ResponseSuperLinksInterface>) => {
      return action.payload;
    },
    deleteSuperLink: (state: Draft<ResponseSuperLinksInterface>, action: PayloadAction<string>) => {
      const { docs: links } = state;
      const updatedLinks = links.filter((l: SuperLinksInterface) => l._id !== action.payload);
      return { ...state, docs: updatedLinks, totalDocs: updatedLinks?.length };
    },
  },
});

export const { setSuperLinks, deleteSuperLink } = superLinksSlice.actions;

export const selectSuperLinks = (state: RootState) => state.superLinks;

export const superLinksReducer = superLinksSlice.reducer;
