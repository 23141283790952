import Alert from 'components/alerts/alert';
import If from 'components/conditionals/if.conditional';
import LoadingRed from 'components/svg/loading-red';
import { getToken } from 'lib/cookie.helper';
import { getAllQueryString, getAmazonApiType } from 'lib/helpers';
import { AmazonTokenRedirect } from 'pages/users/amazon/amazon.interface';
import { tokenRedirect } from 'pages/users/amazon/amazon.thunks';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

const AmazonLoginRedirect = () => {
  const navigate = useNavigate();
  const isLoggedIn = getToken();
  if (!isLoggedIn) {
    navigate(`/`);
  }

  const { isLoading, isValidationError, isFailure } = useAppSelector(selectRequest);

  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    async function getToken() {
      const tokenQueries = getAllQueryString() as AmazonTokenRedirect;
      const amazonApiType = getAmazonApiType('token-redirect');
      const token = await tokenRedirect(tokenQueries, amazonApiType);
      setToken(token.message);
    }
    getToken();
  }, []);

  if (token) {
    navigate(`/dashboard/amazon`);
  }

  return (
    <div className="flex justify-center pt-36 h-screen">
      <div>
        <div className="bg-white overflow-hidden shadow rounded-lg p-12">
          <Alert messageOnly={true} />
          <If show={isLoading}>
            <LoadingRed />
          </If>
          <If show={isValidationError || isFailure}>
            <div className="text-center">
              <div className="mt-6">
                <Link
                  to="/dashboard/amazon"
                  className="inline-flex mr-2 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md
                text-white bg-rose-600"
                >
                  Go back
                </Link>
              </div>
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default AmazonLoginRedirect;
