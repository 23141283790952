import { SplitTestInterface } from 'pages/users/split-tests/split-tests.interface';
import { getSplitTest, getSplitTests } from 'pages/users/split-tests/split-tests.thunks';
import { useCallback, useEffect, useState } from 'react';

export function useSplitTests(): SplitTestInterface[] {
  const [splitTest, setSplitTest] = useState<SplitTestInterface[]>([]);

  const paged = useCallback(async () => {
    const data = await getSplitTests();
    setSplitTest(data.docs);
  }, []);

  useEffect(() => {
    paged().catch(() => Promise.reject());
  }, []);

  return splitTest;
}

export function useSplitTest(id: string): SplitTestInterface | undefined {
  const [splitTest, setSplitTest] = useState<SplitTestInterface>();

  const paged = useCallback(async () => {
    const data = await getSplitTest(id);
    setSplitTest(data);
  }, []);

  useEffect(() => {
    paged().catch(() => Promise.reject());
  }, []);

  return splitTest;
}
