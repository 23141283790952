import IfConditional from 'components/conditionals/if.conditional';
import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { ImageSizes } from 'interfaces/resize-image.interface';
import { getHeatmapCoverImage, getImageSize } from 'lib/helpers';
import { editHeatmapItem } from 'pages/users/heat-maps/heat-maps-item.slice';
import { showImage } from 'pages/users/heat-maps/heat-maps.slice';
import React, { ReactElement } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { ActionIcon, Button, Text, Tooltip } from '@mantine/core';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';

const HeatMapsItem = ({ item }): ReactElement => {
  const dispatch = useAppDispatch();
  const isGroup = Object.hasOwn(item, 'workspaceId');

  function onEdit(selectedGroup: string, selectedId: string) {
    dispatch(
      editHeatmapItem({
        isOpen: true,
        selectedGroup,
        selectedId,
        isEditing: true,
      })
    );
  }

  function onDelete(selectedGroup: string, selectedId: string) {
    const deleteUrl = isGroup ? 'groups' : 'images';
    dispatch(
      openDialog({
        isOpen: true,
        deleteId: selectedId,
        deleteUrl: `/heatmap/heatmaps/${deleteUrl}/`,
        customProps: { buttonLabel: 'Delete' },
      })
    );
  }

  function getImage(item) {
    let source;
    if (isGroup) {
      source = getHeatmapCoverImage(item);
    } else if (item.outputFile && item.outputFile.length > 0) {
      source = getImageSize(item.outputFile, ImageSizes.THUMBNAIL);
    } else {
      source = getImageSize(item.inputFile, ImageSizes.THUMBNAIL);
    }

    return source;
  }

  function showImagePreview() {
    dispatch(
      showImage({
        currentImage: item,
      })
    );
  }

  //group cover
  const processingStatuses = ['queued', 'predicting', 'processing'];
  return (
    <li key={item._id} className="group rounded-md relative hover:scale-[1.02] transition-all bg-black overflow-hidden">
      <div className="max-w-md rounded overflow-hidden shadow-lg">
        <div className={'relative flex justify-center items-center w-full'}>
          <img
            src={getImage(item)}
            alt={item.name}
            className={`object-cover h-52 w-full cursor-pointer group-hover:opacity-30 transition-all`}
          />{' '}
          {/*</div>*/}
          <Button className="!absolute !hidden group-hover:!block" onClick={() => showImagePreview()}>
            View
          </Button>
        </div>
        <div className="px-6 py-4 bg-white">
          <div className="font-medium text-xl mb-2 flex justify-between">
            <div className="flex justify-between flex-col truncate">
              <Tooltip label={item.name}>
                <span className="font-semibold truncate text-sm"> {item.name}</span>
              </Tooltip>
            </div>
            <div className="grow">
              <IfConditional show={processingStatuses.includes(item.status)}>
                <p className="block absolute bg-amber-200 top-2 left-2 text-sm font-medium rounded-sm text-black m-1 px-2">
                  Processing
                </p>
              </IfConditional>
              <IfConditional show={item.status === 'error'}>
                <p className="block absolute bg-red-200 top-2 left-2 text-sm font-medium rounded-sm text-black m-1 px-2">
                  Error
                </p>
              </IfConditional>
            </div>
            <div className="w-16 space-x-2 text-right items-center flex">
              <ActionIcon color="dark" onClick={() => onEdit(item.name, item._id)}>
                <PencilIcon className={`h-6 w-6`} aria-hidden="true" />
              </ActionIcon>
              <ActionIcon color="red" onClick={() => onDelete(item.name, item._id)}>
                <TrashIcon className={`h-6 w-6`} aria-hidden="true" />
              </ActionIcon>
            </div>
          </div>

          <div className="flex m-0 truncate">
            <Text fz="xs" c="dimmed" fw={500} className="mr-1">
              Preset:
            </Text>
            <Tooltip label={item.preset ? item.preset : '--'}>
              <Text tt="capitalize" fz="xs" c="dimmed" className="truncate">
                {item.preset ? item.preset : '--'}
              </Text>
            </Tooltip>
          </div>
        </div>
      </div>
    </li>
  );
};

export default HeatMapsItem;
