import { Center, Text } from '@mantine/core';
import * as React from 'react';
import Spinner from 'components/shimmer/spinner';

export const SpinnerText = ({ text }: { text: string }) => {
  return (
    <>
      <Center maw={400} h={100} mx="auto" className="my-2">
        <Spinner />
        <div className="mx-1">
          <Text fz="md">{text}</Text>
        </div>
      </Center>
    </>
  );
};
