import React, { createContext, ReactNode, useContext } from 'react';
import { emptyFunction } from 'lib/empty';
import { PromptContextInterface } from 'pages/admin/prompts/prompt.interface';

const PromptContext = createContext({
  system: {
    prompt1: '',
  },
  bullets: {
    prompt1: '',
    prompt2: '',
    model: '',
  },
  titles: {
    prompt1: '',
    model: '',
  },
  description: {
    benefits: '',
    features: '',
    story: '',
    model: '',
  },
  suggestion: [
    {
      type: 'text',
      text: '',
    },
    {
      type: 'image_url',
      image_url: {
        url: '',
      },
    },
  ],
  setTitles: emptyFunction,
  setBullets: emptyFunction,
  setDescriptions: emptyFunction,
  setModel: emptyFunction,
  setSystem: emptyFunction,
  setSuggestion: emptyFunction,
} as PromptContextInterface);

export const promptContext = () => {
  return useContext(PromptContext);
};

export const PromptTemplateProvider = ({ children, data }: { data: PromptContextInterface; children: ReactNode }) => {
  return <PromptContext.Provider value={{ ...data }}>{children}</PromptContext.Provider>;
};
