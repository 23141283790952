import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { PayoutModalInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: PayoutModalInterface = {
  isShowing: false,
  customer: null,
};

export const payoutModalSlice = createSlice({
  name: 'payoutModal',
  initialState,
  reducers: {
    setPayoutModal(state: Draft<PayoutModalInterface>, action: PayloadAction<PayoutModalInterface>) {
      state.isShowing = action.payload.isShowing;
      state.customer = action.payload.customer;
    },
  },
});

export const { setPayoutModal } = payoutModalSlice.actions;
export const selectPayoutModal = (state: RootState) => state.payoutModal;
export const payoutModalReducer = payoutModalSlice.reducer;
