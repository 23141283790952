import { TableColumnRowInterface } from 'components/tables/table-body.helper';
import { SuperLinksInterface } from 'interfaces/super-links.interface';
import { tableColumns } from '../tables/table-body.helper';
import FormInputAddon from 'components/forms/form-input-addon';
import KeywordBadge from 'components/badges/keyword.badge';

function superLinkTableData(): TableColumnRowInterface[] {
  const columnData = [
    'name',
    'asin',
    'marketplace',
    {
      key: 'destinationUrl',
      render: (link: SuperLinksInterface) => {
        const redirectLink = 'https://' + link.domain + '/' + link.uid;

        return <FormInputAddon link={redirectLink} />;
      },
    },
    {
      key: 'keywords',
      render: (rowData: SuperLinksInterface) => <KeywordBadge keywords={rowData.keywords} />,
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/superlinks', field: 'name' });
}

export default superLinkTableData;
