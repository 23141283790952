import { useEffect, useState } from 'react';

const useFetch = (query = 'funnels', fetchApi) => {
  let cancelrequest = false;
  const [data, setData] = useState<any>([]);
  const [status, setStatus] = useState<string>('idle');

  useEffect(() => {
    if (!query) return;

    const fetchData = async () => {
      setStatus('fetching');
      try {
        const res = await fetchApi();
        if (cancelrequest) return;
        setStatus('fetched');
        setData(res);
      } catch (err) {
        if (cancelrequest) return;
        setStatus('failed');
      }
    };
    fetchData();
    return function cleanUp() {
      cancelrequest = true;
    };
  }, [query]);

  return { data, isFetching: status === 'fetching' };
};

export default useFetch;
