import { getUserNavigation } from 'components/sidebar/sidebar-user-navigation';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectSidebarOpen, toggleSidebar } from './sidebar.slice';
import { selectUserData } from 'pages/users/user/user.slice';
import { classNames } from 'lib/helpers';
import adminNavigation from './sidebar-admin-navigation';
import { NavLink } from '@mantine/core';
import { SidebarGroupedNavigationInterface } from './navigation.interface';

function Sidebar() {
  const location = useLocation();
  const isSidebarOpen = useAppSelector(selectSidebarOpen);
  const dispatch = useAppDispatch();
  const { roles, isAdmin } = useAppSelector(selectUserData);
  const navItems: SidebarGroupedNavigationInterface[] = isAdmin ? adminNavigation() : getUserNavigation(roles);

  const setSidebarOpen = (open) => {
    dispatch(toggleSidebar(open));
  };

  const isCurrent = (item) => {
    const mainItem = location.pathname.replace(`/dashboard/`, '') === item.href;
    const childItem = location.pathname.includes(`/dashboard/${item.href}`) && item.href !== '';

    return mainItem || childItem;
  };

  return (
    <>
      <div className="mt-3 flex-1 flex flex-col font-semibold relative">
        {navItems.map((item, index) => {
          return (
            item?.subMenu?.length !== 0 && (
              <NavLink label={isSidebarOpen ? item.name : ''} childrenOffset={0} defaultOpened key={index}>
                {item?.subMenu?.map((subMenu) => {
                  return (
                    <NavLink
                      className={classNames(
                        isCurrent(subMenu)
                          ? '!border-l-4 !border-solid !border-red-500 !bg-gray-100 text-red-500'
                          : 'text-red-600',
                        'group relative !w-[auto] mx-1 rounded mb-1'
                      )}
                      icon={
                        <subMenu.icon
                          className={classNames(
                            isCurrent(subMenu) ? 'bg-gray-100 text-red-500' : 'text-red-600',
                            'flex-shrink-0 h-5 w-5 hover:text-red-500 '
                          )}
                          aria-hidden="true"
                        />
                      }
                      label={
                        isSidebarOpen ? (
                          <div className="ml-3">{subMenu?.name}</div>
                        ) : (
                          <div className="absolute left-2 items-center hidden ml-10 inset-y-0 right-0 w-fit group-hover:flex">
                            <div className="w-5 h-5 -mr-3 rounded-md rotate-45 bg-white text-red-600 border border-red-600"></div>
                            <span className="z-40 p-2 text-xs w-28 leading-none text-red-600 bg-white border border-red-600 border-l-0 rounded-lg">
                              {subMenu?.name}
                            </span>
                          </div>
                        )
                      }
                      key={subMenu.name}
                      component={Link}
                      to={subMenu?.href !== 'ppc-ai' ? `/dashboard/${subMenu?.href}` : '/ppc-ai'}
                    />
                  );
                })}
              </NavLink>
            )
          );
        })}
        {/*<If show={!isAdmin}>*/}
        {/*  <NavLink label={isSidebarOpen ? 'SAAS' : ''} childrenOffset={0} defaultOpened>*/}
        {/*    {navlinkComponent(saasLinks)}*/}
        {/*  </NavLink>*/}

        {/*  <NavLink label={isSidebarOpen ? 'Admin Tools' : ''} childrenOffset={0} defaultOpened>*/}
        {/*    {navlinkComponent(adminToolLinks)}*/}
        {/*  </NavLink>*/}
        {/*  <NavLink label={isSidebarOpen ? 'Analytics' : ''} childrenOffset={0} defaultOpened>*/}
        {/*    {navlinkComponent(analyticsLink)}*/}
        {/*  </NavLink>*/}
        {/*</If>*/}

        {/*<NavLink label={isSidebarOpen ? 'Others' : ''} childrenOffset={0} defaultOpened>*/}
        {/*  {navlinkComponent(otherLinks)}*/}
        {/*</NavLink>*/}

        <div className="absolute bottom-0 right-2">
          <button
            type="button"
            className="p-1 border rounded-full border-gray-200 text-gray-500 invisible sm:visible focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-200"
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          >
            <span className="sr-only">Open sidebar</span>
            {isSidebarOpen ? (
              <ChevronLeftIcon className="h-8 w-8 text-gray-500" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
