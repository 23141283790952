import Decimal from 'decimal.js';
import { AdsSalesReportsInterface } from 'pages/users/analytics/analytics.interface';

export type Obj = { [key: string]: number };
Decimal.set({ precision: 4, defaults: true });

export function calculateTotals(arr: Obj[]): AdsSalesReportsInterface {
  // Initialize an empty object for the totals
  const totals = {};

  // Loop through each object in the input array
  for (const obj of arr) {
    // Loop through each property in the object
    for (const prop in obj) {
      // If the property does not exist in the totals object,
      // add it and set its value to 0
      if (!Object.prototype.hasOwnProperty.call(totals, prop)) {
        totals[prop] = 0;
      }

      // Add the property value from the object to the corresponding
      // property in the totals object
      if (typeof obj[prop] === 'number') {
        const current = obj[prop] !== null ? obj[prop] : 0;
        const total = totals[prop] !== null ? totals[prop] : 0;
        totals[prop] = Decimal.add(current, total).toNumber();
      } else {
        totals[prop] = obj[prop];
      }
    }
  }

  // Return the totals object
  return totals as AdsSalesReportsInterface;
}

export function safeDivide(numerator: number, demoninator: number): number {
  return isFinite(numerator / demoninator) ? numerator / demoninator : 0;
}
