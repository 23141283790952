import { PlusIcon } from '@heroicons/react/solid';
import FormButton from 'components/forms/form-button';
import FormSelect from 'components/forms/form-select';
import { alertOrRedirect, optionFormat } from 'lib/helpers';
import markets from 'lib/markets';
import { AmazonApiTypes } from 'pages/users/amazon/amazon.interface';
import { login } from 'pages/users/amazon/amazon.thunks';
import { Form } from 'react-final-form';

const AmazonSellerForm = () => {
  const submit = async (values, type) => {
    const res = await login({ ...values, type });
    alertOrRedirect(res.loginUrl);
  };

  return (
    <Form
      onSubmit={(values) => submit(values, AmazonApiTypes.SP)}
      initialValues={null}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="">
          <div>
            <div className="flex w-fit gap-4">
              <FormSelect
                name="country"
                label=""
                values={markets}
                initialValue={optionFormat(markets[0])}
                optionValueFormat={(value) => optionFormat(value)}
                optionLabel="country"
                optionValue="id"
              />
              <FormButton
                leadingIcon={<PlusIcon className="w-5 h-5 mr-1" />}
                buttonLabel="Seller Account"
                type="submit"
                classes="whitespace-nowrap"
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default AmazonSellerForm;
