import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { MagicLinksInterface, ResponseMagicLinksInterface } from 'interfaces/magic-links.interface';
import { RootState } from 'redux/store';

const initialState: ResponseMagicLinksInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const redirectLinksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    setMagicLinks: (state: Draft<ResponseMagicLinksInterface>, action: PayloadAction<ResponseMagicLinksInterface>) => {
      return action.payload;
    },
    deleteMagicLink: (state: Draft<ResponseMagicLinksInterface>, action: PayloadAction<string>) => {
      const { docs: links } = state;
      const updatedLinks = links.filter((l: MagicLinksInterface) => l._id !== action.payload);
      return { ...state, docs: updatedLinks, totalDocs: updatedLinks?.length };
    },
  },
});

export const { setMagicLinks, deleteMagicLink } = redirectLinksSlice.actions;

export const selectMagicLinks = (state: RootState) => state.links;

export const redirectLinksReducer = redirectLinksSlice.reducer;
