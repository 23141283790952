import DeployFunnel from 'pages/users/funnels/deploy-buttons/deploy.button';
import PauseFunnel from 'pages/users/funnels/deploy-buttons/pause.button';
import RedeployFunnel from 'pages/users/funnels/deploy-buttons/redeploy.button';
import StopFunnel from 'pages/users/funnels/deploy-buttons/stop.button';
import { selectFunnel } from 'pages/users/funnels/rebate/rebate.slice';
import { useAppSelector } from 'redux/hooks';

const FunnelButtonsWrapper = ({ deploy, redeploy }) => {
  const funnel = useAppSelector(selectFunnel);

  return (
    <div className="flex justify-end">
      <DeployFunnel deploy={deploy} funnel={funnel} />
      <StopFunnel funnel={funnel} />
      <PauseFunnel funnel={funnel} />
      <RedeployFunnel redeploy={redeploy} funnel={funnel} />
    </div>
  );
};

export default FunnelButtonsWrapper;
