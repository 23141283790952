import React, { createContext, ReactNode, useContext } from 'react';
import { emptyFunction } from 'lib/empty';
import { AbTestDefineElementInterface } from 'pages/users/ab-test/abtest-define-elements/abtest-define.interface';

const ABTestDefineElementsContext = createContext({
  data: [],
  isTrace: false,
  currentImageIndex: 0,
  setElementItems: emptyFunction,
  setCurrentPosition: emptyFunction,
  setTrace: emptyFunction,
} as AbTestDefineElementInterface);

export const abTestDefineElementsContext = () => {
  return useContext(ABTestDefineElementsContext);
};
const ABTestDefineElementsProvider = ({
  children,
  data,
}: {
  data: AbTestDefineElementInterface;
  children: ReactNode;
}) => {
  return <ABTestDefineElementsContext.Provider value={{ ...data }}>{children}</ABTestDefineElementsContext.Provider>;
};

export default ABTestDefineElementsProvider;
