import DeleteButton from 'components/buttons/delete-button';
import EditButton from 'components/buttons/edit-button';
import IfElseConditional from 'components/conditionals/if-else.conditional';
import IfConditional from 'components/conditionals/if.conditional';
import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { ImageSizes } from 'interfaces/resize-image.interface';
import { defaultHeatmapImage, getHeatmapCoverImage, getImageSize } from 'lib/helpers';
import { editHeatmapItem } from 'pages/users/heatmap/heat-map-item.slice';
import { showImage } from 'pages/users/heatmap/heatmap.slice';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { Text, Tooltip } from '@mantine/core';

const HeatmapItem = ({ item }): ReactElement => {
  const dispatch = useAppDispatch();
  const isGroup = Object.hasOwn(item, 'workspaceId');

  function onEdit(selectedGroup: string, selectedId: string) {
    dispatch(
      editHeatmapItem({
        isOpen: true,
        selectedGroup,
        selectedId,
        isEditing: true,
      })
    );
  }

  function onDelete(selectedGroup: string, selectedId: string) {
    const deleteUrl = isGroup ? 'groups' : 'images';
    dispatch(
      openDialog({
        isOpen: true,
        deleteId: selectedId,
        deleteUrl: `/heatmap/heatmaps/${deleteUrl}/`,
        customProps: { buttonLabel: 'Delete' },
      })
    );
  }

  function getImage(item) {
    let source;
    if (isGroup) {
      source = getHeatmapCoverImage(item);
    } else if (item.outputFile && item.outputFile.length > 0) {
      source = getImageSize(item.outputFile, ImageSizes.THUMBNAIL);
    } else {
      source = getImageSize(item.inputFile, ImageSizes.THUMBNAIL);
    }

    return source;
  }

  function showImagePreview() {
    dispatch(
      showImage({
        currentImage: item,
      })
    );
  }

  //group cover
  let cover = item?.coverGrid ? item?.coverGrid[item?.coverGrid.length - 1] : defaultHeatmapImage;
  cover = cover ? cover : defaultHeatmapImage;
  const processingStatuses = ['queued', 'predicting', 'processing'];
  return (
    <li key={item._id} className="relative">
      <div className="max-w-md rounded overflow-hidden shadow-lg">
        <IfElseConditional show={isGroup}>
          <Link to={`/dashboard/heatmaps-old/${item._id}/uploads`}>
            <img src={cover} alt={item.name} className={`object-cover h-52 w-full`} />
          </Link>
          <img
            src={getImage(item)}
            alt={item.name}
            className={`object-cover h-52 w-full cursor-pointer`}
            onClick={() => showImagePreview()}
          />
        </IfElseConditional>
        <div className="px-6 py-4">
          <div className="font-medium text-xl mb-2 flex">
            <div className="flex justify-between flex-col truncate">
              <Tooltip label={item.name}>
                <span className="font-semibold truncate text-base"> {item.name}</span>
              </Tooltip>
              <IfConditional show={!isGroup}>
                <div className="flex m-0 truncate">
                  <Text fz="sm" c="dimmed" fw={500} className="mr-1">
                    Preset:
                  </Text>
                  <Tooltip label={item.preset ? item.preset : '--'}>
                    <Text tt="capitalize" fz="sm" c="dimmed" className="truncate">
                      {item.preset ? item.preset : '--'}
                    </Text>
                  </Tooltip>
                </div>
              </IfConditional>
            </div>
            <div className="grow">
              <IfConditional show={!isGroup && processingStatuses.includes(item.status)}>
                <p className="block absolute bg-amber-200 top-2 left-2 text-sm font-medium rounded-sm text-black m-1 px-2">
                  Processing
                </p>
              </IfConditional>
              <IfConditional show={!isGroup && item.status === 'error'}>
                <p className="block absolute bg-red-200 top-2 left-2 text-sm font-medium rounded-sm text-black m-1 px-2">
                  Error
                </p>
              </IfConditional>
            </div>
            <div className="w-16 space-x-1 text-right items-center flex">
              <EditButton onClick={() => onEdit(item.name, item._id)} />
              <DeleteButton onClick={() => onDelete(item.name, item._id)} />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default HeatmapItem;
