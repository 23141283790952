import { Button, FileInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import SubpageContainer from 'components/containers/subpage.container';
import ShowAdmin from 'components/show-admin/show-admin';
import { handleUploadImageMultiple } from 'lib/helpers';
import { useNavigate, useParams } from 'react-router-dom';

export default function SplitTestUploadResult() {
  const params = useParams();
  const id = params?.id || '';
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      comment: '',
      files: [],
      _id: id,
      isAdmin: true,
    },
    validate: {
      comment: (value) => (value?.length < 10 ? 'Comment must have at least 10 characters' : null),
      files: (value) => (value?.length < 1 ? 'Upload at least one image' : null),
    },
  });
  const submit = async ({ files: uploadedFiles, comment, _id }) => {
    const formData = new FormData();
    const files: File[] = uploadedFiles;
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('name', files[0].name);
    formData.append('comment', comment);
    await handleUploadImageMultiple(
      formData,
      files,
      '/split-tests/admin/upload-result/' + _id,
      (res) => {
        if (res) {
          navigate(`/dashboard/split-tests`);
        }
      },
      true
    );
  };

  return (
    <ShowAdmin redirect={true}>
      <SubpageContainer title="Upload Result">
        <form onSubmit={form.onSubmit((values) => submit(values))} className="space-y-2">
          <FileInput {...form.getInputProps('files')} label="Upload files" placeholder="Upload files" multiple />
          <Textarea {...form.getInputProps('comment')} placeholder="Comment" label="Your comment" withAsterisk />
          <Button type="submit">Add Result</Button>
        </form>
      </SubpageContainer>
    </ShowAdmin>
  );
}
