/* This example requires Tailwind CSS v2.0+ */
import { PlusIcon } from '@heroicons/react/solid';
import FormButton from 'components/forms/form-button';
import { snakeToCapitalize } from 'lib/helpers';
import { singular } from 'pluralize';
import { ReactElement } from 'react';

export default function EmptyState({
  title: head = '',
  handleClick,
  addNew = true,
  colSpan = 6,
  noDataMsg = '',
  buttonLabel = '',
}): ReactElement {
  const title = snakeToCapitalize(noDataMsg ? noDataMsg : 'No ' + head);
  const btnLabel = buttonLabel ? buttonLabel : `New ${singular(head)}`;

  return (
    <tbody className="w-full">
      <tr className="w-full">
        <td colSpan={colSpan} className="">
          <div className="text-center py-16 w-full overflow-hidden border border-gray-200 sm:rounded-sm">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>

            {!noDataMsg && (
              <p className="mt-1 text-sm text-gray-500">{`Get started by creating a new ${singular(head)}.`}</p>
            )}
            <div className="mt-6">
              {addNew && (
                <FormButton
                  leadingIcon={<PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
                  buttonLabel={btnLabel}
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}
