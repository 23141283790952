import SubpageContainer from 'components/containers/subpage.container';
import { useForm } from '@mantine/form';
import { PasswordInput } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { changePassword, profile } from 'pages/users/settings/user-settings.thunks';
import { UserDataInterface } from 'pages/users/settings/setting.interface';
import FormSubmit from 'components/forms/form-submit';

function ChangePassword() {
  const { data: userProfile } = useQuery<UserDataInterface>(['user'], profile);
  const initialValues = {
    oldPassword: '',
    password: '',
    repeatPassword: '',
  };
  const form = useForm({
    initialValues: initialValues,
  });
  const passwordUpdateMutation = useMutation(async (values: { [key: string]: string | undefined }) => {
    const res = await changePassword(values);
    if (res) {
      form.setValues(initialValues);
    }
  });

  return (
    <SubpageContainer title="Change Password">
      <div className="p-6 grid grid-cols-1 lg:grid-cols-[60%,0] md:grid-cols-[80%,0] gap-4 bg-white rounded-md">
        <form
          className="space-y-6"
          onSubmit={form.onSubmit((values) => passwordUpdateMutation.mutate({ ...values, user: userProfile?._id }))}
        >
          <PasswordInput label="Old Password" placeholder="Old Password" {...form.getInputProps('oldPassword')} />
          <PasswordInput mt="sm" label="Password" placeholder="Password" {...form.getInputProps('password')} />
          <PasswordInput
            mt="sm"
            label="Confirm password"
            placeholder="Confirm password"
            {...form.getInputProps('repeatPassword')}
          />
          <FormSubmit label="Submit" showButtonOnly disabled={passwordUpdateMutation.isLoading} />
        </form>
      </div>
    </SubpageContainer>
  );
}

export default ChangePassword;
