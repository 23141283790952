import FormLabel from 'components/forms/form-label';
import React, { ReactElement } from 'react';

/**
 * Form Button - will show button only if label is specified
 * @param label - form label
 * @param buttonLabel - button label
 * @param leadingIcon - leading icon or component : <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
 * @param trailingIcon - trailing icon or component
 * @param buttonColor - color, check tailwind color
 * @param props - other props
 */
const FormButton = ({
  label = null,
  buttonLabel = '',
  leadingIcon = null as unknown,
  trailingIcon = null as unknown,
  buttonColor = 'red',
  classes = '',
  ...props
}): ReactElement => {
  const { radius = 'md', disabled } = props;
  const button = (
    <button
      type={props.type || 'button'}
      className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm 
                                font-medium rounded-${radius} text-white disabled:opacity-75
                                bg-${buttonColor}-600 hover:bg-${buttonColor}-700
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${buttonColor}-500 ${classes}`}
      {...props}
      disabled={disabled}
    >
      {leadingIcon}
      {buttonLabel}
      {trailingIcon}
    </button>
  );

  if (!label) return button;

  return (
    <>
      <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center ">
        <FormLabel label={label} />
        <div className="mt-1 sm:mt-0 sm:col-span-3">{button}</div>
      </div>
    </>
  );
};

export default FormButton;
