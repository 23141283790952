import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { ActionIcon } from '@mantine/core';
import { IconCloudDownload, IconPictureInPictureOff } from '@tabler/icons';
import ShowAdmin from 'components/show-admin/show-admin';
import If from 'components/conditionals/if.conditional';

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      style={{ ...style, display: 'block' }}
      className={`-right-11 !bg-red-600 rounded-full !text-white h-10 w-10 ${className}`}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      style={{ ...style, display: 'block' }}
      className={` -left-11 !bg-red-600 rounded-full !text-white h-10 w-10 ${className}`}
      onClick={onClick}
    />
  );
}

export default function CarouselComponent({ data, showImage }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  const slideContent = data.length !== 0 ? data : [...Array(5)];

  const slides = slideContent.map((item, index) => (
    <div className=" !flex justify-center relative" key={index}>
      <img
        src={data[index]?.images[1].image ? data[index]?.images[1].image : '/placeholder.jpeg'}
        className="object-cover pointer-events-none group-hover:opacity-75 rounded rounded-lg"
      />
      <div className="flex absolute p-2 bottom-0 right-2 pr-0">
        <ActionIcon
          color="red"
          variant="filled"
          className="mr-2"
          onClick={() => showImage(data[index]?.images[0].image)}
        >
          <IconPictureInPictureOff size="1rem" />
        </ActionIcon>
        <ShowAdmin>
          <ActionIcon
            color="red"
            variant="filled"
            className="mr-2"
            component="a"
            href={data[index]?.images[2].image}
            target="_blank"
          >
            <IconCloudDownload size="1rem" />
          </ActionIcon>
        </ShowAdmin>
      </div>
    </div>
  ));
  return (
    <>
      <If show={slideContent.length > 1}>
        <Slider {...settings} className="w-2/5">
          {slides}
        </Slider>
      </If>
      <If show={slideContent.length <= 1}>{slides}</If>
    </>
  );
}
