import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import React from 'react';
import { BrandsInterface } from 'interfaces/brands.interface';

function BrandsTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'name',
      render: (rowData: BrandsInterface) => {
        const id = rowData?._id;
        return (
          <div className="flex flex-row">
            <a href={`/dashboard/brands/${id}`} className="hover:underline">
              {rowData.name}
            </a>
          </div>
        );
      },
    },
    {
      key: 'label',
      render: (rowData: BrandsInterface) => {
        return <div className="flex flex-row">{rowData.label}</div>;
      },
    },
    {
      key: 'seller',
      render: (rowData: BrandsInterface) => {
        return <div className="flex flex-row">{rowData.seller}</div>;
      },
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default BrandsTableData;
