import ModalAlert from 'components/dialogs-old/modal.alert';
import { ImageSizes } from 'interfaces/resize-image.interface';
import { defaultHeatmapImage, getImageSize } from 'lib/helpers';
import { hideImage, selectHeatmap } from 'pages/users/heat-maps/heat-maps.slice';
import { HeatMapsPreview } from 'pages/users/heat-maps/images/heat-maps-preview';
import React, { useState } from 'react';
import 'react-before-after-slider-component/dist/build.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// todo, separate header into a new component

function HeatMapsDisplay() {
  const { currentImage } = useAppSelector(selectHeatmap);
  let heatmapTypes = [{ children: 'Original', value: 'original' }];

  const originalImage = getImageSize(currentImage?.inputFile, ImageSizes.RESIZED);
  const heatmapImage = getImageSize(currentImage?.outputFile, ImageSizes.RESIZED);

  // don't show other if there is heatmap is still in process
  if (heatmapImage !== defaultHeatmapImage) {
    heatmapTypes = [...heatmapTypes, { children: 'Heatmap', value: 'heatmap' }, { children: 'Dual', value: 'dual' }];
  }

  const [imageType, setImageType] = useState<string>(heatmapTypes[1]?.value);
  const dispatch = useAppDispatch();

  const nonDualImage = imageType === 'original' ? originalImage : heatmapImage;
  let src = nonDualImage;
  if (nonDualImage === defaultHeatmapImage) {
    src = getImageSize(currentImage?.inputFile, ImageSizes.RESIZED);
  }

  const closeModal = () => dispatch(hideImage());

  const isOpen = currentImage !== undefined;
  const wrapper =
    'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all';

  return (
    <ModalAlert
      open={isOpen}
      closeModal={closeModal}
      cancelButtonRef={closeModal}
      isCustomChild={true}
      customChildClass={wrapper}
    >
      <div className="w-screen bg-black h-screen">
        <HeatMapsPreview src={src} nonDualImage={src} imageType={imageType} setImageType={setImageType} />
      </div>
    </ModalAlert>
  );
}

export default HeatMapsDisplay;
