// todo, refactor!!
function Badge({ label, value }: { label: string; value: string }) {
  const colorObj = {
    asin: 'bg-cyan-500',
    sku: 'bg-gray-800',
    price: 'bg-blue-500',
  };

  const color = colorObj[label?.toLocaleLowerCase()] || (value === 'inactive' ? 'bg-red-700' : 'bg-blue-700');

  return (
    <>
      <div
        className={`inline-flex items-center px-2.5 py-1 rounded-md bg-cyan text-xs font-medium text-white ${color}`}
      >
        {`${label?.toUpperCase()} ${value}`?.toUpperCase()}
      </div>
    </>
  );
}

export default Badge;
