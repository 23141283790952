import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { addDays, format } from 'date-fns';
import { ReportsByDateInterface } from 'pages/users/analytics/analytics.interface';
import { RootState } from 'redux/store';

const initialState: ReportsByDateInterface = {
  endDate: +format(new Date(), 'yyyyMMdd'),
  startDate: +format(addDays(new Date(), -7), 'yyyyMMdd'),
  amazonAdsUserId: null,
  data: [],
};

export const reportsByDateSlice = createSlice({
  name: 'reportsByDate',
  initialState,
  reducers: {
    setReportsParams: (state: Draft<ReportsByDateInterface>, action: PayloadAction<ReportsByDateInterface>) => {
      return action.payload;
    },
  },
});

export const { setReportsParams } = reportsByDateSlice.actions;

export const selectReportsByDate = (state: RootState) => state.reportsByDate;

export const reportsByDateReducer = reportsByDateSlice.reducer;
