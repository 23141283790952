import React from 'react';
import { Modal, List, Text } from '@mantine/core';
function InstructionModal({ opened, close }) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title={<Text fw={700}>How A/B Tests Works:</Text>}
      size="xl"
      overlayColor={'gray'}
      overlayOpacity={0.5}
      overlayBlur={5}
    >
      <List listStyleType="disc" className="!list-decimal" type="ordered" spacing="sm">
        <List.Item>You upload images that you want analyzed and compared.</List.Item>
        <List.Item>
          You choose a campaign goal (for Amazon sellers, this would be "Clicks" for a main image and "Product Sales"
          for other images).
        </List.Item>
        <List.Item>Our system then analyzes the images within the context of the campaign goal.</List.Item>
        <List.Item>
          Based on human training data, the A.I. simulates eye movement, focus, and mouse activity to determine which
          elements of the images draw the most focus.
        </List.Item>
        <List.Item>
          Within 48 hours you will be presented with a detailed outline, with heat map visualization, explaining what
          elements within your images draw the most attention.
        </List.Item>
      </List>
    </Modal>
  );
}

export default InstructionModal;
