import { ReactNode } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { Link, Outlet } from 'react-router-dom';
import { singular } from 'pluralize';

import FormButton from 'components/forms/form-button';
import Alert from 'components/alerts/alert';
import ConfirmDialog from 'components/dialogs-old/confirm.dialog';
import FunnelButtonsWrapper from 'pages/users/funnels/deploy-buttons/funnel-buttons.wrapper';
import If from 'components/conditionals/if.conditional';

/**
 * Dashboard layout
 * @param title - page title
 * @param addUrl - Add resource link
 * @param children - other component children
 * @param showAdd - whether to show the add button or not
 * @param showActions - whether to show the actions buttons
 * @param deploy - deploy callback
 * @param redeploy - redeploy callback
 * @param custom - custom component
 */
function SubpageContainer({
  title,
  addUrl,
  children,
  showAdd = false,
  showAddString = '',
  showActions = false,
  deploy,
  redeploy,
  rightElement,
  ...props
}: {
  title: string;
  addUrl?: string;
  showAdd?: boolean;
  showAddString?: string;
  showActions?: boolean;
  deploy?: (e) => Promise<void>;
  redeploy?: (e) => Promise<void>;
  children: ReactNode;
  rightElement?: ReactNode;
}) {
  const addLabel = showAddString || `Add ${singular(title?.split(' ').slice(-1)[0])}`;
  const addLink = addUrl || window.location.href + '/new';
  return (
    <>
      <main className="flex-1 relative overflow-y-auto focus:outline-none max-w-full">
        <div className="py-6">
          <div className={`flex mx-auto px-4 sm:px-6  lg:px-8 ${title ? 'mb-3' : 'mb-0'}`}>
            <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900">{title}</h1>
            <div className="flex flex-grow justify-end">
              <If show={showAdd}>
                <Link to={addLink}>
                  <FormButton
                    buttonLabel={addLabel}
                    leadingIcon={<PlusCircleIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />}
                    {...props}
                  />
                </Link>
              </If>
              {rightElement}
            </div>
            <If show={showActions}>
              <FunnelButtonsWrapper deploy={deploy} redeploy={redeploy} />
            </If>
          </div>
          <div className="mx-auto px-4 sm:px-6  lg:px-8">
            <Outlet />
            <Alert />
            {children}
            <ConfirmDialog />
          </div>
        </div>
      </main>
    </>
  );
}

export default SubpageContainer;
