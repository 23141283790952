import React, { useRef } from 'react';
import HeatMapsStart from './heat-maps-start';
import HeatMapsBanner from './heat-maps-banner';
import HeatMapsGroupList from './heat-maps-group-list';
import ConfirmDialog from '../../../components/dialogs-old/confirm.dialog';
function NewHeatMaps() {
  const containerRef = useRef(null);
  return (
    <div className="mx-auto px-4 sm:px-6 py-6 overflow-auto flex flex-col h-full" ref={containerRef}>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <HeatMapsStart />
        </div>
        <div className="col-span-2">
          <HeatMapsBanner />
        </div>
      </div>
      <HeatMapsGroupList />
      <ConfirmDialog />
    </div>
  );
}

export default NewHeatMaps;
