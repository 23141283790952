import { clearAllData } from 'lib/cookie.helper';
import { selectUserData } from 'pages/users/user/user.slice';
import { useAppSelector } from 'redux/hooks';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoggedIn } = useAppSelector(selectUserData);
  if (isLoggedIn) {
    return children;
  } else {
    clearAllData();
    window.location.href = '/';

    return <></>;
  }
}
