export enum AmazonApiTypes {
  SP = 'sp',
  ADS = 'ads',
}
export interface AmazonInterface {
  type: AmazonApiTypes;
}

// export interface AmazonAccountInterface extends AmazonInterface {
//   adAccount: AmazonDataInterface;
// }

export interface AmazonLoginInterface extends AmazonInterface {
  region: string;
  country: string;
}

export type AmazonQueryParamsInterface = { [p: string]: string } | AmazonLoginRedirectInterface | AmazonTokenRedirect;

export interface AmazonLoginRedirectInterface {
  amazon_callback_uri: string;
  amazon_state: string;
}

export interface AmazonTokenRedirect {
  spapi_oauth_code: string;
  state: string;
  selling_partner_id: string;
}

export interface AmazonDataInterface {
  code: string;
  country: string;
  createdAt: string;
  name: string;
  nextCrawl: string;
  region: string;
  seller: string;
  state: string;
  status: string;
  updatedAt: string;
  user: string;
  adAccount?: AmazonDataInterface;
  _id: string;
  amazonUserId?: string;
}

export interface AmazonRenameUnlinkInterface {
  adAccount: AmazonDataInterface;
  sellerAccount?: AmazonDataInterface;
  sellerAccounts?: AmazonDataInterface[];
  type: AmazonApiTypes;
  unlink?: boolean;
}
