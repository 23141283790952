import React from 'react';
import { Grid, BackgroundImage, Button, Text, Space } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import InstructionModal from './abtest.instruction-modal';
function BannerComponent() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <InstructionModal opened={opened} close={close} />

      <BackgroundImage src="/image2.png" radius="md" className="!bg-right-top">
        <Grid m={0}>
          <Grid.Col span={7} p={{ lg: 35, sm: 20 }}>
            <Text size={25} fw={500} color="white">
              Unleash Stunning, Unforgettable Designs
            </Text>
            <Space h="sm" />
            <Text fz={{ lg: 'md', sm: 'sm' }} color="white">
              Ignite your creativity with mesmerizing, unforgettable designs! Tap into the magic of AI-powered design
              insights inspired by neuroscience. Watch as your ad creatives and designs transform to captivate your
              audience's attention and leave a lasting impression.
            </Text>
            <Button my={20} onClick={open}>
              See How it works
            </Button>
          </Grid.Col>
        </Grid>
      </BackgroundImage>
    </>
  );
}

export default BannerComponent;
