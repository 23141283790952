import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { FunnelInterface, ResponseFunnelsInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: ResponseFunnelsInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const funnelsSlice = createSlice({
  name: 'funnels',
  initialState,
  reducers: {
    setFunnels: (state: Draft<ResponseFunnelsInterface>, action: PayloadAction<ResponseFunnelsInterface>) => {
      return action.payload;
    },
    setFunnelsFilter: (state: Draft<ResponseFunnelsInterface>, action: PayloadAction<FunnelInterface[]>) => {
      const funnels = action.payload;
      return { ...initialState, docs: funnels };
    },
    updateFunnels: (state: Draft<ResponseFunnelsInterface>, action: PayloadAction<FunnelInterface>) => {
      const { docs: funnels } = state;
      const updatedFunnels = funnels.map((f: FunnelInterface) => (f._id === action.payload._id ? action.payload : f));
      return { ...state, docs: updatedFunnels, totalDocs: updatedFunnels?.length };
    },
    deleteFunnel: (state: Draft<ResponseFunnelsInterface>, action: PayloadAction<string>) => {
      const { docs: funnels } = state;
      const updatedFunnels = funnels.filter((f: FunnelInterface) => f._id !== action.payload);
      return { ...state, docs: updatedFunnels, totalDocs: updatedFunnels?.length };
    },
  },
});

export const { setFunnels, updateFunnels, deleteFunnel, setFunnelsFilter } = funnelsSlice.actions;

export const selectFunnels = (state: RootState) => state.funnels;

export const funnelsReducer = funnelsSlice.reducer;
