import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AmazonDataInterface } from 'pages/users/amazon/amazon.interface';

import { RootState } from 'redux/store';

const initialState: AmazonDataInterface[] = [];

/**
 * A redundant state like amazon
 */
export const amazonAdsSlice = createSlice({
  name: 'amazonAds',
  initialState,
  reducers: {
    setAmazonAdsAccounts: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<AmazonDataInterface[]>) => {
      return action.payload;
    },
    editAmazonAdsAccounts: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<AmazonDataInterface>) => {
      const updatedAccounts = state.map((a: AmazonDataInterface) =>
        a._id === action.payload._id ? action.payload : a
      );
      return updatedAccounts;
    },
    unlinkAmazonAdsAccount: (state: Draft<AmazonDataInterface[]>, action: PayloadAction<string>) => {
      const updatedAccounts = state.filter((a: AmazonDataInterface) => a._id !== action.payload);
      return updatedAccounts;
    },
  },
});

export const { setAmazonAdsAccounts, editAmazonAdsAccounts, unlinkAmazonAdsAccount } = amazonAdsSlice.actions;

export const selectAmazonAdsAccounts = (state: RootState) => state.amazonAds;

export const selectFirstAmazonAdsAccounts = (state: RootState) => state.amazonAds[0];

export const amazonAdsReducer = amazonAdsSlice.reducer;
