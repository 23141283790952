import SubpageContainer from 'components/containers/subpage.container';
import AmazonAccounts from 'pages/users/amazon/amazon-accounts';
import React from 'react';

const Amazon = () => {
  return (
    <>
      <SubpageContainer title="Amazon Accounts">
        <div className={`grid gap-4 grid-cols-1`}>
          <AmazonAccounts />
        </div>
      </SubpageContainer>
    </>
  );
};

export default Amazon;
