import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import IfElse from 'components/conditionals/if-else.conditional';
import AmazonRenameUnlink from 'pages/users/amazon/amazon-rename.unlink';
import { AmazonApiTypes } from 'pages/users/amazon/amazon.interface';

const AmazonAccountInfo = ({ sellerAccount, sellerAccounts }) => {
  const adAccount = sellerAccount?.adAccount;
  return (
    <>
      <div className="border-t border-gray-200 px-7">
        <div className="px-4 py-2 w-3/4">
          <div className="grid grid-cols-2">
            <p className="font-semibold">Code:</p>
            <p className="">{sellerAccount?.code}</p>
          </div>
          <div className="grid grid-cols-2">
            <p className="font-semibold">Seller ID:</p>
            <p className="">{sellerAccount?.seller}</p>
          </div>

          <div className="grid grid-cols-2 justify-center items-center">
            <p className="font-semibold">Ad Account:</p>
            <div className="flex gap-1 items-center">
              <IfElse show={Boolean(adAccount)}>
                <AmazonRenameUnlink
                  sellerAccounts={sellerAccounts}
                  sellerAccount={sellerAccount}
                  adAccount={adAccount}
                  type={AmazonApiTypes.ADS}
                />
                <div>---</div>
              </IfElse>
            </div>
          </div>
          <div className="grid grid-cols-2">
            <p className="font-semibold">Attribution Tag:</p>
            <div>
              <IfElse show={adAccount?.attributionTag}>
                <div className="flex text-sm font-bold text-gray-600">
                  <CheckCircleIcon className="h-5 w-5 text-green-600" /> Enabled
                </div>
                <div className="flex text-sm font-bold text-gray-600">
                  <XCircleIcon className="h-5 w-5 text-red-600" /> None
                </div>
              </IfElse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmazonAccountInfo;
