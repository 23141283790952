import { Tabs } from '@mantine/core';
import { IconAlphabetLatin, IconBlockquote, IconListSearch, IconTags } from '@tabler/icons';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apolloData } from 'pages/users/apollo/apollo.thunk';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { TabBadge } from 'pages/users/apollo/steps/listing/tab-badge';

export default function TabsBranding({ children }) {
  const navigate = useNavigate();
  const { title, bullets, setDescription } = listingContext();
  const { tabValue, id } = useParams();

  const { data } = useQuery(['apollo-data-description', id], {
    queryFn: () => apolloData(id as string, 'description'),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data?.description) {
      setDescription(data?.description);
    }
  }, [data]);

  return (
    <>
      <div>
        <Tabs
          defaultValue={tabValue}
          onTabChange={(value) => {
            navigate(`/dashboard/apollo/create-list/${value}/${id}`);
          }}
        >
          <Tabs.List>
            <Tabs.Tab
              value="title"
              icon={<IconAlphabetLatin size="0.8rem" />}
              rightSection={<TabBadge max={1} value={(title && 1) || 0} />}
            >
              Title
            </Tabs.Tab>
            <Tabs.Tab
              value="bullets"
              icon={<IconListSearch size="0.8rem" />}
              rightSection={<TabBadge max={5} value={bullets.length} />}
            >
              Bullets
            </Tabs.Tab>
            <Tabs.Tab value="description" icon={<IconBlockquote size="0.8rem" />}>
              Description
            </Tabs.Tab>
            <Tabs.Tab value="suggestion" icon={<IconBlockquote size="0.8rem" />}>
              Image Suggestions
            </Tabs.Tab>
            <Tabs.Tab value="tags" icon={<IconTags size="0.8rem" />}>
              Notes
            </Tabs.Tab>
          </Tabs.List>
          <div className="pt-5">{children}</div>
        </Tabs>
      </div>
    </>
  );
}
