import { adminCookieName, getToken } from 'lib/cookie.helper';
import { logout } from 'pages/users/user/user.slice';
import { store } from 'redux/store';
import { FormMethod, RequestInterface } from 'request/request.interface';
import { getApiUrl } from './request.helpers';
import {
  isRequestFinished,
  requestFailure,
  requestInProgress,
  resetRequest,
  saveSuccess,
  validationError,
} from './request.slice';

/**
 * Send a GET request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param saveMessage save message texts
 * @param isAdmin
 */
export const getFetch = ({ url, saveMessage = 'Saved', isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, saveMessage, httpMethod: FormMethod.GET, isAdmin });
};

/**
 * Send a GET request and will always HIDE dispatch "Saved" messages
 * @param url - url of the request
 */
export const getSilentFetch = ({ url, isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, httpMethod: FormMethod.GET, dispatchSaveMessage: false, isAdmin });
};
/**
 * Send a POST request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 * @param isAdmin
 */
export const postFetch = ({ url, data, saveMessage, isAdmin = false }): Promise<any> => {
  return fetchRequest({
    url,
    formData: data,
    httpMethod: FormMethod.POST,
    dispatchSaveMessage: true,
    saveMessage,
    isAdmin,
  });
};

/**
 * Send a POST request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 */
export const postSilentFetch = ({ url, data, isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, formData: data, httpMethod: FormMethod.POST, dispatchSaveMessage: false, isAdmin });
};

/**
 * Send a PUT request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 */
export const putFetch = ({ url, data, saveMessage = 'Saved', isAdmin = false }): Promise<any> => {
  return fetchRequest({
    url,
    formData: data,
    httpMethod: FormMethod.PUT,
    dispatchSaveMessage: true,
    saveMessage,
    isAdmin,
  });
};

/**
 * Send a PUT request and will always show dispatch "Saved" messages
 * @param url - url of the request
 * @param data - post body data
 * @param saveMessage save message texts
 */
export const putSilentFetch = ({ url, data, isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, formData: data, httpMethod: FormMethod.PUT, dispatchSaveMessage: false, isAdmin });
};

/**
 * Send a DELETE request and will always SHOW dispatch "Saved" messages
 * @param url - url of the request
 * @param saveMessage save message texts
 */
export const deleteFetch = ({ url, saveMessage = 'Saved', isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, httpMethod: FormMethod.DELETE, saveMessage, isAdmin });
};

/**
 * Send a DELETE request and will always show dispatch "Saved" messages
 * @param url - url of the request
 */
export const deleteSilentFetch = ({ url, isAdmin = false }): Promise<any> => {
  return fetchRequest({ url, httpMethod: FormMethod.DELETE, dispatchSaveMessage: false, isAdmin });
};

/**
 * wrapper for all requests, automated request in progress and failure state
 * DO NOT USE THIS DIRECTLY, USE THE ABSTRACTION METHODS ABOVE INSTEAD
 * @private
 * @param url - url of the request
 * @param data - post body data
 * @param httpMethod - http method
 * @param dispatchSaveMessage - display save message
 * @param saveMessage = save message texts, default is "Saved"
 */

/**
 * Code above will soon be deprecated
 * @param url
 * @param formData
 * @param httpMethod
 * @param dispatchSaveMessage
 * @param saveMessage
 */
export const fetchRequest = ({
  url,
  formData,
  httpMethod,
  dispatchSaveMessage,
  saveMessage,
  isAdmin,
}: RequestInterface): Promise<any> => {
  let data;
  let contentTypeJson: null | { 'Content-Type': string } = { 'Content-Type': 'application/json' };
  // upload , multi-part
  if ((formData as unknown) instanceof FormData) {
    data = formData;
    contentTypeJson = null;
  } else {
    data = formData ? JSON.stringify(formData) : null;
  }
  const method = httpMethod || (!data ? 'get' : 'post');
  const token = isAdmin ? getToken(adminCookieName) : getToken();

  store.dispatch(resetRequest());
  store.dispatch(requestInProgress());
  const apiUrl = getApiUrl(url);

  return fetch(apiUrl, {
    method,
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      ...contentTypeJson,
    },
    body: data,
  })
    .then((response) => {
      store.dispatch(isRequestFinished());
      if (response.status === 200) {
        if (dispatchSaveMessage && method != 'get') {
          store.dispatch(saveSuccess({ message: saveMessage || '' }));
        }
        return response.text().then((t) => {
          if (t === 'OK') {
            return null;
          } else {
            return JSON.parse(t);
          }
        });
      } else if (response.status === 422) {
        return response.json().then((a) => {
          store.dispatch(validationError({ message: a.message }));
        });
      } else if (response.status === 401) {
        store.dispatch(logout());
      } else {
        store.dispatch(requestFailure({ url: apiUrl, method }));
      }

      return Promise.reject();
    })
    .catch((err) => {
      store.dispatch(isRequestFinished());
      store.dispatch(requestFailure({ url: apiUrl, method }));
      return Promise.reject(err);
    });
};
