import funnelDetailTableData from 'pages/users/funnels/funnel-customers/funnel-details.data';
import orderDetailTableData from 'pages/users/funnels/funnel-customers/funnel-order-details.data';
import OrderDetail from 'pages/users/funnels/funnel-customers/funnel-order.detail';
import { selectPayoutModal } from 'pages/users/funnels/funnel-customers/payout/payout-modal.slice';
import { useAppSelector } from 'redux/hooks';

function FunnelOrderDetails() {
  const { customer } = useAppSelector(selectPayoutModal);

  if (!customer) return null;
  const { orderNumber, paypal, funnel, order } = customer;

  const productName = order?.item?.productName;
  const { total, currency } = order;

  const detailsData = orderDetailTableData({ ...order.item, total, currency, paypal, orderNumber });
  const funnelDetailsData = funnelDetailTableData({ ...funnel });

  return (
    <>
      <div className="p-6">{productName || '—'}</div>
      <div className="grid grid-cols-4 text-sm w-full td">
        {detailsData.map(({ header, value }) => (
          <OrderDetail header={header} value={value} />
        ))}
      </div>
      <div className="text-md text-center p-2 leading-6 font-bold text-white bg-red-600">FUNNEL DETAILS</div>
      <div className="grid grid-cols-4 text-sm w-full td">
        {funnelDetailsData.map(({ header, value }) => (
          <OrderDetail header={header} value={value} />
        ))}
      </div>
    </>
  );
}

export default FunnelOrderDetails;
