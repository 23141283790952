import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { SalesInterface } from 'pages/users/analytics/analytics.interface';
import { RootState } from 'redux/store';

const initialState: SalesInterface[] = [];
// no longer used as of now
export const salesByDateSlice = createSlice({
  name: 'salesByDate',
  initialState,
  reducers: {
    setSalesByDate: (state: Draft<SalesInterface[]>, action: PayloadAction<SalesInterface[]>) => {
      return action.payload;
    },
  },
});

export const { setSalesByDate } = salesByDateSlice.actions;

export const selectSalesByDate = (state: RootState) => state.salesByDate;

export const salesByDateReducer = salesByDateSlice.reducer;
