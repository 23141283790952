import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { Table } from '@mantine/core';
import { MTableHead } from 'components/mantine/tables/m-table-head';
import { MTableBody } from 'components/mantine/tables/m-table-body';

export function MTable({ data, columns }) {
  const [newData, setData] = useState([]);

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);

  const table = useReactTable({
    data: newData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table fontSize="sm" horizontalSpacing="xl" verticalSpacing="sm" striped withBorder>
      <MTableHead {...table} />
      <MTableBody {...table} />
    </Table>
  );
}
