import { Button, CloseButton, Badge, Text, LoadingOverlay, Tooltip, Pagination } from '@mantine/core';
import If from 'components/conditionals/if.conditional';
import ShowAdmin from 'components/show-admin/show-admin';
import { deleteModal } from 'hooks/deleteModal';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getCoverPhoto } from '../abtest.helpers';
import { removeSplitTest } from '../abtest.thunk';
import { SplitTestInterface } from '../abtest.interface';
import { useABTest } from '../abtest.hook';
import { friendlyDate } from '../../../../lib/formatting.helpers';
import { useDisclosure } from '@mantine/hooks';
import CustomLoader from './abtest.loader';
import IfElse from '../../../../components/conditionals/if-else.conditional';

export default function ABdataListComponent() {
  const navigate = useNavigate();
  const [deletedName, setDeletedName] = useState<any>(4);
  const [visible, { toggle }] = useDisclosure(false);
  const [activePage, setPage] = useState(1);
  const { abTestListData, reloadListData } = useABTest(true, activePage);
  const totalItems = abTestListData ? abTestListData?.docs.length : 0;
  const totalPages = Math.ceil(Number(abTestListData?.totalDocs) / Number(abTestListData?.limit));
  useEffect(() => {
    reloadListData();
  }, [activePage]);

  function goToResult(id) {
    navigate('/dashboard/abtests/results/' + id);
  }

  function goToUploadResult(id: string) {
    navigate('/dashboard/abtests/upload/output/' + id);
  }
  function goToViewUploads(id: string) {
    navigate('/dashboard/abtests/upload/' + id);
  }

  const onDelete = (file: SplitTestInterface) =>
    deleteModal(file.name, file._id, async () => {
      setDeletedName(file.name);
      toggle();
      await removeSplitTest(file._id);
      navigate(0);
    });
  return (
    <IfElse show={abTestListData !== undefined && abTestListData?.totalDocs !== 0}>
      <div className=" mt-8 flex-1 flex flex-col">
        <div className="text-right">
          <If show={totalItems !== 0}>
            <span className="text-sm  text-slate-500">
              Showing {abTestListData?.pagingCounter}-
              {Number(abTestListData?.pagingCounter) + Number(abTestListData?.docs.length) - 1} of{' '}
              {abTestListData?.totalDocs}
            </span>
          </If>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
          >
            <LoadingOverlay
              visible={visible}
              overlayBlur={5}
              loader={<CustomLoader textMessage={`Deleting ${deletedName}....`} />}
            />
            {abTestListData?.docs.map((file) => {
              const badgeColor = file.status === 'done' ? 'green' : 'yellow';
              const date: any = file.createdAt;
              const formattedCreatedDate = friendlyDate(new Date(date));
              const formattedGoal =
                file.goal === 'productSales' || file.goal === 'product_sales' ? 'Product Sales' : file.goal;
              return (
                <li key={file._id} className="relative rounded-md border border-gray-200 bg-white ">
                  <div className="absolute p-2">
                    <Badge color={badgeColor}>
                      <Text tt="uppercase" fz="sm" fw={500}>
                        {file.status}
                      </Text>
                    </Badge>
                  </div>
                  <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
                    <div className="absolute right-0 opacity-100 m-2 bg-white hover:bg-slate-400 rounded drop-shadow-md">
                      <CloseButton title="Close popover" size="md" iconSize={20} onClick={() => onDelete(file)} />
                    </div>

                    <img src={getCoverPhoto(file)} alt="" className="object-cover h-52 w-full pointer-events-none " />
                  </div>
                  <div className="flex justify-between p-3 flex-col">
                    <div className="flex justify-between flex-col ">
                      <div className="flex justify-between flex-col">
                        <Tooltip label={file.name}>
                          <span className="font-semibold truncate text-sm">{file.name}</span>
                        </Tooltip>

                        <div className="flex m-0">
                          <Text fz="sm" c="dimmed" fw={500} className="mr-1">
                            Goal:
                          </Text>
                          <Text tt="capitalize" fz="sm" c="dimmed">
                            {formattedGoal ? formattedGoal : '--'}
                          </Text>
                        </div>
                      </div>
                      <div className="flex m-0 mt-2">
                        <Text fz="xs" c="dimmed" fw={500} className="mr-1">
                          Uploaded on:
                        </Text>
                        <Text fz="xs" c="dimmed">
                          {formattedCreatedDate}
                        </Text>
                      </div>
                      <div className="flex m-0">
                        <Text fz="xs" c="dimmed" fw={500} className="mr-1">
                          Uploaded by:
                        </Text>
                        <Text fz="xs" c="dimmed">
                          {file?.userOwner?.firstName} {file?.userOwner?.lastName}
                        </Text>
                      </div>
                    </div>
                    <div className="flex justify-between flex-wrap mt-2">
                      <If show={file.status === 'done'}>
                        <Button onClick={() => goToResult(file._id)} size="xs" className="mt-1" color="teal">
                          View Result
                        </Button>
                      </If>
                      <Button onClick={() => goToViewUploads(file._id)} size="xs" className="mt-1" color="dark">
                        View Uploads
                      </Button>
                      <ShowAdmin>
                        <If show={file.status === 'processing'}>
                          <Button size="xs" onClick={() => goToUploadResult(file._id)} className="mt-1">
                            Upload Result
                          </Button>
                        </If>
                      </ShowAdmin>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="w-full mt-3">
            <Pagination page={activePage} onChange={setPage} total={totalPages} className="!justify-center" />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-3 flex-1 items-center  text-slate-500 bg-white rounded-lg">
        <Text fz="lg" fw={500}>
          No A/B tests added yet.
        </Text>
      </div>
    </IfElse>
  );
}
