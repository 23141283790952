import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ResponseFunnelsInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: ResponseFunnelsInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setFunnelCustomers: (state: Draft<ResponseFunnelsInterface>, action: PayloadAction<ResponseFunnelsInterface>) => {
      return action.payload;
    },
  },
});

export const { setFunnelCustomers } = customersSlice.actions;

export const selectFunnelCustomers = (state: RootState) => state.customers;

export const funnelCustomerReducer = customersSlice.reducer;
