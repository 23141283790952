// import { Switch } from "@headlessui/react"
import { SwitchHorizontalIcon } from '@heroicons/react/solid';

import FormButton from 'components/forms/form-button';
import { UsersInterface } from 'interfaces/users.interace';
import SwitchUserSearchBar from 'pages/admin/manage-users/switch-user/switch-user.search.bar';
import { selectSearchUser, setSearchUser } from 'pages/admin/manage-users/switch-user/switch-user.slice';
import { searchUsers, switchUser } from 'pages/admin/manage-users/switch-user/switch-user.thunks';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const SwitchUser = () => {
  const users = useAppSelector(selectSearchUser);
  const [selectedUser, setSelectedUser] = useState<UsersInterface>();
  const dispatch = useAppDispatch();

  const handleSearch = async (values) => {
    await searchUsers(values);
  };

  const handleSwitch = async () => {
    const res = await switchUser(selectedUser?._id);
    if (res) {
      window.location.href = process.env.REACT_APP_URL + '/tokenized/' + res.token || '';
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSearch}
        initialValues={{ key: 'email' }}
        render={({ values }) => {
          const handleAutoFill = () => {
            setSelectedUser({
              _id: '',
              email: '',
              firstName: '',
              lastName: '',
              level: '',
              gravatar: '',
              roles: [],
              createdAt: '',
              updatedAt: '',
              token: '',
            });
            dispatch(setSearchUser({ users: [] }));
          };

          return (
            <form onSubmit={handleSearch} className="w-full flex justify-start items-center">
              <div className="flex justify-center items-center sm:w-full lg:w-1/2 invisible md:visible">
                <div className="w-full grid grid-cols-1">
                  <div className="input-group flex w-full gap-1">
                    <SwitchUserSearchBar
                      searchApi={searchUsers}
                      formValues={values}
                      handleAutoFill={handleAutoFill}
                      autoFillValue={selectedUser?.email}
                      data={users}
                      setSelectedUser={setSelectedUser}
                    />
                    <FormButton
                      leadingIcon={<SwitchHorizontalIcon className="h-5 w-5 mr-1" />}
                      buttonLabel="Switch"
                      onClick={handleSwitch}
                      disabled={!selectedUser?.email}
                    />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default SwitchUser;
