import { SuccessInterface, UserInterface } from 'interfaces/user.interface';
import { getSilentFetch, postFetch, postSilentFetch } from 'request/request';

export const register = async (values) => {
  return postFetch(`/users/register/`, { ...values }, 'Please check your email for confirmation');
};

export const login = async (values): Promise<UserInterface> => {
  return postSilentFetch(`/users/login`, values);
};

export const forgot = async (values) => {
  return postFetch(
    `/users/forgot/`,
    values,
    'If your account exists, you will receive an email containing instructions on how to change your password.'
  );
};

export const resetVerify = async (email, token): Promise<SuccessInterface> => {
  return getSilentFetch(`/users/reset-password/${email}/${token}`);
};

export const resetPassword = async (values): Promise<SuccessInterface> => {
  return postFetch('/users/reset-password', values, 'Password successfully reset!');
};
