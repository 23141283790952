import Cropper, { ReactCropperElement } from 'react-cropper';
import React, { createRef, useState } from 'react';
import { Button, Image, Switch } from '@mantine/core';
import { abTestDefineElementsContext } from 'pages/users/ab-test/abtest-define-elements/abtest-define.context';
import If from 'components/conditionals/if.conditional';
import { IconScreenshot } from '@tabler/icons';
import 'cropperjs/dist/cropper.css';

const ABTestDefineCropper = () => {
  const { data, setElementItems, currentImageIndex, isTrace, setTrace } = abTestDefineElementsContext();
  const [showZone, setShowZone] = useState<boolean>(true);
  const cropperRef = createRef<ReactCropperElement>();
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const cropBox = cropperRef.current?.cropper.getCropBoxData();
      const { left, top, width, height } = cropBox;
      const index = data[currentImageIndex].currentItem;
      const currentElements = data[currentImageIndex].elements[index];
      currentElements.position.top = top;
      currentElements.position.left = left;
      currentElements.position.width = width;
      currentElements.position.height = height;
      currentElements.position.imageHeight = cropperRef.current?.cropper.crop().getImageData().height;
      currentElements.position.imageWidth = cropperRef.current?.cropper.crop().getImageData().width;
      currentElements.crop = cropperRef.current?.cropper.crop().getData();
      setElementItems(currentImageIndex, data[currentImageIndex].elements);
      setTrace(!isTrace);
    }
  };
  return (
    <div>
      <div className="flex flex-row my-4 items-center justify-between">
        <Button onClick={getCropData} className="w-30" leftIcon={<IconScreenshot size="1rem" />} disabled={!isTrace}>
          Save Element
        </Button>
        <Switch label="Show Zones" checked={showZone} color="red" onChange={() => setShowZone(!showZone)} />
      </div>
      <div className="relative">
        <Image src={data?.[currentImageIndex]?.imageSource} className={'w-full h-full object-cover'} />
        <If show={showZone}>
          {data?.[currentImageIndex]?.elements?.map((d, index: number) => {
            return (
              d.position.top === 0 || (
                <div
                  key={index}
                  className="absolute inset-0 flex items-center justify-center border-4 border-white text-white font-medium"
                  style={{
                    left: d.position.left,
                    top: d.position.top,
                    width: d.position.width,
                    height: d.position.height,
                  }}
                >
                  {d.text}
                </div>
              )
            );
          })}
        </If>
        <If show={isTrace}>
          <Cropper
            className="absolute inset-0"
            ref={cropperRef}
            style={{ height: '100%', width: '100%', opacity: 0.6, left: 0, top: 0 }}
            initialAspectRatio={1}
            src={data?.[currentImageIndex]?.imageSource}
            checkCrossOrigin={false}
            viewMode={1}
            zoomable={false}
            minCropBoxHeight={4}
            minCropBoxWidth={4}
            background={false}
            responsive={true}
            autoCropArea={0.4}
            checkOrientation={false}
            guides={true}
          />
        </If>
      </div>
    </div>
  );
};

export default ABTestDefineCropper;
