import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import CircularButtons from 'components/forms/form-circular.buttons';
import FormInput from 'components/forms/form-input';
import FormSelect from 'components/forms/form-select';
import FormSubmit from 'components/forms/form-submit';
import ShimmerWrapper from 'components/shimmer';
import RebateFunnelTabParent from 'pages/users/funnels/rebate/rebate-funnel-tab.parent';
import { selectFunnel } from 'pages/users/funnels/rebate/rebate.slice';
import RebateOptionsShimmer from 'pages/users/funnels/shimmers/rebate-options';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useAppSelector } from 'redux/hooks';

const searchFindByOptions = [
  { _id: 'search-find-by', name: "Search Find Buy with FallBack URL for if the buyer can't find the product" },
  { _id: 'fallback-url', name: 'Use only FallBack URL' },
];

const defaultKeyword = { keywords: '', fallbackUrl: '', weight: 1 };

function RebateFunnelRebateOptions() {
  return (
    <RebateFunnelTabParent
      render={({ isFetching, isSubmitting, push }) => {
        const funnel = useAppSelector(selectFunnel);
        const addKeyword = () => {
          push('keywords', defaultKeyword);
        };

        return (
          <ShimmerWrapper loading={isFetching} fallback={<RebateOptionsShimmer />}>
            <div className="grid grid-cols-2 gap-4 px-4">
              <FormSelect values={searchFindByOptions} name="searchFindBy" label="Search Find By" />
            </div>
            <div className="bg-gray-100 divide-y divide-dashed">
              <FieldArray name="keywords" value={funnel.keywords}>
                {({ fields }) =>
                  fields.map((keyword, index) => (
                    <div className="p-4" key={index}>
                      <div className="grid grid-cols-2 gap-4 ">
                        <FormInput disabled={isSubmitting} label="Keywords" name={`${keyword}.keywords`} />
                        <div className="flex gap-2">
                          <CircularButtons onClick={addKeyword} disabled={isSubmitting}>
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          </CircularButtons>
                          <CircularButtons onClick={() => fields.remove(index)}>
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          </CircularButtons>
                        </div>
                        <FormInput
                          label="Weight"
                          name={`${keyword}.weight`}
                          type="number"
                          disabled={isSubmitting}
                          value={`${keyword}.weight`}
                          defaultValue={`${keyword}.weight`}
                        />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
            <FormSubmit label="Update Rebate" col={8} span={2} disabled={isSubmitting} />
          </ShimmerWrapper>
        );
      }}
    />
  );
}

export default RebateFunnelRebateOptions;
