interface DevUrlsInterface {
  service: string;
  url: string;
}

/**
 * Dev use only, if you want to mix and match dev and testing server
 */
const devUrls: DevUrlsInterface[] = [
  { service: 'amazon', url: 'http://localhost:8081' },
  { service: 'funnels', url: 'http://localhost:8082' },
  { service: 'users', url: 'http://localhost:8083' },
  { service: 'magic-links', url: 'http://localhost:8084' },
  { service: 'rebates-customer', url: 'http://localhost:8085' },
  { service: 'uploads', url: 'http://localhost:8087' },
  { service: 'heatmap', url: 'http://localhost:8088' },
  { service: 'credits', url: 'http://localhost:8089' },
  { service: 'superlinks', url: 'http://localhost:8090' },
  { service: 'ads', url: 'http://localhost:8091' },
  { service: 'teams', url: 'http://localhost:8092' },
  { service: 'split-tests', url: 'http://localhost:8093' },
  { service: 'apollo', url: 'http://localhost:8094' },
  { service: 'brand-analytics', url: 'http://localhost:8072' },
  { service: 'sqp', url: 'http://localhost:8058' },
];

/**
 * Get API URL, for dev use, add another service in devUrls
 * @param urlName : string - url where the first url segment is the service name
 * eg: /magic-links/page => service = magic-links
 */
export function getApiUrl(urlName: string): string {
  const productionUrl = process.env.REACT_APP_API + urlName;
  const apiService = urlName.split('/')[1];
  const devUrl = devUrls.find(({ service }) => service === apiService)?.url;
  if (process.env.REACT_APP_USE_DEV) {
    return devUrl ? devUrl + urlName.replace(apiService + '/', '') : productionUrl;
  }
  return productionUrl;
}
