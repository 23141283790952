import { Button, Center, Checkbox, Text } from '@mantine/core';
import TabsBranding from 'pages/users/apollo/steps/listing/tabs';
import React from 'react';
import If from 'components/conditionals/if.conditional';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { SpinnerText } from 'components/shimmer/spinner-text';
import { useApolloResult } from 'pages/users/apollo/steps/apollo.result.hooks';
import IfElse from 'components/conditionals/if-else.conditional';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';

export default function Bullets() {
  const { bullets: selectedBullets, setSelectedBullets } = listingContext();
  const { data, isLoading, bullets, createBulletsVariant } = useApolloResult('bullets');
  const { id } = useParams();
  const navigate = useNavigate();
  const isDisabled = (choice: string) => {
    if (selectedBullets.find((x: string) => x === choice)) {
      return false;
    }
    return selectedBullets.length === 5;
  };
  const status = data?.prompt.postGeneration.bullets.status ?? 'pending';

  const navigateToDescriptions = () => {
    navigate('/dashboard/apollo/create-list/description/' + id);
  };
  const navigateToTitle = () => {
    navigate('/dashboard/apollo/create-list/title/' + id);
  };
  return (
    <>
      <TabsBranding>
        <Text c="dimmed" fz="md" className="mb-2">
          Choose 5 bullets
        </Text>

        <Checkbox.Group value={selectedBullets} withAsterisk onChange={setSelectedBullets}>
          {bullets?.map((choice, index: number) => {
            return (
              <div key={index} className="flex items-center my-1 w-full">
                <Checkbox value={choice} label={choice} disabled={isDisabled(choice)} />
              </div>
            );
          })}
        </Checkbox.Group>

        <IfElse show={isLoading || !['success', 'error'].includes(status)}>
          <SpinnerText text={'🤖 AI is Creating New Variants. Please wait... 🚀'} />
          <Center inline className="mt-8">
            <IfElse show={!isLoading && status === 'error'}>
              <Button onClick={createBulletsVariant}>Retry</Button>
              <Button
                disabled={isLoading || status === 'pending' || data?.prompt.postGeneration.bullets.addMore === 0}
                onClick={createBulletsVariant}
              >
                Add More
              </Button>
            </IfElse>
            <If show={data?.prompt.postGeneration.bullets.addMore !== 0}>
              <IfElse show={!isLoading && status === 'error'}>
                <Text c="dimmed" fz="sm" className="ml-4" color="red">
                  There was an error generating new variants.
                </Text>
                <Text c="dimmed" fz="sm" className="ml-4">
                  AI will create 5 new variants
                </Text>
              </IfElse>
            </If>
          </Center>
        </IfElse>
        <div className="flex justify-between mt-5">
          <Button onClick={navigateToTitle} leftIcon={<ArrowLeftIcon className="w-5 h-5 " />} color="dark">
            Previous
          </Button>
          <Button onClick={navigateToDescriptions} rightIcon={<ArrowRightIcon className="w-5 h-5 " />} color="dark">
            Next
          </Button>
        </div>
      </TabsBranding>
    </>
  );
}
