import StatusBadge from 'components/badges/status.badge';
import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import { ProductInterface } from 'interfaces/rebates.interface';
import { Link } from 'react-router-dom';

function productsTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'name',
      render: (rowData: ProductInterface) => {
        return (
          <div className="w-full 2xl:w-full overflow-hiden py-2">
            <Link to={rowData._id} className="w-20 cursor-pointer hover:text-red-600">
              {rowData.name}
            </Link>
          </div>
        );
      },
    },
    {
      key: 'price',
      render: (rowData: ProductInterface) => {
        const { price } = rowData;
        return <div className="min-w-max">{`$ ${price}`}</div>;
      },
    },
    'asin1',
    {
      key: 'sellerSku',
      render: (rowData: ProductInterface) => {
        const { sellerSku } = rowData;
        return <div className="min-w-max">{sellerSku}</div>;
      },
    },
    {
      key: 'status',
      render: (rowData: ProductInterface) => {
        const { status } = rowData;
        return <StatusBadge text={status} />;
      },
    },
  ];

  return tableColumns(columnData);
}

export default productsTableData;
