import { unlinkAmazonAdsAccount } from 'pages/users/amazon/amazon-ads.slice';
import { deleteFunnel } from 'pages/users/funnels/funnels.slice';
import { deleteGroup } from 'pages/users/heatmap/groups/heatmap-groups.slice';
import { deleteHeatmap } from 'pages/users/heatmap/heat-map-list.slice';
import { deleteMagicLink } from 'pages/users/magic-links/magic-link.slice';
import { deleteUser } from 'pages/admin/manage-users/manage-users.slice';
import { store } from 'redux/store';
import { deleteFetch } from 'request/request';
import { openDialog } from './confirm-dialog.slice';
import { unlinkAmazonAccount } from 'pages/users/amazon/amazon.slice';
import { deleteSuperLink } from 'components/super-links/super-links.slice';
import { deleteSellerAccount } from '../../pages/admin/sqp/seller-accounts/seller-accounts.slice';
import { deleteBrands } from '../../pages/admin/sqp/brands/brands.slice';
import { deleteAccounts } from '../../pages/admin/sqp/accounts/accounts.slice';

const deleteItemAction: any = (url, id) => {
  switch (url) {
    case '/magic-links/':
      return deleteMagicLink(id);
    case '/funnels/':
      return deleteFunnel(id);
    case '/heatmap/heatmaps/groups/':
      return deleteGroup(id);
    case '/heatmap/heatmaps/images/':
      return deleteHeatmap(id);
    case '/users/superadmin/':
      return deleteUser(id);
    case '/amazon/accounts/connected-accounts/':
      return unlinkAmazonAccount(id);
    case '/ads/accounts/connected-accounts/':
      return unlinkAmazonAdsAccount(id);
    case '/superlinks/':
      return deleteSuperLink(id);
    case '/teams/members/':
      return deleteSuperLink(id);
    case '/teams/admin/member/':
      return deleteUser(id);
    case '/sqp/admin/seller-accounts/':
      return deleteSellerAccount(id);
    case '/sqp/admin/brands/':
      return deleteBrands(id);
    case '/sqp/admin/accounts/':
      return deleteAccounts(id);
    default:
      null;
  }
};

export const deleteItem = async (url, id: string | null) => {
  const res = await deleteFetch(url + id, 'Deleted Successfully');

  if (res) {
    store.dispatch(deleteItemAction(url, id));
  }
  store.dispatch(openDialog({ isOpen: false, deleteUrl: null }));

  //  return res;
};
