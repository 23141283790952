import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

/**
 * Opens a modal, modal show/hide must be controlled via dispatch
 * @param open
 * @param isCustomChild - if you want a bare child element like fullscreen, set this to true
 * @param customChildClass
 * @param closeModal - function to close the modal eg: ` const closeModal = () => store.dispatch(myAction())`
 * @param children
 * @param cancelButtonRef
 * @constructor
 */
export default function ModalAlert({
  open = false,
  isCustomChild = false,
  customChildClass = '',
  closeModal,
  children,
  cancelButtonRef,
}) {
  // ifl else component doesn't work with transition
  // we need to pass a div not a fragment since transition doesnt pass refs to it's children
  const child = isCustomChild ? (
    <div className={customChildClass}>{children}</div>
  ) : (
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-7xl">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">{children}</div>
      </div>
    </div>
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {child}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
