import { LinkIcon } from '@heroicons/react/outline';

/**
 * Form Input with Addon
 * @param link - link to navigate to
 */
const FormInputAddon = ({ link }: { link: string }) => {
  return (
    <div className="mt-1 grid grid-cols-3 rounded-md shadow-sm w-full">
      <input
        type="text"
        name="link"
        id="link"
        value={link || ''}
        readOnly
        className="block w-full col-span-2 p-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
      />
      <a
        target="_blank"
        href={link}
        className="col-span-1 inline-flex justify-center hover:text-blue-400 items-center rounded-r-md border border-l-0 p-1 border-gray-300 bg-gray-50 text-gray-500"
      >
        <LinkIcon className="h-4 w-4" />
        Link
      </a>
    </div>
  );
};

export default FormInputAddon;
