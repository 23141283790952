export enum ImageStatus {
  DONE = 'done',
  PROCESSING = 'processing',
  PENDING = 'pending',
  ERROR = 'error',
}

export enum ImageSizes {
  THUMBNAIL = 'thumbnail',
  RESIZED = 'resized',
  ORIGINAL = 'original',
}

export interface ResizedImage {
  image: string;
  width: ImageSizes;
  _id: string;
}
