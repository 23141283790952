import { ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';
import { FormSuffix } from './form.suffix';

/**
 *
 * @param label - input label
 * @param labelSpan - labels column span
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param gap - column gap
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param classes - additional classes
 * @param props - other input props
 * @param {boolean} hasSuffix - if input has suffix
 * @param {object} suffix - input suffix
 * @param {string} prefix - input prefix
 * @param {number} colSpan - label grid col, see tailwind col
 * @constructor
 */
const FormWithInput = ({
  col = 4,
  label = '',
  name,
  gap = 4,
  span = 3,
  type = 'text',
  classes = '',
  suffix = '',
  prefix = '',
  hasSuffix = false,
  colSpan = null,
  ...props
}): ReactElement => {
  // const { isLoading } = useSelector((state: any) => state.requestReducers);
  const className = `block w-full shadow-sm focus:ring-indigo-500 
  focus:border-indigo-500 ${hasSuffix && 'px-6'} sm:max-w-s sm:text-sm 
  border-gray-300 rounded-md ${classes}`;

  const field = (
    <FormSuffix hasSuffix={hasSuffix} suffix={suffix} prefix={prefix}>
      <Field
        autoComplete="off"
        type={type}
        name={name}
        id={name}
        component="input"
        className={className}
        placeholder={label}
        {...props}
      />
    </FormSuffix>
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} sm:items-center col-span-${colSpan}`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormWithInput;
