import { PromptTemplateProvider } from 'pages/admin/prompts/prompt.context';
import React from 'react';
import { PromptSystem } from 'pages/admin/prompts/tabs/prompt.system';
import { PromptTitles } from 'pages/admin/prompts/tabs/prompt.titles';
import { PromptBullets } from 'pages/admin/prompts/tabs/prompt.bullets';
import { PromptDescription } from 'pages/admin/prompts/tabs/prompt.description';
import { usePromptSetup } from 'pages/admin/prompts/setup/prompt-setup.hooks';
import { useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons';
import { PromptSuggestion } from '../tabs/prompt.suggestion';

const tabs = {
  system: PromptSystem,
  title: PromptTitles,
  bullets: PromptBullets,
  description: PromptDescription,
  suggestion: PromptSuggestion,
};

const PromptSetup = () => {
  const { tabValue } = useParams();
  const { context, update } = usePromptSetup();
  const TabPanel = tabs[tabValue || 'title'];

  return (
    <>
      <PromptTemplateProvider data={{ ...context }}>
        <TabPanel />
      </PromptTemplateProvider>
      <Button className="my-2" onClick={update} leftIcon={<IconDeviceFloppy size="1rem" />}>
        Save Apollo Prompts
      </Button>
    </>
  );
};

export default PromptSetup;
