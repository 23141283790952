import SelectProductPreview from 'pages/users/funnels/select-product/select-product.preview';
import { openModal } from 'pages/users/funnels/select-product/select-product.slice';
import ModalSelectProduct from 'pages/users/funnels/select-product/select-products';
import React from 'react';
import { store } from 'redux/store';

function SelectProductForm({ product, setProduct }) {
  const selectProductLabel = product ? 'Select a Different Product' : 'Choose Product';
  const onClickAsin = () => {
    store.dispatch(openModal({ isOpen: true, sellerAccount: '1' }));
  };

  return (
    <>
      <ModalSelectProduct setProduct={setProduct} />
      <div>
        <button
          type="button"
          onClick={onClickAsin}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                                font-medium rounded-md text-white
                                bg-pink-600 hover:bg-pink-700
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
        >
          {selectProductLabel}
        </button>
        <SelectProductPreview product={product} />
      </div>
    </>
  );
}

export default SelectProductForm;
