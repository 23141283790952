import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { SearchchUserInterface } from 'interfaces/users.interace';
import { RootState } from 'redux/store';

const initialState: SearchchUserInterface = {
  users: [],
};

export const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState,
  reducers: {
    setSearchUser: (state: Draft<SearchchUserInterface>, action: PayloadAction<SearchchUserInterface>) => {
      return action.payload;
    },
  },
});

export const { setSearchUser } = searchUserSlice.actions;

export const selectSearchUser = (state: RootState) => state.searchUser;

export const searchUserReducer = searchUserSlice.reducer;
