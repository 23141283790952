import { UserInterface, UserDataInterface } from 'interfaces/user.interface';
import { getSilentFetch, postFetch } from 'request/request';

export const profile = async (): Promise<UserDataInterface> => {
  return await getSilentFetch('/users/profile');
};

export const saveProfile = async (values): Promise<UserInterface> => {
  return await postFetch('/users/profile', values, 'Profile saved Successfully!');
};

export const changePassword = async (values): Promise<UserInterface> => {
  return await postFetch('/users/profile/change-password', values, 'Password Changed successfully!');
};
