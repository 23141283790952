import { selectUserData } from 'pages/users/user/user.slice';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
/**
 * Show the component if the user is an admin, usually when isAdminMimickingUser: true
 * @param children - react child
 * @constructor
 */
export default function ShowAdmin({ children, redirect = false }: { children: ReactNode; redirect?: boolean }) {
  const userData = useAppSelector(selectUserData);
  if (userData.isAdminMimickingUser) {
    return <>{children}</>;
  } else {
    if (redirect) {
      return <Navigate to="/dashboard/404" />;
    } else {
      return null;
    }
  }
}
