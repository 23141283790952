import { Input } from '@mantine/core';
import React, { ReactElement } from 'react';

const MFormInput = ({ label, required = false, ...props }: { label: string; required?: boolean }): ReactElement => {
  return (
    <div className="sm:grid sm:grid-cols-7 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 text-end">
        {label} <span className={`text-red-500 ${!required && 'hidden'}`}>*</span>
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-6">
        <Input {...props} required={required} className="w-1/2" />
      </div>
    </div>
  );
};

export default MFormInput;
