import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { DeleteItemInterface } from 'interfaces/delete-dialog.interface';
import { RootState } from 'redux/store';

const initialState: DeleteItemInterface = {
  isOpen: false,
  deleteId: null,
  deleteUrl: null,
  confirmText: '',
  customProps: null,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state: Draft<DeleteItemInterface>, action: PayloadAction<DeleteItemInterface>) => {
      state.isOpen = action.payload.isOpen;
      state.deleteUrl = action.payload.deleteUrl;
      (state.deleteId = action.payload.deleteId), (state.items = action.payload.items);
      state.confirmText = action.payload.confirmText;
      state.customProps = action.payload.customProps;
    },
  },
});

export const { openDialog } = dialogSlice.actions;
export const selectDeleteDialog = (state: RootState) => state.dialog;
export const deleteDialogueReducer = dialogSlice.reducer;
