import { renderLabels } from 'lib/render-labels';
import React from 'react';

import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

export default SiteLabelRender;

function SiteLabelRender({ defaultLabels, label }) {
  const mergedLabels = defaultLabels[label];
  const { isLoading } = useAppSelector(selectRequest);

  return (
    <>
      {renderLabels(mergedLabels, isLoading).map((c: any, i) => (
        <div className="px-4" key={c?.key + i}>
          {c?.component}
        </div>
      ))}
    </>
  );
}
