import EmptyState from 'components/common/empty-state';
import IfElse from 'components/conditionals/if-else.conditional';
import RequestContainer from 'components/containers/request.container';
import SubpageContainerCustom from 'components/containers/subpage-container.custom';
import SubpageTaskbar from 'components/containers/subpage-container.taskbar';

import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TablePagination from 'components/tables/table.pagination';
import useFetch from 'hooks/useFetch';
import { selectAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import { amazonAccounts } from 'pages/users/amazon/amazon.thunks';
import { selectFunnels } from 'pages/users/funnels/funnels.slice';
import funnelsTableData from 'pages/users/funnels/funnels.tabledata';
import { loadFunnels } from 'pages/users/funnels/funnels.thunks';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export const headers = [
  'Name',
  'Units Sold',
  'Sales',
  'Add to Cart',
  'Views',
  'Customers',
  'Url',
  'Rebate Counter',
  'Account',
  'Status',
  'Action',
  '',
];

function Rebate() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isFetching } = useFetch('funnels' + currentPage, () => loadFunnels({ page: currentPage }));
  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectFunnels);
  const navigate = useNavigate();

  const columns = funnelsTableData();
  const colSpan = headers.length;

  useFetch('amazon-accounts', () => amazonAccounts());
  const accounts = useAppSelector(selectAmazonAccounts);

  const title = 'Funnels';

  return (
    <>
      <IfElse show={accounts.length === 0}>
        <SubpageContainer title={title}>
          <table className="w-full">
            <EmptyState
              buttonLabel="Login with Amazon SP"
              handleClick={() => navigate('/dashboard/amazon')}
              noDataMsg="You need to link your account before you can create a Funnel, Click the button below to link your Amazon seller account!"
            />
          </table>
        </SubpageContainer>
        <SubpageContainerCustom
          title={title}
          custom={
            <SubpageTaskbar addUrl="new" title={title}>
              <></>
            </SubpageTaskbar>
          }
        >
          <Table headers={headers}>
            <RequestContainer
              loading={isFetching}
              fallback={<TableShimmer headers={headers} span={colSpan} />}
              colSpan={colSpan}
              data={docs}
            >
              <TableBody data={docs} columns={columns} deleteUrl="/funnels/" />
            </RequestContainer>
          </Table>
          <TablePagination
            setCurrentPage={setCurrentPage}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            nextPage={nextPage}
            page={page}
            pagingCounter={pagingCounter}
            prevPage={prevPage}
            totalPages={totalPages}
            docs={docs?.length}
          />
        </SubpageContainerCustom>
      </IfElse>
    </>
  );
}

export default Rebate;
