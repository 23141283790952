import Steps from 'pages/users/signup/steps';
import { ReactNode } from 'react';

export default function PaymentForm({ children }: { children: ReactNode }) {
  return (
    <div className="lg:w-1/2 bg-white flex items-center justify-center lg:min-h-screen pt-6 md:pt-12 px-4 xl:px-0">
      <div className="w-11/12 xl:w-1/2 h-full">
        <div className="h-full" id="loginForm">
          <div className="flex flex-col items-center justify-between h-full w-full">
            <div>
              <img className="mx-auto md:h-24 w-auto" src="/logo-white.png" alt="Signalytics" />
            </div>
            <div className="w-full md:py-8 lg:py-0">
              <div className="">
                <Steps step={2} />
              </div>
              <h2 className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold xl:leading-8 text-gray-900 pt-6">
                Payment Details
              </h2>
              <p className="text-rose-500 text-xs text-center capitalize pt-4"></p>

              <div>{children}</div>
            </div>
            <div className="pb-6 md:pb-12 mt-4">
              <p className="text-xs sm:text-sm font-medium leading-4 2xl:leading-6 text-center text-gray-500">
                By signing up, you agree to our&nbsp;
                <span className="text-rose-600 underline cursor-pointer font-semibold">Terms Of Use</span>
                &nbsp;and&nbsp;
                <span className="text-rose-600 underline cursor-pointer font-semibold">Privacy Policy</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
