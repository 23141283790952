import SubpageContainer from 'components/containers/subpage.container';
import React, { ReactElement } from 'react';
import { Button, Card } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { IconSettings, IconWand } from '@tabler/icons';
import IfElse from 'components/conditionals/if-else.conditional';
import PromptSetup from 'pages/admin/prompts/setup/prompt.setup';
import PromptOptimizer from 'pages/admin/prompts/optimizer/prompt.optimizer';

export const Prompts = (): ReactElement => {
  const navigate = useNavigate();
  const { type = 'setup' } = useParams();

  const nextStep = () => {
    if (type === 'setup') {
      navigate('/dashboard/prompts/optimizer/title');
    } else {
      navigate('/dashboard/prompts/setup/title');
    }
  };

  return (
    <SubpageContainer
      title={type === 'setup' ? 'Apollo Prompts' : 'Prompt Optimizer'}
      rightElement={
        <>
          <IfElse show={type === 'setup'}>
            <Button onClick={nextStep} className="mr-2" color={'dark'} leftIcon={<IconWand size="1rem" />}>
              Prompts Optimizer
            </Button>
            <Button onClick={nextStep} className="mr-2" color={'dark'} leftIcon={<IconSettings size="1rem" />}>
              Apollo Prompts
            </Button>
          </IfElse>
        </>
      }
    >
      <Card>
        <IfElse show={type === 'setup'}>
          <PromptSetup />
          <PromptOptimizer />
        </IfElse>
      </Card>
    </SubpageContainer>
  );
};
