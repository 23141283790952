import { PackageInterface, PlanDetailsInterface } from 'pages/users/subscription/plans/plans.interface';
import { getCredits, getPlans } from 'pages/users/subscription/plans/plans.thunks';
import { useCallback, useEffect, useState } from 'react';

export function usePlans(currentPlan: string): PackageInterface[] {
  const [allPlans, setPlans] = useState<PackageInterface[]>([]);

  const getPayment = useCallback(async () => {
    const data = await getPlans();
    const plans: PackageInterface[] = [];
    for (const plan in data) {
      const currentPlan = { plan, ...data[plan] };
      plans.push(currentPlan);
    }

    setPlans(plans);
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, [currentPlan]);

  return allPlans;
}

export function usePlanDetails(): PlanDetailsInterface[] {
  const [planDetails, setPlanDetails] = useState<PlanDetailsInterface[]>([]);

  const getPayment = useCallback(async () => {
    const data = await getCredits();
    setPlanDetails(data);
  }, []);

  useEffect(() => {
    getPayment().catch(() => Promise.reject());
  }, []);

  return planDetails;
}
