import { ComponentPropsWithoutRef, MouseEventHandler, ReactNode } from 'react';

export default function Button({
  color = 'red',
  type = 'button',
  className = '',
  onClick,
  children,
  ...props
}: {
  color?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  type?: 'submit' | 'reset' | 'button' | undefined;
} & ComponentPropsWithoutRef<'button'>) {
  const classes =
    className ||
    `
        inline-flex disabled:opacity-50 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500
        `;
  /// todo update heatmap button
  // className for heatmap button
  // inline-flex items-center p-1 border border-transparent
  //         shadow-sm text-white bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2
  //         focus:ring-offset-2 focus:ring-${color}-500 ${className} `}
  return (
    <>
      <button onClick={onClick} type={type} className={classes} {...props}>
        {children}
      </button>
    </>
  );
}
