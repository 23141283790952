import AlertSuccess from 'components/alerts/success.alert';
import FormColorsMenu from 'components/forms/form-colors';
import FormHidden from 'components/forms/form-hidden';
import FormInput from 'components/forms/form-input';
import FormLabel from 'components/forms/form-label';
import FormSelect from 'components/forms/form-select';
import FormSubmit from 'components/forms/form-submit';
import FormTextarea from 'components/forms/form-textarea';
import FormUpload from 'components/forms/form-upload';
import FormWebsite from 'components/forms/form-website';
import ShimmerWrapper from 'components/shimmer';
import useFetch from 'hooks/useFetch';
import { handleUploadImage, snakeToCapitalize } from 'lib/helpers';
import { checkAvailability, getFunnelTemplates } from 'pages/users/funnels/funnels.thunks';
import RebateFunnelTabParent from 'pages/users/funnels/rebate/rebate-funnel-tab.parent';
import { selectRebateSite, setNotify } from 'pages/users/funnels/rebate/tabs/rebate-funnel-site-slice';
import FunnelSiteShimmer from 'pages/users/funnels/shimmers/funnel-site';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

function RebateFunnelSite() {
  const { id } = useParams();
  const { notify } = useAppSelector(selectRebateSite);
  const dispatch = useAppDispatch();

  const { data: siteTemplates } = useFetch('funnel-templates', () => getFunnelTemplates());

  const onSubmit = async () => {
    const subdomain = document.querySelector('#site\\.url')?.getAttribute('value');
    await checkAvailability(id, { site: { url: subdomain } });
  };

  const closeNotification = () => {
    dispatch(setNotify(false));
  };

  useEffect(() => {
    if (notify) {
      setTimeout(() => {
        closeNotification();
      }, 3000);
    }
  }, [notify]);

  const message = 'The URL has been copied to your clipboard!';

  return (
    <>
      <RebateFunnelTabParent
        render={({ funnel, isFetching, isSubmitting }) => {
          const isDeployed = funnel?.isDeployed;

          const props = isDeployed ? { colSpan: 4 } : {};
          const { themes } = funnel?.site?.brands || { themes: [] };
          const subdomainClasses = isDeployed
            ? 'bg-gray-200 border-0 focus:border-0 focus:ring-0 cursor-not-allowed'
            : '';

          const handleLogoUpload = async (e, form) => {
            const formData = new FormData();
            const file: File = e.target.files[0];
            formData.append('file', file, file.name);
            handleUploadImage(formData, file, '/uploads/user-image/single', (uploadedData) => {
              if (uploadedData.Location) {
                form.change('site.brands.logo', uploadedData.Location);
              }
            });
          };

          return (
            <ShimmerWrapper loading={isFetching} fallback={<FunnelSiteShimmer />}>
              {notify && <AlertSuccess message={message} onClose={closeNotification} />}
              <div className="grid grid-cols-4 gap-4 px-4">
                <div className="grid grid-cols-1 gap-4 col-span-4">
                  <FormWebsite
                    domain={`.${process.env.REACT_APP_FUNNEL_DOMAIN}`}
                    label="Funnel Url"
                    name="site.url"
                    classes={subdomainClasses}
                    readOnly={funnel?.isDeployed === true}
                    placeholder="mysubdomain-funnel"
                    col={8}
                    span={6}
                    hasDomain={isDeployed}
                    onSubmit={onSubmit}
                    {...props}
                  />
                </div>
                <div className="grid gap-4 col-span-2">
                  <FormInput disabled={isSubmitting} name="site.title" label="Site Title" />
                  <FormInput disabled={isSubmitting} name="site.description" label="Site Description" />
                </div>
                <div className="grid gap-4 col-span-2">
                  <FormSelect values={siteTemplates} name="site.template" label="Template" />
                  <div className="sm:grid grid-cols-4 items-center">
                    <FormLabel label="Brand Colors" />
                    <div className="col-span-3 flex flex-wrap gap-2 text-center">
                      {Object.keys(themes).map((key) => (
                        <div key={key}>
                          <FormLabel
                            label={snakeToCapitalize(key)}
                            className="block text-xs font-medium text-gray-700 mb-1"
                          />
                          <FormColorsMenu name={`site.brands.themes.${key}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="grid gap-4 col-span-2 items-start">
                  <FormTextarea disabled={isSubmitting} label="Script" name="site.script" />
                </div>
                <div className="grid gap-4 col-span-2 items-start">
                  <FormUpload
                    name="site.brands.logo"
                    label="Site Logo"
                    isSubmitting={isSubmitting}
                    handleUpload={handleLogoUpload}
                    buttonText={`width - 200px, height - 100px
                      png, jpg, jpeg Only`}
                  />
                </div>
              </div>
              <FormHidden name="site.logo" defaultValue="logo" />
              <FormSubmit label="Update Rebate" col={8} span={2} disabled={isSubmitting} />
            </ShimmerWrapper>
          );
        }}
      />
    </>
  );
}

export default RebateFunnelSite;
