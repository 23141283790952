import { Button, Skeleton, TextInput } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { putSilentFetch } from 'request/request-api';

/**
 * Show modal edit
 * @param model - mongoose model
 * @param property - what property to update, must be a string
 * @param url - api url to update via put request
 * @constructor
 */
export default function EditModal({ model, property, url }) {
  const navigate = useNavigate();
  const prop = model[property];
  const [value, setValue] = useState(prop);
  const [isUpdating, setIsUpdating] = useState(false);
  const onSubmit = async () => {
    setIsUpdating(true);
    putSilentFetch({ url: `${url}/${model._id}`, data: { ...model, [property]: value } })
      .then(() => {
        closeAllModals();
      })
      .finally(() => setIsUpdating(false));

    navigate(0);
  };

  if (isUpdating) {
    return <Skeleton height={44} width="100%" radius="sm" />;
  }

  return (
    <>
      <TextInput value={value} onChange={(event) => setValue(event.currentTarget.value)} />
      <Button fullWidth onClick={() => onSubmit()} mt="md">
        Submit
      </Button>
    </>
  );
}
