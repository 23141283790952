import { FunnelDetailsInterface } from 'interfaces/rebates.interface';

const funnelDetailTableData = (values: FunnelDetailsInterface) => {
  const {
    _id,
    bonusRebateType,
    name,
    bonusRebate,
    rebateAmountType,
    refundTax,
    refundDiscount,
    maxRebate,
    rebateAmount,
  } = values;

  return [
    {
      header: 'Funnel ID',
      value: _id,
    },
    {
      header: 'Name',
      value: name,
    },
    {
      header: 'Refund Discount',
      value: refundDiscount,
    },
    {
      header: 'Bonus Rebate',
      value: bonusRebate,
    },
    {
      header: 'Rebate Amount',
      value: rebateAmount,
    },
    {
      header: 'Max Rebate',
      value: maxRebate,
    },
    {
      header: 'Bonus Rebate Type',
      value: bonusRebateType,
    },
    {
      header: 'Rebate Amount Type',
      value: rebateAmountType,
    },
    {
      header: 'Refund Tax',
      value: refundTax,
    },
    {
      header: 'Url',
      value: '',
    },
  ];
};

export default funnelDetailTableData;
