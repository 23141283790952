import { HeatmapInterface, HeatmapListInterface } from 'interfaces/heatmap.interface';
import { editHeatmapItem } from 'pages/users/heat-maps/heat-maps-item.slice';
import { editHeatmap } from 'pages/users/heat-maps/heat-maps-list.slice';
import { store } from 'redux/store';
import { postSilentFetch, putFetch } from 'request/request';

export const loadImages = async (id, page): Promise<HeatmapListInterface<HeatmapInterface>> => {
  return await postSilentFetch('/heatmap/heatmaps/images/filter', {
    filters: [
      {
        key: 'group',
        value: id,
      },
    ],
    page,
  });
};

export const imageEdit = async (values): Promise<HeatmapInterface> => {
  const { _id } = values;
  const res = await putFetch(`/heatmap/heatmaps/images/${_id}`, values, 'Image updated Successfully');
  if (res) {
    store.dispatch(editHeatmap(res));
    store.dispatch(editHeatmapItem({ isOpen: false }));
  } else {
    store.dispatch(editHeatmapItem({ isOpen: false }));
  }
  return res;
};
