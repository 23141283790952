const OrderDetail = ({ header, value }) => {
  const span = header === 'Total Rebate' || header === 'Url' ? 3 : 1;

  return (
    <div className={`w-full col-span-${span}`}>
      <div className="font-bold p-4 bg-gray-100">{header}</div>
      <div className="p-4">{value}</div>
    </div>
  );
};

export default OrderDetail;
