import { store } from 'redux/store';
import { ResponseListInterface } from 'request/response.interface';
import { SuperLinksInterface } from 'interfaces/super-links.interface';
import { setSuperLinks } from './super-links.slice';
import { getSilentFetch, postFetch, postSilentFetch, putFetch } from 'request/request';

export const loadSuperLinks = async (values): Promise<ResponseListInterface<SuperLinksInterface>> => {
  const res = await postSilentFetch('/superlinks/page/', values);

  if (res) {
    store.dispatch(setSuperLinks(res));
  }
  return res;
};

export const createSuperlink = async (values): Promise<SuperLinksInterface> => {
  return postFetch('/superlinks/', values, 'Superlink Created Successfully!');
};

export const updateSuperlink = async (id, values): Promise<SuperLinksInterface> => {
  return putFetch('/superlinks/' + id, values, 'Superlink Saved Successfully!');
};

export const getSuperLink = async (id): Promise<SuperLinksInterface> => {
  const res = await getSilentFetch('/superlinks/' + id);
  return res;
};

export const getDomains = async (): Promise<any> => {
  const res = await getSilentFetch('/superlinks/domains');
  return res;
};
