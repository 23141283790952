import { Dialog } from '@headlessui/react';
import FunnelOrderDetails from 'pages/users/funnels/funnel-customers/funnel-order-details';
import Modal from 'pages/users/funnels/funnel-customers/payout/modal';
import { selectPayoutModal, setPayoutModal } from 'pages/users/funnels/funnel-customers/payout/payout-modal.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

function PayoutModal() {
  const { isShowing } = useAppSelector(selectPayoutModal);

  const dispatch = useAppDispatch();

  const setOpen = (open) => {
    dispatch(setPayoutModal(open));
  };

  return (
    <>
      <Modal setOpen={setOpen} isShowing={isShowing}>
        <div
          className="inline-block align-bottom bg-white rounded-lg pt-5
            pb-4 text-left overflow-hidden shadow-xl transform transition-all
            sm:my-8 sm:align-middle max-w-5xl  sm:p-0 p-0 m-0 px-0"
        >
          <div className="sm:flex sm:items-start">
            <div className="mt-0 text-center sm:mt-0 sm:ml-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg text-center p-3 leading-6 font-bold text-white bg-red-600 rounded-lg rounded-b-none"
              >
                ORDER DETAILS
              </Dialog.Title>
              <div className="">
                <FunnelOrderDetails />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PayoutModal;
