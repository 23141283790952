import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

export default function StripePaymentMethodForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const redirectUrl = document.location.origin + '/dashboard/subscription/payment-method';

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsStripeLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (error) {
      alert(error.message);
      setIsStripeLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        disabled={!stripe || !elements}
        id="submit"
        className="disabled:bg-gray-300 mt-4 w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
                          text-sm py-3 px-6 font-medium text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
      >
        <span id="button-text">{isStripeLoading ? 'Processing..' : 'Set Payment Method'}</span>
      </button>
    </form>
  );
}
