import React, { useReducer } from 'react';
import SearchTagComponent from './top-searches.search.tag';
import TopSearchesChart from './top-searches.line-chart';
import TopSearchesTable from './top-searches.table';
import { searchTermReducer, topSearchesStates } from './top-searches.reducers';
import TopSearchesContext from './top-searches.context';

function TopSearches() {
  const [searchTermStates, searchTermDispatch] = useReducer(searchTermReducer, topSearchesStates);
  const providerState = {
    topSearchesStates: searchTermStates,
    dispatch: searchTermDispatch,
  };

  return (
    <TopSearchesContext.Provider value={providerState}>
      <div className="p-3 overflow-auto	">
        <SearchTagComponent />
        <TopSearchesChart />
        <TopSearchesTable />
      </div>
    </TopSearchesContext.Provider>
  );
}

export default TopSearches;
