import React, { useState, useRef, useEffect } from 'react';
import { Button, Group, Input, Text, ActionIcon, Paper } from '@mantine/core';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';

const KeywordExtractorTagsComponent = ({
  buttonLabel,
  dataSet,
  loading,
  getResults,
  similarAsins,
  similarKeywords,
  contextValues,
}) => {
  const {
    setkeywordCount,
    keywordCount,
    setAsins,
    setKeywords,
    setKeyword,
    keywords,
    asins,
    asinCount,
    setasinCount,
    setSearchKeyword,
    dataSource,
    setSearchAsin,
  } = contextValues;
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>() as any;
  const containerRefs = useRef<any>([]) as any;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const data = dataSet === 'product' ? asins : keywords;
  const countSet = dataSet === 'product' ? asinCount : keywordCount;

  const showInputField = () => {
    setInputVisible(true);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // Removes Container
  const remove = (keywordSelected) => {
    if (dataSet === 'product') {
      const excludeItem = asins.filter((keyword) => keyword !== keywordSelected);
      setAsins([...excludeItem]);
      setasinCount(excludeItem.length);
      if (similarAsins.length > 0 && similarKeywords.length > 0) {
        getResults(keywords, [...excludeItem], dataSource);
      }
    } else {
      const excludeItem = keywords.filter((keyword) => keyword !== keywordSelected);
      const lastKeyword = excludeItem.slice(-1);
      setKeyword(lastKeyword[0]);
      setKeywords([...excludeItem]);
      setkeywordCount(excludeItem.length);
      if (similarAsins.length > 0 && similarKeywords.length > 0) {
        getResults([...excludeItem], asins, dataSource);
      }
    }
    setSearchAsin('');
    setSearchKeyword('');
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (inputValue == '' || !inputValue.trim().length) {
        setInputVisible(false);
      } else {
        if (dataSet === 'product') {
          const updatedData = [...asins, inputValue];
          setAsins([...updatedData]);
          setasinCount(updatedData.length);
        } else {
          const updatedData = [...keywords, inputValue];
          setKeywords([...updatedData]);
          setkeywordCount(updatedData.length);
          setKeyword(inputValue);
        }
        setInputVisible(false);
        setInputValue('');
        setSearchAsin('');
        setSearchKeyword('');
      }
    }
  };

  // closes input field when clicking on anything not the input field itself
  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setInputVisible(false);
    }
  };

  useEffect(() => {
    const handleMouseOut = () => {
      setHoveredIndex(null);
    };

    containerRefs.current.forEach((containerRef, index) => {
      if (containerRef && containerRef.contains(document.activeElement)) {
        containerRef.addEventListener('mouseover', handleMouseOver);
        handleMouseOver(index);
      }
    });

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      containerRefs.current.forEach((containerRef) => {
        if (containerRef) {
          containerRef.removeEventListener('mouseover', handleMouseOver);
          containerRef.removeEventListener('mouseout', handleMouseOut);
        }
      });
    };
  }, []);
  return (
    <Paper shadow="xs">
      <Group position="center" grow>
        {data.map((keyword, index) => (
          <Group
            position="apart"
            key={index}
            style={{ padding: 12 }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            className={hoveredIndex === index ? 'bg-gray-100' : ''}
          >
            <Text fw={500} color="gray">
              {keyword}
            </Text>
            {hoveredIndex === index && (
              <ActionIcon
                style={{ width: 25, height: 25, minHeight: 'unset' }}
                onClick={remove.bind(null, keyword, index)}
              >
                <TrashIcon style={{ width: '100%', height: '100%' }} className="text-red-600 " />
              </ActionIcon>
            )}
          </Group>
        ))}
        {inputVisible ? (
          <Input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleInputKeyPress}
            style={{ padding: 6, borderRight: '1px solid lightgrey' }}
          />
        ) : (
          countSet !== 5 && (
            <Button
              onClick={showInputField}
              variant="subtle"
              color="dark"
              style={{ margin: 6 }}
              disabled={loading}
              loading={loading}
              leftIcon={<PlusCircleIcon className="h-5 w-5" style={{ color: '#101113', marginRight: '5px' }} />}
            >
              {buttonLabel}
            </Button>
          )
        )}
      </Group>
    </Paper>
  );
};

export default KeywordExtractorTagsComponent;
