import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { UserInterface } from 'interfaces/user.interface';
import { SearchchUserInterface } from 'interfaces/users.interace';
import { setSearchUser } from 'pages/admin/manage-users/switch-user/switch-user.slice';
import { store } from 'redux/store';
import { getSilentFetch, postSilentFetch } from 'request/request';

export const searchUsers = async (values): Promise<SearchchUserInterface> => {
  const res = await postSilentFetch('/users/superadmin/search', values);
  if (res) {
    store.dispatch(setSearchUser(res));
  }
  return res;
};

export const switchUser = async (id): Promise<UserInterface> => {
  const res = await getSilentFetch(`/users/superadmin/switch/${id}`);
  if (res) {
    store.dispatch(openDialog({ isOpen: false, deleteUrl: null }));
  } else {
    store.dispatch(openDialog({ isOpen: false, customProps: { userId: '' } }));
  }
  return res;
};
