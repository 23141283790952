import { ReactNode } from 'react';
import TableWrapper from 'components/tables/table-wrapper';

/**
 * Display data in table
 * @param headers - table headers
 * @param children - other component children
 * @param setCurrentPage=null - changes current page
 */
function Table({ headers, children }: { headers: string[]; children: ReactNode }) {
  return (
    <>
      <TableWrapper>
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, i) => (
              <th key={i} scope="col" className="th">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </TableWrapper>
    </>
  );
}

export default Table;
