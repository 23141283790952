import React from 'react';
import { ActionIcon } from '@mantine/core';
import { DownloadIcon } from '@heroicons/react/outline';

function DownloadCsvButton({ data, dataType, headers, currentPage }) {
  headers = headers.map((header) => {
    return `"${header}"`;
  });
  const downloadCsv = () => {
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(generateCsv(data));
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', dataType + '_table_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateCsv = (data) => {
    const dataRows = data.map((row, index) => {
      if (dataType === 'product') {
        return `"${row.asin} - ${row.title.replace(/"/g, '""""')}"` + ',' + `"${index + 1 + 20 * (currentPage - 1)}"`;
      } else {
        return `"${row.search_term}"` + ',' + `"${index + 1 + 20 * (currentPage - 1)}"`;
      }
    });
    return [headers, ...dataRows].join('\n');
  };
  return (
    <ActionIcon onClick={downloadCsv}>
      <DownloadIcon className="h-4 w-4" />
    </ActionIcon>
  );
}

export default DownloadCsvButton;
