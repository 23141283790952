import { ReactNode } from 'react';

// redux cannot accept component as a param or non-serializable value was detected in an action
export enum SliderOverChild {
  updatepaymentmethod = 'updatePaymentMethod',
  newSubscription = 'newSubscription',
}

export interface SlideOverInterface {
  title?: string | ReactNode;
  open: boolean;
  children?: null | SliderOverChild;
}
