import { postSilentFetch } from 'request/request-api';
import { TopSearchResultInterfaces } from './top-searches.interfaces';

export const getDaily = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/search', data: values });
};

export const getTopAsins = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/top-asins', data: values });
};

export const getSearchTermWeekly = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/search-term-weekly', data: values });
};

export const getSearchTermMonthly = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/search-term-monthly', data: values });
};

export const getWeekly = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/weekly', data: values });
};

export const getMonthly = (values): Promise<TopSearchResultInterfaces[]> => {
  return postSilentFetch({ url: '/brand-analytics/tst/monthly', data: values });
};
