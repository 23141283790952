import React from 'react';
import { Modal, Text, Button, TextInput } from '@mantine/core';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
function KeywordResearchSaveModal({ opened, close, action, setSetName, label }) {
  const { topSearchesStates } = useTopSearchesContext();
  const searchTerms = topSearchesStates.reportData.map((data) => data.label);
  const params =
    label === 'Update'
      ? {
          id: topSearchesStates.dataSet?._id,
          values: {
            name: topSearchesStates.setName,
            searchTerms,
          },
        }
      : { name: topSearchesStates.setName, searchTerms };
  const noSetName = topSearchesStates.setName === '' || !topSearchesStates.setName.trim().length;
  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title={<Text fw={700}>{label} Keywords</Text>}
      size="xl"
      overlayColor={'gray'}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <div className="my-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {`Set Name`}
        </label>
        <div className="my-1">
          <TextInput value={topSearchesStates.setName} onChange={(event) => setSetName(event.currentTarget.value)} />
        </div>
      </div>
      <div className="px-0 py-2 sm:px-0 sm:flex pl-0 ">
        <Button type="submit" onClick={() => action(params)} disabled={noSetName}>
          {label}
        </Button>
        <Button type="button" variant="white" className="ml-2" onClick={close}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default KeywordResearchSaveModal;
