import React, { ReactElement } from 'react';
import FormLabel from './form-label';
import { FormSuffix } from './form.suffix';

/**
 *
 * @param label - input label
 * @param labelSpan - labels column span
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param gap - column gap
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param classes - additional classes
 * @param props - other input props
 * @param {boolean} hasSuffix - if input has suffix
 * @param {object} suffix - input suffix
 * @param {number} colSpan - label grid col, see tailwind col
 */
const FormPercentOff = ({
  col = 4,
  label = '',
  gap = 4,
  span = 3,
  classes = '',
  suffix = '',
  hasSuffix = false,
  colSpan = null,
  ...props
}): ReactElement => {
  const className = `block w-full shadow-sm focus:ring-indigo-500 
  focus:border-indigo-500 ${hasSuffix && 'px-6'} sm:max-w-s sm:text-sm 
  border-gray-300 rounded-md ${classes}`;

  const field = (
    <FormSuffix hasSuffix={hasSuffix} suffix={suffix}>
      <input type="number" {...props} className={className} />
    </FormSuffix>
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} sm:items-center col-span-${colSpan}`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormPercentOff;
