import React, { useState } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { ActionIcon } from '@mantine/core';

const FormInputPassword = ({ col = 4, label = '', name, gap = 4, span = 3, placeholder = '' }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const field = (
    <div className="flex items-center gap-2">
      <Field
        name={name}
        component="input"
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-s sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed read-only:bg-gray-200"
      />
      <ActionIcon variant="light" aria-label="Settings" size="lg" onClick={toggleShowPassword}>
        {showPassword ? <EyeOffIcon height={16} width={16} /> : <EyeIcon height={16} width={16} />}
      </ActionIcon>
    </div>
  );
  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:grid-row-1 sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormInputPassword;
