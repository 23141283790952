import If from 'components/conditionals/if.conditional';
import Stripe from 'components/stripe/stripe';
import PaymentForm from 'pages/users/signup/payment-form';
import Price from 'pages/users/signup/price';
import { useGetPackage, usePaymentIntent } from 'pages/users/signup/signup.hooks';
import { Dispatch } from 'pages/users/signup/signup.interface';
import { checkCouponCode } from 'pages/users/signup/signup.thunks';
import StripeCheckout from 'pages/users/signup/stripe-checkout';
import { PackageInterface, PackageTypes } from 'pages/users/subscription/plans/plans.interface';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SignupContext } from './signup';

export default function SignupPayment() {
  const navigate = useNavigate();
  const { priceId, userId, email, type = 'monthly' } = useParams();
  const params = new URLSearchParams(window.location.search);
  const coupon = (params.get('coupon') as string) ?? '';
  const selectedPackage: PackageInterface | null = useGetPackage(priceId, type, navigate);
  const clientSecret = usePaymentIntent(userId, priceId, type, coupon);
  const customerEmail = email || '';
  const redirectUrl = document.location.origin + '/signup-success';

  const [couponCode, setCouponCode] = useState(null);
  useEffect(() => {
    checkCouponCode(coupon, selectedPackage?.productId).then((counponData) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return setCouponCode(counponData);
    });
  }, [selectedPackage]);

  return (
    <>
      <div className="flex flex-col min-h-screen bg-white">
        <div className="flex-1">
          <section>
            <div className="flex flex-col flex-col-reverse align-middle lg:flex-row md:items-strech">
              <If show={selectedPackage !== null}>
                <PaymentForm>
                  <Stripe clientSecret={clientSecret}>
                    <StripeCheckout
                      redirectUrl={redirectUrl}
                      email={customerEmail}
                      buttonLabel={'Confirm Payment'}
                      clientSecret={clientSecret}
                    ></StripeCheckout>
                  </Stripe>
                </PaymentForm>
                <SignupContext.Provider
                  value={{
                    coupon: couponCode,
                    setCoupon: setCouponCode as Dispatch,
                    productId: selectedPackage?.productId,
                  }}
                >
                  <Price type={type as PackageTypes} selectedPackage={selectedPackage as PackageInterface} />
                </SignupContext.Provider>
              </If>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
