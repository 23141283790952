import {
  ClipboardListIcon,
  CodeIcon,
  CogIcon,
  CollectionIcon,
  CreditCardIcon,
  CursorClickIcon,
  ExternalLinkIcon,
  EyeIcon,
  LinkIcon,
  PresentationChartLineIcon,
  SparklesIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import {
  SidebarGroupedNavigationInterface,
  SidebarNavigationInterface,
  UserRolesType,
} from 'components/sidebar/navigation.interface';

export const navigation = [
  {
    name: 'AI tools',
    subMenu: [
      { name: 'Heat Maps', href: 'heatmaps', icon: EyeIcon, current: false, id: 'heatmaps' },
      { name: 'A/B Tests', href: 'abtests', icon: CollectionIcon, current: false, id: 'splitTests' },
      { name: 'Apollo', href: 'apollo', icon: SparklesIcon, current: false, id: 'apollo' },
      {
        name: 'Keyword Extractor',
        href: 'keyword-extractor',
        icon: ClipboardListIcon,
        current: false,
        id: 'keyword-extractor',
      },
      { name: 'PPC-AI', href: 'ppc-ai', icon: CodeIcon, current: false, id: 'ppcai' },
    ],
  },
  {
    name: 'Admin Tools',
    subMenu: [
      {
        name: 'Super Links',
        href: 'superlinks',
        icon: ExternalLinkIcon,
        current: false,
        id: 'superlinks',
      },
      {
        name: 'Magic Links',
        href: 'magic-urls',
        icon: CursorClickIcon,
        current: false,
        id: 'magic-links',
      },
    ],
  },
  {
    name: 'Analytics',
    subMenu: [
      {
        name: 'TSQ',
        href: '',
        icon: PresentationChartLineIcon,
        current: true,
        id: 'tsq',
      },
      {
        name: 'SQP',
        href: '',
        icon: PresentationChartLineIcon,
        current: true,
        id: 'sqp',
      },
      {
        name: 'Keyword Research',
        href: '',
        icon: PresentationChartLineIcon,
        current: true,
        id: 'keyword-research',
      },
      {
        name: 'Forecasting',
        href: '',
        icon: PresentationChartLineIcon,
        current: true,
        id: 'forecasting',
      },
    ],
  },
  {
    name: 'Settings',
    subMenu: [
      {
        name: 'Team',
        href: 'teams',
        icon: UsersIcon,
        current: false,
        id: 'teams',
      },
      { name: 'User Settings', href: 'settings', icon: CogIcon, current: false, id: 'settings', isForAll: true },
      { name: 'Link Account', href: 'amazon', icon: LinkIcon, current: false, id: 'link-account' },
      {
        name: 'Subscription',
        href: 'subscription',
        icon: CreditCardIcon,
        current: false,
        id: 'subscription',
      },
    ],
  },
];

// if you want to add another role without showing it to the nav, add it here
export const rolesWithoutNavigation: UserRolesType[] = [
  { name: 'Products', id: 'products' },
  { name: 'Orders', id: 'orders' },
  { name: 'Advertising', id: 'ads' },
  { name: 'External', id: 'external' },
];

/**
 * filter navigation based on roles
 * @param roles
 *  show all if role is *
 *  always add settings and subscription regardless of role
 */
export const getUserNavigation = function (roles: Array<string>): SidebarGroupedNavigationInterface[] {
  const userSidebarNavigation: SidebarGroupedNavigationInterface[] = navigation.map((item) => {
    return { ...item };
  });
  if (!roles.includes('*')) {
    // filter based on role + isForAll

    userSidebarNavigation.map((menu) => {
      menu.subMenu = menu.subMenu?.filter(
        (item: SidebarNavigationInterface) => roles.includes(item.id) || item?.isForAll
      );
    });
  }
  return userSidebarNavigation;
};

export const getAdminRolesList = (): UserRolesType[] => {
  const roles: SidebarNavigationInterface[] = [];
  navigation.map((menu) => {
    menu.subMenu = menu.subMenu?.filter((item: SidebarNavigationInterface) => !item.isForAll);
    roles.push(...menu.subMenu);
  });
  return [...roles, ...rolesWithoutNavigation];
};
