import { TrashIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { openDialog } from './confirm-dialog.slice';

/**
 *
 * @param deleteUrl - delete endpoint
 * @param deleteId - Item Id
 * @param confirmText - text to type to confirm delete
 * @returns jsx
 */
const DeleteButton = ({ deleteUrl, deleteId, confirmText = '' }) => {
  const dispatch = useAppDispatch();
  const [isOver, setIsOver] = useState<boolean>(false);

  const handleHover = () => {
    setIsOver(!isOver);
  };

  return (
    <>
      {isOver && (
        <div className="absolute -top-5 z-50 bg-gray-600 text-white text-xs px-2 rounded-md min-w-max">Delete</div>
      )}
      <button
        type="button"
        onClick={() =>
          dispatch(
            openDialog({
              isOpen: true,
              deleteUrl,
              deleteId,
              confirmText,
              customProps: { buttonLabel: 'Delete' },
            })
          )
        }
        onMouseOver={handleHover}
        onMouseOut={handleHover}
        className="flex justify-center items-center p-0 h-8 w-8 border border-transparent shadow-sm text-sm
                font-medium rounded-full text-white
                bg-transparent hover:bg-red-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        <TrashIcon
          className={`h-5 w-5 p-0 m-0 flex justify-center ${isOver ? 'text-white' : 'text-red-600'} hover:text-white`}
          aria-hidden="true"
        />
      </button>
    </>
  );
};

export default DeleteButton;
