import { useMutation, useQuery } from '@tanstack/react-query';
import { getPromptOptimizer, updatePromptOptimizer } from 'pages/admin/prompts/prompt.thunks';
import { useEffect, useState } from 'react';
import {
  DefaultPromptInterface,
  DescriptionPromptInterface,
  PromptTemplateInterface,
} from 'pages/admin/prompts/prompt.interface';
import { useParams } from 'react-router-dom';

export const usePromptOptimizer = () => {
  const { tabValue = 'title' } = useParams();
  const [titles, setTitles] = useState<DefaultPromptInterface>({ prompt1: '' });
  const [bullets, setBullets] = useState<DefaultPromptInterface>({ prompt1: '' });
  const [system, setSystem] = useState<DefaultPromptInterface>({ prompt1: '' });
  const [description, setDescriptions] = useState<DescriptionPromptInterface>({
    benefits: '',
    features: '',
    story: '',
  });

  const { data } = useQuery([`manage-prompt-optimizer`, 1], {
    queryFn: () => getPromptOptimizer(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: updatePrompt } = useMutation({
    mutationFn: (data: PromptTemplateInterface) => {
      return updatePromptOptimizer(data);
    },
  });

  const update = () => {
    updatePrompt({
      system,
      bullets,
      titles,
      description,
      type: tabValue,
    });
  };

  useEffect(() => {
    if (data) {
      setTitles(data.titles);
      setDescriptions(data.description);
      setBullets(data.bullets);
      setSystem(data.system ?? { prompt1: '' });
    }
  }, [data]);

  return {
    data,
    context: {
      system,
      titles,
      bullets,
      description,
      setTitles,
      setBullets,
      setDescriptions,
      setSystem,
    },
    update,
  };
};
