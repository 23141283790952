import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ProductInterface, SelectProductInterface } from 'interfaces/rebates.interface';
import { RootState } from 'redux/store';

const initialState: SelectProductInterface = {
  sellerAccount: '',
  isOpen: false,
  product: null,
  amazonAccount: null,
  products: [],
};

export const selectProductSlice = createSlice({
  name: 'selectProduct',
  initialState,
  reducers: {
    openModal: (state: Draft<SelectProductInterface>, action: PayloadAction<SelectProductInterface>) => {
      state.sellerAccount = action.payload.sellerAccount;
      state.isOpen = action.payload.isOpen;
    },
    setAmazonAccount: (state: Draft<SelectProductInterface>, action: PayloadAction<string>) => {
      state.amazonAccount = action.payload;
    },
    setSelectProducts: (state: Draft<SelectProductInterface>, action: PayloadAction<ProductInterface[]>) => {
      state.products = action.payload;
    },
  },
});

export const { openModal, setAmazonAccount, setSelectProducts } = selectProductSlice.actions;

export const selectProduct = (state: RootState) => state.selectProduct;

export const selectProductReducer = selectProductSlice.reducer;
