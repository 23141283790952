import SubpageContainer from 'components/containers/subpage.container';
import Card from 'components/card/card';
import { Button, Text } from '@mantine/core';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { retryListing } from 'pages/users/apollo/apollo.thunk';

export default function ApolloRetryListing({ refetch }: { refetch: () => void }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const retry = async () => {
    const { _id } = await retryListing(id);
    navigate('/dashboard/apollo/create-list/title/' + _id);
    refetch();
  };

  return (
    <>
      <SubpageContainer title="">
        <Card>
          <div className="flex flex-wrap flex-col justify-center">
            <Text fz="lg" fw={500} className="self-center mt-4">
              There was an error generating new listing
            </Text>
            <Text fz="md" className="self-center">
              An error occurred. If this issue persist please contact admin.
            </Text>
            <Button onClick={retry} className="self-center my-5">
              Retry
            </Button>
          </div>
        </Card>
      </SubpageContainer>
    </>
  );
}
