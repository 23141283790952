import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';
import IconButton from 'components/common/icon.button';
import { ClipboardCopyIcon, QrcodeIcon, SearchIcon } from '@heroicons/react/outline';
import ModalAlert from 'components/dialogs-old/modal.alert';
import QR from 'components/common/qr-code.component';
import { copyUrl } from 'lib/helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectRebateSite, setIsQROpen, setNotify } from 'pages/users/funnels/rebate/tabs/rebate-funnel-site-slice';

/**
 * Form Input Website
 * @param domain - main domain to display as a trailing button. must begin with a dot eg: ` .signalytics-landingpages.ai`
 * @param label - input label
 * @param placeholder
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param props - other input props
 * @param classes - other input classnames
 * @param {number} colSpan - label grid col, see tailwind col
 * @param onSubmit - submit subdomian availability
 * @param hasDomain - Whether the funnel is deployed or not
 * @constructor
 */
const FormWebsite = ({
  span = 3,
  gap = 4,
  col = 4,
  domain = '',
  label = '',
  placeholder = '',
  name,
  type = 'text',
  classes = 'text',
  hasDomain,
  onSubmit,
  ...props
}): ReactElement => {
  const scheme = process.env.NODE_ENV === 'development' ? 'http' : 'https';

  const fieldClass = domain ? '' : 'rounded-r-md';
  const value = document.querySelector('#site\\.url')?.getAttribute('value');
  const uri = `${scheme}://${value}.${process.env.REACT_APP_FUNNEL_DOMAIN}`;
  const dispatch = useAppDispatch();
  const { isQROpen } = useAppSelector(selectRebateSite);

  const handleCopyUrl = () => {
    copyUrl(uri);
    dispatch(setNotify(true));
  };

  const toggleQR = () => {
    dispatch(setIsQROpen(!isQROpen));
  };

  const field = (
    <Field
      autoComplete="off"
      type={type}
      name={name}
      id={name}
      {...props}
      component="input"
      className={`focus:ring-red-500 focus:border-red-500 pl-3 sm:text-sm border-gray-300 flex-1 block w-full rounded-none  ${fieldClass} ${classes} `}
      placeholder={placeholder || label}
    />
  );

  const domainButton = (
    <>
      <button
        className="-ml-px flex-none relative inline-flex items-center space-x-2 px-4 py-2
                       border border-gray-300 text-sm rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100
                      focus:outline-none focus:ring-1 focus:ring-red-500 focus:red-indigo-500 font-bold"
      >
        {domain}
      </button>
      <div className="space-x-1 flex pl-1">
        {hasDomain ? (
          <>
            <IconButton Icon={ClipboardCopyIcon} text="Copy Url" bgColor="orange" onClick={handleCopyUrl} />
            <IconButton Icon={QrcodeIcon} text="Qr Code" onClick={toggleQR} />
          </>
        ) : (
          <IconButton Icon={SearchIcon} text="Check Availability" onClick={onSubmit} />
        )}
      </div>
    </>
  );

  if (!label) return field;

  return (
    <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} items-center`}>
      <FormLabel label={label} />
      <div className={`sm:mt-0 sm:col-span-${span}`}>
        <div className="flex rounded-md shadow-sm">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
            {`${scheme}://`}
          </span>
          {field}
          {domainButton}
        </div>
      </div>
      <ModalAlert open={isQROpen} closeModal={toggleQR} cancelButtonRef={toggleQR}>
        <QR url={uri} />
      </ModalAlert>
    </div>
  );
};

export default FormWebsite;
