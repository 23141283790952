import PrimaryButton from 'components/buttons/primary-button';
import If from 'components/conditionals/if.conditional';
import FormStackCards from 'components/forms/form-stack-cards';
import { toggleSliderOver } from 'components/slide-over/slider-over.slice';
import { setCard } from 'pages/users/subscription/payment-method/default-card.slice';
import { CreditCardInterface } from 'pages/users/subscription/payment-method/payment-method.interface';
import { savePaymentMethod } from 'pages/users/subscription/payment-method/payment-method.thunks';
import { usePaymentMethods } from 'pages/users/subscription/payment-method/payment-methods.hooks';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function PaymentMethods() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { options: paymentMethods, default: defaultPayment, methods } = usePaymentMethods();

  const onSubmit = (values) => {
    savePaymentMethod(values.paymentMethod).then(() => navigate('/dashboard/subscription'));
    dispatch(toggleSliderOver({ open: false }));
    const newDefaultCard = methods.find((p) => p.id === values.paymentMethod) as unknown as CreditCardInterface;
    dispatch(setCard(newDefaultCard));
  };

  return (
    <>
      <Form
        initialValues={{ paymentMethod: defaultPayment?.id }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <div className="space-y-5">
              <If show={paymentMethods.length > 0}>
                <form onSubmit={handleSubmit} autoComplete="off" className="grid grid-cols-1 gap-6 px-4">
                  <FormStackCards name="paymentMethod" options={paymentMethods}></FormStackCards>
                  <PrimaryButton type="submit">Set as Default</PrimaryButton>
                </form>
              </If>
            </div>
          );
        }}
      />
    </>
  );
}
