export function LinkStatus({ link }) {
  const colors = {
    working: 'bg-green-100 text-green-800',
    'in-progress': 'bg-gray-100 text-gray-800',
    error: 'bg-red-100 text-red-800',
  };
  const color = colors[link];

  return (
    <>
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}>{link}</span>
    </>
  );
}
