import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from './form-label';

/**
 * Create a form dropdown
 * @param {number} span - grid span, see tailwind col
 * @param {number} gap - grid gap, see tailwind col
 * @param {number} col - grid col, see tailwind col
 * @param label - form label
 * @param name - dropdown name
 * @param {string} selectName - dropdown field name
 * @param values - array of values for the dropdown
 * @param optionValue - we will use this as value for select option
 * @param optionLabel- we will use this as label for select option
 * @param autoFillValue - the value to autofill,
 * @param handleAutoFill - on autofill value change
 * @param handleChange - track user typing
 * @param type - input type
 * @param {any} ...props - other input props
 */
const FormInputSelect = ({
  span = 3,
  gap = 4,
  col = 4,
  label,
  name,
  selectName = '',
  values,
  type = 'search',
  optionValue = '_id',
  optionLabel = 'name',
  autoFillValue = '',
  handleChange,
  handleAutoFill = undefined,
  ...props
}): ReactElement => {
  const field = (
    <div className="relative rounded-md shadow-sm">
      {autoFillValue ? (
        <input
          type={type}
          value={autoFillValue}
          onChange={handleAutoFill}
          className="form-control flex-auto min-w-0 w-full px-3 pr-28 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-1 focus:ring-red-500 border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-500 focus:outline-none"
          {...props}
        />
      ) : (
        <Field autoComplete="on" name={name}>
          {({ input }) => {
            return (
              <input
                autoComplete="off"
                type={type}
                onChange={(e) => {
                  input.onChange(e);
                  handleChange && handleChange(e);
                }}
                className="form-control flex-auto min-w-0 w-full px-3 pr-28 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-1 focus:ring-red-500 border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-500 focus:outline-none"
                {...props}
              />
            );
          }}
        </Field>
      )}

      <div className="absolute inset-y-0 right-0 flex items-center border-l rounded-lg">
        <Field name={selectName} type="select">
          {({ input }) => {
            return (
              <select
                className="focus:ring-red-500 focus:border-red-500  text-center h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                onChange={input.onChange}
              >
                {values?.map((o: any) => (
                  <option value={o[optionValue]} key={o[optionValue]}>
                    {o[optionLabel]}
                  </option>
                ))}
              </select>
            );
          }}
        </Field>
      </div>
    </div>
  );

  if (!label) return field;

  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormInputSelect;
