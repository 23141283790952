import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { classNames } from 'lib/helpers';
import { Fragment } from 'react';

const MenuPopup = ({ actions, handleClick }) => {
  return (
    <Menu as="div" className="absolute top-0 right-0">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="absolute top-0 right-0 rounded-full m-2 p-2 hover:bg-gray-500 bg-opacity-0">
              <span className="sr-only">Open group menu</span>
              <DotsVerticalIcon className="h-5 w-5 text-white" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {actions.map((action) => (
                <Menu.Item key={action.name}>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'px-4 py-2 text-sm text-gray-700 flex pr-8 w-full'
                      )}
                      onClick={handleClick}
                    >
                      {action.name === 'Edit' ? (
                        <PencilIcon className="h-5 w-5 mr-2" />
                      ) : (
                        <TrashIcon className="h-5 w-5 mr-2" />
                      )}
                      {action.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default MenuPopup;
