import { openDialog } from 'components/dialogs-old/confirm-dialog.slice';
import { getAmazonTypeUrl } from 'pages/users/amazon/amazon.helper';
import { AmazonApiTypes } from 'pages/users/amazon/amazon.interface';
import { store } from 'redux/store';

const amazonUnlinkAccount = (e, account, type: AmazonApiTypes) => {
  store.dispatch(
    openDialog({
      isOpen: true,
      deleteId: account?._id,
      deleteUrl: '/' + getAmazonTypeUrl(type) + '/accounts/connected-accounts/',
      customProps: {
        message: 'Are you sure you want to unlink this Amazon Account?',
        buttonLabel: 'Unlink',
      },
    })
  );
};

export default amazonUnlinkAccount;
