import { stateInterface } from './top-searches.interfaces';
import { chartColors } from 'lib/colors.helpers';

export const topSearchesStates: stateInterface = {
  dataType: 'daily',
  startDate: new Date(new Date().getFullYear(), 0, 1),
  endDate: new Date(),
  keywords: [],
  inputVisible: false,
  searchTerm: '',
  graphLabels: [],
  chartData: [],
  weeklyTableData: [],
  monthlyTableData: [],
  fetchedData: [],
  isLoading: false,
};
export const searchTermReducer = (state, action) => {
  switch (action.type) {
    case 'set_input_visibility':
      return { ...state, inputVisible: !state.inputVisible };
    case 'set_input_value':
      return { ...state, searchTerm: action.payload };
    case 'add_keyword':
      return { ...state, keywords: [...state.keywords, action.payload] };
    case 'remove_keyword': {
      const filterKeywords = state.keywords.filter(
        (keyword) => keyword.keyword.toLowerCase() !== action.payload.toLowerCase()
      );
      return { ...state, keywords: [...filterKeywords] };
    }
    case 'set_graph_labels':
      return { ...state, graphLabels: action.payload };
    case 'add_weekly_table_data':
      return { ...state, weeklyTableData: [...action.payload, ...state.weeklyTableData] };
    case 'add_monthly_table_data':
      return { ...state, monthlyTableData: [...action.payload, ...state.monthlyTableData] };
    case 'remove_monthly_table_data': {
      const filterData = state.monthlyTableData.filter(
        (data) => data.label.toLowerCase() !== action.payload.toLowerCase()
      );
      return { ...state, monthlyTableData: [...filterData] };
    }
    case 'remove_weekly_table_data': {
      const filterData = state.weeklyTableData.filter(
        (data) => data.label.toLowerCase() !== action.payload.toLowerCase()
      );
      return { ...state, weeklyTableData: [...filterData] };
    }
    case 'add_graph_data': {
      const data = [...state.chartData, action.payload];
      data.sort((a, b) => {
        return (
          state.keywords.findIndex((item) => item.keyword === a.label) -
          state.keywords.findIndex((item) => item.keyword === b.label)
        );
      });
      return { ...state, chartData: data };
    }
    case 'update_graph_labels': {
      const data = state.chartData.map((item, index) => {
        item.backgroundColor = chartColors[index];
        item.borderColor = chartColors[index];
        return item;
      });
      return { ...state, chartData: data };
    }
    case 'remove_graph_data': {
      const filterData = state.chartData.filter((data) => data.label.toLowerCase() !== action.payload.toLowerCase());
      return { ...state, chartData: [...filterData] };
    }
    case 'reset_graph_data':
      return { ...state, chartData: [] };
    case 'set_graph_type':
      return { ...state, dataType: action.payload };
    case 'set_start_date':
      return { ...state, startDate: action.payload };
    case 'set_end_date':
      return { ...state, endDate: action.payload };
    case 'set_fetched_data':
      return { ...state, fetchedData: action.payload };
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
