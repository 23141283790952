import FormButton from 'components/forms/form-button';
import React from 'react';

function OrderNumber({
  customer,
  onClick,
}: {
  customer: any;
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  if (customer?.orderNumber && customer?.order) {
    return <FormButton radius="2xl" buttonLabel={customer.orderNumber} onClick={onClick} />;
  }
  return <div className="px-4">—</div>;
}

export default OrderNumber;
