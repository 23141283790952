import { Badge, Button } from '@mantine/core';
import DeleteButton from 'components/buttons/delete-button';
import EditButton from 'components/buttons/edit-button';
import PrimaryButton from 'components/buttons/primary-button';
import If from 'components/conditionals/if.conditional';
import ShowAdmin from 'components/show-admin/show-admin';
import Subpage from 'components/subpage/subpage';
import { deleteModal, editModal } from 'hooks/deleteModal';
import { getCoverPhoto } from 'pages/users/split-tests/split-test.helpers';
import { useSplitTests } from 'pages/users/split-tests/split-test.hooks';
import { SplitTestInterface } from 'pages/users/split-tests/split-tests.interface';
import { removeSplitTest } from 'pages/users/split-tests/split-tests.thunks';
import { useNavigate } from 'react-router-dom';

export default function SplitTests() {
  const splitTests = useSplitTests();
  const navigate = useNavigate();
  const upload = () => {
    navigate('/dashboard/split-tests/new');
  };

  function goToResult(id) {
    navigate('/dashboard/split-tests/result/' + id);
  }

  function goToUploadInput(id) {
    navigate('/dashboard/split-tests/input/' + id);
  }

  function goToUploadResult(id: string) {
    navigate('/dashboard/split-tests/upload-result/' + id);
  }

  const onEdit = (file: SplitTestInterface) => editModal(file, 'name', '/split-tests');

  const onDelete = (file: SplitTestInterface) =>
    deleteModal(file.name, file._id, async () => {
      await removeSplitTest(file._id);
      navigate(0);
    });

  return (
    <Subpage
      title="Split Tests"
      rightElement={
        <PrimaryButton size="sm" onClick={upload}>
          Upload
        </PrimaryButton>
      }
      show={splitTests.length > 0}
    >
      <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {splitTests.map((file) => {
          const badgeColor = file.status === 'done' ? 'green' : 'yellow';
          return (
            <li key={file._id} className="relative">
              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src={getCoverPhoto(file)}
                  alt=""
                  className="object-cover h-52 w-full pointer-events-none group-hover:opacity-75"
                />
              </div>
              <div className="flex my-2 justify-between">
                <div className="block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.name}</div>
                <div className="block text-sm font-medium text-gray-500 pointer-events-none">
                  <Badge color={badgeColor}>{file.status}</Badge>
                </div>
              </div>
              <div className="mt-2 flex justify-between">
                <If show={file.status === 'done'}>
                  <Button onClick={() => goToResult(file._id)} color="gray" size="xs">
                    View Result
                  </Button>
                </If>
                <Button size="xs" onClick={() => goToUploadInput(file._id)}>
                  Show Input
                </Button>
                <ShowAdmin>
                  <Button size="xs" onClick={() => goToUploadResult(file._id)}>
                    Upload Result
                  </Button>
                </ShowAdmin>
                <div className="w-16 space-x-1 text-right items-center flex">
                  <EditButton onClick={() => onEdit(file)} />
                  <DeleteButton onClick={() => onDelete(file)} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </Subpage>
  );
}
