import React, { useEffect, useState } from 'react';
import useContextValues, { useSimilar } from './keyword-extractor.hooks';
import { Space, Grid, Button, Flex, Text, Radio } from '@mantine/core';
import KeywordExtractorTableComponent from './keyword-extractor.table.component';
import { ExtractorContext } from './keyword-extractor.context';
import KeywordExtractorTagsComponent from './keyword-extractor.tags.component';
import { useLocation, useNavigate } from 'react-router-dom';
import If from 'components/conditionals/if.conditional';
import KeywordExtractorBannerComponent from './keyword-extractor.banner';
import { IconListSearch, IconArrowRight } from '@tabler/icons';
import { AsinInterface } from 'pages/users/apollo/keyword-extractor/keyword-extractor.interfaces';

function KeywordExtractor({ hasNext }) {
  const navigate = useNavigate();
  const [isDisabled, setDisabled] = useState(true);
  const contextValues = useContextValues();
  let tableAsins: any[] = [];
  let tableKeywords: any[] = [];
  const location = useLocation();
  const keywordHeaders = ['Keyword', 'Similarity Rank'];
  const productHeaders = ['Product', 'Similarity Rank'];
  const params = new URLSearchParams(window.location.search);
  const [specialKeywordAdded, setSpecialKeywordAdded] = useState<boolean>(false);
  const specialKeyword = params.get('keyword');

  const { getResults, queryResults, isLoading, rankResults } = useSimilar();

  const queryData = queryResults.map((result) => {
    return result.data;
  });
  queryData.map((result) => {
    if (result) {
      tableAsins.push(result.asins);
      tableKeywords.push(result.keywords);
    }
  });
  tableAsins = rankResults(tableAsins);
  tableKeywords = rankResults(tableKeywords);
  tableAsins = tableAsins.slice(0, 100);
  tableKeywords = tableKeywords.slice(0, 100);
  const onNext = async (event) => {
    event.preventDefault();
    const asins = [...contextValues.asins] as string[];
    const keywords = [...contextValues.keywords] as string[];
    const asinsFromData = tableAsins.slice(0, 10 - contextValues.asinCount);
    const keywordsFromData = tableKeywords.slice(0, 10 - contextValues.keywordCount);
    await asinsFromData?.map((asin: AsinInterface) => {
      asins.push(asin.asin);
    });
    await keywordsFromData?.map((keyword: any) => {
      keywords.push(keyword.search_term);
    });
    navigate(`/dashboard/apollo/create-list/brand?asins=${asins.join(',')}&keywords=${keywords.join(',')}`);
  };

  useEffect(() => {
    setDisabled(tableKeywords.length === 0 && tableAsins.length === 0);
  }, [tableKeywords, tableAsins]);

  useEffect(() => {
    contextValues.resetContextValues();
    if (specialKeyword && !specialKeywordAdded) {
      contextValues.setKeywords([specialKeyword]);
      contextValues.setkeywordCount(1);
      contextValues.setKeyword(specialKeyword);
      setSpecialKeywordAdded(true);
      getResults([specialKeyword], [], contextValues.dataSource);
    } else {
      getResults([], [], contextValues.dataSource);
    }
  }, [location.pathname]);
  return (
    <ExtractorContext.Provider value={{ ...contextValues }}>
      <Grid grow gutter="xs" className="p-3 overflow-auto">
        <Grid.Col span={12}>
          <KeywordExtractorBannerComponent hasNext={hasNext} />
          <Space h="md" />
          <KeywordExtractorTagsComponent
            buttonLabel={'Add A New Keyword'}
            dataSet="keyword"
            loading={isLoading}
            getResults={getResults}
            similarAsins={tableAsins}
            similarKeywords={tableKeywords}
            contextValues={contextValues}
          />
          <Space h="md" />
          <KeywordExtractorTagsComponent
            buttonLabel={'Add A New Product'}
            dataSet="product"
            loading={isLoading}
            getResults={getResults}
            similarAsins={tableAsins}
            similarKeywords={tableKeywords}
            contextValues={contextValues}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex mih={50} justify="space-between" align="center" direction="row" wrap="wrap">
            <div className="flex items-center">
              <Text className="mr-2" fw={500}>
                Get results from:
              </Text>
              <Radio.Group
                offset={0}
                name="dataAPI"
                color="red"
                defaultValue={contextValues.dataSource}
                onChange={(e) => contextValues.setDataSource(e)}
              >
                <Radio value="brand-analytics" label="Brand Analytics" color="red" />
                <Radio value="amazon" label="Amazon" color="red" />
              </Radio.Group>
            </div>
            <Flex mih={50} justify="flex-end" align="center" direction="row" wrap="wrap">
              <Button
                leftIcon={<IconListSearch size="1rem" />}
                onClick={() => getResults(contextValues.keywords, contextValues.asins, contextValues.dataSource)}
                loading={isLoading}
              >
                Get Similar Products/Keywords
              </Button>
              <If show={hasNext}>
                <Button
                  leftIcon={<IconArrowRight size="1rem" />}
                  ml={8}
                  onClick={onNext}
                  disabled={isLoading || isDisabled}
                  color={'dark'}
                >
                  Next Step
                </Button>
              </If>
            </Flex>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <KeywordExtractorTableComponent
            tableLabel="Similar Products"
            tableHeaders={productHeaders}
            tableData={tableAsins}
            searchLabel="Search by Product"
            dataType="product"
            fetchingSA={isLoading}
          />
        </Grid.Col>
        {/*)}*/}
        {/*{data && (*/}
        <Grid.Col span={6}>
          <KeywordExtractorTableComponent
            tableLabel="Similar Keywords"
            tableHeaders={keywordHeaders}
            tableData={tableKeywords}
            searchLabel="Search by keyword"
            dataType="keyword"
            fetchingSA={isLoading}
          />
        </Grid.Col>
        {/*)}*/}
      </Grid>
    </ExtractorContext.Provider>
  );
}

export default KeywordExtractor;
