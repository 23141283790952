function OrderBadge({ status }) {
  const colors = {
    Pending: 'bg-orange-100 text-orange-800 border',
    Shipped: 'bg-blue-100 text-blue-800',
    Refund: 'bg-gray-400 text-gray-100 border',
    Canceled: 'bg-red-100 text-red-800',
    Unfulfillable: 'bg-gray-100 text-gray-800',
    Unshipped: 'bg-purple-200 text-purple-900',
    PartiallyShipped: 'bg-orange-100 text-orange-800',
    PendingAvailability: 'bg-green-100 text-green-800',
  };
  const color = colors[status] || 'bg-gray-100 text-gray-800';

  return (
    <>
      {status ? (
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}>
          {status}
        </span>
      ) : (
        <div className="px-4">—</div>
      )}
    </>
  );
}

export default OrderBadge;
