import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import FormSelectWithIcon from 'components/forms/form-select-with-icon';
import markets from 'lib/markets';
import FormTextarea from 'components/forms/form-textarea';
import { createSuperlink, getDomains, getSuperLink, updateSuperlink } from './super-links.thunks';
import useFetch from 'hooks/useFetch';
import ShimmerWrapper from 'components/shimmer';
import BasicInfoShimmer from 'pages/users/funnels/shimmers/basic-info';
import FormElement from 'components/forms/form-element';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { XIcon } from '@heroicons/react/solid';
import { PlusIcon } from '@heroicons/react/outline';
import FormSelect from '../forms/form-select';

const SuperLinkForm = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const { id } = useParams();
  const isNew = id && id === 'new';
  let isFetching = false;
  const { data: domains } = useFetch('superlink_domains', () => getDomains());
  let superlink = { domain: domains[0]?.url, marketplace: markets[0].country };

  if (!isNew) {
    ({ isFetching, data: superlink } = useFetch('superlink' + id, () => getSuperLink(id)));
  }
  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const onSubmit = async (values) => {
    if (isNew) {
      const res = await createSuperlink(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/superlinks'), 1000);
      }
    } else {
      await updateSuperlink(id, values);
    }
  };

  return (
    <>
      <SubpageContainer title="Super Links">
        <ShimmerWrapper loading={isFetching && !isNew} fallback={<BasicInfoShimmer />}>
          <Form
            onSubmit={onSubmit}
            initialValues={superlink}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              submitting,
              values,
              form: {
                mutators: { push, pop },
              },
            }) => {
              const addKeyword = () => {
                setKeyword('');
                push('keywords', keyword);
              };

              const handleRemoveKeyword = (index) => {
                pop('keywords', index);
              };

              return (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="bg-white overflow-hidden shadow rounded-lg py-6 px-8"
                >
                  <div className="grid grid-cols-2 gap-4 pb-4">
                    <FormInput disabled={submitting} name="name" label="Campaign Name" />
                    <FormInput disabled={submitting} name="asin" label="ASIN" />
                    <FormSelectWithIcon
                      values={markets}
                      defaultLabel="country"
                      name="marketplace"
                      label="Market Place"
                      hasFlag
                      defaultIndex={1}
                    />
                    <FormInput name="destinationUrl" label="Destination URL" />
                    <FormSelect values={domains} name="domain" label="Domain" optionValue="url" optionLabel="url" />
                    <FormTextarea label="Head Tracking" name="head" placeholder="(optional)" />
                    <FormTextarea label="Body Tracking" name="body" placeholder="(optional)" />
                    <FormElement label="Keywords" col={4} span={3} align="items-start">
                      <div className={'grid grid-cols-1 mt-1 sm:mt-0 w-full gap-2'}>
                        <div className="w-full relative">
                          <input
                            autoComplete="off"
                            type="text"
                            id="keywords"
                            onChange={handleChange}
                            value={keyword}
                            className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-s sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed read-only:bg-gray-200"
                            placeholder="Enter Keyword"
                          />
                          {keyword && (
                            <div className="absolute flex top-0 right-0 border w-14 h-full bg-red-600 rounded-md">
                              <button
                                type="button"
                                onClick={addKeyword}
                                className="flex w-full h-full justify-center items-center rounded-md"
                              >
                                <PlusIcon className="w-5 h-5 text-white" />
                              </button>
                            </div>
                          )}
                        </div>
                        {values?.keywords?.length !== 0 && values?.keywords?.length && (
                          <div className="flex gap-2 border rounded-md px-3 py-2">
                            <FieldArray name="keywords" initialValue={values.keywords}>
                              {({ fields }) =>
                                fields.map((keyword, index) => (
                                  <div key={keyword + index}>
                                    <Field name={keyword}>
                                      {({ input }) => {
                                        return (
                                          <div className="flex justify-center items-center gap-4 border px-2 py-1 w-fit rounded-md bg-gray-100">
                                            <div className="flex-1">{input.value}</div>

                                            <XIcon className="w-4 h-4" onClick={() => handleRemoveKeyword(index)} />
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                ))
                              }
                            </FieldArray>
                          </div>
                        )}
                      </div>
                    </FormElement>
                  </div>
                  <FormSubmit disabled={submitting} col={8} span={2} label="Save Campaign" />
                </form>
              );
            }}
          />
        </ShimmerWrapper>
      </SubpageContainer>
    </>
  );
};

export default SuperLinkForm;
