import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import bullets from './listing/bullets';
import description from './listing/description';
import suggestion from './listing/image-suggestion';
import title from 'pages/users/apollo/steps/listing/titles';
import tags from 'pages/users/apollo/steps/listing/tags';
import SubpageContainer from 'components/containers/subpage.container';
import Card from 'components/card/card';
import { Button } from '@mantine/core';
import { ListingTabsProvider } from 'pages/users/apollo/steps/listing/context';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getApolloPrompt, updateApolloResult } from 'pages/users/apollo/apollo.thunk';
import { ApolloCreateInterface, SuggestionResponse } from 'pages/users/apollo/apollo.interfaces';
import { IconDeviceFloppy, IconEdit } from '@tabler/icons';
import { getApollo } from 'pages/users/apollo/steps/apollo.result.hooks';
import ShowAdmin from 'components/show-admin/show-admin';
import ApolloRetryListing from 'pages/users/apollo/steps/apollo.retry-listing';
import { SpinnerText } from 'components/shimmer/spinner-text';
import If from 'components/conditionals/if.conditional';

const tabs = {
  title,
  bullets,
  description,
  suggestion,
  tags,
};

export const ApolloCreateListingTabs = ({ component }: { component?: string }): JSX.Element => {
  const [enableFetch, setEnableFetch] = useState<boolean>(true);
  const { tabValue, id } = useParams();
  const navigate = useNavigate();

  const { data: apollo } = getApollo();

  const { data: promptData } = useQuery(['apollo', id], {
    queryFn: () => getApolloPrompt(id as string),
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    enabled: enableFetch,
    cacheTime: 0,
  });

  const TabPanel = tabs[tabValue || component || 'title'];

  const [title, setSelectedTitle] = useState<string>('');
  const [bullets, setSelectedBullets] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const [suggestion, setSuggestion] = useState<SuggestionResponse[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const status = promptData?.status ?? 'pending';

  const { mutate: update } = useMutation((data: ApolloCreateInterface) => updateApolloResult(data), {
    onSuccess: () => navigate('/dashboard/apollo/'),
  });

  const updateOnClick = (event) => {
    event.preventDefault();
    update({
      title,
      bullets,
      tags,
      suggestion,
      promptId: id?.toString(),
      description,
    } as ApolloCreateInterface);
  };

  useEffect(() => {
    if (apollo) {
      setSelectedBullets(apollo.bullets);
      setSelectedTitle(apollo.title);
      setDescription(apollo.description);
      setSuggestion(apollo?.suggestion);
      setTags(apollo.tags);
    }
  }, [apollo]);

  useEffect(() => {
    if (status !== 'pending') {
      setEnableFetch(false);
    }
  }, [promptData]);

  return (
    <>
      <If show={status === 'pending'}>
        <SubpageContainer title="Apollo">
          <Card>
            <SpinnerText text={'🤖 AI Content is Loading 🚀'} />
          </Card>
        </SubpageContainer>
      </If>
      <If show={status === 'error'}>
        <ApolloRetryListing refetch={() => setEnableFetch(true)} />
      </If>
      <If show={status === 'success'}>
        <SubpageContainer
          title="Apollo"
          rightElement={
            <div>
              <ShowAdmin>
                <Button
                  onClick={() => navigate('/dashboard/apollo/prompt/' + id)}
                  className="mr-2"
                  leftIcon={<IconDeviceFloppy size="1rem" />}
                  color="dark"
                >
                  Prompts
                </Button>
              </ShowAdmin>
              <Button
                onClick={updateOnClick}
                disabled={bullets.length < 5 || !title}
                leftIcon={<IconEdit size="1rem" />}
              >
                Update
              </Button>
            </div>
          }
        >
          <Card>
            <div className="flex gap-4 px-2 py-2">
              <div className="flex flex-col grow">
                <div className="pt-2">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ListingTabsProvider
                      data={{
                        title,
                        bullets,
                        description,
                        tags,
                        suggestion,
                        setSelectedTitle,
                        setSelectedBullets,
                        setDescription,
                        setTags,
                        setSuggestion,
                      }}
                    >
                      <TabPanel />
                    </ListingTabsProvider>
                  </Suspense>
                </div>
              </div>
            </div>
          </Card>
        </SubpageContainer>
      </If>
    </>
  );
};
