import MenuPopup from 'components/common/menu-popup';

import FormLabel from 'components/forms/form-label';
import Spinner from 'components/shimmer/spinner';
import React, { ReactElement, useRef } from 'react';
import { Field, useForm } from 'react-final-form';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

const actions = [{ name: 'Delete' }];

/**
 * Form Upload
 * @param label - input label
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param props - other input props
 * @params disabled - whether the upload button is disabled
 * @params hasInput - if
 * @constructor
 */
const FormUpload = ({
  label = '',
  name,
  col = 4,
  span = 3,
  disabled = false,
  hasPreview = false,
  multiple = false,
  handleUpload,
  buttonText = 'png, jpg, jpeg upto 10MB',
  ...props
}): ReactElement => {
  const form = useForm();
  const fileRef = useRef<HTMLInputElement>(null);
  const { isLoading } = useAppSelector(selectRequest);

  const handleClick = () => {
    fileRef?.current?.click();
  };

  const handleDelete = () => {
    form.change(name, null);
  };

  const field = (
    <Field
      name={name}
      id={name}
      component="input"
      className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-s sm:text-sm border-gray-300 rounded-md"
      {...props}
    >
      {({ input }) => {
        const uploadLabel = input.value ? 'Change Image' : 'Upload Image';

        return (
          <>
            <input type="hidden" />
            <div className="relative bg-gray-900 bg-opacity-75 rounded-lg">
              {input.value && <MenuPopup actions={actions} handleClick={handleDelete} />}
              <button
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 bg-opacity-75 bg-gray-900 rounded-lg text-white font-semibold disabled:opacity-75 disabled:cursor-not-allowed"
                onClick={handleClick}
                disabled={disabled || isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center gap-2">
                    <Spinner /> Uploading...
                  </div>
                ) : (
                  <div className="flex flex-col text-center">
                    <div className="mb-1">{uploadLabel}</div>
                    <span className="text-xs font-semibold text-gray-400">{buttonText}</span>
                  </div>
                )}
              </button>
              {input.value ? (
                <img className="object-contain h-52 w-full" src={hasPreview ? null : input.value} />
              ) : (
                <div className="object-contain h-52 w-full rounded-lg" />
              )}
            </div>
            <input
              ref={fileRef}
              type="file"
              style={{ display: 'none' }}
              multiple={multiple}
              onChange={(e) => handleUpload(e, form)}
            />
          </>
        );
      }}
    </Field>
  );

  if (!label) return field;
  return (
    <>
      <div className={`sm:grid sm:grid-cols-4 sm:gap-${col} items-start`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormUpload;
