import Table from 'components/tables/table';
import { numberToFriendlyDate } from 'lib/ads.helper';
import { analyticsColumns } from 'pages/users/analytics/analytics-columns';
import { getValueByColumn } from 'pages/users/analytics/analytics-formula';
import { AdsSalesReportsInterface, StatisticsTableColumns } from 'pages/users/analytics/analytics.interface';

function AnalyticsTableExport({ adsData }: { adsData: AdsSalesReportsInterface[] }) {
  const headers = ['Date', ...analyticsColumns.map((s) => s.name)];
  const keys: StatisticsTableColumns[] = [
    { column: 'date', formatter: numberToFriendlyDate },
    ...analyticsColumns.map((s) => s),
  ];
  return (
    <>
      <Table headers={headers}>
        <tbody>
          {adsData.map((a, index) => {
            return (
              <tr key={a._id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                {keys.map((k) => {
                  return (
                    <td
                      key={a._id + k.column}
                      className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {getValueByColumn(k.column, a, k.formatter)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default AnalyticsTableExport;
