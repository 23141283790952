import React, { useEffect } from 'react';
import { UserDataInterface } from 'pages/users/settings/setting.interface';
import { useForm } from '@mantine/form';

export const useProfileForm = (
  isLoading: boolean,
  userProfile: UserDataInterface | undefined,
  setPreview: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  const form = useForm<Record<string, unknown>>();
  useEffect(() => {
    if (!isLoading) {
      form.setValues({
        firstName: userProfile?.firstName,
        lastName: userProfile?.lastName,
      });
      setPreview(userProfile?.gravatar ? userProfile?.gravatar : '/placeholder.jpeg');
    }
  }, [userProfile]);
  return { form };
};
