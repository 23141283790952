import { CardInterface } from 'components/card/card.interface';
import If from 'components/conditionals/if.conditional';

export default function Card({ title, subtitle = null, withBorder = true, children, className }: CardInterface) {
  const classBorder = withBorder ? 'border-t border-gray-200 ' : '';
  const classNames = className || 'overflow-hidden bg-white shadow rounded-lg';
  return (
    <>
      <div className={classNames}>
        <If show={title !== undefined}>
          <div className="p-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
            <If show={subtitle !== null}>
              <p className="max-w-2xl text-sm text-gray-500">{subtitle}</p>
            </If>
          </div>
        </If>
        <div className={`${classBorder} p-5`}>{children}</div>
      </div>
    </>
  );
}
