import React, { useRef } from 'react';
import { Button, Paper, Input } from '@mantine/core';
import useTopSearchesEvents from 'pages/users/keyword-research/keyword-research.hooks';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';

const SearchTagComponent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { topSearchesStates, dispatch } = useTopSearchesContext();
  const { enterKeyword, fetchData } = useTopSearchesEvents();

  return (
    <Paper shadow="xs" className="!flex w-full items-center p-2 gap-2">
      <Input
        placeholder="Enter Search Term"
        className=" w-full "
        value={topSearchesStates.searchTerm}
        onChange={(e) => dispatch({ type: 'set_input_value', payload: e.target.value })}
        disabled={topSearchesStates.isLoading}
        ref={inputRef}
        onKeyPress={(e) => enterKeyword(e)}
      />
      <Button
        loading={topSearchesStates.isLoading}
        disabled={topSearchesStates.searchTerm === '' || !topSearchesStates.searchTerm.trim().length}
        onClick={() => fetchData(topSearchesStates.searchTerm)}
      >
        {/*<Button className="ml-3">*/}
        Add Keyword
      </Button>
    </Paper>
  );
};

export default SearchTagComponent;
