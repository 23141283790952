import React from 'react';
import { Button, LoadingOverlay, Pagination, Paper, Table, Text } from '@mantine/core';
import If from '../../../components/conditionals/if.conditional';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
import useTopSearchesEvents from 'pages/users/keyword-research/keyword-research.hooks';
import CustomLoader from 'pages/users/ab-test/components/abtest.loader';
import { Link } from 'react-router-dom';

function KeywordResearchRelatedKeywords() {
  const { topSearchesStates, dispatch } = useTopSearchesContext();
  const { queryResults, sortByWeekNo, setKeywordDetails, sortByFirstSFR } = useTopSearchesEvents();
  const isLoading = topSearchesStates.tableDataLoading || queryResults.some((query) => query.isLoading);
  let queryData = queryResults.map((result) => {
    return result.data ? sortByWeekNo(result.data) : [];
  });
  queryData = sortByFirstSFR(queryData);
  const itemsPerPage = 10;
  const startIndex = (topSearchesStates.currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(queryData.length / itemsPerPage);
  queryData = queryData.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    dispatch({ type: 'set_current_page', payload: newPage });
  };

  const tableRows = queryData?.map((tData, index) => (
    <tr key={index}>
      <td className="whitespace-nowrap">
        <Text
          className="hover:underline !text-red-500 cursor-pointer"
          onClick={() => setKeywordDetails(sortByWeekNo(queryData[index], tData[0]?.search_term))}
        >
          {tData[0]?.search_term}
        </Text>
      </td>
      <td>
        <Link target={'_blank'} to={`/dashboard/keyword-extractor?keyword=${tData[0]?.search_term}`}>
          <Button size="xs" color="dark">
            Similar Keywords
          </Button>
        </Link>
      </td>
      {queryData[index]?.map((item, itemIndex) => {
        return <td key={itemIndex}>{item.search_frequency_rank}</td>;
      })}
    </tr>
  ));
  return (
    <If show={queryData.length > 0 || isLoading}>
      <Paper shadow="xs" p="xs" className="mt-3 overflow-auto relative">
        <LoadingOverlay visible={isLoading} overlayBlur={5} loader={<CustomLoader textMessage="Loading Data..." />} />
        <Text fw={500} fz="lg">
          Related Keywords for "{topSearchesStates.focusedKeyword?.label}"
        </Text>
        <Table className="border-slate-300 border p-3 my-2 !rounded-md border-x-0" striped>
          <thead>
            <tr>
              <th className="whitespace-nowrap">Keyword</th>
              <th className="whitespace-nowrap">Similar Keywords</th>
              {queryData[0]?.map((item, itemIndex) => {
                return <th key={itemIndex}>Week {item.week_no}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableRows}
            <If show={queryData.length === 0}>
              <tr>
                <td colSpan={12} className="text-center	">
                  No search term entered.
                </td>
              </tr>
            </If>
          </tbody>
        </Table>
        <Pagination
          total={totalPages}
          position="center"
          page={topSearchesStates.currentPage}
          onChange={handlePageChange}
        />
      </Paper>
    </If>
  );
}

export default KeywordResearchRelatedKeywords;
