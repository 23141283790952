import { ExclamationIcon } from '@heroicons/react/outline';
import GenericMessage from 'components/errors/generic-message';

const ConfirmEmailFail = () => (
  <GenericMessage
    Icon={<ExclamationIcon className="h-14 w-14 text-red-500" aria-hidden="true" />}
    title="Email Confirmation Failed!"
    desc="Your token is either invalid or expired."
  />
);

export default ConfirmEmailFail;
