import { openConfirmModal } from '@mantine/modals';
import PrimaryButton from 'components/buttons/primary-button';
import If from 'components/conditionals/if.conditional';
import { epochToDate, stripeNumberToMoney } from 'lib/formatting.helpers';
import { showObject } from 'lib/helpers';
import { InvoiceInterface } from 'pages/users/subscription/invoices/invoices.interface';
import PlanTitle from 'pages/users/subscription/plans/plan-title';
import PlanUsage from 'pages/users/subscription/plans/plan-usage';
import { usePlanDetails } from 'pages/users/subscription/plans/plans.hooks';
import { PlanInterface } from 'pages/users/subscription/plans/plans.interface';
import { useNavigate } from 'react-router-dom';
import { useInvoices } from '../invoices/invoices.hooks';
import { cancelSubscription } from './plans.thunks';

export default function PlanDetails({ plan, upcoming }: { plan: PlanInterface; upcoming: InvoiceInterface }) {
  const invoices = useInvoices();
  const lastPaid = invoices.find((invoice) => invoice.status === 'paid');
  const navigate = useNavigate();
  const planDetails = usePlanDetails();
  const cancelPlan = () =>
    openConfirmModal({
      title: 'Cancel Subscription',
      centered: true,
      children: <>Are you sure to cancel your subscription? This action is irreversible.</>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        await cancelSubscription();
        navigate(0);
      },
    });
  return (
    <If show={showObject(plan)}>
      <div className="bg-white">
        <div className="p-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            <PlanTitle plan={plan} />
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Last paid: {stripeNumberToMoney(lastPaid?.amount_paid)} on{' '}
            {epochToDate(lastPaid?.created ? lastPaid?.created : 0)}
          </p>
          <p className="max-w-2xl text-sm text-gray-500">
            Next billing: {stripeNumberToMoney(upcoming.amount_due)} on {epochToDate(upcoming.period_end)}
          </p>
        </div>
        <div className={`p-5`}>
          <div>
            <div className="grid grid-cols-2 gap-5">
              {planDetails.map((i) => (
                <PlanUsage data={i} key={i.name} />
              ))}
            </div>
            <div className="pt-8 justify-end flex space-x-2">
              <PrimaryButton size="xs" onClick={cancelPlan} color="red">
                Cancel Plan
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      {/*<Card title={title} subtitle={subtitle} subtext={subtext} className="bg-white">*/}

      {/*</Card>*/}
    </If>
  );
}
