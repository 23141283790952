import React from 'react';
import { ActionIcon, Button, LoadingOverlay, Paper, Table, Text } from '@mantine/core';
import { useTopSearchesContext } from 'pages/users/keyword-research/keyword-research.context';
import useTopSearchesEvents from 'pages/users/keyword-research/keyword-research.hooks';
import { Link } from 'react-router-dom';
import KeywordResearchSaveModal from './keyword-research.save-modal';
import If from 'components/conditionals/if.conditional';
import { XIcon } from '@heroicons/react/outline';
import CustomLoader from 'pages/users/ab-test/components/abtest.loader';
function KeywordResearchSearchResults() {
  const { topSearchesStates } = useTopSearchesContext();
  const {
    setRelatedKeywords,
    openedSaveModal,
    openSaveModal,
    closeModal,
    setSetName,
    saveKeywords,
    updateSet,
    searchTermsUpdated,
    removeKeyword,
  } = useTopSearchesEvents();
  const data = topSearchesStates.reportData;
  const isLoading = topSearchesStates.tableDataLoading;
  const tableRows = data?.map((tData, index) => (
    <tr key={index}>
      <td>
        <ActionIcon size="sm" color="red" variant="filled" onClick={() => removeKeyword(tData)}>
          <XIcon className="h-4 w-4" strokeWidth={4} />
        </ActionIcon>
      </td>
      <td>
        <Text className="hover:underline !text-red-500 cursor-pointer" onClick={() => setRelatedKeywords(tData)}>
          {tData.label}
        </Text>
      </td>
      <td>
        <Link target={'_blank'} to={`/dashboard/keyword-extractor?keyword=${tData.label}`}>
          <Button size="xs" color="dark">
            Similar Keywords
          </Button>
        </Link>
      </td>
      {tData.data?.map((item, itemIndex) => {
        return <td key={itemIndex}>{item.search_frequency_rank}</td>;
      })}
    </tr>
  ));

  return (
    <Paper shadow="xs" p="xs" className="mt-3 overflow-auto relative">
      <KeywordResearchSaveModal
        opened={openedSaveModal}
        close={closeModal}
        action={topSearchesStates.dataSet ? updateSet : saveKeywords}
        setSetName={setSetName}
        label={topSearchesStates.dataSet ? 'Update' : 'Save'}
      />
      <LoadingOverlay visible={isLoading} overlayBlur={5} loader={<CustomLoader textMessage="Loading Data..." />} />

      <div className="flex justify-between items-center">
        <Text fw={500} fz="lg">
          Search Terms
        </Text>
        <If show={data.length !== 0 && !topSearchesStates.dataSet}>
          <Button onClick={openSaveModal}>Save Keywords</Button>
        </If>
        <If show={data.length !== 0 && topSearchesStates.dataSet !== null}>
          <Button onClick={openSaveModal} disabled={searchTermsUpdated()}>
            Update Keywords
          </Button>
        </If>
      </div>

      <Table className="border-slate-300 border p-3 mt-2 !rounded-md border-x-0" striped>
        <thead>
          <tr>
            <th className="whitespace-nowrap"></th>
            <th className="whitespace-nowrap">Keyword</th>
            <th className="whitespace-nowrap">Similar Keywords</th>
            {data[0]?.data?.map((item, itemIndex) => {
              return <th key={itemIndex}>Week {item.week_no}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableRows}
          <If show={data.length === 0}>
            <tr>
              <td colSpan={12} className="text-center	">
                No search term entered.
              </td>
            </tr>
          </If>
        </tbody>
      </Table>
    </Paper>
  );
}

export default KeywordResearchSearchResults;
