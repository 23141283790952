import { PlusIcon } from '@heroicons/react/solid';

import FormButton from 'components/forms/form-button';
import FormSelect from 'components/forms/form-select';
import regions from 'lib/ads-regions';
import { alertOrRedirect } from 'lib/helpers';
import { AmazonApiTypes } from 'pages/users/amazon/amazon.interface';
import { login } from 'pages/users/amazon/amazon.thunks';
import { Form } from 'react-final-form';

const AmazonAdsForm = ({ sellerAccounts }) => {
  const submit = async (values, type) => {
    const res = await login({ ...values, type });
    alertOrRedirect(res.loginUrl);
  };

  return (
    <Form
      onSubmit={(values) => submit(values, AmazonApiTypes.ADS)}
      initialValues={null}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="">
          <div className="">
            <div className="flex justify-end items-end w-full gap-4">
              <FormSelect name="country" label="" values={regions} initialValue={regions[0]._id} />
              <FormSelect name="sellerAccount" label="" values={sellerAccounts} initialValue={sellerAccounts[0]?._id} />
              <FormButton
                leadingIcon={<PlusIcon className="w-5 h-5 mr-1" />}
                buttonLabel="Ad Account"
                buttonColor="blue"
                type="submit"
                classes="whitespace-nowrap"
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default AmazonAdsForm;
