import If from 'components/conditionals/if.conditional';
import SubpageContainer from 'components/containers/subpage.container';
import Stripe from 'components/stripe/stripe';
import StripeCheckout from 'pages/users/signup/stripe-checkout';
import { usePaymentRegisteredIntent } from 'pages/users/subscription/subscription.hooks';
import { selectUserData } from 'pages/users/user/user.slice';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export default function Reactivate() {
  const { priceId } = useParams();
  const clientSecret = usePaymentRegisteredIntent(priceId || '');
  const { email } = useAppSelector(selectUserData);
  const redirectUrl = document.location.origin + '/dashboard/subscription';
  return (
    <>
      <SubpageContainer title="Reactivate Subscription">
        <div className="max-w-lg">
          <If show={clientSecret !== null}>
            <Stripe clientSecret={clientSecret}>
              <StripeCheckout
                redirectUrl={redirectUrl}
                email={email}
                buttonLabel={'Confirm Payment'}
                noAlert={true}
                clientSecret={clientSecret}
              ></StripeCheckout>
            </Stripe>
          </If>
        </div>
      </SubpageContainer>
    </>
  );
}
