import React, { useEffect, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import ABTestDefineElementsProvider from 'pages/users/ab-test/abtest-define-elements/abtest-define.context';
import {
  AbtestDefineElementImageInterface,
  AbtestDefineImageCropInterface,
} from 'pages/users/ab-test/abtest-define-elements/abtest-define.interface';
import { Image, Text } from '@mantine/core';
import { useABTest } from 'pages/users/ab-test/abtest.hook';
import ABTestDefineCropper from './abtest-define-cropper';
import AbtestDefineElements from 'pages/users/ab-test/abtest-define-elements/abtest-define-elements';

function AbtestDefine() {
  const [items, setElementItems] = useState<AbtestDefineElementImageInterface[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isTrace, setTrace] = useState<boolean>(false);
  const { abTestData: data } = useABTest(false);

  useEffect(() => {
    if (data) {
      const d: AbtestDefineElementImageInterface[] = [];
      data?.inputFile.map((data) => {
        d.push({
          imageSource: data?.images[0].image,
          currentItem: 0,
          elements: [],
        });
      });
      setElementItems(d);
    }
  }, [data]);

  const setImageElementItems = (imageIndex: number, elements: AbtestDefineImageCropInterface[]) => {
    items[imageIndex].elements = elements;
    setElementItems([...items]);
  };
  const currentElementSelected = (imageIndex: number, currentIndex: number | undefined) => {
    setCurrentImageIndex(imageIndex);
    items[imageIndex].currentItem = currentIndex ?? 0;
    setElementItems([...items]);
  };

  return (
    <div className="  px-4 sm:px-6  lg:px-8 py-6 h-full flex-auto	flex overflow-hidden flex-col	">
      <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900 mb-3">AB Test Define Elements</h1>
      <ABTestDefineElementsProvider
        data={{
          data: items,
          setElementItems: setImageElementItems,
          currentImageIndex,
          setCurrentPosition: currentElementSelected,
          isTrace,
          setTrace,
        }}
      >
        <div className="flex flex-auto overflow-hidden">
          <div>
            <div className="flex h-full flex-grow flex-col gap-6 overflow-y-auto">
              {data?.inputFile.map((image, index) => {
                return (
                  <div className=" pr-4 sm:pr-6  lg:pr-8 pl-2" key={index}>
                    <Text fz="sm" className="!text-gray-500">
                      {index + 1}.
                    </Text>
                    <div
                      className={`${
                        currentImageIndex === index && 'border-red-600'
                      } rounded-md border border-2  hover:scale-105 transition-all `}
                    >
                      <Image
                        height={150}
                        width={150}
                        fit="contain"
                        src={image.images[1].image}
                        radius="sm"
                        className={`cursor-pointer`}
                        onClick={() => setCurrentImageIndex(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 py-2 col-span-7 flex-auto h-full">
              <div className="mx-2">
                <ABTestDefineCropper />
              </div>
              <AbtestDefineElements />
            </div>
          </div>
        </div>
      </ABTestDefineElementsProvider>
    </div>
  );
}

export default AbtestDefine;
