import { TransformComponent, TransformWrapper } from '@pronestor/react-zoom-pan-pinch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HeatmapPreviewControles from './heatmap-preview-controles';

export function HeatmapPreview({ src, nonDualImage, imageType, setImageType }) {
  const alt = 'example';
  const backgroundColor = 'black';
  const scaleUp = true;
  const zoomFactor = 8;

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

  const imageScale = useMemo(() => {
    if (containerWidth === 0 || containerHeight === 0 || imageNaturalWidth === 0 || imageNaturalHeight === 0) return 0;
    const scale = Math.min(containerWidth / imageNaturalWidth, containerHeight / imageNaturalHeight);
    return scaleUp ? scale : Math.max(scale, 1);
  }, [scaleUp, containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth);
    setImageNaturalHeight(image.naturalHeight);
  };

  useEffect(() => {
    const image = new Image();
    image.onload = () => handleImageOnLoad(image);
    image.src = src;
  }, [src]);

  return (
    <>
      <div
        className="w-screen bg-black h-screen"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor,
        }}
        ref={(el: HTMLDivElement | null) => setContainer(el)}
      >
        {imageScale > 0 && (
          <TransformWrapper
            panning={{ disabled: true }}
            minScale={-5}
            key={`${containerWidth}x${containerHeight}`}
            initialScale={1}
            maxScale={imageScale * zoomFactor}
            centerOnInit
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className="absolute left-2 top-2 w-16 z-50">
                  <HeatmapPreviewControles
                    nonDualImage={nonDualImage}
                    imageType={imageType}
                    setImageType={setImageType}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={resetTransform}
                  />
                </div>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img alt={alt} src={src} />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        )}
      </div>
    </>
  );
}
