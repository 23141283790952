const CircularButtons = ({ buttonColor = 'red', children, ...props }) => {
  return (
    <div>
      <button
        {...props}
        type="button"
        className={`inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-${buttonColor}-600 hover:bg-${buttonColor}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${buttonColor}-500`}
      >
        {children}
      </button>
    </div>
  );
};

export default CircularButtons;
