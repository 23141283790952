import { Form } from 'react-final-form';

import { selectAmazonAccounts } from 'pages/users/amazon/amazon.slice';
import FormInputSelect from 'components/forms/form-input-search-with-select';
import FormSelectWithIcon from 'components/forms/form-select-with-icon';
import { amazonProducts, getProducts } from 'pages/users/funnels/select-product/select-product.thunk';
import useAmazonAccount from 'hooks/useAmazonAccount';
import { useAppSelector } from 'redux/hooks';
import { ResponseSearchFilterInterface, SearchInterface } from 'interfaces/products.interface';

const searchOptions = [
  {
    label: 'Name',
    _id: 'name',
    value: 'name',
  },
];

const SearchFilter = ({
  onFilter = getProducts,
  onSearch = amazonProducts,
}: {
  onFilter?: (user: string, toBeStored?: boolean) => Promise<ResponseSearchFilterInterface<unknown>>;
  onSearch?: (
    values: SearchInterface,
    amazonUser: string,
    storeResult?: boolean
  ) => Promise<ResponseSearchFilterInterface<unknown>>;
}) => {
  const amazonAccounts = useAppSelector(selectAmazonAccounts);
  const { selectedAcc, setSelectedAcc } = useAmazonAccount();

  const handleSubmit = async (values) => {
    await onSearch(values, values.amazonUser, true);
  };

  const handleChangeAccount = async (e, target) => {
    setSelectedAcc(target);
    await onFilter(target, false);
  };

  return (
    <Form
      initialValues={{ key: 'name' }}
      onSubmit={handleSubmit}
      render={({ values }) => {
        const handleSearch = async (e) => {
          if (e.target.value.length > 1) {
            await onSearch(values, selectedAcc, true);
          } else if (e.target.value.length === 0) {
            await onFilter(selectedAcc, false);
          }
        };

        return (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex gap-4">
              <FormInputSelect
                values={searchOptions}
                optionLabel="label"
                optionValue="value"
                name="value"
                selectName="key"
                label=""
                placeholder="Search products..."
                aria-label="Search"
                handleChange={handleSearch}
                autoFillValue={''}
              />
              <FormSelectWithIcon
                values={amazonAccounts}
                name="amazonUser"
                label=""
                bgColor="bg-gray-900"
                targetKey="_id"
                handleChange={handleChangeAccount}
                textColor="text-white"
                selectorColor="text-white"
                bgSelected="gray-900"
                border="border hover:ring-2 border-white ring-gray-900"
                defaultIndex={0}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

export default SearchFilter;
