import { PasswordInput, TextInput } from '@mantine/core';
import Alert from 'components/alerts/alert';
import { SignupContext } from 'pages/users/signup/signup';
import Steps from 'pages/users/signup/steps';
import { register } from 'pages/users/user/user.thunks';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkCouponCode } from './signup.thunks';
import { useForm } from '@mantine/form';

export default function SignupForm() {
  const navigate = useNavigate();
  const { priceId, type = 'monthly' } = useParams();
  const [couponCode, setcouponCode] = useState<string>('');
  const [isError, setisError] = useState<boolean>(false);
  const [isValid, setisValid] = useState<boolean>(false);
  const { coupon, setCoupon, productId } = useContext(SignupContext);

  const couponClassName = `${isError ? ' mb-px' : ''} w-2/3 !text-black ${
    isValid ? '!border-2 rounded-md border-green-500' : ''
  }`;

  const onSubmit = async (values) => {
    const res = await register({ ...values, priceId, type });

    if (res) {
      const couponUrl = coupon?.valid ? `?coupon=${couponCode}` : '';
      navigate(`/signup-payment/${priceId}/${res._id}/${values.email}/${type}${couponUrl}`);
    }
  };
  const onApplyCode = async () => {
    if (couponCode.length > 0) {
      const coupon = await checkCouponCode(couponCode, productId);
      setisError(!coupon.valid);
      setisValid(coupon.valid);
      setCoupon(coupon);
    }
  };

  const onCodeInput = async (val) => {
    setcouponCode(val.target.value);
  };

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
    },

    validate: {
      firstName: (value) => (value ? null : 'First Name is required'),
      lastName: (value) => (value ? null : 'Last Name is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  return (
    <div className="lg:w-1/2 bg-white flex items-center justify-center lg:min-h-screen pt-6 md:pt-12 px-4 xl:px-0">
      <div className="w-11/12 xl:w-1/2 h-full">
        <div className="h-full" id="loginForm">
          <div className="flex flex-col items-center justify-between h-full w-full">
            <div>
              <img className="mx-auto md:h-24 w-auto" src="/logo-white.png" alt="Signalytics" />
            </div>
            <div className="w-full md:py-8 lg:py-0">
              <div className="flex justify-between">
                <span
                  className="cursor-pointer text-xs font-medium leading-none underline text-rose-600"
                  onClick={() => history.back()}
                >
                  Back
                </span>
                <Steps />
              </div>
              <h2 className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold xl:leading-8 text-gray-900 pt-6">
                Create an Account
              </h2>
              <div className="flex items-center mt-5">
                <p className="text-xs font-medium leading-none text-gray-500">Already have an account?</p>
                <a className="text-xs font-medium leading-none underline text-rose-600 ml-2" href="/">
                  Login here
                </a>
              </div>
              <p className="text-rose-500 text-xs text-center capitalize pt-4"></p>

              <div>
                <form className="space-y-6" onSubmit={form.onSubmit((values) => onSubmit(values))}>
                  <Alert />
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    withAsterisk
                    {...form.getInputProps('firstName')}
                  />
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    withAsterisk
                    {...form.getInputProps('lastName')}
                  />
                  <TextInput
                    label="Email Address"
                    placeholder="Email Address"
                    withAsterisk
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    placeholder="Password"
                    label="Password"
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                  <PasswordInput
                    placeholder="Repeat Password"
                    label="Repeat Password"
                    withAsterisk
                    {...form.getInputProps('repeatPassword')}
                  />
                  <label htmlFor="couponCode" className="block text-sm font-medium text-gray-700">
                    Coupon Code
                  </label>
                  <div className="!mt-0 flex">
                    <TextInput
                      className={couponClassName}
                      error={coupon && !coupon?.valid ? coupon?.message : false}
                      name="couponCode"
                      onInput={onCodeInput}
                    />

                    <button
                      onClick={onApplyCode}
                      type="button"
                      className={`${
                        isError && 'mb-5'
                      } w-1/3 ml-2 border-2 border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500`}
                    >
                      Apply Code
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
                          text-sm py-3 px-6 font-medium text-white bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Create Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="pb-6 md:pb-12 mt-4">
              <p className="text-xs sm:text-sm font-medium leading-4 2xl:leading-6 text-center text-gray-500">
                By signing up, you agree to our&nbsp;
                <span className="text-rose-600 underline cursor-pointer font-semibold">Terms Of Use</span>
                &nbsp;and&nbsp;
                <span className="text-rose-600 underline cursor-pointer font-semibold">Privacy Policy</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
