import { ShimmerLabel, ShimmerRow, ShimmerTxtField, ShimmerWrap } from 'components/shimmer/utils';

const InputFormShimmer = () => {
  return (
    <ShimmerWrap gap={5}>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField span={5} />
      </ShimmerRow>
    </ShimmerWrap>
  );
};

export default InputFormShimmer;
