import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

import { deleteItem } from './confirm-dialog.thunk';
import { openDialog, selectDeleteDialog } from './confirm-dialog.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import Spinner from 'components/shimmer/spinner';
import { switchUser } from 'pages/admin/manage-users/switch-user/switch-user.thunks';

function ConfirmDialog() {
  const [dialogOpen, setDialogState] = useState(false);
  const cancelButtonRef = useRef(null);
  const { isOpen, deleteUrl, deleteId, confirmText, customProps } = useAppSelector(selectDeleteDialog);
  const { isLoading } = useAppSelector(selectRequest);
  const dispatch = useAppDispatch();
  const [confirm, setConfirm] = useState<string>('');
  const userId = customProps?.userId;
  const message = customProps?.message;
  const buttonLabel = customProps?.buttonLabel;

  const handleDelete = () => deleteItem(deleteUrl, deleteId || null);

  // avoid memory leak
  useEffect(() => {
    setDialogState(isOpen);
  }, [isOpen]);

  const handleCloseDialog = () => {
    dispatch(
      openDialog({
        isOpen: false,
        deleteId: null,
        deleteUrl: null,
        customProps: null,
      })
    );
  };

  const handleSwitch = async () => {
    const res = await switchUser(userId);
    if (res) {
      window.location.href = process.env.REACT_APP_URL + '/tokenized/' + res.token || '';
    }
  };

  const handleChange = (e) => {
    setConfirm(e.target.value);
  };

  const message_ = message ? message : 'Are you sure you want to delete this Item?';

  const confirmLabel = confirmText ? (
    <span>
      Please type <strong>{confirmText}</strong> to Confirm
    </span>
  ) : (
    <span>{message_}</span>
  );

  return (
    <Transition.Root show={dialogOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={dialogOpen}
        onClose={handleCloseDialog}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Attention Needed
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{confirmLabel}</p>
                  </div>
                  {confirmText && (
                    <div>
                      <input
                        type="text"
                        className="flex-auto min-w-0 w-full px-3 py-1.5 mt-4 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-1 focus:ring-red-500 border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-500 focus:outline-none"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                <button
                  type="button"
                  disabled={isLoading || (Boolean(confirmText) && confirm !== confirmText)}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm disabled:opacity-75"
                  onClick={userId ? handleSwitch : handleDelete}
                >
                  {isLoading ? (
                    <>
                      <Spinner w={5} />
                      {`${buttonLabel?.slice(0, -1)}ing`}
                    </>
                  ) : (
                    buttonLabel || 'Closing'
                  )}
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium bg-gray-700 text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                  onClick={handleCloseDialog}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmDialog;
