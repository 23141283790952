import React, { ReactElement } from 'react';

const FormLabel = ({ label, ...props }): ReactElement => {
  return (
    <>
      <label className="block text-sm font-medium text-gray-700 sm:mt-px" {...props}>
        {label}
      </label>
    </>
  );
};

export default FormLabel;
