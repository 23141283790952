import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AccountsInterface } from '../../../../interfaces/accounts.interface';

const initialState: AccountsInterface[] = [];

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts: (state: Draft<AccountsInterface[]>, action: PayloadAction<AccountsInterface[]>) => {
      return action.payload;
    },
    deleteAccounts: (state: Draft<AccountsInterface[]>, action: PayloadAction<string>) => {
      const accounts = state;
      const updatedAccounts = accounts.filter((u: AccountsInterface) => u._id !== action.payload);
      return updatedAccounts;
    },
  },
});

export const { setAccounts, deleteAccounts } = accountsSlice.actions;

export const selectAccounts = (state: RootState) => state.accounts;

export const accountsReducer = accountsSlice.reducer;
