import Sidebar from 'components/sidebar/sidebar';
import Topbar from 'components/top-bar/Topbar';
import RequireAuth from 'components/auth/require-auth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectSidebarOpen } from 'components/sidebar/sidebar.slice';
import { useEffect } from 'react';

function DashboardContainer() {
  const isSidebarOpen = useAppSelector(selectSidebarOpen);
  const { pathname } = useLocation();
  const dashboard = pathname.replaceAll('/', '');

  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard === 'dashboard') {
      navigate('');
    }
  }, [dashboard]);

  const width = isSidebarOpen ? 56 : 14;
  const logoSrc = isSidebarOpen ? '/logo-white.png' : '/logo_mini.png';
  const env = process.env.NODE_ENV;
  const colors = {
    development: 'bg-pink-200',
    production: 'bg-white',
    staging: 'bg-emerald-100',
  };
  const sidebarColor = colors[env] || 'bg-lime-200';

  return (
    <>
      <RequireAuth>
        <div className="h-screen flex overflow-hidden bg-gray-100">
          <div className={`${sidebarColor} flex flex-shrink-0`}>
            <div className={`flex flex-col w-${width} border-r border-r-gray-200`}>
              <div className="flex items-center flex-shrink-0 pl-4">
                <img className={`h-12 ${isSidebarOpen ? 'mr-1' : ''} pt-4`} src={logoSrc} alt="Signalytics" />
              </div>
              <Sidebar />
            </div>
          </div>
          <Topbar>
            <Outlet />
          </Topbar>
        </div>
      </RequireAuth>
    </>
  );
}

export default DashboardContainer;
