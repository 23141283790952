import { ReactElement } from 'react';

/**
 *
 * @param user - user details
 * @param handleSelect = click item event
 * @returns
 */
const SearchItem = ({ user, handleSelect }): ReactElement => {
  return (
    <button
      key={user?._id}
      type="button"
      className="px-3 py-2 text-start border-b hover:bg-gray-100"
      onClick={() => handleSelect(user)}
    >
      <div className="grid grid-cols-1 w-full">
        <div className="grid grid-cols-8">
          <div className="col-span-4">
            <span className="col-span-">{user?.email}</span>
          </div>
          <div className="col-span-2">
            {user?.firstName ? <span className="">{user.firstName}</span> : <span className="text-center">—</span>}
          </div>
          <div className="col-span-2">
            {user?.lastName ? <span className="">{user.lastName}</span> : <span className="text-center">—</span>}
          </div>
        </div>
      </div>
    </button>
  );
};

export default SearchItem;
