import Alert from 'components/alerts/alert';
import { forgot } from 'pages/users/user/user.thunks';
import { useForm } from '@mantine/form';
import { Button, TextInput } from '@mantine/core';

function Forgot() {
  const onSubmit = async (values) => {
    await forgot(values);
  };
  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-auto" src="/logo-white.png" alt="Signalytics" />
        </div>

        <div className="my-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-12 px-8 shadow sm:rounded-lg sm:px-10">
            <h2 className="mb-6 text-center text-2xl font-bold text-gray-900">Forgot Password</h2>
            <form className="space-y-6" onSubmit={form.onSubmit((values) => onSubmit(values))}>
              <Alert />
              <TextInput
                withAsterisk
                label="Enter Email"
                placeholder="Email Address"
                {...form.getInputProps('email')}
              />
              <div className="flex">
                <Button
                  type="submit"
                  className="!w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgot;
