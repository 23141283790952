import { createEmotionCache, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import * as Sentry from '@sentry/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ErrorBoundary } from 'components/errors/errorboundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import App from './App';
// import { createRoot } from 'react-dom/client'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

const myCache = createEmotionCache({ key: 'mantine' });

if (!process.env.REACT_APP_USE_DEV) {
  Sentry.init({
    dsn: 'https://da63dcd798de4008aa55ba099a9e08af@o1123448.ingest.sentry.io/6575326',
    integrations: [new Sentry.Replay()],
    environment: 'production',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const container = document.getElementById('root');
const queryClient = new QueryClient();
const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <ErrorBoundary>
              <MantineProvider emotionCache={myCache} withGlobalStyles withNormalizeCSS theme={theme}>
                <ModalsProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen />
                </ModalsProvider>
              </MantineProvider>
            </ErrorBoundary>
          </PersistQueryClientProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  container
);

//TODO: in two months: To be used when we upgrade to React 18

// const root = createRoot(container)
// root.render(
//   // <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </PersistGate>
//     </Provider>
//   // {/* </React.StrictMode>  */}
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
