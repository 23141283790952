import Alert from 'components/alerts/alert';
import FormInput from 'components/forms/form-input';
import { resetPassword } from 'pages/users/user/user.thunks';
import { Form } from 'react-final-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

function ResetPassword() {
  const { email, passwordResetToken } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const res = await resetPassword({ ...values, email, passwordResetToken });
    if (res) {
      setTimeout(() => navigate('/reset-password/done'), 2000);
    }
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-auto" src="/logo-white.png" alt="Signalytics" />
        </div>

        <div className="my-8 sm:mx-auto sm:w-full sm:max-w-md">
          <Alert />
          <div className="bg-white py-12 px-8 shadow sm:rounded-lg sm:px-10">
            <h2 className="mb-6 text-center text-2xl font-bold text-gray-900">Enter your new Password</h2>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      New Password
                    </label>
                    <div className="mt-1">
                      <FormInput name="password" type="password" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="repeatPassword" className="block text-sm font-medium text-gray-700">
                      Repeat Password
                    </label>
                    <div className="mt-1">
                      <FormInput name="repeatPassword" type="password" />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="flex items-center">
                      <span className="text-gray-400 pr-2">Got an Account?</span>{' '}
                      <Link to="/" className="font-medium text-red-600 hover:text-red-500">
                        Login Here
                      </Link>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
