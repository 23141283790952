import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  colors: {
    brand: [
      '#fef2f2',
      '#fee2e2',
      '#fecaca',
      '#fca5a5',
      '#f87171',
      '#ef4444',
      '#dc2626',
      '#b91c1c',
      '#991b1b',
      '#7f1d1d',
    ],
  },
  primaryColor: 'brand',
  focusRingStyles: {
    styles: (theme) => ({ outline: `${theme.colors.red[5]}` }),
    inputStyles: (theme) => ({ outline: `${theme.colors.red[5]}` }),
  },
  // Override any other properties from default theme
};
