import FormLabel from 'components/forms/form-label';
import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';

/**
 * Create a form dropdown
 * @param span
 * @param gap
 * @param col
 * @param label - form label
 * @param name - dropdown name
 * @param values - array of values for the dropdown
 * @param optionValue - we will use this as value for select option
 * @param optionValueFormat
 * @param optionLabel- we will use this as label for select option
 * @param props
 * @constructor
 */

interface FormSelectInterface<FormValue> {
  span?: number;
  gap?: number;
  col?: number;
  label?: string;
  name: string;
  initialValue?: string;
  values: FormValue[];
  optionValue?: string;
  optionLabel?: string;
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  optionValueFormat?: (value: FormValue) => string;
}

/**
 * TODO: create generics FormSelectInterface<any>
 */
const FormSelect = ({
  span = 3,
  gap = 4,
  col = 4,
  label = '',
  name,
  values,
  optionValue = '_id',
  optionValueFormat,
  optionLabel = 'name',
  handleChange,
  ...props
}: FormSelectInterface<any>): ReactElement => {
  const formatValue = (value): string => {
    const v = optionValueFormat ? optionValueFormat(value) : value[optionValue];
    return v;
  };

  const field = (
    <Field name={name} {...props}>
      {({ input }) => (
        <>
          <select
            {...input}
            onChange={(e) => {
              handleChange && handleChange(e);
              input.onChange(e);
            }}
            className="block focus:ring-red-500 focus:border-red-500 w-full shadow-sm sm:max-w-s sm:text-sm border-gray-300 rounded-md"
          >
            {values?.map((value) => (
              <option value={formatValue(value)} key={value[optionValue]}>
                {value[optionLabel]}
              </option>
            ))}
          </select>
        </>
      )}
    </Field>
  );
  if (!label) return field;
  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-${gap} items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormSelect;
