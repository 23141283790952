import { postSilentFetch } from 'request/request-api';
import { tableDataInterface } from './keyword-extractor.interfaces';

export const searchResults = (values): Promise<tableDataInterface[]> => {
  const { keyword, type, dataSource } = values;
  if (dataSource === 'brand-analytics') {
    return postSilentFetch({ url: '/brand-analytics/keyword-extractor/search', data: { keyword, type } });
  } else {
    return postSilentFetch({ url: '/brand-analytics/keyword-extractor/amazon', data: { keyword, type } });
  }
};
