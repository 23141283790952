import { Button } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons';
import Card from 'components/card/card';
import SubpageContainer from 'components/containers/subpage.container';
import { useSplitTest } from 'pages/users/split-tests/split-test.hooks';
import { useParams } from 'react-router-dom';

export default function SplitTestResult() {
  const params = useParams();
  const id = params?.id || '';
  const data = useSplitTest(id);
  return (
    <>
      <SubpageContainer title="Result">
        <div className="grid grid-cols-2 space-x-2">
          <Card>
            {data?.outputFile?.map((file) => {
              return (
                <div className="border-b-2 border-b-gray-100 space-y-4 py-6 text-center" key={file[0]?._id}>
                  <img src={file[0]?.image} />
                  <Button
                    component="a"
                    href={file[2]?.image}
                    variant="outline"
                    target="_blank"
                    leftIcon={<IconExternalLink size="0.9rem" />}
                  >
                    Download Image
                  </Button>
                </div>
              );
            })}
          </Card>
          <Card title="Feedback:">{data?.comment}</Card>
        </div>
      </SubpageContainer>
    </>
  );
}
