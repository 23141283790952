import React, { ReactElement } from 'react';
import SubpageContainer from 'components/containers/subpage.container';
import { MFormDropZoneButton } from 'components/mantine/forms/m-form-dropzone-button';
import { ABTestImageGrid } from 'pages/users/ab-test/components/abtest.image-grid';
import { useABTest } from 'pages/users/ab-test/abtest.hook';
import { Button, Skeleton } from '@mantine/core';
import If from 'components/conditionals/if.conditional';
import ShowAdmin from 'components/show-admin/show-admin';

export const ABTestUpload = (): ReactElement => {
  const { abTestData: data, uploadImage, id, navigate, isLoading } = useABTest(false);

  const status = data?.status ?? 'pending';

  function goToUploadResult() {
    navigate('/dashboard/abtests/upload/output/' + id);
  }
  return (
    <SubpageContainer title={''}>
      <div className="flex flex-col">
        <div className=" w-1/2 self-center">
          <Skeleton visible={isLoading}>
            <If show={status === 'pending'}>
              <MFormDropZoneButton
                disabled={data && data?.inputFile.length === 10}
                text="Upload Images"
                image={
                  <div className="flex justify-center">
                    <img src={'/abtest/upload.png'} alt="" className="bg-contain bg-center w-28" />
                  </div>
                }
                handleUpload={uploadImage}
                subText="Add up to 10 images per analysis"
              />
            </If>
          </Skeleton>
        </div>
        <ShowAdmin>
          <If show={status === 'processing'}>
            <div className="px-20 flex justify-end">
              <Button size="xs" onClick={goToUploadResult} className="mt-1">
                Upload Result
              </Button>
            </div>
          </If>
        </ShowAdmin>
        <div className="px-20 py-10">
          <Skeleton visible={isLoading}>
            <div className="grid grid-cols-5 gap-6 ">
              {[...Array(10)].map((x, index: number) => {
                return (
                  <ABTestImageGrid
                    key={index}
                    fullImage={data?.inputFile[index]?.images[0]?.image}
                    thumbnail={data?.inputFile[index]?.images[1]?.image}
                    download={data?.inputFile[index]?.images[2]?.image}
                    currentIndex={index + 1}
                    hideDelete={status === 'processing' || status === 'done'}
                  />
                );
              })}
            </div>
          </Skeleton>
        </div>
        <div className="self-center">
          <Skeleton visible={isLoading}>
            <If show={id === '' || status === 'pending'}>
              <Button
                color="dark"
                size="md"
                onClick={() => navigate(`/dashboard/abtests/goal/${id}`)}
                disabled={id === '' || (data && data?.inputFile.length < 2)}
              >
                Next
              </Button>
            </If>
          </Skeleton>
        </div>
      </div>
    </SubpageContainer>
  );
};
