import {
  ShimmerButton,
  ShimmerButtonWrapper,
  ShimmerDivider,
  ShimmerLabel,
  ShimmerRow,
  ShimmerTransparent,
  ShimmerTxtField,
  ShimmerWrap,
} from 'components/shimmer/utils';

const RebatesShimmer = () => (
  <div className="w-100">
    <ShimmerWrap>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerDivider />
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerDivider />
      <ShimmerButtonWrapper>
        <ShimmerTransparent />
        <ShimmerButton />
      </ShimmerButtonWrapper>
    </ShimmerWrap>
  </div>
);

export default RebatesShimmer;
