import { Component, ReactNode } from 'react';
import GenericError from 'components/errors/generic.error';
import * as Sentry from '@sentry/react';

interface ErrorStateInterface {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{ children: ReactNode }, ErrorStateInterface> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <GenericError />
        </>
      );
    }
    return this.props.children;
  }
}
