import React from 'react';
import { Modal, Text, Button } from '@mantine/core';
function KeywordResearchConfirmModal({ opened, close, selectedDataSet, deleteSet }) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title={<Text fw={700}>You are about to delete a Brand/Project</Text>}
      size="md"
      overlayColor={'gray'}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <div className="my-4">
        <Text>
          Are you sure you want to delete <strong>"{selectedDataSet?.label}"</strong> ?
        </Text>
      </div>
      <div className="px-0 py-2 sm:px-0 sm:flex pl-0 justify-end">
        <Button type="submit" onClick={() => deleteSet(selectedDataSet?.value)}>
          Yes
        </Button>
        <Button type="button" variant="white" className="ml-2" onClick={close}>
          No
        </Button>
      </div>
    </Modal>
  );
}

export default KeywordResearchConfirmModal;
