import { format, getISOWeek, parse, sub, subMonths } from 'date-fns';
import { useTopSearchesContext } from './top-searches.context';
import { useMutation } from '@tanstack/react-query';
import { getDaily, getMonthly, getWeekly } from './top-searches.thunks';
import { getMonthsBetweenDates, getWeekNumbersBetweenDates, getYearlyQuarters } from './top-searches.helper';
import { TopSearchResultInterfaces, tsqInterface } from './top-searches.interfaces';
import { useEffect } from 'react';
import { chartColors } from 'lib/colors.helpers';

export default function useTopSearchesEvents() {
  const { topSearchesStates, dispatch } = useTopSearchesContext();
  const { mutate: create } = useMutation({
    mutationFn: (data: tsqInterface) => getDaily(data),
    onSuccess: (data, variables) => {
      data = data.map((item) => {
        item.keyword_label = variables.keyword;
        return item;
      });
      dispatch({ type: 'set_fetched_data', payload: data });
    },
    cacheTime: 0,
  });
  const { mutate: getWeeklyData } = useMutation({
    mutationFn: (data: any) => getWeekly(data),
    onSuccess: (data: any) => {
      if (data.length !== 0) {
        data = data.map((item) => {
          item.label = topSearchesStates.searchTerm;
          return item;
        });
        dispatch({ type: 'add_weekly_table_data', payload: data });
      }
    },
  });
  const { mutate: getMonthlyData } = useMutation({
    mutationFn: (data: any) => getMonthly(data),
    onSuccess: (data: any) => {
      if (data.length !== 0) {
        data = data.map((item) => {
          item.label = topSearchesStates.searchTerm;
          return item;
        });
        dispatch({ type: 'add_monthly_table_data', payload: data });
      }
    },
  });
  function enterKeyword(e) {
    if (e.key === 'Enter') {
      if (e.target.value !== '' && e.target.value.replace(/\s/g, '').length) {
        dispatch({ type: 'set_input_value', payload: e.target.value });
        if (
          !topSearchesStates.keywords.find((keyword) => keyword.keyword.toLowerCase() === e.target.value.toLowerCase())
        ) {
          fetchData(e.target.value);
        }
      }
      dispatch({ type: 'set_input_visibility' });
    }
  }

  const removeKeyword = (keyword) => {
    dispatch({ type: 'set_input_value', payload: '' });
    dispatch({ type: 'remove_keyword', payload: keyword.keyword });
    dispatch({ type: 'remove_graph_data', payload: keyword.keyword });
    dispatch({ type: 'remove_monthly_table_data', payload: keyword.keyword });
    dispatch({ type: 'remove_weekly_table_data', payload: keyword.keyword });
    dispatch({ type: 'update_graph_labels' });
  };

  const showInputField = () => {
    dispatch({ type: 'set_input_visibility' });
  };

  const handleOutsideClicks = (event, ref) => {
    if (ref.current && !ref.current.contains(event.target)) {
      dispatch({ type: 'set_input_visibility' });
    }
  };

  const setDataType = (e) => {
    dispatch({ type: 'set_graph_type', payload: e });
  };

  const setStartDate = (e) => {
    dispatch({ type: 'set_start_date', payload: e });
  };

  const setEndDate = (e) => {
    dispatch({ type: 'set_end_date', payload: e });
  };

  const loadChartData = (fetchedData) => {
    dispatch({ type: 'set_loading', payload: false });
    let total = 0;
    const { dateArray, dataPoints } = generateLabels(fetchedData);
    const currentDate = new Date(); // Current date
    const previousWeek = sub(currentDate, { weeks: 1 });
    const weekNumber = getISOWeek(previousWeek);
    const previousMonth = subMonths(currentDate, 1); // Subtract one month
    const previousMonthName = format(previousMonth, 'MMMM'); // Format as month name

    dataPoints.map((point) => {
      total += Number(point);
    });

    dispatch({ type: 'set_graph_labels', payload: dateArray });
    const existingKeyword = topSearchesStates.keywords.find(
      (keyword) => keyword.keyword === topSearchesStates.searchTerm
    );

    if (topSearchesStates.searchTerm && !existingKeyword) {
      const keyword = {
        keyword: topSearchesStates.searchTerm,
        totalFrequency: total,
        startFrequency: fetchedData[0]?.search_frequency_rank ?? 0,
        endFrequency: fetchedData[fetchedData.length - 1]?.search_frequency_rank ?? 0,
      };
      dispatch({ type: 'add_keyword', payload: keyword });
      getWeeklyData({
        keyword: topSearchesStates.searchTerm,
        weekNo: weekNumber,
        year: '2023',
      });
      getMonthlyData({
        keyword: topSearchesStates.searchTerm,
        monthName: previousMonthName,
        year: '2023',
      });
    }
    if (fetchedData.length !== 0) {
      if (!topSearchesStates.chartData.find((e) => e.label === fetchedData[0]?.keyword_label)) {
        dispatch({
          type: 'add_graph_data',
          payload: {
            label: fetchedData[0]?.keyword_label,
            data: dataPoints,
            borderColor: chartColors[topSearchesStates.chartData.length],
            backgroundColor: chartColors[topSearchesStates.chartData.length],
          },
        });
        dispatch({ type: 'update_graph_labels' });
      }
    }
  };

  const generateLabels = (fetchedData: TopSearchResultInterfaces[]) => {
    const startDateObj = new Date(topSearchesStates.startDate);
    const endDateObj = new Date(topSearchesStates.endDate);
    const currentDate = startDateObj;
    const dataPoints: (number | null)[] = [];
    let dateArray: string[] | number[] = [] as string[];
    switch (topSearchesStates.dataType) {
      case 'daily': {
        while (currentDate <= endDateObj) {
          const formattedDate = format(currentDate, 'yyyy-MM-dd');
          const dataByDate = fetchedData?.find((data) => data.start_date === formattedDate);
          if (dataByDate) {
            dataPoints.push(dataByDate.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
          dateArray.push(format(currentDate, 'MMM dd'));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return { dateArray, dataPoints };
      }
      case 'monthly': {
        const months = getMonthsBetweenDates(startDateObj, endDateObj);
        months.map((month) => {
          const dataByMonth = fetchedData?.find((data) => {
            if (data.month != null) {
              const date = parse(data.month, 'MMMM', new Date());
              const shortMonthName = format(date, 'MMM');
              return `${shortMonthName}-${data.year}` === month;
            }
          });
          if (dataByMonth) {
            dataPoints.push(dataByMonth.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
        });
        dateArray = months;
        return { dateArray, dataPoints };
      }
      case 'quarterly': {
        const quarters = getYearlyQuarters(startDateObj, endDateObj);
        quarters.map((quarter) => {
          const dataByQuarter = fetchedData?.find((data) => `Q${data.quarter_no}-${data.year}` === quarter);
          if (dataByQuarter) {
            dataPoints.push(dataByQuarter.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
        });
        dateArray = quarters;
        return { dateArray, dataPoints };
      }
      case 'weekly': {
        const weekly = getWeekNumbersBetweenDates(startDateObj, endDateObj);

        weekly.map((weekNo) => {
          const dataByDate = fetchedData?.find((data) => `Week ${data.week_no}-${data.year}` === weekNo);
          if (dataByDate) {
            dataPoints.push(dataByDate.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
        });
        dateArray = weekly;
        return { dateArray, dataPoints };
      }
      default:
        return { dateArray, dataPoints };
    }
  };

  const reloadData = (keywords) => {
    if (keywords.length !== 0) {
      dispatch({ type: 'set_loading', payload: true });
      dispatch({ type: 'reset_graph_data' });
      keywords.map((keyword) => {
        fetchData(keyword.keyword);
      });
    }
  };

  const fetchData = (keyword) => {
    dispatch({ type: 'set_loading', payload: true });

    create({
      startDate: format(topSearchesStates.startDate, 'yyyy-MM-dd'),
      endDate: format(topSearchesStates.endDate, 'yyyy-MM-dd'),
      keyword,
      period: topSearchesStates.dataType,
    });
  };

  useEffect(() => {
    if (topSearchesStates) {
      reloadData(topSearchesStates.keywords);
    }
  }, [topSearchesStates?.dataType]);

  const generateSearchTermChartData = (fetchedData, dataType) => {
    const dataPoints: (number | null)[] = [];
    let dateArray: string[] | number[] = [] as string[];

    const chartData = {
      label: 'Search Frequency Rank',
      data: dataPoints,
      borderColor: chartColors[2],
      backgroundColor: chartColors[2],
    };
    let { minStartDate, maxStartDate } = fetchedData.reduce(
      (acc, curr) => {
        acc.minStartDate = acc.minStartDate
          ? curr.start_date < acc.minStartDate
            ? curr.start_date
            : acc.minStartDate
          : curr.start_date;
        acc.maxStartDate = acc.maxStartDate
          ? curr.start_date > acc.maxStartDate
            ? curr.start_date
            : acc.maxStartDate
          : curr.start_date;
        return acc;
      },
      { minStartDate: null, maxStartDate: null }
    );
    minStartDate = new Date(minStartDate);
    maxStartDate = new Date(maxStartDate);
    minStartDate.setDate(minStartDate.getDate() - 1);

    switch (dataType) {
      case 'weekly': {
        const weekly = getWeekNumbersBetweenDates(minStartDate, maxStartDate);
        weekly.map((weekNo) => {
          const dataByDate = fetchedData?.find((data) => `Week ${data.week_no}-${data.year}` === weekNo);
          if (dataByDate) {
            dataPoints.push(dataByDate.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
        });
        dateArray = weekly;
        chartData.data = dataPoints;
        return { dateArray, dataPoints, chartData };
      }
      case 'monthly': {
        const months = getMonthsBetweenDates(minStartDate, maxStartDate);
        months.map((month) => {
          const dataByMonth = fetchedData?.find((data) => {
            if (data.month != null) {
              const date = parse(data.month, 'MMMM', new Date());
              const shortMonthName = format(date, 'MMM');
              return `${shortMonthName}-${data.year}` === month;
            }
          });
          if (dataByMonth) {
            dataPoints.push(dataByMonth.search_frequency_rank);
          } else {
            dataPoints.push(null);
          }
        });
        dateArray = months;
        chartData.data = dataPoints;
        return { dateArray, dataPoints, chartData };
      }
      default:
        return { dateArray, dataPoints, chartData };
    }
  };

  return {
    enterKeyword,
    removeKeyword,
    showInputField,
    handleOutsideClicks,
    setStartDate,
    setEndDate,
    setDataType,
    reloadData,
    fetchData,
    loadChartData,
    generateSearchTermChartData,
  };
}
