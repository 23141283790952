import { Button, Grid, Image, Text } from '@mantine/core';
import TabsBranding from 'pages/users/apollo/steps/listing/tabs';
import React from 'react';
import { listingContext } from 'pages/users/apollo/steps/listing/context';
import { SpinnerText } from 'components/shimmer/spinner-text';
import IfElse from 'components/conditionals/if-else.conditional';
import { useApolloResult } from 'pages/users/apollo/steps/apollo.result.hooks';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { SuggestionResponse } from '../../apollo.interfaces';

export default function ImageSuggestion() {
  const { suggestion } = listingContext();
  const { isLoading } = useApolloResult('suggestion');
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToTags = () => {
    navigate('/dashboard/apollo/create-list/tags/' + id);
  };
  const navigateToDescription = () => {
    navigate('/dashboard/apollo/create-list/description/' + id);
  };
  return (
    <>
      <TabsBranding>
        <IfElse show={isLoading}>
          <SpinnerText text={'🤖 AI is Creating New Variants. Please wait... 🚀'} />
          <div className="flex flex-col gap-4">
            {suggestion?.map((suggest: SuggestionResponse, index: number) => (
              <div className="bg-gray-100 my-4 rounded-md">
                <Grid key={`Suggestion-${index}`} className="px-4 py-6">
                  <Grid.Col span={12} className="flex flex-col items-center">
                    <div className="w-1/2 mb-3">
                      <Image src={suggest.url} className="rounded-md" />
                    </div>
                    <Text>
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{ __html: suggest.response?.replaceAll('\n', '<br />') }}
                      ></div>
                    </Text>
                  </Grid.Col>
                </Grid>
              </div>
            ))}
          </div>
        </IfElse>

        <div className="flex justify-between mt-5">
          <Button onClick={navigateToDescription} leftIcon={<ArrowLeftIcon className="w-5 h-5 " />} color="dark">
            Previous
          </Button>
          <Button onClick={navigateToTags} rightIcon={<ArrowRightIcon className="w-5 h-5 " />} color="dark">
            Next
          </Button>
        </div>
      </TabsBranding>
    </>
  );
}
