import Alert from 'components/alerts/alert';
import { adminCookieName, setCookieToken } from 'lib/cookie.helper';
import { useForm } from '@mantine/form';
import { setUserData } from 'pages/users/user/user.slice';
import { login } from 'pages/users/user/user.thunks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useQuery } from '@tanstack/react-query';
import { validateSplitTest } from './abtest.thunk';
import IfElse from '../../../components/conditionals/if-else.conditional';
import { switchUser } from '../../admin/manage-users/switch-user/switch-user.thunks';
import { Button, LoadingOverlay, PasswordInput, TextInput } from '@mantine/core';
import CustomLoader from './components/abtest.loader';
import React from 'react';
import GenericMessage from '../../../components/errors/generic-message';
import { store } from '../../../redux/store';
import { validationError } from '../../../request/request.slice';

function ABTestAutoLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = params?.id || '';
  const { data, isError, isLoading } = useQuery(['validate-abtest'], {
    queryFn: () => validateSplitTest(id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: true,
    cacheTime: 0,
  });
  const onSubmit = async (values) => {
    const adminLogin = await login(values);
    if (adminLogin && adminLogin.user.isAdmin) {
      setCookieToken(adminLogin.token);
      const res = await switchUser(data?.user);
      dispatch(setUserData({ ...res.user, isAdminMimickingUser: true }));
      setCookieToken(adminLogin.token, adminCookieName);
      setCookieToken(res.token);
      navigate('/dashboard/abtests/upload/' + data?._id);
    } else {
      store.dispatch(validationError({ message: 'You must log in as superadmin.' }));
    }
  };
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  return (
    <div>
      <IfElse show={isLoading}>
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={5}
          loader={<CustomLoader textMessage="Verifying split test id..." />}
        />
        <IfElse show={isError}>
          <GenericMessage title="Split-test does not exist !" desc="It may have been deleted or the id is incorrect." />
          <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto w-auto" src="/logo-white.png" alt="Signalytics" />
            </div>

            <div className="my-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-12 px-8 shadow sm:rounded-lg sm:px-10">
                <h2 className="mb-6 text-center text-2xl font-bold text-gray-900">Sign in to your account</h2>
                <form className="space-y-6" onSubmit={form.onSubmit((values) => onSubmit(values))}>
                  <Alert />
                  <TextInput
                    withAsterisk
                    label="Email Address"
                    placeholder="Email Address"
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    placeholder="Password"
                    label="Password"
                    withAsterisk
                    {...form.getInputProps('password')}
                  />

                  <div className="text-sm">
                    <Link to="forgot" className="font-medium text-red-600 hover:text-red-500">
                      Forgot your password?
                    </Link>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      className="!w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Sign In
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </IfElse>
      </IfElse>
    </div>
  );
}

export default ABTestAutoLogin;
