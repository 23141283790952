import { useQuery } from '@tanstack/react-query';
import { searchProduct } from 'pages/users/apollo/apollo.thunk';

export const useApolloCompetitors = () => {
  const params = new URLSearchParams(window.location.search);
  const asins = params.get('asins')?.split(',') as string[];
  const keywords = params.get('keywords')?.split(',') as string[];

  const { data: products } = useQuery(['apollo-details', asins[0]], {
    queryFn: () => searchProduct(asins),
    refetchOnWindowFocus: false,
    cacheTime: 60000 * 25, // 25minutes
    staleTime: 60000 * 15, // 15minutes
  });

  return {
    keywords,
    asins,
    products,
  };
};
