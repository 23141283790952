import { CheckCircleIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import PrimaryButton from 'components/buttons/primary-button';
import IfElse from 'components/conditionals/if-else.conditional';
import { moneyFormat } from 'lib/formatting.helpers';
import { usePlans } from 'pages/users/subscription/plans/plans.hooks';
import {
  PackagePriceInterface,
  PackageTypes,
  PlanInterface,
  PlanType,
} from 'pages/users/subscription/plans/plans.interface';
import { changePlan } from 'pages/users/subscription/plans/plans.thunks';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import CustomLoader from '../../ab-test/components/abtest.loader';
import React from 'react';
import { useDisclosure } from '@mantine/hooks';

const activeClass = {
  parent: 'ring-2 ring-red-500 border-0',
  check: 'h-5 w-5 text-red-600',
};
const inActiveClass = {
  parent: 'border-gray-300',
  check: 'hidden',
};

function setClass(price: PackagePriceInterface, defaultId: string, property: string) {
  if (price.monthly === defaultId || price.annual === defaultId) {
    return activeClass[property];
  } else {
    return inActiveClass[property];
  }
}

function setDisable(price: PackagePriceInterface, defaultId: string, planStatus: string) {
  if (planStatus === 'canceled') {
    return false;
  } else {
    return price.monthly === defaultId || price.annual === defaultId;
  }
}

export default function Plans({
  planType = PlanType.BASIC,
  type = PackageTypes.MONTHLY,
  currentPlanPackage,
  planStatus,
}: {
  planType: string;
  type: PackageTypes;
  currentPlanPackage: PlanInterface;
  planStatus: string;
}) {
  const navigate = useNavigate();
  const currentPlan = currentPlanPackage?.id;
  const plans = usePlans(currentPlan).filter((plan) => plan.type === planType);
  const [visible, { toggle }] = useDisclosure(false);

  async function reSubscribe(priceId: string) {
    toggle();
    if (planStatus === 'canceled') {
      navigate(`/dashboard/subscription/reactivate/${priceId}`);
    } else {
      const result = await changePlan(priceId);
      if (result) {
        setTimeout(() => {
          navigate(0);
        }, 1000);
      }
    }
  }
  let cta = 'Subscribe';
  const cols = planType === PlanType.BASIC ? 'grid-cols-3' : 'grid-cols-2 xl:grid-cols-4';
  return (
    <div>
      <div className={`mt-4 grid gap-y-1 ${cols} gap-x-4`}>
        {plans.map((plan) => {
          if (planStatus === 'canceled') {
            cta = 'Subscribe';
          } else if (currentPlanPackage.packagePlan.tier === plan.tier) {
            cta = 'Current Plan';
          } else if (currentPlanPackage.packagePlan.tier > plan.tier) {
            cta = 'Downgrade';
          } else {
            cta = 'Upgrade';
          }
          const description = planType === PlanType.BASIC ? '' : plan?.costSpend || <>&nbsp;</>;
          const included = plan?.included || [];
          const features = plan?.included ? [...included, '', ...plan.features] : plan.features;
          return (
            <div
              key={plan.name}
              className={`space-y-2  ${setClass(
                plan.priceId,
                currentPlan,
                'parent'
              )} bg-white border rounded-lg shadow-sm p-4 focus:outline-none relative`}
            >
              <LoadingOverlay
                visible={visible}
                overlayBlur={3}
                className="rounded-lg"
                loader={<CustomLoader textMessage="" />}
              />
              <div key={plan.productId} className={` relative flex`}>
                <>
                  <div className="flex-1 flex mb-4">
                    <div className="flex flex-col w-full">
                      <h3 className="block text-md font-medium text-gray-600">{plan.title}</h3>
                      <h3 className="block text-xl xl:text-2xl font-bold text-gray-900">
                        {moneyFormat(plan.price[type])}{' '}
                        <span className="text-xs xl:text-sm text-red-700">{description}</span>
                      </h3>

                      <ul role="list" className="mt-4 space-y-2">
                        {features.map((feature) => (
                          <li key={feature} className="flex space-x-3">
                            <IfElse show={feature.length > 0}>
                              <>
                                <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                <span className="text-sm text-gray-500">{feature}</span>
                              </>
                              <div className="h-1 my-2 border-t-2 border-gray-200 w-full"></div>
                            </IfElse>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <CheckCircleIcon className={setClass(plan.priceId, currentPlan, 'check')} aria-hidden="true" />
                  <div
                    className="border-transparent absolute -inset-px rounded-lg pointer-events-none"
                    aria-hidden="true"
                  />
                </>
              </div>
              <PrimaryButton
                onClick={() => reSubscribe(plan.priceId[type])}
                size="sm"
                disabled={setDisable(plan.priceId, currentPlan, planStatus)}
              >
                {cta}
              </PrimaryButton>
            </div>
          );
        })}
      </div>
    </div>
  );
}
