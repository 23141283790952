import SubpageContainer from 'components/containers/subpage.container';
import { selectGroup } from 'pages/users/heatmap/groups/heatmap-groups.slice';
import HeatmapHeader from 'pages/users/heatmap/heat-map.header';
import HeatmapImage from 'pages/users/heatmap/images/heatmap-image';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const HeatmapUploads = () => {
  const { docs: groups } = useAppSelector(selectGroup);
  const navigate = useNavigate();
  const { id } = useParams();

  const group = groups?.find((g) => g._id === id);

  return (
    <SubpageContainer title="">
      <HeatmapHeader title={group?.name} buttonLabel="Upload" handleAction={() => navigate('new')} />
      <HeatmapImage />
    </SubpageContainer>
  );
};

export default HeatmapUploads;
