import { emptyFunction } from 'lib/empty';
import React from 'react';
import { keywordExtractorContextInterface } from './keyword-extractor.interfaces';

export const ExtractorContext = React.createContext<keywordExtractorContextInterface>({
  active: 0,
  keywordCount: 0,
  asinCount: 0,
  dataSource: 'brand-analytics',
  setDataSource: emptyFunction,
  asins: [],
  setActive: emptyFunction,
  keywords: [],
  keyword: '',
  searchKeyword: '',
  searchAsin: '',
  setSearchKeyword: emptyFunction,
  setSearchAsin: emptyFunction,
  setAsins: emptyFunction,
  setKeywords: emptyFunction,
  setKeyword: emptyFunction,
  setasinCount: emptyFunction,
  setkeywordCount: emptyFunction,
});
