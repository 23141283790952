import RequireAuth from 'components/auth/require-auth';
import React from 'react';

import PPCAIHeader from './ppc-ai.header';

const ppc = 'https://signalytics.ppc-board.com/#/login';
const PPCAI = () => {
  return (
    <RequireAuth>
      <>
        <div>
          <PPCAIHeader />
          <iframe src={ppc} className="w-full h-screen"></iframe>
        </div>
      </>
    </RequireAuth>
  );
};

export default PPCAI;
