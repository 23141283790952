import StatusBadge from 'components/badges/status.badge';
import SubpageContainer from 'components/containers/subpage.container';
import FormElement from 'components/forms/form-element';
import ShimmerWrapper from 'components/shimmer';
import useFetch from 'hooks/useFetch';
import { fallbackImage } from 'lib/helpers';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { productInfo } from 'pages/users/products/products.thunks';
import { useParams } from 'react-router-dom';

const Container = ({ bg = 'bg-white', children }) => {
  return <div className={`py-2 ${bg} px-6`}>{children}</div>;
};

const ProductInfo = () => {
  const { id } = useParams();

  const { isFetching, data: product } = useFetch('product-information', () => productInfo(id));

  const addDefaultSource = (e) => {
    e.target.src = fallbackImage;
  };

  return (
    <SubpageContainer title="Product Information">
      <ShimmerWrapper loading={isFetching} fallback={<RebatesShimmer />}>
        <form className="mt-4">
          <div className=" bg-white rounded-md">
            <div className="grid w-full grid-cols-1 gap-4">
              <div className="grid grid-cols-1 gap-4">
                <Container bg="bg-gray-50 pt-6 pb-4 rounded-t">
                  <FormElement label="Name" align="items-start" col={6} span={5}>
                    <div className="text-md">{product.name}</div>
                  </FormElement>
                </Container>
                <Container bg="py-0">
                  <FormElement label="Price" col={6} span={5}>
                    <div className="text-md">{`$ ${product.price}`}</div>
                  </FormElement>
                </Container>
                <Container bg="bg-gray-50 py-4">
                  <FormElement label="ASIN" col={6} span={5}>
                    <div className="text-md">{product.asin1}</div>
                  </FormElement>
                </Container>
                <Container bg="py-0">
                  <FormElement label="Seller SKU" col={6} span={5}>
                    <div className="text-md">{product.sellerSku}</div>
                  </FormElement>
                </Container>
                <Container bg="bg-gray-50  py-4">
                  <FormElement label="Status" col={6} span={5}>
                    <div>
                      <StatusBadge text={product.status} />
                    </div>
                  </FormElement>
                </Container>
                <Container bg="py-0">
                  <FormElement label="Description" align="items-start" col={6} span={5}>
                    <div className="text-md">{product.description}</div>
                  </FormElement>
                </Container>
                <Container bg="bg-gray-50  py-4 pb-6 rounded-b">
                  <FormElement label="Image" align="items-start" col={6} span={5}>
                    <div>
                      <img src={product?.productImages?.main} onError={addDefaultSource} />
                    </div>
                  </FormElement>
                </Container>
              </div>
            </div>
          </div>
        </form>
      </ShimmerWrapper>
    </SubpageContainer>
  );
};

export default ProductInfo;
