import SubpageContainer from 'components/containers/subpage.container';
import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import { getApolloResults } from 'pages/users/apollo/apollo.thunk';
import { MTable } from 'components/mantine/tables/m-table';
import apolloTableData from 'pages/users/apollo/apollo.tabledata';
import RequestContainer from 'components/containers/request.container';
import MTableShimmer from 'components/mantine/tables/m-table-shimmer';
import { MTableWrapper } from 'components/mantine/tables/m-table-wrapper';
import TablePagination from 'components/tables/table.pagination';

export default function Apollo() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useFetch('apollo' + currentPage, () => getApolloResults({ page: currentPage }));

  const { docs: apollos, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } = data;
  const columns = apolloTableData();

  return (
    <>
      <SubpageContainer title="Apollo" showAdd showAddString="Create New" addUrl={'keyword-extractor'}>
        <MTableWrapper hasData={apollos?.length !== 0}>
          <RequestContainer
            loading={isFetching}
            fallback={<MTableShimmer span={columns.length} />}
            colSpan={columns.length}
            data={apollos}
            noDataMsg="No apollo results found!"
            addNew={false}
          >
            <MTable columns={columns} data={apollos} />
          </RequestContainer>
        </MTableWrapper>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={apollos?.length}
        />
      </SubpageContainer>
    </>
  );
}
