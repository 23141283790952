export enum FormMethod {
  'GET' = 'GET',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'DELETE' = 'DELETE',
}

export interface RequestInterface {
  url: string;
  formData?: FormData;
  httpMethod?: FormMethod;
  dispatchSaveMessage?: boolean;
  saveMessage?: string;
  isAdmin: boolean;
}
