import React, { Dispatch } from 'react';
import {
  chartDataSetInterface,
  keywordInterface,
  stateInterface,
  TopSearchResultInterfaces,
} from './top-searches.interfaces';

export type Action = {
  type: string;
  payload?:
    | boolean
    | TopSearchResultInterfaces[]
    | Date
    | chartDataSetInterface
    | keywordInterface
    | string[]
    | string
    | number[];
};
interface contextInterface {
  topSearchesStates: stateInterface;
  dispatch: Dispatch<Action>;
}

const TopSearchesContext = React.createContext({} as contextInterface);

export function useTopSearchesContext() {
  return React.useContext(TopSearchesContext);
}

export default TopSearchesContext;
