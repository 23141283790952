import React, { ReactElement } from 'react';
import { Field } from 'react-final-form';
import FormLabel from 'components/forms/form-label';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**
 * Form Textarea
 * @param label - input label
 * @param name - input name
 * @param col - label grid col , see tailwind col
 * @param span - input grid span
 * @param type - input type text, number, email
 * @param props - other input props
 * @constructor
 */
const FormRichTextEditor = ({ label = '', name, col = 4, span = 3, type = 'text', ...props }): ReactElement => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const field = (
    <Field
      autoComplete="off"
      type={type}
      name={name}
      id={name}
      {...props}
      className="block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-s sm:text-sm border-gray-300 rounded-md"
      placeholder={label}
    >
      {({ input }) => (
        <>
          <ReactQuill theme="snow" value={input.value} onChange={input.onChange} modules={modules} />
        </>
      )}
    </Field>
  );

  if (!label) return field;
  return (
    <>
      <div className={`sm:grid sm:grid-cols-4 sm:gap-${col} sm:items-center`}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{field}</div>
      </div>
    </>
  );
};

export default FormRichTextEditor;
