import FormLabel from 'components/forms/form-label';
import React, { ReactElement } from 'react';

const FormCustom = ({ label = '', children, col = 4, span = 3, ...props }): ReactElement => {
  return (
    <>
      <div className={`sm:grid sm:grid-cols-${col} sm:gap-4`} {...props}>
        <FormLabel label={label} />
        <div className={`mt-1 sm:mt-0 sm:col-span-${span}`}>{children}</div>
      </div>
    </>
  );
};

export default FormCustom;
