import React from 'react';
import { BackgroundImage, Center, Button, Box } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

function ABTestStart() {
  const navigate = useNavigate();

  function goToUploads() {
    navigate('/dashboard/abtests/upload');
  }

  return (
    <Box className="border-dashed border-2 border-slate-800 rounded-lg bg-white" h="100%">
      <BackgroundImage src="/image1.png" h="100%" className="!bg-left-top">
        <Center p="md" h="100%">
          <Button onClick={goToUploads} size="md">
            Start Here
          </Button>
        </Center>
      </BackgroundImage>
    </Box>
  );
}

export default ABTestStart;
