import OrderBadge from 'components/badges/order.status';
import PayoutBadge from 'components/badges/payout.badge';
import StatusBadge from 'components/badges/status.badge';
import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import { CustomerInterface } from 'interfaces/rebates.interface';
import OrderNumber from 'pages/users/funnels/funnel-customers/order-number';
import { setPayoutModal } from 'pages/users/funnels/funnel-customers/payout/payout-modal.slice';
import { useAppDispatch } from 'redux/hooks';

function customersTableData(): TableColumnRowInterface[] {
  const dispatch = useAppDispatch();

  const setModalShow = (open, customer) => {
    dispatch(setPayoutModal({ isShowing: open, customer }));
  };

  const columnData = [
    {
      key: 'name',
      render: (rowData: CustomerInterface) => {
        return <div>{`${rowData.firstName} ${rowData.lastName}`}</div>;
      },
    },
    'email',
    'paypalEmail',
    {
      key: 'status',
      render: (rowData: CustomerInterface) => {
        const status = rowData?.status;
        return <StatusBadge text={status} />;
      },
    },
    {
      key: 'orderNumber',
      render: (rowData: CustomerInterface) => {
        return (
          <div className="p-2">
            <OrderNumber customer={rowData} onClick={() => setModalShow(true, rowData)} />
          </div>
        );
      },
    },
    {
      key: 'orderStatus',
      render: (rowData: CustomerInterface) => {
        const status = rowData?.orderStatus;
        return <OrderBadge status={status} />;
      },
    },
    {
      key: 'payoutStatus',
      render: (rowData: CustomerInterface) => {
        const { payoutStatus } = rowData;
        return <PayoutBadge payout={payoutStatus} />;
      },
    },
  ];

  return tableColumns(columnData);
}

export default customersTableData;
