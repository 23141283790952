import { MagicLinksInterface } from 'interfaces/magic-links.interface';
import { setMagicLinks } from 'pages/users/magic-links/magic-link.slice';
import { store } from 'redux/store';
import { postFetch, postSilentFetch } from 'request/request';
import { ResponseListInterface } from 'request/response.interface';

export const loadLinks = async (values): Promise<ResponseListInterface<MagicLinksInterface>> => {
  const res = await postSilentFetch('/magic-links/page/', values);
  if (res) {
    store.dispatch(setMagicLinks(res));
  }
  return res;
};

export const createLink = async (values): Promise<boolean> => {
  return postFetch('/magic-links/', values, 'Link Created Successfully');
};
